<template >
    <ModalBack :closeAction="hideModal">
        <div class="dep_preciation_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">계정과목관리</div>
            
            <CommonAddBtn v-if="isAllowedForInsert" title="계정과목등록" style="margin-top:10px;" :clickAction="() => {curMode = MODE.INSERT; isShowAccountRegister = true}"></CommonAddBtn>
            <div style="height:40px;"></div>
            <div class="line"></div>
            <div style="flex:1; overflow:hidden; display:flex; flex-direction:column;">
                
                <BatchBtns 
                    :show-column-setting-action="() => {isShowColumnSettings = true}" 
                    :downloadExcel="downloadExcel"
                    :isShowExcelDownload="isAllowedForExcel"
                    ></BatchBtns>
                <div style="flex:1; overflow:hidden">
                    <CommonTable :plist="list" :pcolumn="column" :pcustom="custom" 
                        :clickDoubleRow="showAccountRegister"
                   
                    ></CommonTable>
                </div>
                <ColumnSetting v-if="isShowColumnSettings" 
                    :vue-name="$options.name" 
                    :default-settings="column" 
                    :closeSettings="() => {isShowColumnSettings = false}" 
                    :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
                </ColumnSetting>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">닫기</button>
            </div>

            <AccountRegister v-if="isShowAccountRegister" :hideModal="() => {isShowAccountRegister = false}" :curMode="curMode" :completeFunc="getAccounts" :transferData="transferData"></AccountRegister>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ConditionManager from '@/modules/ConditionManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import AccountRegister from '@/components/modal/AccountRegister.vue';
import {MODE, FONT_AWESOME, AUTH} from '@/util/Constants.js';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';

let showAccountRegister;
let deleteAccount;
const rightBtns = {
    template : `
        <div style="display:flex;">
            <div v-if="isAllowedForUpdate" @click.stop="modifyAccount">
                <font-awesome-icon :icon="FONT_AWESOME.PEN" :isHoverColorApply="true" style="font-size:15px; color:#9b9cb0;" />
            </div>
            <div style="width:10px;"></div>
            <div v-if="isAllowedForDelete" @click.stop="deleteAccount">
                <font-awesome-icon icon="fa-regular fa-trash-can" :isHoverColorApply="true" class="icon" style="color:#9b9cb0; " />
            </div>
        </div>`,
    props : ['index', 'data', 'column'],
    data(){
        return {
            FONT_AWESOME :FONT_AWESOME
        }
    },
    computed : {
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.DELETE)
        },
    },
    methods : {
        deleteAccount(){
            deleteAccount(this.data);  
        },
        modifyAccount(){
            showAccountRegister(this.data);
        },
    }
}

const wayText = {
    template : `<div>{{this.data.depreciation == '0' ? '정액법' : '정률법'}}</div>`,
    props : ['index', 'data', 'column']
}

export default {
    name : 'DepreciationRegister',
    props : {
        hideModal : Function
    },
    components : {
        ModalBack, CommonModalCloseBtn,
        CommonAddBtn, ColumnSetting, CommonTable, BatchBtns, AccountRegister
    },
    data(){
        return {
            MODE : MODE,
            isShowAccountRegister : false,
            column: [
                { key: "accountCode", name: "계정코드", w: "120px"},
                { key: "accountName", name: "계정명", w: "100px"},
                { key: "depreciation", name: "감가상각법", w: "100px" },
                { key: "durableYear", name: "내용연수", w: "100px" },
                { key: "column5", name: "", w: "100px" },
            ],
            list : [],
            curMode : MODE.INSERT,
            custom:{
                column5 : rightBtns,
                depreciation : wayText
            },
            transferData : {},
            isShowColumnSettings : false,
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.EXCEL)
        },
        isAllowedForSettingSelect(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각_환경설정, AUTH.SELECT)
        },
    },
    methods : {
        getAccounts(){
            const parameters = this.getParameters();

            axios.get('/api/account/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    this.list = res.data.data.accounts;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.conditions = "np_assetCode";
            parameters.searchWords = this.assetCode; 
            parameters.deleted = 0; 
            parameters.page = 1;
            parameters.sizePerPage = 1000;
            return parameters;
        },
        showAccountRegister(data){
            if(!this.isAllowedForUpdate) return;
            this.curMode = MODE.UPDATE;
            this.transferData = data;
            this.isShowAccountRegister = true;
        },
        deleteAccount(data){
            if(!confirm('[' + data.accountName + ']을(를) 삭제하시겠습니까?')){
                return;
            }

            const parameters = {}
            parameters.accountIdx = data.accountIdx;

            axios.post('/api/account/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.getAccounts();
                }else if(res.data.code == ResCode.Fail){
                    alert(res.data.message);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        downloadExcel(){
            let queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/account/list/excel?" + queryStr);
            location.href = this.$versionPath + "/api/account/list/excel?" + queryStr
        },
    },
    created(){
        showAccountRegister = this.showAccountRegister;
        deleteAccount = this.deleteAccount;
        this.getAccounts();
        ConditionManager.setConditions(this.$options.name, this.conditions);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
.dep_preciation_register_wrap{
    background-color:white; width:750px; height:90%;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
.line{height:1px; background-color:#e6e7ec;}
.asset_bottom_btns{text-align:right;padding-top: 10px;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>