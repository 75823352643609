<template>
    <TableSearchPanel :is-show-right-panel="true" :is-show-search-panel="true" :is-show-table-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="감가상각관리">
                <CommonAddBtn title="계정과목관리" :isShowPlus="false" :clickAction="() => {isShowDepRegister = true}" :style="{marginRight: (isAllowedForSettingSelect ? '150px' : '')}"></CommonAddBtn>
                <CommonAddBtn v-if="isAllowedForSettingSelect" title="감가상각 환경설정" :clickAction="() => {isShowDepreSetting = true}" :isShowPlus="false" ></CommonAddBtn>
            </PageHeader>
        </template>
        <template v-slot:search_panel>
            <div style="display:flex;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label>재산구분</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="itemName" :clickInput="(data) => {isShowItemSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>위치</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="locName" :clickInput="(data) => {isShowLocSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>부서</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="groupName" :clickInput="(data) => {isShowGroupSearch = true;}"></CommonInput> 
                    </template>
                </LabelPanel>
            </div>
            <div style="display:flex;margin-top:12px;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label>사용상태</template>
                    <template v-slot:content>
                        <CommonSelect :list="states" :detectChange="detectStateChange"></CommonSelect>
                    </template>
                </LabelPanel>
                 <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>
                        <div style=" position: absolute;top: -5px;left: -2px;"><CommonCheckbox :flag="isBuyDateChecked" :click-check="toggleBuyDateCheck" color="#e6e7ec"></CommonCheckbox></div><span style="padding:13px;"></span>취득일자
                    </template>
                    <template v-slot:content>
                        <CommonInput :click-input="showCalendar" v-model="buyDate"></CommonInput>
                        <div v-if="isShowCalendar" ref="periodWrap" style="position:fixed; z-index:2; background-color:white; border:1px solid #e6e7ec; border-radius:4px; ">
                            <PeriodCalendar :complete-callback="completSelectDate" :init-start-date="startDate" :init-end-date="endDate" :cancel-callback="hideCalendar"></PeriodCalendar>
                        </div>
                    </template>
                </LabelPanel>
                <LabelPanel style="width:233px; margin-left:19px;">
                    <template v-slot:label>검색어</template>
                    <template v-slot:content>
                        <ConditionInput v-model="searchWord" :searchList="conditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:5px;">
                    <template v-slot:label>&nbsp;</template>
                    <template v-slot:content>
                        <button class="search_btn" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
                    </template>
                </LabelPanel>
            </div>
        </template>
         <template v-slot:table_panel>
            <BatchBtns 
                :isShowSaveBtn="true" 
                :isShowDepreciationDo="true" 
                :is-show-first-checkbox="true" 
                :isShowSelectAllBtn="true" 
                :isShowModifyBtn="isAllowedForUpdate" 
                :modifyAction="() => {if(!isAllowedForUpdate) return;showDepreciationBatchRegister()}"
                :delete-action="deleteList" 
                :searchCnt="searchCnt"
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :downloadExcel="downloadExcel"
                :isShowExcelDownload="isAllowedForExcel"
                ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable :plist="list" 
                    :pcolumn="column" 
                    :pcustom="custom" 
                    :paging="paging"
                    :change-page="detectChangePage"
                    :is-show-checkbox="true"
                    :isShowNumbering="true"
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="assetCode"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    :isGoTopWhenRefresh="isGoTopWhenRefresh"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>

         </template>
        <template v-slot:etc>
            <DepreciationRegister v-if="isShowDepRegister" :hideModal="hideDepRegister"></DepreciationRegister>
            <LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {locName = (data.text !== '위치코드' ?  data.text : ''); locCode = data.value}"></LocSearch>
            <GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {groupName = (data.text !== '부서코드' ?  data.text : ''); groupCode = data.value}"></GroupSearch>
            <ItemSearch v-if="isShowItemSearch" :hideModal="() => {isShowItemSearch = false}" :completeSave="(data) => {itemName =  (data.text !== '재산구분코드' ? data.text : ''); itemCode = data.value}"></ItemSearch>
            <DepreciationSetting v-if="isShowDepreSetting" :hideModal="() => {isShowDepreSetting = false}" ></DepreciationSetting>
            <DepreciationBatchRegister v-if="isShowDepreciationBatchRegister" :hideModal="() => {isShowDepreciationBatchRegister = false}" :transferData="transferData" :completeFunc="completeFuncBatchSaved" ></DepreciationBatchRegister>
        </template>
    </TableSearchPanel>
</template>

<script>

import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import PeriodCalendar from '@/components/common/PeriodCalendar.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import Calendar from '@/components/common/Calendar.vue';
import DepreciationRegister from '@/components/modal/DepreciationRegister.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import ItemSearch from '@/components/modal/ItemSearch.vue';
import DepreciationSetting from '@/components/modal/DepreciationSetting.vue';
import DepreciationBatchRegister from '@/components/modal/DepreciationBatchRegister.vue';

import ConditionInput from '@/components/common/ConditionInput.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import moment from 'moment';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import { states, AUTH, NUMBER_STYLE, SIZE_PER_PAGE} from '@/util/Constants.js';

let save;
let accounts = [];

const menu = {
    template : `
        <div style="position:relative;height:100%;">
            <div @click="clickText" style="cursor:pointer;height:100%; display:flex; width:100%;justify-content:center; align-items:center; color:#0066fd; text-align:center;">{{data.depreNotUsed === 'True' ? 'X' : 'O'}}</div>
            <input type='text' style="width:0px; height:0px; border:0px; appearance:none; outline:none;" ref='hiddenText' @blur="blurred">
            <div v-if="isShow" style="position:absolute;top:47px;width:145px; left:0px;background-color:white;border:1px solid #e6e7ec;border-radius:5px; z-index:10;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;">
                <div style="padding:18px 30px; color:#494a4f;cursor:pointer;" @click.stop="setDepre('False')">
                    <span style="margin-left:10px;">상각</span>
                </div>
                <div style="padding:18px 30px;color:#494a4f; border-top:1px solid #e6e7ec; cursor:pointer;" @click.stop="setDepre('True')">
                    <span style="margin-left:10px;">안함</span>
                </div>
            </div>
        </div>
    `,
    props : ['data', 'column', 'index'],
    computed : {
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
    },
    data(){
        return {
            isShow : false,
        }
    },
    methods : {
        clickText(){
            if(!this.isAllowedForUpdate) return;
            this.$refs['hiddenText'].focus();
            this.isShow = !this.isShow;
        },
        blurred(){
            setTimeout(() => {
                this.isShow = false;
            }, 100)
        },
        setDepre(value){
            this.data.depreNotUsed = value;
            save(this.data);
        }
    }
}

const accountsBtns = {
    template : `
        <div style="position:relative;height:100%;">
            <div @click="clickText($event)" style="height:100%; display:flex; width:100%;justify-content:center; align-items:center; color:#0066fd; text-align:center;">{{data.accountName}}</div>
            <input type='text' style="width:0px; height:0px; border:0px; appearance:none; outline:none;" ref='hiddenText' @blur="blurred">
            <div v-if="isShow" style="position:fixed;;width:145px;;background-color:white;border:1px solid #e6e7ec;border-radius:5px; z-index:10;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;" :style="{top : top, left : left}">
                <div v-for="each, i in accounts" style="padding:18px 30px; color:#494a4f;cursor:pointer;" @click="setAccount(each)">{{each.accountName}}</div>
            </div>
        </div>
    `,
    props : ['data', 'column', 'index'],
    computed : {
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
    },
    data(){
        return {
            isShow : false,
            accounts : accounts
        }
    },
    methods : {
        clickText(e){
            if(!this.isAllowedForUpdate) return;
            const rect = e.currentTarget.getBoundingClientRect();
            this.left = rect.x + 'px';
            console.log(rect);
            const docHeight = document.body.clientHeight;
            const selectorHeight = 53 * this.accounts.length;
            if((docHeight - rect.bottom) > selectorHeight){
                this.top = (rect.y + 47) + 'px';
            }else {
                this.top = (rect.y - selectorHeight)  + 'PX';
            }
            this.$refs['hiddenText'].focus();
            this.isShow = !this.isShow;
        },
        blurred(){
            setTimeout(() => {
                this.isShow = false;
            }, 100)
        },
        setAccount(data){
            this.data.accountIdx = data.accountIdx;
            this.data.durableYear = data.durableYear;
            if(this.data.buyPrice == null) this.data.buyPrice = 0;
            if(this.data.scrapPrice == null) this.data.scrapPrice = 0;
            save(this.data);
        }
    }
}

const buyPrice = {
    template : `
        <div style="position:relative;height:100%;">
            <input type="text" style="width: 100%;height: 100%;text-align:right; border:0px; font-size:16px; color:#0066fd;; background-color:transparent" 
            v-bind:value="$commonFunc.comma($commonFunc.uncomma(data.buyPrice))" @input="data.buyPrice = $event.target.value"
            @click="select($event)" @keyup="keyup($event)" @focus="focus" @blur="blur">
        </div>
    `,
    props : ['data', 'column', 'index'],
    computed : {
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
    },
    data(){
        return {
            isShow : false,
            prevBuyPrice : 0,
            isEsc : false,
        }
    },
    methods : {
        blur(){
            if(this.isEsc){
                this.isEsc = false;
                this.$set(this.data, 'buyPrice', this.prevBuyPrice);
                return;
            } 

            if(this.prevBuyPrice == this.data.buyPrice) return;
            if(!this.isAllowedForUpdate) return;
            save(this.data);
        },
        focus(){
            this.prevBuyPrice = this.data.buyPrice;
        },
        keyup(e){
            if(e.keyCode === 27){
                this.isEsc = true;
                e.currentTarget.blur();
            }else if(e.keyCode === 13){
                e.currentTarget.blur();
            }
        },
        select(e){
            e.currentTarget.select();
        },
    },
}

const scrapPrice = {
    template : `
        <div style="position:relative;height:100%;">
            <input type="text" style="width: 100%;height: 100%;text-align:right; border:0px; font-size:16px; color:#0066fd;; background-color:transparent" 
            v-bind:value="$commonFunc.comma($commonFunc.uncomma(data.scrapPrice))" @input="data.scrapPrice = $event.target.value"
            @click="select($event)" @keyup="keyup($event)" @focus="focus" @blur="blur">
        </div>
    `,
    props : ['data', 'column', 'index'],
    computed : {
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
    },
    data(){
        return {
            isShow : false,
            prevScrapPrice : 0,
            isEsc : false,
        }
    },
    methods : {
        blur(){
            if(this.isEsc){
                this.isEsc = false;
                this.$set(this.data, 'scrapPrice', this.prevScrapPrice);
                return;
            } 

            if(this.prevScrapPrice == this.data.scrapPrice) return;
            if(!this.isAllowedForUpdate) return;
            save(this.data);
        },
        focus(){
            this.prevScrapPrice = this.data.scrapPrice;
        },
        keyup(e){
            if(e.keyCode === 27){
                this.isEsc = true;
                e.currentTarget.blur();
            }else if(e.keyCode === 13){
                e.currentTarget.blur();
            }
        },
        select(e){
            e.currentTarget.select();
        },
    },
}

const calendarInput = {
    template : `
        <div style="position:relative;height:100%;">
            <input type="text" style="width: 100%;height: 100%; border:0px; font-size:16px;color:#0066fd; background-color:transparent" v-model="data.buyDate" @click="select($event)">
            <div v-if="isShowCalendar" style="position:fixed; left:0px; top:0px; width:100vw; height:100vw" @click="closeCalendar"></div>
            <div v-if="isShowCalendar" style="position:fixed;background-color:white;border:1px solid #e6e7ec;border-radius:5px; z-index:10;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;" :style="{top : top}">
                <Calendar :select-date-callback="selectDateFunc" :startDate="data.column7"></Calendar>
            </div>
        </div>
    `,
    props : ['data', 'column', 'index'],
    computed : {
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
    },
    components : {
        Calendar,
    },
    data(){
        return {
            isShowCalendar : false,
            top : '0px'
        }
    },
    methods : {
        select(e){
            if(!this.isAllowedForUpdate) return;
            e.currentTarget.select();
            const rect = e.currentTarget.getBoundingClientRect();
            this.top = (rect.y + 47) + 'px';
            this.isShowCalendar = true;
        },
        selectDateFunc(selectedDate){
            const prevDateTime = this.data.buyDate;

            this.$set(this.data, 'buyDate', selectedDate)
            this.isShowCalendar = false;

            if(this.data.buyDate){
                if(prevDateTime){
                    this.data.buyDate = selectedDate + ' ' + prevDateTime.substr(11, 8);
                }else {
                    this.data.buyDate = selectedDate + ' ' + moment().format('HH:mm:ss'); 
                }
                
                save(this.data);
            }
        },
        closeCalendar(){
            this.isShowCalendar = false;
        }
    }
}

const depreRate = {
    template : `<div style='text-align:right;'>{{data.depreciation === '0' ? $commonFunc.comma(data.depreRate) : data.depreRate}}</div>`,
    props : ['data', 'column', 'index'],
}

const depreciation = {
    template : `<div>{{!data.depreciation ? '' : (data.depreciation == '0' ? '정액법' : '정률법')}}</div>`,
    props : ['data', 'column', 'index']
}


export default {
    name: 'DepreciationMng',
    components : {
        PageHeader, TableSearchPanel, CommonTable, ColumnSetting, BatchBtns, LabelPanel, CommonInput, CommonSelect, PeriodCalendar, 
        CommonCheckbox, CommonAddBtn, DepreciationRegister, LocSearch, ItemSearch, GroupSearch, ConditionInput, DepreciationSetting,
        DepreciationBatchRegister
    },
    data(){
        return {
            itemName : '',
            itemCode : '',
            groupName : '',
            groupCode : '',
            locName : '',
            locCode : '',
            isShowColumnSettings : false,
            isShowDepreSetting : false,
            startDate : '',
            endDate : '',
            buyDate : '',
            isShowCalendar : false,
            isBuyDateChecked : false,
            isShowDepRegister : false,
            isShowLocSearch : false,
            isShowGroupSearch : false,
            isShowItemSearch : false,
            isGoTopWhenRefresh : true,
            isShowDepreciationBatchRegister : false,
            searchWord : '',
            stateCode : '',
            searchCnt : 0,
            lastPage : 1,
            lastSizePerPage: SIZE_PER_PAGE,
            lastWithoutSearchWord : '',
            transferData : {},
            savedSelectedList : {},
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
                isNoDepreciationHidden : false,
            },
            list: [],
            custom : {
                depreNotUsed : menu,
                buyDate : calendarInput,
                buyPrice : buyPrice,
                scrapPrice : scrapPrice,
                depreciation : depreciation,
                accountName : accountsBtns,
                depreRate : depreRate,
            },
            column: [
                { key: "depreNotUsed", name: "상각여부", w: "80px"},
                { key: "itemName", name: "재산구분", w: "100px"},
                { key: "assetName", name: "자산명칭", w: "150px" },
                { key: "standard", name: "규격", w: "100px" },
                { key: "groupName", name: "부서", w: "100px" },
                { key: "locName", name: "위치", w: "100px" },
                { key: "buyDate", name: "취득일자", w: "100px", dateLength10 : true},
                { key: "buyPrice", name: "취득가액", w: "100px", comma : true },
                { key: "accountName", name: "계정과목", w: "100px" },
                { key: "depreciation", name: "감가상각법", w: "100px" },
                { key: "durableYear", name: "내용연수", w: "100px" },
                { key: "scrapPrice", name: "잔존가액", w: "100px", comma : true },
                { key: "depreRate", name: "상각비/상각율", w: "100px" },
                //  { key: "column14", name: "기타", w: "100px" },
            ],
            paging : {},
            states : states,
            conditions: [
                {name : '자산코드', key : 'assetCode', checked : true},
                {name : '자산명칭', key : 'assetName', checked : true},
                {name : '모델명', key : 'modelName', checked : true},
                {name : '시리얼', key : 'serial', checked : true},
                {name : '규격', key : 'standard', checked : true},
                {name : '취득가액/자존가액', key : 'np_price', checked : true},
                {name : '담당자/사용자', key : 'userName', checked : true},
                {name : '구매처', key : 'selling', checked : true},
                {name : '바코드', key : 'barcode', checked : true},
                {name : '추가항목1', key : 'field1', checked : true},
                {name : '추가항목2', key : 'field2', checked : true},
                {name : '추가항목3', key : 'field3', checked : true},
                {name : '추가항목4', key : 'field4', checked : true},
                {name : '추가항목5', key : 'field5', checked : true},
                {name : '추가항목6', key : 'field6', checked : true},
                {name : '추가항목7', key : 'field7', checked : true},
                {name : '추가항목8', key : 'field8', checked : true},
            ]
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.EXCEL)
        },
        isAllowedForSettingSelect(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각_환경설정, AUTH.SELECT)
        },
        isAllowedForSettingInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각_환경설정, AUTH.INSERT)
        },
    },
    methods : {
        showCalendar(){
            this.isShowCalendar = true;
        },
        hideCalendar(){
            this.isShowCalendar = false;
        },
        completSelectDate(startDate, endDate){
            this.isBuyDateChecked = true;
            this.startDate = startDate;
            this.endDate = endDate;
            this.buyDate = startDate + ' ~ ' + endDate;
            this.hideCalendar();
        },
        toggleBuyDateCheck(){
            this.isBuyDateChecked = !this.isBuyDateChecked;
        },
        deleteList(){

        },
        detectChangePage(page){
            this.getDepreciation(page, this.lastWithoutSearchWord);
        },
        hideDepRegister(){
            this.isShowDepRegister = false;
        },
        detectStateChange(data){
            this.stateCode = data;
        },
        search(){
             this.getDepreciation(1, this.lastWithoutSearchWord);
        },
        lastSearch(){
            this.getDepreciation(this.lastPage, this.lastWithoutSearchWord, false);
        },
        enter(){
            this.search();
        },
        getAccounts(){
            const parameters = {};
            axios.get('/api/account/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    // const accounts = res.data.data.accounts;
                    accounts = res.data.data.accounts
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getDepreciation(page, withoutSearchWord, isGoTopWhenRefresh = true){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            
            const parameters = this.getParameters();
            axios.get('/api/depreciation/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const depreciations = data.depreciations.content;
                    this.searchCnt = data.depreciations.totalElements;
                    data.depreciations.content = null;
                    this.list = depreciations;
                    this.paging = data.depreciations;   

                    if(!isGoTopWhenRefresh){
                        this.isGoTopWhenRefresh = false;
                        this.$nextTick(() => {
                            this.isGoTopWhenRefresh = true;
                        })
                    }
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;
            parameters.deleted = 0;

            let conditions = [];
            let searchWords = [];

            if(this.stateCode !== ''){
                conditions.push('stateCode');
                searchWords.push(this.stateCode);
            }

            if(this.locName != ''){
                conditions.push('np_locName');
                searchWords.push(this.locName);
            }

            if(this.itemName != ''){
                conditions.push('np_itemName');
                searchWords.push(this.itemName);
            }

            if(this.groupName != ''){
                conditions.push('np_groupName');
                searchWords.push(this.groupName);
            }

            if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.conditions, this.searchWord);
            }

            if(this.btnState.isNoDepreciationHidden){
                parameters.depreNotUsed = 'true';
            }
            

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            if(this.isBuyDateChecked){
                parameters.searchBeginDate = this.startDate;
                parameters.searchEndDate = this.endDate;
            }

            return parameters;
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/depreciation/list/excel?" + queryStr);
            location.href = this.$versionPath + "/api/depreciation/list/excel?" + queryStr
        },
        save(data){
            const parameters = {};
            parameters.assetCodes = [data.assetCode];
            parameters.isAll = false;
            parameters.accountIdx = data.accountIdx;
            parameters.durableYear = data.durableYear;
            parameters.buyDate = data.buyDate;
            parameters.buyPrice = this.$commonFunc.uncomma(data.buyPrice);
            parameters.scrapPrice = this.$commonFunc.uncomma(data.scrapPrice);
            parameters.depreNotUsed = data.depreNotUsed;
        
            axios.post('/api/depreciation/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.lastSearch();
                }
            }).catch(res => {
                console.log(res);
            });
        },
        showDepreciationBatchRegister(){
            this.transferData = {
                savedSelectedList : this.savedSelectedList,
                isSelectAll : this.btnState.isSelectAll,
                queryParameters : this.getParameters()
            } 
            this.isShowDepreciationBatchRegister = true;
        },
        completeFuncBatchSaved(){
            this.savedSelectedList = {};
            this.lastSearch();
        }
    },
    created(){
        save = this.save;
        const today = moment().format('YYYY-MM-DD');
        this.startDate = today;
        this.endDate = today;
        this.buyDate = today + ' ~ ' + today;

        this.getAccounts();
        this.getDepreciation(1, false);
        this.$commonFunc.setFieldNames(this.conditions);
        ConditionManager.setConditions(this.$options.name, this.conditions);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>

<style scoped>
    .search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>
