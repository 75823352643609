<template>
  <div style="height: 100%;">
     <router-view></router-view>
  </div>
 
</template>

<script>

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style scoped>

</style>
