<template >
    <div class="filter_radio_btn">
        <input type="radio" :id="name + initVal" :name="name" :value="initVal" :checked="value == initVal" @change="($event) => $emit('input', $event.target.value)" :disabled="isReadonly"/>
        <label :for="name + initVal">
            <div class="filter_radio_btn_wrap">
                <div class="filter_radio_round" :style="{backgroundColor : isReadonly ? 'gray' : 'black'}"></div>
            </div>
            <span v-if="label" style="margin-left:5px;">{{label}}</span>
        </label>
    </div>
</template>
<script>
export default {
    name : 'CommonRadio',
    props: {
        name : {
            type : String,
            default : ''
        },
        value : {
            type : String,
            default : ''
        },
        initVal : {
            type : String,
            default : ''
        },
        label : {
            type : String,
            default : ''
        },
        isReadonly : {
            type : Boolean,
            default : false
        }
    }
}
</script>
<style scoped>
    .filter_radio_btn input[type=radio]:checked + label .filter_radio_round{
        content : '';
        width:100%;
        height:100%;
  
        display:inline-block;
        position:absolute;
        left:0px; top:0px;
        border-radius:50%;
        transform: scale(0.75);
    }
    .filter_radio_btn input[type=radio]{display:none;}
    .filter_radio_btn label{display:inline-block; cursor:pointer;}
    .filter_radio_btn_wrap{display:inline-block; width:16px; height:16px; border-radius:8px; border:1px solid gray;position:relative; vertical-align: middle; }
    .filter_radio_btn{ display:inline-block;}
    .filter_radio_btn span{vertical-align: middle;}
    .filter_radio_btn + .filter_radio_btn{ margin-left:7px;}
</style>