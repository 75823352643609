<template >
    <ModalBack :closeAction="hideModal">
        <div class="company_register_wrap">
        <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
        <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >
            <template v-if="curMode === MODE.INSERT || curMode === MODE.COMPLETE">자산대여</template>
            <template v-if="curMode === MODE.UPDATE">대여수정</template>
        </div>
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                    <col>
                </colgroup>
                <template v-if="!(curMode === MODE.COMPLETE)">
                    <tr>
                        <td>
                            <div class="label required">대여일자</div>
                            <CalendarInput v-model="form.rentalDate" :isReadonly="curMode === MODE.COMPLETE"></CalendarInput>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">대여자</div>
                            <div class="row_flex">
                                <CommonInput style="flex:1;" v-model="form.rentalName" :isReadonly="curMode === MODE.COMPLETE" :clickInput="() => {isShowUserSearch = true}"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowUserSearch = true}"></MaginifyingGlassButton>
                            </div>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">대여업체</div>
                            <div class="row_flex">
                                <CommonInput style="flex:1;" v-model="form.rentalCompany" :isReadonly="curMode === MODE.COMPLETE" :clickInput="() => {isShowCompanySearch = true}"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowCompanySearch = true}"></MaginifyingGlassButton>
                            </div>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">대여내용</div>
                            <CommonTextarea height="90px" v-model="form.rentalMemo" :isReadonly="curMode === MODE.COMPLETE"></CommonTextarea>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                </template>
                <template v-if="!(curMode === MODE.INSERT || (curMode === MODE.UPDATE && !isAfterRental))">
                    <tr>
                        <td>
                            <div class="label required">반납일자</div>
                            <CalendarInput v-model="form.returnDate" :isReadonly="curMode === MODE.INSERT || (curMode === MODE.UPDATE && !isAfterRental)"></CalendarInput>
                        </td>
                    </tr>
                </template>
                
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
            <button class="asset_bottom_btn_ok" @click="save">{{curMode === MODE.INSERT ? '요청' : (curMode === MODE.UPDATE ? '수정' : '완료')}}</button>
        </div>

        <UserSearch v-if="isShowUserSearch" :hideModal="() => {isShowUserSearch = false}" :completeSave="(data) => {this.form.rentalName = data.userName;}"></UserSearch>
        <CompanySearch v-if="isShowCompanySearch" :hideModal="() => {isShowCompanySearch = false}" :completeSave="(data) => {this.form.rentalCompany = data.companyName;}"></CompanySearch>
    </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonTextarea from '@/components/common/CommonTextarea.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import UserSearch from '@/components/modal/UserSearch.vue';
import CompanySearch from '@/components/modal/CompanySearch.vue';
import axios from 'axios';
import moment from 'moment';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';

export default {
    name : 'AssetRepairRegister',
    props : {
        hideModal : Function,
        data : Object,
        curMode : Number,
        completeAction : {
            type : Function,
            default : () => {}
        },
        assetCode : String
    },
    components : {
        CommonInput,
        MaginifyingGlassButton,
        ModalBack,
        CommonModalCloseBtn,
        CalendarInput,
        CommonTextarea,
        UserSearch,
        CompanySearch
    },
    data(){
        return {
            MODE : MODE,
            isAfterRental : false,
            isShowCompanySearch : false,
            isShowUserSearch : false,
            form : {
                rentalDate : '',
                rentalName : '',
                rentalCompany : '',
                rentalMemo : '',
                returnDate : '',
            }
        }
    },
    methods : {
        insertRentalHistory(){
            if(this.form.rentalDate === ''){
                alert('대여일자를 입력해주세요.');
                return;
            }

            if(this.form.rentalName === ''){
                alert('대여자를 선택해주세요.');
                return;
            }

            if(this.form.rentalCompany === ''){
                alert('대여업체를 선택해주세요.');
                return;
            }

            if(this.form.rentalMemo === ''){
                alert('대여내용을 입력해주세요.');
                return;
            }

            if(!confirm('자산대여하시겠습니까?')){
                return;
            }


            const parameters = {};
            parameters.assetCode = this.assetCode;
            parameters.rentalDate = this.form.rentalDate;
            parameters.rentalName = this.form.rentalName;
            parameters.rentalCompany = this.form.rentalCompany;
            parameters.rentalMemo = this.form.rentalMemo;

            axios.post('/api/asset/rentalhistory/insert', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.$GlobalStore.refreshAssetMng();
                    this.completeAction();
                    this.hideModal();
                   
                }
            }).catch(res => {
                console.log(res);
            });
        },
        updateRentalHistory(){
            if(this.form.rentalDate === ''){
                alert('대여일자를 입력해주세요.');
                return;
            }

            if(this.form.rentalName === ''){
                alert('대여자를 선택해주세요.');
                return;
            }

            if(this.form.rentalCompany === ''){
                alert('대여업체를 선택해주세요.');
                return;
            }

            if(this.form.rentalMemo === ''){
                alert('대여내용을 입력해주세요.');
                return;
            }

            if(this.form.returnDate === ''){
                alert('반납일자를 입력해주세요.');
                return;
            }

            const parameters = {};
            parameters.rentalIdx = this.data.rentalIdx;
            parameters.assetCode = this.assetCode;
            parameters.rentalDate = this.form.rentalDate;
            parameters.rentalName = this.form.rentalName;
            parameters.rentalCompany = this.form.rentalCompany;
            parameters.rentalMemo = this.form.rentalMemo;
            parameters.returnDate = this.form.returnDate;
            parameters.isComplete = this.curMode === MODE.COMPLETE;
                        
            axios.post('/api/asset/rentalhistory/update', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.$GlobalStore.refreshAssetMng();
                    this.completeAction();
                    this.hideModal();
                   
                }
            }).catch(res => {
                console.log(res);
            });
        },
        save(){
            if(this.curMode === MODE.UPDATE || this.curMode === MODE.COMPLETE){
                this.updateRentalHistory();
            }else {
                this.insertRentalHistory();
            }
        }
    },
    created(){
        if(this.curMode === MODE.UPDATE || this.curMode === MODE.COMPLETE){
            this.form.rentalDate = this.data.rentalDate.substr(0, 10);
            this.form.rentalName = this.data.rentalName;
            this.form.rentalCompany = this.data.rentalCompany;
            this.form.rentalMemo = this.data.rentalMemo;
            this.form.returnDate = this.data.returnDate ? this.data.returnDate.substr(0, 10) : '';

            if(this.data.returnDate){
                this.isAfterRental = true;
            }

        }else if(this.curMode === MODE.INSERT) {
            this.form.rentalDate = moment().format('YYYY-MM-DD');
        }
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 400px;
        max-height: 90%;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right; padding-top: 14px;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>