<template>
  <ModalBack :closeAction="closeSettings">
    <div class="settings_wrap">
      <CommonModalCloseBtn :hideModal="closeSettings"></CommonModalCloseBtn>
      <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">표시항목선택</div>

      <div style="display:flex; flex-direction:column; height:100%; width:100%;overflow:hidden">
          <div style="flex:1;;overflow:hidden">
              <CommonTable ref="cTable" :plist="list" :pcolumn="column" :is-show-checkbox="true" :pcustom="custom" :isKeepClickedIndex="true" :isGoTopWhenRefresh="false" ></CommonTable>
          </div>
      </div>

      <div class="asset_bottom_btns">
          <button class="asset_bottom_btn_canel" @click="closeSettings">닫기</button>
          <button class="asset_bottom_btn_ok" @click="saveSettings">저장</button>
      </div>

      <div style="position:absolute; top :0px; right : 6px; width:25px; height:150px; display:flex; flex-direction:column; height:100%; justify-content:center;">
        <div class="arrow_back" @click="goTop">
          <font-awesome-icon icon="fa-solid fa-angles-up" />
        </div>
        <div class="arrow_back" @click="goUp">
          <font-awesome-icon icon="fa-solid fa-angle-up" />
        </div>
        <div class="arrow_back" @click="goDown">
          <font-awesome-icon icon="fa-solid fa-angle-down" />
        </div>
        <div class="arrow_back" @click="goBottom">
          <font-awesome-icon icon="fa-solid fa-angles-down" />
        </div>
      </div>

    </div>  
  </ModalBack>
</template>

<script>
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import _ from 'lodash'
let setListNew;

const input = {
  template : `<input type='text' :value="replaceWidth" @keyup="keyup($event)" style='width:100%; height:100%;background-color:transparent; font-size:16px;appearance:none; width:100%;border:0px;'>`,
  props : ['data', 'column', 'index'],
  data(){
    return {
    }
  },
  computed : {
    replaceWidth(){
      return this.data.w.replace('px', '');
    }
  },
  methods : {
    keyup(e){
        setListNew(this.index, e.currentTarget.value + 'px');
        // this.$set(data, 'w', '')
    },
  }
  
};

export default {
  name: "ColumnSetting",
  components : {CommonTable, ModalBack, CommonModalCloseBtn},
  props: {
    vueName : String,
    defaultSettings : Array,
    completeSave : Function,
    closeSettings : Function
  },
  data(){
    return {
        custom : {
            "w" : input
        },
        column: [
            {key : 'name', name : '항목명', w: '120px'},
            {key : 'w', name : '항목넓이', w: '100px'}
        ],
        list : []
    }
  },
  methods :{
    setListNew(index, value){
        this.$set(this.list[index], 'w', value);
    },
    saveSettings(){
      this.selectedList();
    },
    close(){

    },
    selectedList(){
      let list = this.$refs.cTable.__getSelectedList();
      const selectedListHashed = _.keyBy(list, 'key');
      for(let i = 0; i < this.list.length; i++){
        const pattern = /[0-9]+[px]{1}/gi;
        if(!pattern.test(this.list[i].w)){
          alert('항목넓이는 숫자만 입력가능합니다.');
          return;
        }

        if(selectedListHashed[this.list[i].key] != null){
          this.list[i].show = true;
        }else {
          this.list[i].show = false;
        }
      }

      ColumnManager.save(this.vueName, this.list);
      this.completeSave(this.list);
    },
    goTop(){
      const curClickedIndex = this.$refs.cTable.__getClickedIndex();

      if(curClickedIndex === -1){
        alert('항목을 선택해주세요.');
        return;
      }

      if(this.list[curClickedIndex].fixed){
        alert('고정된 열은 순서를 변경할 수 없습니다.');
        return;
      }

      //고정열 다음으로 이동.
      let nextClickedIndex = 0;
      for(const index in this.list){
        if(!this.list[index].fixed){
          nextClickedIndex = parseInt(index);
          break;
        }
      }
      this.moveTo(curClickedIndex, nextClickedIndex);
    },
    goUp(){
      const curClickedIndex = this.$refs.cTable.__getClickedIndex();

      if(curClickedIndex === -1){
        alert('항목을 선택해주세요.');
        return;
      }

      if(this.list[curClickedIndex].fixed){
        alert('고정된 열은 순서를 변경할 수 없습니다.');
        return;
      }

      if(curClickedIndex === 0) return;
      
      const nextClickedIndex = curClickedIndex - 1;

      if(this.list[nextClickedIndex] && this.list[nextClickedIndex].fixed) return;

      this.moveTo(curClickedIndex, nextClickedIndex);

    },
    goDown(){
      const curClickedIndex = this.$refs.cTable.__getClickedIndex();

      if(curClickedIndex === -1){
        alert('항목을 선택해주세요.');
        return;
      }

      if(this.list[curClickedIndex].fixed){
        alert('고정된 열은 순서를 변경할 수 없습니다.');
        return;
      }


      if(curClickedIndex === this.list.length - 1) return;

      this.moveTo(curClickedIndex, curClickedIndex + 1);

    },
    goBottom(){
      const curClickedIndex = this.$refs.cTable.__getClickedIndex();

      if(curClickedIndex === -1){
        alert('항목을 선택해주세요.');
        return;
      }

      if(this.list[curClickedIndex].fixed){
        alert('고정된 열은 순서를 변경할 수 없습니다.');
        return;
      }

      this.moveTo(curClickedIndex, this.list.length - 1);
    },
    moveTo(curClickedIndex, nextClickIndex){
      const curList = window.copyObject(this.list);
      const copiedTarget = window.copyObject(curList[curClickedIndex]);
      curList.splice(curClickedIndex, 1);
      curList.splice(nextClickIndex, 0, copiedTarget);
      this.list = curList;
      this.$refs.cTable.__setClickedIndex(nextClickIndex);
        
    
     
    }

  },
  created(){
    setListNew = this.setListNew
    const settings = ColumnManager.getSettings(this.vueName, this.defaultSettings)

    for(let i = 0; i < settings.length; i++){
      settings[i]._selected = settings[i].show;
    }
    this.list = settings;
  }
  
}
</script>

<style scoped>
.settings_wrap{
    background-color:white; width:400px; height:90%;
    max-height: 700px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
    transform: translate3d(0,0,0);
}
.asset_bottom_btns{text-align:right;padding-top: 10px;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}

.arrow_back{
  border:1px solid #e6e7ec;
  display:flex; 
  justify-content: center;
  align-items: center;;
  padding:5px;
  color:#494a4f;
  cursor:pointer;
}

.arrow_back + .arrow_back{
  margin-top:7px;
}

</style>