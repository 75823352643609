<template>
    <div style="flex: 1;display: flex; flex-direction: column;overflow: hidden;" ref="commonTreeWrap">
        <div class="tree_search_wrap" v-if="isShowSearchPanel">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" style="position:absolute; left:10px; top: 12px; font-size:18px;color:#9b9cb6"/>
            <input type="text" class="tree_search_input" v-model="searchWord" @keyup="enter($event)" ref="searchWord">
            <a href="javascript:void(0)" class="search_btn" @click="selectNextSearch"><img :src="require('@/assets/btn_down_arrow.png')"></a>
            <a href="javascript:void(0)" class="search_btn" @click="selectPrevSearch"><img :src="require('@/assets/btn_down_arrow.png')" style="transform:rotate(180deg);"></a>
            <a v-if="isUseExcelDownload" href="javascript:void(0)" class="search_btn" style="padding:5px 11px;" @click="detectExcelDownload"><font-awesome-icon icon="fa-regular fa-file-excel" :isHoverColorApply="true" style="width:16px; font-size:20px; color:#9b9cb6" /></a>
            <div style="color:#969696;padding: 13px 8px;" v-if="searchListCnt > 0">({{lastSelectedSearchListIndex + 1}}/{{searchListCnt}})</div>
        </div>
    
        <div class="tree_wrap"  @mouseup="mouseUp($event)" @mousemove="mouseMove($event)" ref="treeScrollWrap">
        <!-- <div class="tree_wrap" ref="treeScrollWrap"> -->
            <CommonTreeEach v-for="each, i in treeData" 
                :tree-data="each" :tree="tree" :key="'tree' + i" 
                :level=1 
                :module-share="moduleShare" 
                :detectSelect="detectSelect" 
                :clickDouble="clickDouble"
                :isDragWork="isDragWork"
                :isShowHoverBtns="isShowHoverBtns"
                ></CommonTreeEach>
        </div>
    </div>
</template>
<script>
import {AUTH, FONT_AWESOME} from '@/util/Constants.js';
import CommonTreeEach from '@/components/common/CommonTreeEach'

export default {
    name: "CommonTree",
    props : {
        pTreeData : Array,
        multiple : Boolean,
        isShowSearchPanel : {
            type : Boolean,
            default : true
        },
        detectSelect : {
            type : Function,
            default : () => {}
        },
        isDragWork : {
            type : Boolean,
            default : false
        },
        isFocus : {
            type : Boolean,
            default : false
        },
        isShowHoverBtns : {
            type : Boolean,
            default : false,
        },
        detectAddTree : {
            type : Function,
            default: () => {}
        },
        detectRemoveTree : {
            type : Function,
            default: () => {}
        },
        detectEditTree : {
            type : Function,
            default: () => {}
        },
        detectDragComplete : {
            type : Function,
            default: () => {}
        },
        clickDouble : {
            type : Function,
            default: () => {}
        },
        isUseSubcode : {
            type : Boolean,
            default : false,
        },
        showSubcodeRegister : {
            type : Function,
            default : () => {}
        },
        isUseExcelDownload : {
            type : Boolean,
            default : false,
        },
        detectExcelDownload : {
            type : Function,
            default : () => {}
        }
    },
    components : {
        CommonTreeEach
    },
    data(){
        return {
            tree : CommonTreeEach,
            treeData : [],
            moduleShare : null,
            prevSearchWord : '',
            searchWord : '',
            searchList : [],
            lastSelectedSearchListIndex : 0,
        }
    },
    computed : {
        searchListCnt (){
            return this.searchList.length;
        }
    },
    watch : {
        pTreeData(){
            const newTreeData = window.copyObject(this.pTreeData);
            this.treeData = this.addIndexInTreeData(newTreeData);
            this.$nextTick(() => {
                this.moduleShare.treeScrollWrap = this.$refs.treeScrollWrap;
            });
        },
    },
    methods : {
        __getSelectedData(){
            let selectedDataArr = [];
            for(let i = 0; i < this.treeData.length; i++){
                this.getSelectedDataRecursively(this.treeData[i], selectedDataArr)
            }

            if(this.multiple !== true) return selectedDataArr[0];

            return selectedDataArr;
        },
        __setSelectWithSearchWord(searchWord){
            let returnTreeData;

            const getTreeDataWithSearchWord = (treeData, searchWord) => {
                for(let i = 0; i < treeData.length; i++){
                    if(treeData[i].text === searchWord){
                        returnTreeData = treeData[i];
                    }

                    if(treeData[i].children != null && treeData[i].children.length > 0){
                        getTreeDataWithSearchWord(treeData[i].children, searchWord);
                    }
                }
            }

            getTreeDataWithSearchWord(this.treeData, searchWord);
            this.setParentActive(returnTreeData);
            this.select(returnTreeData);
        },
        getSelectedDataRecursively(pTreeData, selectedDataArr){
            if(pTreeData.selected === true){
                selectedDataArr.push({text : pTreeData.text, value : pTreeData.value})
            }
            if(pTreeData.children && pTreeData.children.length > 0){
                for(let i = 0; i < pTreeData.children.length; i++){
                    this.getSelectedDataRecursively(pTreeData.children[i], selectedDataArr);
                }
            }
        },
        mouseMove(e){
            if(!this.moduleShare.moveTarget) return;
            const target = this.moduleShare.moveTarget;
            target.style.visibility = 'hidden';
            let tempEl = document.elementFromPoint(e.clientX, e.clientY);
            target.style.visibility = 'visible';

            if(!tempEl.hasAttribute('dragt')) {
                const parent = tempEl.parentNode
                if(parent.hasAttribute('dragt')){
                    tempEl = parent;
                }else if(parent.parentNode.hasAttribute('dragt')){
                    tempEl = parent.parentNode;
                }
            }

            const treeWrapScrollTop = this.moduleShare.treeScrollWrap.scrollTop;
            target.style.transform = "translate(" + (e.clientX - this.moduleShare.startX) + "px, " + (e.clientY - this.moduleShare.startY + (treeWrapScrollTop - this.moduleShare.curScrollTop )) + "px)"

            if(!tempEl || !tempEl.hasAttribute('dragt')){
                this.$refs.commonTreeWrap.querySelectorAll('.tree_between.active, .tree_each.active').forEach((each) => {
                    each.classList.remove('active');
                });
                this.moduleShare.hoverTarget = null;
                return ;
            }else if(tempEl.hasAttribute('dragt')){
                if(tempEl.hasAttribute('drag1')){
                    this.$refs.commonTreeWrap.querySelectorAll('.tree_between.active').forEach((each) => {
                        each.classList.remove('active');
                    });
                }else if(tempEl.hasAttribute('drag2')){
                    this.$refs.commonTreeWrap.querySelectorAll('.tree_each.active').forEach((each) => {
                        each.classList.remove('active');
                    });
                }
            }

            if(tempEl.hasAttribute('dragt')){
                //타켓 아래로 이동
                if(tempEl.hasAttribute('drag1')){
                    this.moduleShare.hoverTarget = tempEl;
                    this.moduleShare.hoverTarget.classList.add('active');
                //동레벨 이동
                }else if(tempEl.hasAttribute('drag2')){
                    const hoverParentcode = parseInt(tempEl.getAttribute('data-parentcode'));
                    const hoverIdx = parseInt(tempEl.getAttribute('data-idx'));
                    const dragTargetIdx = this.moduleShare.dragTargetIdx
                
                    if(dragTargetIdx !== hoverIdx && this.moduleShare.dragTargetParentCode === hoverParentcode){
                        this.moduleShare.hoverTarget = tempEl;
                        this.moduleShare.hoverTarget.classList.add('active');
                    }
                }
            }
        },
        mouseUp(e){
            this.moduleShare.isDragging = false;
            if(this.moduleShare.moveTarget != null) {
                this.moduleShare.moveTarget.style.transform = "translate(0px, 0px)";
                this.moduleShare.moveTarget.style.zIndex = '0';
            }
            if(this.moduleShare.hoverTarget != null){
                this.$refs.commonTreeWrap.querySelectorAll('.tree_each.active, .tree_between.active').forEach((each) => {
                    each.classList.remove('active');
                });
                let idx = parseInt(this.moduleShare.hoverTarget.getAttribute('data-idx'));

                let curHoverIndex = 0;
                let targetTreeData = null;
                const recursiveFunc = (pTreeData) => {
                    if(curHoverIndex === idx) targetTreeData = pTreeData;
                    curHoverIndex++;

                    if(pTreeData.children != null && pTreeData.children.length > 0){
                        for(let j = 0; j < pTreeData.children.length; j++){
                            recursiveFunc(pTreeData.children[j])
                        }
                    }
                }

                for(let i = 0; i < this.treeData.length; i++){
                    recursiveFunc(this.treeData[i]);
                }

                let isBetween = this.moduleShare.hoverTarget.hasAttribute('drag2');

                if(this.moduleShare.isAllowedForUpdate) this.detectDragComplete(this.moduleShare.mouseDownTreeData, targetTreeData, isBetween);
            } 

            if(Math.abs(e.clientY - this.moduleShare.startY) < 6 && Math.abs(e.clientX - this.moduleShare.startX) < 6){
                let tempTarget = this.moduleShare.moveTarget;
                this.select(this.moduleShare.mouseDownTreeData, false);
                

                this.$nextTick(() => {
                    tempTarget.classList.add('active');    
                })
            }
              
            this.moduleShare.hoverTarget = null;
            this.moduleShare.moveTarget = null;
        },
        enter(e){
            if(e.keyCode === 13){

                if(this.searchWord !== ''){
                    if(this.searchWord === this.prevSearchWord){
                        this.selectNextSearch();
                    }else {
                        this.searchList = [];
                        this.lastSelectedSearchListIndex = 0;
                        this.setSearchListRecursively(this.treeData, this.searchWord);
                        this.selectSearchList(0);
                    }
                
                }
                 this.prevSearchWord = this.searchWord;
            }
        },
        setSearchListRecursively(treeData, searchWord){
            for(let i = 0; i < treeData.length; i++){
                if(treeData[i].text.indexOf(searchWord) > -1){
                    this.searchList.push(treeData[i]);
                }

                if(treeData[i].children != null && treeData[i].children.length > 0){
                    this.setSearchListRecursively(treeData[i].children, searchWord);
                }
            }
        },
        selectPrevSearch(){
            this.lastSelectedSearchListIndex--;
            if(this.lastSelectedSearchListIndex === -1) this.lastSelectedSearchListIndex = this.searchList.length - 1;
            this.selectSearchList(this.lastSelectedSearchListIndex);
        },
        selectNextSearch(){
            this.lastSelectedSearchListIndex++;
            if(this.lastSelectedSearchListIndex === this.searchList.length) this.lastSelectedSearchListIndex = 0;
            this.selectSearchList(this.lastSelectedSearchListIndex);
        },
        selectSearchList(index){
            if(this.searchList.length === 0) return
            this.lastSelectedSearchListIndex = index;

            this.setParentActive(this.searchList[index]);

            this.select(this.searchList[index]);
        },
        setParentActive(treeData){
            const activeParentRecursively = (pTreeData) => {
                if(pTreeData.parent == null) return;
                this.$set(pTreeData, 'active', true);
                this.moduleShare.foldList[pTreeData.value] = true;
                activeParentRecursively(pTreeData.parent);
            }

            activeParentRecursively(treeData)
        },
        select(pTreeData, isScrollInto = true){
            //단일 선택일때
            if(!this.moduleShare.isMultiple && this.moduleShare.prevSelectedTreeData) {
                
                this.$refs.commonTreeWrap.querySelectorAll('.tree_each.active').forEach((each) => {
                    each.classList.remove('active');
                });
                this.$set(this.moduleShare.prevSelectedTreeData, 'selected', false);
            }
            

            this.$set(pTreeData, 'selected', true)
            this.$refs.commonTreeWrap.querySelector(".tree_each[data-idx='" + pTreeData.index + "']").classList.add('active');
            this.moduleShare.prevSelectedTreeData = pTreeData;
            this.moduleShare.lastSelectedValue = pTreeData.value;
            this.detectSelect(pTreeData);
            if(isScrollInto){
                this.$nextTick(() => {
                    const activeEl = this.$refs.commonTreeWrap.querySelector('.tree_each.active');
                    if(activeEl) activeEl.scrollIntoView({block : 'center'})
                })
            }
        },
        addIndexInTreeData(treeData){
            let index = 0;

            let foldList = this.moduleShare.foldList;
            const hasOwnProperty = Object.prototype.hasOwnProperty;
            const recursiveAddIndex = (pTreeData, parent) => {
                for(let i = 0; i < pTreeData.length; i++){
                    const curTreeData = pTreeData[i];
                    curTreeData.index = index;
                    const value = curTreeData.value;
                    if(curTreeData.level === 1) curTreeData.active = false;
                    if(hasOwnProperty.call(foldList, value)) {
                        curTreeData.active = foldList[value];
                    }

                    if(this.moduleShare.lastSelectedValue === curTreeData.value) {
                        // this.moduleShare.prevSelectedTreeData = curTreeData;
                        curTreeData.selected = true;
                    }
                    if(parent) curTreeData.parent = parent;

                    index++;

                    if(pTreeData[i].children != null && pTreeData[i].children.length > 0){
                        recursiveAddIndex(pTreeData[i].children, pTreeData[i]);
                    }
                }
            }

            recursiveAddIndex(treeData);
            return treeData;
        },
    },
    mounted(){
        if(this.isFocus) setTimeout(() => {this.$refs['searchWord'].focus()}, 300)
    },
    created(){
        this.moduleShare = {
            prevSelectedTreeData : null,
            isMultiple : false,
            selectedVueEl : null,
            mouseDownTreeData : null,
            detectAddTree : this.detectAddTree,
            detectRemoveTree : this.detectRemoveTree,
            detectEditTree : this.detectEditTree,
            isDragging : false,
            isAllowedForInsert : this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.INSERT),
            isAllowedForUpdate : this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.UPDATE),
            isAllowedForDelete : this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.DELETE),
            treeScrollWrap : this.$refs.treeScrollWrap,
            lastSelectedValue : -1,
            dragTargetParentCode : -1,
            dragTargetIdx : -1,
            curScrollTop : 0,
            startX : 0,
            startY : 0,
            moveTarget : null,
            hoverTarget : null,
            foldList : {},
            isUseSubcode : this.isUseSubcode,
            showSubcodeRegister : this.showSubcodeRegister
        },

        this.moduleShare.isMultiple = (this.multiple === true);

        const constcopyTree = this.addIndexInTreeData(window.copyObject(this.pTreeData));

        // console.log(constcopyTree);

        this.originalTree = constcopyTree;
        this.treeData = constcopyTree;
    }
    
}
</script>

<style>
 .tree_sub_wrap{
     padding-left: 23px;
     position:relative;
     display:none;
 }
.tree_sub_wrap.active{
    display:block;
}
.tree_each{
    padding: 9px 10px;
    border:1px solid #e6e7ec;
    border-radius:4px;
    /* margin-top:5px; */
    background-color:#f8f7fc;
    position:relative;
    display:flex;
}

.tree_new_each{
    border:1px solid #e6e7ec;
    border-radius:4px;
    /* margin-top:5px; */
    background-color:#f8f7fc;
    position:relative;
    height:36px;
}

.tree_new_input{
    border:0px; 
    width:100%;
    height:100%;
    background-color: transparent;
    font-size:16px;
    padding:9px 17px;
}




.tree_each.active{
    background-color: #d9edf7;
    border:1px solid #cbdaf4;
}

.tree_each.group:before{
    content : '';
    position:absolute;
    top:-5px;
    width:100%;
    margin-left:-10px;
  
    height:2px;
    background-color:blue;
}

.tree_each.hovered{
    background-color:yellow;
}

.tree_icon_wrap{
    padding-right:5px; font-size:16px; margin-left:8px; cursor:default;
    overflow:hidden;text-overflow: ellipsis; white-space: nowrap;
}
.tree_icon{font-size:15px;}
.tree_plus_minus{
    /* position:absolute; */
    /* left:30px; */
        padding-right: 5px;
        padding-left:5px;
    display:inline-block;
    cursor:pointer;
 
}

.right_btns_wrap{
 display:none;
  
}
.right_btn{padding : 3px 4px 3px 3px; cursor:pointer;}
.tree_each:hover > .right_btns_wrap{
   display:flex;
}



.tree_wrap{margin-top: 4px;overflow-y: auto; overflow-x:hidden; flex:1;}

.tree_search_wrap{display:flex;margin-top:12px;position:relative;}
.tree_search_input{
    border: 1px solid #e6e7ec;
    display: inline-block;
    font-size: 16px;
    border-radius:4px;
    flex:1;
    min-width: 0px;
    padding: 6px 10px 7px 30px;
    margin:0px;
 
}

.tree_between{height:5px;display:flex;justify-content: center; align-items: center;;}
.tree_between_line{display:none; height:3px; background-color:blue;width:100%;}

.tree_between.active > .tree_between_line{display:block;}

.search_btn{
    padding: 11px 12px;
    border:1px solid #e6e7ec;
    display:flex;
    justify-content: center;
    align-items: center;;
    border-radius:4px;
    margin-left:4px;
}
.noselect { user-select: none; }
.tree_cnt{
    font-size:12px;
    color:#969696;
    margin-left:4px;
}

</style>
