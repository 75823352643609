<template>
    <div class="asset_change_wrap">
        <div class="asset_change_contents">
            <BatchBtns 
                :is-show-first-checkbox="true" 
                :is-show-delete-btn="true" 
                :delete-action="deleteList" 
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :downloadExcel="downloadExcel"
            ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :plist="list" 
                    :pcolumn="column" 
                    :pcustom="custom" 
                    :isShowCheckbox="true" 
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="repairIdx"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    :clickDoubleRow="(data) => {curMode = MODE.UPDATE; curRepairData = data;showModiForm()}"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>
        </div>
        <div class="asset_bottom_btns">
            <button class="btn_ok" @click="checkRepairing">수리요청</button>
            <!-- <button class="btn_ok" style="margin-left:7px;" @click="showModiForm">수정</button> -->
            <!-- <button class="btn_ok" style="margin-left:7px;" @click="showCompleteForm">수리완료</button> -->
            <button class="btn_canel" @click="hideAssetDetail" style="margin-left:7px;">닫기</button>
        </div>

        <AssetRepairRegister v-if="isShowAssetRepiarRegister" :hideModal="() => {isShowAssetRepiarRegister = false}" 
            :assetCode="assetCode" 
            :data="curRepairData" 
            :curMode="curMode" 
            :completeAction="() => {savedSelectedList = {};getRepairHistory();}"></AssetRepairRegister>

    </div>
</template>
<script>
import BatchBtns from '@/components/common/BatchBtns.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import AssetRepairRegister from '@/components/modal/AssetRepairRegister.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, NUMBER_STYLE} from '@/util/Constants.js';

let showCompleteForm = null;

const completeBtn = {
    template : `<div v-if="!data.repairDate" @click="complete" style="text-decoration:underline;text-underline-position: under; font-size: 16px; color:#0066fd;padding: 7px; cursor:pointer;">수리완료</div>`,
    props : ['data', 'column', 'index'],
    data(){
        return {
            isShow : false,
        }
    },
    methods : {
        complete(){
            showCompleteForm(this.data);
        },

    },
    created(){
       
    }
}


export default {
    name : 'AssetRepairHistoryPop',
    components : {
        BatchBtns, CommonTable, ColumnSetting, AssetRepairRegister
    },
    props: {
        hideAssetDetail : Function,
        transferData : {
            type : Object,
            default : () => {
                return {}
            }
        }
    },
    data(){
        return {
            MODE : MODE,
            curMode : -1,
            curRepairData : null,
            isShowColumnSettings : false,
            isShowAssetRepiarRegister : false,
            assetCode : '',
            savedSelectedList : {},
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },
            list: [],
            custom : {
                column100 : completeBtn
            },
            column: [
                { key: "requestDate", name: "요청일자", w: "110px", dateLength10 : true},
                { key: "repairCompany", name: "수리업체", w: "100px" },
                { key: "requestContents", name: "요청내용", w: "160px"},
                { key: "repairDate", name: "수리일자", w: "110px", dateLength10 : true},
                { key: "repairContents", name: "수리내역", w: "100px" },
                { key: "repairCost", name: "수리금액", w: "100px", comma: true, style : NUMBER_STYLE },
                { key: "writerName", name: "등록자", w: "60px" },
                { key: "writerGroupName", name: "등록자부서", w: "100px", show: false },
                { key: "column100", name: '', w: "75px", style : {right : '0px', backgroundColor : 'transparent', textAlign : 'right'}, fixed : true}
            ],
        }
    },
    methods : {

        showModiForm(){
            if(!this.curRepairData) return;
            this.isShowAssetRepiarRegister = true
        },
        showCompleteForm(data){
            //  if(!this.curRepairData) return;
            this.curRepairData = data;
            this.curMode = MODE.COMPLETE; 
            this.isShowAssetRepiarRegister = true
        },
        deleteList(){
            const parameters = {}; //this.getParameters();

            let deleteCnt = this.searchCnt;

            if(!this.btnState.isSelectAll){
                const keys = Object.keys(this.savedSelectedList);
                for(const index in keys){
                    if(!this.savedSelectedList[keys[index]].repairDate){
                        alert("[" + keys.length + "]개의 완료되지않은 수리내역은 삭제할 수 없습니다. 완료 후 삭제해주세요.");
                        return;
                    }
                } 
                deleteCnt = keys.length;
                parameters.repairIdxes = Object.keys(this.savedSelectedList);
            }

            if(!confirm('[' + deleteCnt + ']개의 수리내역을 삭제하시겠습니까?')){
                return;
            }

            axios.post('/api/asset/repairhistory/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.savedSelectedList = {};
                    this.getRepairHistory()
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getRepairHistory(){
            const parameters = this.getParameters();

            axios.get('/api/asset/repairhistory', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    this.list = res.data.data.assetRepairHistory.content;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.conditions = "np_assetCode";
            parameters.searchWords = this.assetCode; 
            parameters.deleted = 0; 
            parameters.page = 1;
            parameters.sizePerPage = 1000;
            return parameters;
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/asset/repairhistory/excel?" + queryStr);
            location.href = this.$versionPath + "/api/asset/repairhistory/excel?" + queryStr
        },
        checkRepairing(){
            const parameters = {};
            parameters.assetCode = this.assetCode;

            axios.get('/api/asset/repairhistory/checkrepairing', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    this.curMode = MODE.INSERT; 
                    this.isShowAssetRepiarRegister = true
                }else if(res.data.code == ResCode.Fail){
                    if(confirm(res.data.data.msg)){
                        this.curMode = MODE.INSERT; 
                        this.isShowAssetRepiarRegister = true
                    }
                }
            }).catch(res => {
                console.log(res);
            });
        }
    },
    created(){
        showCompleteForm = this.showCompleteForm;
        this.assetCode = this.transferData.data.assetCode;
        this.getRepairHistory();

        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
.asset_change_wrap{display:flex; flex-direction: column;;height:100%;overflow: hidden;}
.asset_change_contents{flex:1; display:flex; flex-direction: column;overflow: hidden;}
.asset_bottom_btns{text-align:right;border-top:1px solid #e6e7ec; padding-top:14px;}
.btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>