<template >
    <span style="height: 0px;position:relative;float:right;">
       <button class="common_add_btn" @click="clickAction">{{isShowPlus ? '+ ' : ''}}{{title}}</button>
    </span>
</template>
<script>
export default {
    name : 'CommonAddBtn',
    props : {
        title : String,
        isShowPlus : {
            type : Boolean,
            default : true
        },
        clickAction : {
            type : Function,
            default : () => {}
        }
    }
}
</script>
<style scoped>
    .common_add_btn{text-align:left; position:absolute;;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:4px; cursor:pointer;
  padding: 11px 12px;
  border:1px solid #e6e7ec;
  display:inline-block;
  margin-left:4px;
  vertical-align:bottom;
  right: 0px;
  top: -7px;
  white-space: nowrap;
}
</style>