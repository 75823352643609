<template >
    <TableSearchPanel :is-show-right-panel="true" :isShowSearchPanel="true" :is-show-top-panel="true" :is-show-table-panel="true" :isShowTablePanelAbove="true">
        <template v-slot:pageheader>
        <PageHeader title="자산현황 보고서"></PageHeader>
        </template>

        <template v-slot:search_panel>
            <div style="display:flex;">
                <LabelPanel style="width:130px; ">
                    <template v-slot:label>분류구분</template>
                    <template v-slot:content>
                        <div style="height:40px; display: flex;align-items: center;">
                            <CommonRadio name="curCodeType" v-model="curCodeType" :initVal="CODE_TYPE.LOC" label="위치"></CommonRadio>
                            <CommonRadio name="curCodeType" v-model="curCodeType" :initVal="CODE_TYPE.GROUP" label="부서"  style="margin-left:10px;"></CommonRadio>
                        </div>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>분류선택</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="codeName" :clickInput="() => {curCodeType === CODE_TYPE.GROUP ? isShowGroupSearch = true : isShowLocSearch = true}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>재산구분</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="itemName" :clickInput="() => {isShowItemSearch = true}"></CommonInput> 
                    </template>
                </LabelPanel>
            </div>
        </template>
        <template v-slot:table_panel_above>
            <div class="tab_wrap" style="margin-top:14px;">
                <div class="tab" :class="[curTab === 0 ? 'active' : '']" @click="changeTab(0)">현황표</div>
                <div class="tab" :class="[curTab === 1 ? 'active' : '']" @click="changeTab(1)">그래프</div>
            </div>
        </template>
        <template v-slot:table_panel>
            <div v-if="curTab === 0" style="flex:1; overflow:hidden; display:flex; flex-direction:column; margin-top:7px;">
                <BatchBtns 
                    :isShowColumnSetting="false"
                    :downloadExcel="downloadExcel"
                    :isShowExcelDownload="isAllowedForExcel"
                    >
                </BatchBtns>
                <div style="flex:1; overflow:hidden">
                    <CommonTable :plist="list" :pcolumn="column" ></CommonTable>
                </div>
            </div>
            <div v-if="curTab === 1 && !graphToggle" :key="new Date().getTime()" style="flex:1; overflow:hidden; display:flex; flex-direction:column; margin-top:7px;">
                <div style="height:100%;" :style="{display : isLoaded ? 'flex' : ''}">
                    <!-- <Bar
                        :chart-options="chartOptions"
                        :chart-data="chartData"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                    /> -->
                    <BarChart :chartData="chartData" :chartOption="chartOptions" style="width:100%;" ></BarChart>
                </div>
                
            </div>
        </template>

        <template v-slot:etc>
            <LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {setCodeData(data)}"></LocSearch>
            <GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {setCodeData(data)}"></GroupSearch>
            <ItemSearch v-if="isShowItemSearch" :hideModal="() => {isShowItemSearch = false}" :completeSave="(data) => {setItemData(data)}"></ItemSearch>
        </template>

    </TableSearchPanel>
</template>
<script>
import PageHeader from '@/components/common/PageHeader.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonRadio from '@/components/common/CommonRadio.vue';
import BarChart from '@/components/common/BarChart.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import ItemSearch from '@/components/modal/ItemSearch.vue';

// import { Bar } from 'vue-chartjs/legacy'
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Plugin
// } from 'chart.js'
// import zoomPlugin from 'chartjs-plugin-zoom';
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, zoomPlugin)

import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import { CODE_TYPE, AUTH} from '@/util/Constants.js';
import _ from 'lodash'


export default {
    name : 'AssetReport',
    components : {
        PageHeader, TableSearchPanel, CommonTable, LabelPanel, CommonInput, CommonRadio, LocSearch, GroupSearch, ItemSearch,
        BarChart, BatchBtns
        //Bar
    },
    props: {
        // chartId: {
        //     type: String,
        //     default: 'bar-chart'
        // },
        // datasetIdKey: {
        //     type: String,
        //     default: 'label'
        // },
        // width: {
        //     type: Number,
        //     default: 400
        // },
        // height: {
        //     type: Number,
        //     default: 400
        // },
        // cssClasses: {
        //     default: '',
        //     type: String
        // },
        // styles: {
        //     type: Object,
        //     default: () => {}
        // },
        // plugins: {
        //     type: Object,
        //     default: () => {}
        // }
    },
    computed : {
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.자산현황보고서, AUTH.EXCEL);
        },
    },
    data(){
        return {
            CODE_TYPE : CODE_TYPE,
            curCodeType : CODE_TYPE.LOC,
            curTab : 0,
            codeName : '',
            code : 1,
            codeLevel : 1,
            itemLevel : 1,
            itemName : '',
            graphToggle : false,
            reportType : '',
            isLoaded : false,
            isShowLocSearch : false,
            isShowGroupSearch : false,
            isShowItemSearch : false,
            list : [],
            column: [
                { key: "col1", name: "대분류", w: "120px"},
                { key: "col2", name: "중분류", w: "100px"},
                { key: "col3", name: "소분류", w: "100px" },
                { key: "col4", name: "IT 자산", w: "100px", comma:true},
                { key: "col5", name: "비품", w: "100px", comma:true },
                { key: "col6", name: "총계", w: "100px", comma:true },
            ],
            chartData: {
                // labels: [ '총무부', '영업부', '자재부', '생산부', '개발부' ],
                // datasets: [ 
                //     { 
                //         label: 'IT 자산',
                //         backgroundColor : 'rgba(22, 22, 22, 0.5)',
                //         data: [40, 20, 12, 5, 1] 
                //     },
                //     { 
                //         label: '비품',
                //         backgroundColor : 'rgba(34, 113, 34, 0.5)',
                //         data: [10, 30, 60, 3, 5] 
                //     } 
                // ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins : [{
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'x',
                        }
                    }
                }]
            },
        }
    },
    watch : {
        curCodeType(){
            this.codeName = '';
            this.code = 1;
            this.codeLevel = 1;
            
            this.refresh();
        }
    },
    methods : {
        refresh(){
            if(this.curTab === 0){
                this.getReports();
            }else if(this.curTab === 1){
                this.graphToggle = true;
                this.getReportsGraph();
                this.$nextTick(() => {
                    this.graphToggle = false;
                })   
            }
        },
        setCodeData(data){
            console.log(data);

            if(data.text === '위치코드' || data.text === '부서코드'){
                this.codeName = '';
            }else {
                this.codeName = data.text;
            }

            this.code = data.value;
            this.codeLevel = data.level + 1;

            this.refresh();
        },
        setItemData(data){
            this.itemName =  (data.text !== '재산구분코드' ? data.text : ''); 
            this.itemLevel = data.level + 1;

            this.refresh();
        },
        changeTab(index){
            this.curTab = index;
            if(index === 1){
                this.$nextTick(() => {
                    this.isLoaded = true;
                })
            }else {
                this.isLoaded = false;
            }

            // this.refresh();
        },
        getReports(){
            const parameters = {};
            //위치 or 부서
            let searchName = this.codeName;
            parameters.type = this.curCodeType
            parameters.searchName = searchName;
            parameters.code = this.code;

            //품목
            parameters.subItemLevel = this.itemLevel; //찾으려는 level
            parameters.itemSearchName = this.itemName;
            axios.get('/api/asset/reports', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    // console.log(res.data.data.reportStructure);
                    let [column, list] = this.$commonFunc.makeStatisStructure(data.itemKeys, data.reportStructure, data.reportCnt, searchName, data.parentCodes);
                    this.column = column;
                    this.list = list;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getReportsGraph(){
            const parameters = {};
            //위치 or 부서
            parameters.type = this.curCodeType
            parameters.searchName = this.codeName;
            parameters.codeLevel = this.codeLevel;

            //품목
            parameters.itemLevel = this.itemLevel; //찾으려는 level
            parameters.itemSearchName = this.itemName;
            axios.get('/api/asset/reports/graph', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data;
                    console.log(data.reportGraph);

                    this.makeGraphData(data.reportGraph);

                    // console.log(res.data.data.reportStructure);
                    // this.makeStatisStructure(data.itemKeys, data.reportStructure, data.reportCnt, searchName, data.parentCodes);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        makeGraphData(reportGraph){
            let curCodeName = '';
            const labels = [];
            const datasets = [];
            let isFirst = true;
            let datasetsIndex = 0;

            const colors = [
                'rgba(22, 22, 22, 0.5)',
                'rgba(122, 133, 22, 0.5)',
                'rgba(22, 55, 66, 0.5)',
                'rgba(11, 123, 55, 0.5)',
                'rgba(99, 78, 66, 0.5)',
                'rgba(123, 44, 55, 0.5)',
                'rgba(66, 12, 33, 0.5)',
            ]

            for(let i = 0; i < reportGraph.length; i++){
                const curRow = reportGraph[i];
                
                if(curRow.codeName !== curCodeName){
                    curCodeName = curRow.codeName;
                    datasetsIndex = 0;
                    if(labels.length === 0){
                        isFirst = true;
                    } else {
                        isFirst = false;
                    }
                    labels.push(curRow.codeName);
                }

                //데이터 구조 만듬
                if(isFirst){
                    datasets.push({
                        label : curRow.itemName,
                        backgroundColor : colors[datasetsIndex % colors.length],
                        data : [curRow.cnt]
                    })
                }else {
                    datasets[datasetsIndex].data.push(curRow.cnt)
                }

                datasetsIndex++;
            }

            console.log(labels);
            console.log(datasets);

            this.chartData = {
                labels : labels,
                datasets : datasets
            }
        },
        // makeStatisStructure(itemKeys, reportStructure, reportCnt, searchName, parentCodes){
        //     const reportCntHashed = {};
            
        //     let totalItemCnt = 0;
        //     const totalItemCntArr = {};
        //     for(let i = 0; i < itemKeys.length; i++){
        //         totalItemCntArr[itemKeys[i]] = 0;
        //     }

        //     for(const key in reportCnt){
        //         const each = reportCnt[key];
        //         reportCntHashed[each.code + each.name] = each.cnt;
        //         totalItemCntArr[each.name] += each.cnt;
        //         totalItemCnt += each.cnt;
        //     }

        //     const hasOwnProperty = Object.prototype.hasOwnProperty;

        //     //0. 최대 레벨
        //     const maxLevel = _.maxBy(reportStructure, 'level').level; //max level을 바꾸면 분류가 추가되서 나옴.
        //     // maxLevel = 3;

        //     //1. 값입력
        //     for(let i = 0; i < reportStructure.length; i++){
        //         const curRow = reportStructure[i];
        //         for(let i = 1; i < maxLevel + 1; i++){
        //             curRow['level' + i] = '';
        //         }
        //         curRow['level' + curRow.level] = curRow.name;

        //         let totalCnt = 0;
        //         for(let j = 0; j < itemKeys.length; j++){
        //             if(hasOwnProperty.call(reportCntHashed, curRow.code + itemKeys[j])){
        //                 curRow[itemKeys[j]] = reportCntHashed[curRow.code + itemKeys[j]];
        //             }else { 
        //                 curRow[itemKeys[j]] = 0
        //             }
        //             totalCnt += curRow[itemKeys[j]];
        //         }

        //         curRow['총계'] = totalCnt;
        //     }

        //     // console.log(window.copyObject(reportStructure));

        //     //2. 레벨별 소계 합계 행 추가.
        //     const insertList = []


        //     const insert = (index, level, cntArr) => {
        //         const row = {}
        //         row.index = index + 1;
        //         row.level = level; 
        //         for(let i = 1; i < maxLevel + 1; i++){
        //             if(i > level + 1){
        //                 row['level' + i] = {rowspan : 1, value : ''};
        //             } else {
        //                 row['level' + i] = '';
        //             }
        //         }

        //         if(level === 1){
        //             row['level' + (level + 1)] = '[합계]';
        //         }else {
        //             row['level' + (level + 1)] = '[소계]';
        //         }
        //         row['level' + (level)] = {rowspan : 0};
                
        //         insertList.push(row);


        //         let totalCnt = 0;
        //         for(let i = 0; i < itemKeys.length; i++){
        //             row[itemKeys[i]] = cntArr[i];
        //             totalCnt += cntArr[i];
        //             cntArr[i] = 0;
        //         }

        //         row['총계'] = totalCnt;
        //     }

        //     const cntArr = [];
        //     for(let i = 0; i < itemKeys.length; i++){
        //         cntArr.push(0);
        //     }

        //     for(let level = maxLevel - 1; level > 0; level--){

        //         let deepStart = false;
                
        //         for(let i = 0; i < reportStructure.length; i++){
        //             const curRow = reportStructure[i];

        //             for(const key in itemKeys){
        //                 cntArr[key] += curRow[itemKeys[key]];
        //             }

        //             //레벨변화하는 부분에 행을 넣어줌. 혹은 마지막 라인
        //             if(deepStart && (i === reportStructure.length - 1 || reportStructure[i + 1].level <= level)){
        //                 insert(i, level, cntArr);
        //                 deepStart = false;
        //                 continue;
        //             } 

        //             if(curRow.level < level){
        //                 //행만들어줌.
        //                 insert(i, level, cntArr);
        //             }else if(level === curRow.level){
        //                 //다음 행이 있고, 레벨이 높다면 이 그룹의 마지막에 넣어줌.
        //                 if(i !== reportStructure.length - 1 && reportStructure[i + 1].level > level){
        //                     deepStart = true;
        //                     continue;
        //                 //없다면 여기에 넣어줌. 마지막라인.
        //                 } else {
        //                     insert(i, level, cntArr);
        //                 }
        //             }
        //         }
        //     }

        //     let sortedInsertList = _.sortBy(insertList, ['index']).reverse();
        //     // console.log(sortedInsertList);
        //     // console.log(window.copyObject(insertList));

        //     //3. rowspan 입력
        //     for(let i = 0; i < sortedInsertList.length; i++){
        //         let curRow = sortedInsertList[i];
        //         reportStructure.splice(curRow.index, 0, curRow);
        //     }

        //     // console.log(reportStructure);
            

        //     if(searchName){
        //         let searchIndex = 0;
        //         for(let i = 0; i < reportStructure.length; i++){
        //             if(reportStructure[i].name === searchName) {
        //                 searchIndex = i;
        //                 break;
        //             }
        //         }

        //         reportStructure.splice(0, searchIndex) //찾은 부서의 index
        //     }

        //     for(let level = 1; level < maxLevel; level++){
        //         let deepStart = false;
        //         let index = -1;
        //         let cnt = 0;
        //         const levelName = 'level' + level;
        //         for(let i = 0; i < reportStructure.length; i++){
        //             const curRow = reportStructure[i];
        //             if(deepStart){
        //                 if(typeof curRow[levelName] === 'object'){
        //                     deepStart = false;
        //                     cnt++;
        //                     reportStructure[index][levelName] = {rowspan : cnt, value : reportStructure[index][levelName]} 
        //                     reportStructure[index].style = {borderRight : '1px solid #e6e7ec'};
        //                     index = -1;
        //                     cnt = 0;
        //                 }else {
        //                     cnt++;
        //                     reportStructure[i][levelName] = {rowspan : 0}
        //                 }
        //             }else {
        //                 deepStart = true;
        //                 if(typeof curRow[levelName] === 'string' && (curRow[levelName] === '[합계]' || curRow[levelName] === '[소계]')) deepStart = false;
        //                 if(typeof curRow[levelName] === 'object' && (curRow[levelName].rowspan === 1)) deepStart = false;
        //                 cnt = 1;
        //                 index = i;
        //             }
        //         }
        //     }

        //     //4. filter 있다면 일부 변경
        //     if(searchName){
        //         for(const key in parentCodes){
        //             const curParentCode = parentCodes[key];
        //             reportStructure[0]['level' + curParentCode.level].value = curParentCode.name;
        //         }
        //         // reportStructure[0].level1.value = '테스트부서0';
        //         // reportStructure[0].level2.value = '테스트부서1-2';
        //     }

        //     // console.log(reportStructure);
        //     //총계 마지막줄
        //     let totalData = {level1 : '[총계]', 총계 : totalItemCnt};
        //     for(const key of itemKeys){
        //         totalData[key] = totalItemCntArr[key];
        //     }
        //     reportStructure.push(totalData);

        //     this.column = [];
        //     for(let i = 1; i <= maxLevel; i++){
        //         this.column.push({key : 'level' + i, name : i + '차분류', w : '120px'});
        //     }

        //     for(const key of itemKeys){
        //         this.column.push({key : key, name : key, w : '120px'});
        //     }
        //     this.column.push({key : '총계', name : '총계', w : '120px'});

        //     this.list = reportStructure;
        // },
        downloadExcel(){
            this.$commonFunc.downloadExcel("자산현황보고서");
        }
    },
    created(){
        this.getReports();
        this.getReportsGraph();
    }
}
</script>
<style scoped>
.tab_wrap{
    display:flex;
    width:300px;
}
.contents{
    flex:1;
    overflow: hidden;
}
.tab_wrap{
  display:flex;
}
.tab{flex:1;text-align:center;padding:10px; border-top:1px solid #e6e7ec;border-bottom:1px solid #e6e7ec;color:#494a4f; font-size:16px;cursor:pointer; background-color:white;}
.tab + .tab{border-left:1px solid #e6e7ec}
.tab.active{
  background-color:#0066fd;
  border-color:#0066fd;
  color:white;
}
.tab:nth-of-type(1){
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left:1px solid #e6e7ec;
}
.tab.active:nth-of-type(1){
    border-left:1px solid #0066fd;
}

.tab:nth-last-of-type(1){
    border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right:1px solid #e6e7ec;
}

.tab.active:nth-last-of-type(1){
border-right:1px solid #0066fd;
}

</style>