<template >
    <ModalBack>
        <div class="address_search_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">내용연수표</div>
            <iframe :src="$publicPath + '/htm/durable_year1.htm'" ref="ifr_itemHistoryGuide" style="border:none;height:100%;"></iframe>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">닫기</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';

export default {
    name : 'ItemHistoryGuide',
    props : {
        hideModal : Function,
        completeAction : {
            type : Function,
            default : () => {}
        }
    },
    components : {
        ModalBack,
        CommonModalCloseBtn
    },
    methods : { 

    },
}
</script>
<style scoped>
.address_search_wrap{
    background-color:white; width:816px; height:815px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
.asset_bottom_btns{text-align:right;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>