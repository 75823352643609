<template>
    <TableSearchPanel :is-show-right-panel="true" :is-show-top-panel="true" :is-show-table-panel="true" :is-show-search-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="자산라벨출력"></PageHeader>
        </template>
        <template v-slot:search_panel>
            <div style="display:flex;">
                <LabelPanel style="width:180px;">
                        <template v-slot:label>라벨타입</template>
                        <template v-slot:content>
                            <CommonSelect :list="labelType" value="0"></CommonSelect>
                        </template>
                </LabelPanel>
                <LabelPanel style="width:180px;margin-left: 19px;">
                    <template v-slot:label>사용상태</template>
                    <template v-slot:content>
                        <CommonSelect :list="states" :detectChange="detectStateChange"></CommonSelect>
                    </template>
                </LabelPanel>
                <LabelPanel style="width:300px; margin-left:19px;">
                    <template v-slot:label>검색어</template>
                    <template v-slot:content>
                        <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                        <CommonSearchBtn style="margin-left:4px;" :clickAction="() => {search()}"></CommonSearchBtn>
                    </template>
                </LabelPanel>
            </div>  

        </template>
        <template v-slot:table_panel>
            <BatchBtns 
                :is-show-first-checkbox="true" 
                :isShowPrintBtn="true" 
                :isShowSelectAllBtn="true" 
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :searchCnt="searchCnt"
                :downloadExcel="downloadExcel"
                :printAction="showLabelPrinting"
            ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :isShowCheckbox="true" 
                    :isShowNumbering="true"
                    :plist="list" 
                    :pcolumn="column" 
                    :pcustom="custom" 
                    :paging="paging" 
                    :change-page="detectChangePage"
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="assetCode"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>

        </template>
        <template v-slot:etc>
            <LabelPrinting v-if="isShowLabelPrinting" :hideModal="() => {isShowLabelPrinting = false}" :transferData="printData"></LabelPrinting>
        </template>
    </TableSearchPanel>
</template>
<script>
import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import CommonSearchBtn from '@/components/common/CommonSearchBtn.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ConditionManager from '@/modules/ConditionManager.js'
import LabelPrinting from '@/components/modal/LabelPrinting.vue';

import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, states, NUMBER_STYLE, SIZE_PER_PAGE, BROWSER_NAME} from '@/util/Constants.js';
import TTP_PRINTER from '@/modules/TTP_PRINTER.js'
export default {
    name : 'AssetLabelPrint',
    components : {PageHeader, TableSearchPanel, LabelPanel, CommonSelect, CommonSearchBtn, BatchBtns, CommonTable, ColumnSetting, ConditionInput, LabelPrinting },
    data(){
        return {
            lastPage : 1,
            lastSizePerPage : SIZE_PER_PAGE,
            searchWord : '',
            searchCnt : 0,
            lastWithoutSearchWord : false,
            stateCode : '',
            savedSelectedList : {},
            isShowLabelPrinting : false,
            printData : [],
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },
            column: [
                { key: "barcode", name: "바코드", w: "100px"},
                { key: "itemName", name: "재산구분", w: "100px"},
                { key: "assetName", name: "자산명칭", w: "150px" },
                { key: "modelName", name: "모델명", w: "100px" },
                { key: "serial", name: "시리얼", w: "100px" },
                { key: "standard", name: "규격", w: "100px" },
                { key: "buyDate", name: "취득일자", w: "100px" },
                { key: "buyPrice", name: "취득가액", w: "100px", comma: true, style : NUMBER_STYLE  },
                { key: "scrapPrice", name: "잔존가액", w: "100px", comma: true, style : NUMBER_STYLE  },
                { key: "groupName", name: "부서", w: "100px" },
                { key: "locName", name: "위치", w: "100px" },
                { key: "charge", name: "담당자", w: "100px" },
                { key: "user", name: "사용자", w: "100px" },
                { key: "selling", name: "구매처", w: "100px" },
                { key: "baseName", name: "사용상태", w: "100px" },
                { key: "field1", name: "추가항목1", w: "100px" },
                { key: "field2", name: "추가항목2", w: "100px" },
                { key: "field3", name: "추가항목3", w: "100px" },
                { key: "field4", name: "추가항목4", w: "100px" },
                { key: "field5", name: "추가항목5", w: "100px" },
                { key: "field6", name: "추가항목6", w: "100px" },
                { key: "field7", name: "추가항목7", w: "100px" },
                { key: "field8", name: "추가항목8", w: "100px" },
            ],
            isShowColumnSettings : false,
            states : states,
            labelType : [
                {name : '바코드', value : '0'},
                {name : 'RFID', value : '1'},
            ],
            list: [],
            custom : {},
            paging : {},
            searchConditions : [
                {name : '자산명칭', key : 'assetName', checked : true},
                {name : '재산구분', key : 'itemName', checked : true},
                {name : '부서명', key : 'groupName', checked : true},
                {name : '위치명', key : 'locName', checked : true},
                {name : '모델명', key : 'modelName', checked : true},
                {name : '시리얼', key : 'serial', checked : true},
                {name : '규격', key : 'standard', checked : true},
                {name : '담당자', key : 'charge', checked : true},
                {name : '사용자', key : 'user', checked : true},
                {name : '취득가액', key : 'buyPrice', checked : true},
                {name : '취득일자', key : 'buyDate', checked : true},
                {name : '등록일자', key : 'insertDate', checked : true},
                {name : '구매처', key : 'selling', checked : true},
                {name : '바코드', key : 'barcode', checked : true},
                {name : '추가항목1', key : 'field1', checked : true},
                {name : '추가항목2', key : 'field2', checked : true},
                {name : '추가항목3', key : 'field3', checked : true},
                {name : '추가항목4', key : 'field4', checked : true},
                {name : '추가항목5', key : 'field5', checked : true},
                {name : '추가항목6', key : 'field6', checked : true},
                {name : '추가항목7', key : 'field7', checked : true},
                {name : '추가항목8', key : 'field8', checked : true}
            ],

        }
    },
    methods : {
        deleteList(){

        },
        getAssets(page, withoutSearchWord){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            const parameters = this.getParameters();

            axios.get('/api/asset/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                const data = res.data.data
                const assets = data.assets.content;
                this.searchCnt = data.assets.totalElements;
                data.assets.content = null;
                const assetSum = data.assetSum;
                this.list = assets;
                this.paging = data.assets;
                }
            }).catch(res => {
                console.log(res);
            });

        },
        getParameters(){
            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;
            parameters.deleted = 0;

            let conditions = [];
            let searchWords = [];

            if(this.stateCode !== ''){
                conditions.push("np_stateCode");
                searchWords.push(this.stateCode);
            }

            if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            return parameters;
        },
        detectStateChange(stateCode){
            this.stateCode = stateCode;
        },
        detectChangePage(page){
            this.getAssets(page, this.lastWithoutSearchWord);
        },
        enter(){
            this.search();
        },
        search(){
            this.getAssets(1, false);
        },  
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/asset/list/excel?" + queryStr);
            location.href = this.$versionPath + "/api/asset/list/excel?" + queryStr
        },
        showLabelPrinting(){
            if(this.$commonFunc.getBrowser() !== BROWSER_NAME.IE ){
                alert('라벨 프린트는 익스플로어, 엣지 익스플로어 모드에서만 지원합니다.');
                return;
            }

            try{
                TTP_PRINTER.init();    
            }catch(e){
                alert('프린터 출력설정이 필요합니다.\n관련문의는 유비플러스고객센터로 문의 바랍니다. (1544-3486)');
                return;
            }

            this.getPrintList();
        },
        getPrintList(){

            if(this.btnState.isSelectAll){
                const parameters = this.getParameters();
                parameters.showAll = true;

                axios.get('/api/asset/list', {params : parameters}).then(res => {
                    if(res.data.code == ResCode.Success){
                        const data = res.data.data
                        const assets = data.assets.content;
                        this.printData = assets;
                        this.isShowLabelPrinting = true;
                    }
                }).catch(res => {
                    console.log(res);
                });
            }else {
                const newList = [];

                for(const key in this.savedSelectedList){
                    newList.push(this.savedSelectedList[key]);
                }

                this.printData = newList;
                this.isShowLabelPrinting = true;
            }
        },
        // async print(){
        //     if(this.$commonFunc.getBrowser() !== BROWSER_NAME.IE ){
        //         alert('라벨 프린트는 익스플로어, 엣지 익스플로어 모드에서만 지원합니다.');
        //         return;
        //     }

        //     TTP_PRINTER.init();
        //     for(let i = 0; i < 1; i++){
        //         await this.printDelayed();
        //     }
        // },
        // async printDelayed(){
        //     var items = [
        //         {
        //             barcode : '12345678901111',
        //             items : [ '동작나래관', '위탁재산', '2022062100001', '수강용탁자1', '㈜선일, OTOH30230BU', '2022-06-21']
        //         }
        //     ]

        //     return new Promise((resolve, reject) => {
        //         setTimeout(() => {
        //             console.log(TTP_PRINTER.Print(items));
        //             resolve();
        //         }, 500);
                
        //     })
        // }

    },
    created(){
        this.getAssets(1, false);
        this.$commonFunc.setFieldNames(this.searchConditions);
        ConditionManager.setConditions(this.$options.name, this.searchConditions);
        this.$commonFunc.setFieldNames(this.column);
        this.column = ColumnManager.getSettings(this.$options.name, this.column );
    }
}
</script>
<style scoped>
    
</style>