<template >
    <ModalBack>
        <div class="company_register_wrap">
        <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
        <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >
            <template v-if="curMode === MODE.INSERT">자산메모등록</template>
            <template v-if="curMode === MODE.UPDATE">자산메모수정</template>
        </div>
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                    <col>
                </colgroup>
                <tr>
                    <td>
                        <div class="label required">메모일자</div>
                        <CalendarInput v-model="form.memoDate"></CalendarInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label required">메모내용</div>
                        <CommonTextarea v-model="form.memo" height="90px"></CommonTextarea>
                    </td>
                </tr>
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
            <button class="asset_bottom_btn_ok" @click="() => {curMode === MODE.INSERT ? insertMemoHistory() : updateMemoHistory();}">저장</button>
        </div>
    </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonTextarea from '@/components/common/CommonTextarea.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import axios from 'axios';
import moment from 'moment';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';
export default {
    name : 'AssetRepairRegister',
    props : {
        hideModal : Function,
        data : Object,
        curMode : Number,
        completeAction : {
            type : Function,
            default : () => {}
        },
        assetCode : String
    },
    components : {
        ModalBack,
        CommonModalCloseBtn,
        CalendarInput,
        CommonTextarea
    },
    data(){
        return {
            MODE : MODE,
            form : {
               memoDate : '',
               memo : ''
            },
        }
    },
    methods : {
        validate(){
            if(this.form.memoDate === ''){
                alert('메모일자를 입력해주세요.');
                return false;
            }

            if(this.form.memo === ''){
                alert('메모내용을 입력해주세요.');
                return false;
            }

            return true;
        },
        updateMemoHistory(){
            if(!this.validate()) return;

            const parameters = {};
            parameters.memoIdx = this.data.memoIdx;
            parameters.memoDate = this.form.memoDate;
            parameters.memo = this.form.memo;
            axios.post('/api/asset/memohistory/update', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeAction();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });
        },
        insertMemoHistory(){
            if(!this.validate()) return;

            const parameters = {};
            // parameters.memoIdx = this.data.memoIdx;
            parameters.memoDate = this.form.memoDate;
            parameters.memo = this.form.memo;
            parameters.assetCode = this.assetCode;
            axios.post('/api/asset/memohistory/insert', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeAction();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });
        }
    },
    created(){
        if(this.curMode === MODE.UPDATE ){
            this.form.memoDate = this.data.memoDate.substr(0, 10);
            this.form.memo = this.data.memo;
        }else if(this.curMode === MODE.INSERT){
            this.form.memoDate = moment().format('YYYY-MM-DD');
        }
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 400px;
    height: 400px;;;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>