import { render, staticRenderFns } from "./AssetChangeHistory.vue?vue&type=template&id=20b27337&scoped=true&"
import script from "./AssetChangeHistory.vue?vue&type=script&lang=js&"
export * from "./AssetChangeHistory.vue?vue&type=script&lang=js&"
import style0 from "./AssetChangeHistory.vue?vue&type=style&index=0&id=20b27337&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b27337",
  null
  
)

export default component.exports