<template>
    <div class="calendar_wrap" @click="$event.stopPropagation();">
        <div class="calendar_title">
            <button class="calendar_btn_wrap left" type="button" @click="showPrevMonth" style="transform:rotate(180deg)">
                <div class="cal_prev_btn"><img class="arrow_img" :src="require('@/assets/menu_arrow.png')"></div>
            </button>
            <span class="date_str" v-show="selectType === 0" @click="changeSelectType">{{monthStr[month]}} {{year}}</span>
            <span class="date_str" v-show="selectType === 1" @click="changeSelectType">{{yearInYearSelect}}</span>
            <span class="date_str" v-show="selectType === 2" @click="changeSelectType">처음으로</span>
            <button class="calendar_btn_wrap right" type="button" @click="showNextMonth">
                <div class="cal_next_btn"><img class="arrow_img" :src="require('@/assets/menu_arrow.png')" style="vertical-align: -1px;"></div>
            </button>
        </div>
        <div v-show="selectType === 0" class="calendar_con_wrap" >
            <div class="day_title">
                <div style="">Mo</div>
                <div>Tu</div>
                <div>We</div>
                <div>Th</div>
                <div>Fr</div>
                <div>Sa</div>
                <div style=";">Su</div>
            </div>
            <div class="calendar_contents_wrap" >
                <div class="calendar_location" :style="{top: '-' + (selectedWeekIndex * eachWeekHeight) + 'px'}">
                    <div class="month_week" v-for="week, i in monthData" :key="i">
                        <div class="each_date" :class="[selectedRowIndex === i && selectedColIndex === j ? 'active' : '', 
                            day.hasOwnProperty('active') && day.active === false ? 'inactive' : '',
                            day.active === true ? 'period_back' : '',
                            day.active === true && j == 0 ? 'period_back_left' : '',
                            day.active === true && j == 6 ? 'period_back_right' : '',
                            day.key === selectedDate && mode === 'start' ? 'period_back_left' : '',
                            day.key === selectedDate && mode === 'end' ? 'period_back_right' : '',
                            day.key === activeStartDate ? 'period_back_left' : '',
                            day.key === activeEndDate ? 'period_back_right' : '',
                            ]"
                            v-for="day, j in week" @click="selectDate(i, j)" :key="i + '' + j">
                            <div class="date_text" 
                                :class="[parseInt(day.key.substr(5, 2)) !== month ? 'inactive_month' : '', 
                                day.key === todayStr ? 'calendar_today_mark' : '',
                                day.key === selectedDate ? 'selected_round' : ''

                                ]">{{day.date == 0 ? '' : day.date}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="selectType === 1">
            <div class="calendar_contents_wrap col_flex">
                <div class="month_select_row" v-for="each, i in monthSelectList" :key="i" >
                    <div class="month_select_cell" v-for="each2, j in each" :key="i + '' + j" @click="selectMonth(each2)">{{each2}}</div>
                </div>
            </div>
            
        </div>
        <div v-show="selectType === 2">
    
            <div class="calendar_contents_wrap col_flex">
                <div class="month_select_row" v-for="each, i in yearSelectList" :key="i" >
                    <div class="month_select_cell" v-for="each2, j in each" :key="i + '' + j" @click="selectYear(each2)">{{each2}}</div>
                </div>
            </div>
        
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import calendar from '@/modules/calendar';

    

    export default {
        name: "Calendar",
        props:['selectDateCallback', 'startDate', 'monthChangeHandler', 'detectMonthChangeCall', 'isToggleHidden', 'activeStartDate', 'activeEndDate', 'mode', 'detectChangeTargetDate'],
        data(){
            return {
                monthData : [],
                selectType : 0,
                year : '',
                month : '',
                eachWeekHeight : 30,
                showAll : true,
                selectedWeekIndex : 0,
                curHeight : this.eachWeekHeight,
                selectedRowIndex : -1,
                selectedColIndex : -1,
                additionalInfo : {},
                lastSelectedYear : '',
                lastSelectedMonth : '',
                lastSelectedRowIndex : -1,
                lastSelectedColIndex : -1,
                todayStr : '',
                selectedDate : '',
                yearInYearSelect: '',
                execCnt : 0,
                monthSelectList : [],
                yearSelectList : [],
                cal : null,
                monthStr : {
                    1 : 'January',
                    2 : 'February',
                    3 : 'March',
                    4 : 'April',
                    5 : 'May',
                    6 : 'June',
                    7 : 'July',
                    8 : 'August',
                    9 : 'September',
                    10 : 'October',
                    11 : 'November',
                    12 : 'December',

                }
            }
        },
        watch : {
            detectMonthChangeCall(){
                this.handleMonthChange();
            },
            detectChangeTargetDate(){
                this.selectType = 0;
                this.setTargetDate(this.startDate);
            }
        },
        methods : {
            toggleCalendar(){
                //$(".hide").slideToggle(300);
                this.showAll = !this.showAll;
                this.resizeCalendar(true);
            },
            closeCalendar(){
                this.showAll = false;
                // this.resizeCalendar(false);
            },
            resizeCalendar(isShowMore = false){
                //열때
                if(this.showAll){
                    this.selectedWeekIndex = 0;
                    this.curHeight = this.eachWeekHeight * this.cal.getWeekCnt();
                //닫을때
                }
                // else {
                //     // if(this.cal.isTodayMonth()){
                //         this.selectedWeekIndex = this.lastSelectedRowIndex;
                //         //this.selectedWeekIndex = this.cal.getSelectedWeekIndex();
                //     // }else {
                //     //     this.selectedWeekIndex = 0;
                //     // }
                //
                //     this.curHeight = this.eachWeekHeight;
                // }
                //
                // if(!isShowMore){
                    if(this.execCnt < 2){
                        this.selectedRowIndex = this.cal.getSelectedWeekIndex();
                        this.selectedColIndex = this.cal.getSelectedColIndex();
                        this.lastSelectedRowIndex = this.cal.getSelectedWeekIndex();
                        this.lastSelectedColIndex = this.cal.getSelectedColIndex();
                        this.lastSelectedMonth = this.month;
                        this.lastSelectedYear = this.year;
                        this.execCnt++;
                    }else {
                        if(this.month === this.lastSelectedMonth && this.year === this.lastSelectedYear){
                            this.selectedRowIndex = this.lastSelectedRowIndex;
                            this.selectedColIndex = this.lastSelectedColIndex;
                        }else {
                            this.selectedRowIndex = -1;
                            this.selectedColIndex = -1;
                        }
                    }
                // }
            },
            getMonthData(){
                this.monthData = this.cal.getMonthData();
                this.year = this.cal.getYear();
                this.month = parseInt(this.cal.getMonth());
                this.resizeCalendar();
            },
            showPrevMonth(){
                if(this.selectType === 0){
                    this.showAll = true;
                    this.cal.setPrevMonth();
                    this.handleMonthChange();
                } else if(this.selectType === 1){
                    this.yearInYearSelect = this.yearInYearSelect - 1;
                } else if(this.selectType === 2){
                    this.yearInYearSelect = this.yearInYearSelect - 12;
                    this.setYearSelectList();
                }
            },
            showNextMonth(){
                if(this.selectType === 0){
                    this.showAll = true;
                    this.cal.setNextMonth();
                    this.handleMonthChange();
                } else if(this.selectType === 1) {
                    this.yearInYearSelect = this.yearInYearSelect + 1
                }else if(this.selectType === 2){
                    this.yearInYearSelect = this.yearInYearSelect + 12;
                    this.setYearSelectList();
                }
        
            },
            showTargetMonth(year, month){
                this.showAll = true;
                this.cal.setTargetMonth(year, month);
                this.handleMonthChange();
            },
            handleMonthChange(){
                this.getMonthData();
                if(this.monthChangeHandler != null){
                    this.monthChangeHandler((additionalInfo) => {
                        this.additionalInfo = additionalInfo;
                        this.getMonthData();
                    }, this.cal.getYear(), this.cal.getMonth(), this.cal.getDisplayMonthFirstStr(), this.cal.getDisplayMonthLastStr());
                }
            },
            selectDate(i, j){
                this.selectedRowIndex = i;
                this.selectedColIndex = j;
                this.lastSelectedRowIndex = i;
                this.lastSelectedColIndex = j;
                this.lastSelectedYear = this.year;
                this.lastSelectedMonth = this.month;

                const dateStr = this.cal.getDateStrBy(i,j)
                this.selectedDate = dateStr;
                this.selectDateCallback(dateStr);
        
            },
            setToday(){
                this.execCnt = 1;
                this.cal.init(this.startDate, this.activeStartDate, this.activeEndDate);
                this.handleMonthChange();
            },
            setTargetDate(dateStr){
                this.execCnt = 1;
                this.selectedDate = dateStr;
                this.cal.init(dateStr, this.activeStartDate, this.activeEndDate);
                this.handleMonthChange();
            },
            setYearSelectList(){
                let curYearOrder = this.yearInYearSelect % 12;
                let yearList = [];

                for(let i = 0; i < curYearOrder; i++){
                    yearList.push(this.yearInYearSelect - (curYearOrder - i));
                }

                for(let i = curYearOrder; i < 12; i++){
                    yearList.push(parseInt(this.yearInYearSelect) + (i - curYearOrder))
                }

                this.yearSelectList = [
                        [yearList[0], yearList[1], yearList[2]], 
                        [yearList[3], yearList[4], yearList[5]],
                        [yearList[6], yearList[7], yearList[8]],
                        [yearList[9], yearList[10], yearList[11]],
                    ];
            },
            changeSelectType(){
                let nextSelectType = this.selectType + 1;
                
                //월 선택
                if(nextSelectType === 1){
                    this.yearInYearSelect = parseInt(this.year)
                    this.monthSelectList = [[1,2,3], [4, 5,6], [7,8,9], [10,11,12]];
                //년 선택
                }else if(nextSelectType === 2){
                    // this.yearInYearSelect = this.year;
                    this.setYearSelectList();
                }else if(nextSelectType === 3){
                    nextSelectType = 0;
                }
                this.selectType = nextSelectType;
            },
            selectMonth(month){
                this.year = this.yearInYearSelect;
                this.showTargetMonth(this.yearInYearSelect, month)
                this.selectType = 0;
            },
            selectYear(year){
                this.yearInYearSelect = parseInt(year);
                this.selectType = 1;
            }
        },
        mounted(){
            this.setToday();
            // this.cal.init(this.startDate, this.activeStartDate, this.activeEndDate);
            // //cal.setChangeMonthHandler(this.monthChangeHandler);
            // this.handleMonthChange();
        },
        created (){
            this.cal = new calendar();
            this.todayStr = moment().format('YYYY-MM-DD');
            this.selectedDate = this.startDate;
            // if(this.isFirstShowMonth != null && this.isFirstShowMonth){
            //     this.showAll = true;
            //     this.execCnt = 1;
            // }
        }
    }
</script>

<style scoped>

    .calendar_wrap{width:100%;width:268px;height:301px}
    .calendar_contents_wrap{width:100%;}
    .day_title > div{flex:1; text-align:center;padding:5px;}
    .day_title{border-top-left-radius: 5px;border-top-right-radius: 5px;
    display:flex;color:#555;font-size: 12px;font-weight:bold;}
    .month_week{height:37px;display:flex;}
    .month_week + .month_week{margin-top:3px;}
    .month_week > div{flex:1;text-align:center;color:#555;}
    .calendar_con_wrap {background-color:white;}


    .date_str {color:black;font-size: 14px;;font-weight: bold; }
    .cal_prev_btn{
        color: #495057;
        padding:0px 10px;
    }
    .cal_next_btn{color: #495057;padding:0px 15px;}
    .calendar_round{display: inline-block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #3788d8;
        color: #fff;
        line-height: 25px;}

    .calendar_title{
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
        height: 40px;
        position: relative;
    }
    .calendar_title > button, .calendar_title > span{    line-height: 40px;}
    .calendar_today_mark{color:#1ab394}
    .calendar_btn_wrap{border:0px;-webkit-appearance: none;background-color:white;}
    .calendar_holiday{text-align:center; color:red; font-size:11px;display:flex; justify-content: center; align-items:center; height:25px;}
    .calendar_date_sun{color: rgb(215, 24, 24);}
    .calendar_date_sat{color: rgb(24, 69, 215);}
    .each_date{padding:3px;width:38px;height:38px;}
    .date_text{font-size:12px;line-height: 30px;color:#494a4f;font-weight:bold; cursor:pointer; border:1px solid transparent}
    .inactive_month{color:#cccccc;}
    .date_text:hover{
        border-radius:50%;
        border:1px solid #e6eaed;
    }

    .selected_round{background-color:#564ee0;  border-radius: 50%;color:white;}
    .period_back{background-color: #eae8ff;}
    .period_back_left{border-top-left-radius: 50%;border-bottom-left-radius:50%;}
    .period_back_right{border-top-right-radius: 50%;border-bottom-right-radius: 50%;}

    .left{margin-right:auto;}
    .right{margin-left:auto;}
    .arrow_img{vertical-align: bottom;;}

    .month_select_row{display:flex;flex:1;justify-content: center; margin-top:20px}
    .month_select_row + .month_select_row{margin-top:10px;}
    .month_select_cell{cursor:pointer; color: #494a4f; font-weight: bold;width:75px;height:50px; text-align:center; border:1px solid #e6eaed; border-radius:5px; display:flex; justify-content: center; align-items: center;;}
    .month_select_cell + .month_select_cell{margin-left :10px; }
    .col_flex{display:flex; flex-direction: column;;}
</style>