<template >
    <div>
        <ModalBack :closeAction="hideModal">
            <div class="company_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >로그인{{curMode === MODE.INSERT ? '등록' : '수정'}}</div>
            <div class="asset_info_contents">
                
                <table class="asset_info_tb" style="margin-top:25px;">
                    <colgroup>
                        <col>
                        <col style="width:19px">
                        <col >
                    </colgroup>
                    <tr>
                        <td>
                            <div class="label required">사원명</div>
                            <div class="row_flex">
                                <CommonInput v-model="form.userName" :isReadonly="true" :clickInput="() => {isShowUserSearch = true}" style="flex:1;"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowUserSearch = true}"></MaginifyingGlassButton>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="label" style="position:relative;">
                                <span>아이디</span>
                                <div class="row_flex" style="display: inline-block;position: absolute;left: 90px;top: -3px;" >
                                    <CommonCheckbox :flag="isMobileUse" :click-check="toggleMobileUse" color="#e6e7ec"></CommonCheckbox>
                                    <div @click="toggleMobileUse" style="cursor:pointer;display:inline-block; line-height: 1.4;margin-left: 5px;">모바일 사용</div>
                                </div>
                            </div>
                            <CommonInput v-model="form.id" :isReadonly="isAdmin"></CommonInput>
                            
                        </td>
                    
                    </tr>
                    <tr class="margin"><td colspan="2"></td></tr>
                    <tr>
                        <td>
                            <div class="label">부서</div>
                            <CommonInput v-model="form.groupName" :isReadonly="true"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label">비밀번호</div>
                            <form><CommonInput v-model="form.passwd" mode="password"></CommonInput></form>
                            
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="2"></td></tr>
                    <tr>
                        <td>
                            <div class="label">직급</div>
                            <CommonInput v-model="form.userClass" :isReadonly="true"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label">비밀번호확인</div>
                            <form><CommonInput v-model="form.passwd2" mode="password"></CommonInput></form>
                        </td>
                    </tr>

                    <tr class="margin"><td colspan="2"></td></tr>
                    <tr>
                        <td>
            
                        </td>
                        <td></td>
                        <td>
                            <div class="label">권한설정</div>
                            <div class="row_flex">
                                <CommonSelect :list="authrityList" style="flex:1;" :value="form.userLevel" :detectChange="detectChange" :disabled="isAdmin"></CommonSelect>
                                <button class="plus_btn" style="margin-left:5px;" @click="() => {isShowAuthorityRegister = true}">+</button>
                            </div>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="2"></td></tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="label">보기권한</div>
                            <div style="height:40px; display: flex;align-items: center;">
                                <CommonRadio name="limit_view" v-model="form.limit_view" initVal="0" label="전체" ></CommonRadio>
                                <CommonRadio name="limit_view" v-model="form.limit_view" initVal="1" label="부서" style="margin-left:10px;"></CommonRadio>
                                <CommonRadio name="limit_view" v-model="form.limit_view" initVal="2" label="담당자" style="margin-left:10px;"></CommonRadio>
                            </div>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="2"></td></tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="label">수정권한</div>
                            <div style="height:40px; display: flex;align-items: center;">
                                <CommonRadio name="limit_modify" v-model="form.limit_modify" initVal="0" label="전체" ></CommonRadio>
                                <CommonRadio name="limit_modify" v-model="form.limit_modify" initVal="1" label="부서" style="margin-left:10px;"></CommonRadio>
                                <CommonRadio name="limit_modify" v-model="form.limit_modify" initVal="2" label="담당자" style="margin-left:10px;"></CommonRadio>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="save">저장</button>
            </div>

           
        </div>
        </ModalBack>    
        <UserSearch v-if="isShowUserSearch" :hideModal="() => {isShowUserSearch = false}" 
            :completeSave="completeUserSearch"></UserSearch>
        <AuthorityRegister v-if="isShowAuthorityRegister" :hideModal="() => {isShowAuthorityRegister = false}" :startMode="MODE.INSERT" :completeFunc="() => {getUserLevels();}"></AuthorityRegister>

    </div>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import CommonRadio from '@/components/common/CommonRadio.vue';
import UserSearch from '@/components/modal/UserSearch.vue';
import AuthorityRegister from '@/components/modal/AuthorityRegister.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';


export default {
    name : 'UserRegister',
    props : {
        hideModal : Function,
        data : Object,
        curMode : Number,
        completeAction : {
            type : Function,
            default : () => {}
        },
    },
    components : {
        CommonInput,
        MaginifyingGlassButton,
        ModalBack,
        CommonModalCloseBtn,
        CommonSelect,
        CommonCheckbox,
        CommonRadio,
        UserSearch,
        AuthorityRegister
    },
    data(){
        return {
            MODE : MODE,

            form : {
                userName : '',
                id : '',
                passwd : '',
                passwd2 : '',
                limit_view : '0',
                limit_modify : '0',
                userLevel : 'LV001',
                userClass : '',
                groupName : '',
                userCode : '',
                idx : '',
            },
            isAdmin : false,
            isShowUserSearch : false,
            isMobileUse : false,
            isShowImageMenu : false,
            isShowAuthorityRegister : false,
            authrityList : []
        }
    },
    methods : {
        detectChange(userLevel){
            this.form.userLevel = userLevel;
        },
        toggleMobileUse(){
            this.isMobileUse = !this.isMobileUse;
        },
        getUserLevels(){
            axios.get('/api/login/authority/list').then(res => {
                if(res.data.code == ResCode.Success){
                    const newList = res.data.data.authorities.map((each) => { return { name : each.levelName, value : each.userLevel}})
                    this.authrityList = newList;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        save(){
            if(this.form.userName === ''){
                alert('사원을 선택해주세요.');
                return;
            }

            if(this.curMode === MODE.INSERT){
                if(this.form.passwd !== this.form.passwd2){
                    alert('비밀번호가 일치하지 않습니다.');
                    return;
                }
            }

            const parameters = {};
            parameters.userName = this.form.userName;
            parameters.id = this.form.id;
            parameters.passwd = this.form.passwd;
            parameters.limit_view = this.form.limit_view;
            parameters.limit_modify = this.form.limit_modify;
            parameters.login_type = this.isMobileUse ? '1' : '0';
            parameters.userCode = this.form.userCode;
            parameters.userLevel = this.form.userLevel;

            if(this.curMode === MODE.UPDATE) parameters.idx = this.form.idx;

            console.log(parameters);
            axios.post('/api/login/save', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeAction();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });
        },
        completeUserSearch(data){
            const parameters = {}
            parameters.userCode = data.userCode;

            axios.get('/api/login/checkexist', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    this.form.userName = data.userName; 
                    this.form.userClass = data.userClass; 
                    this.form.groupName = data.groupName;
                    this.form.userCode = data.userCode;
                } else if(res.data.code == ResCode.Fail){
                    alert("[" + data.userName + "] 이미 로그인 사용자로 등록되어있습니다.")
                }
            }).catch(res => {
                console.log(res);
            });
        }
    },
    created(){

        if(this.curMode === MODE.UPDATE){
            if(this.data.idx == '1') this.isAdmin = true;
            
            console.log(this.data);

            this.form.userName = this.data.userName;
            this.form.id = this.data.id;
            this.form.passwd = this.data.passwd;
            this.form.passwd2 = this.data.passwd;
            this.form.limit_view = this.data.limit_view + '';
            this.form.limit_modify = this.data.limit_modify + '';
            this.form.userLevel = this.data.userLevel;
            this.form.userClass = this.data.userClass;
            this.form.groupName = this.data.groupName;
            this.form.userCode = this.data.userCode;
            this.form.idx = this.data.idx;
            this.isMobileUse = this.data.login_type == '1' ? true : false;

        }

        this.getUserLevels();
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 600px;
    height: 670px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}

.plus_btn{
    border:0px; background-color:#0066fd;
    border-radius:5px;
    font-size:30px; 
    width:40px; height:40px;
    color:white; 
padding-top: 3px;
cursor:pointer;

}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>