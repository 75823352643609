<template>
    <TableSearchPanel :is-show-right-panel="true" :is-show-search-panel="true" :is-show-table-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="수리내역"></PageHeader>
        </template>
        <template v-slot:search_panel>
            <div style="display:flex;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label>재산구분</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="itemName" :clickInput="(data) => {isShowItemSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>위치</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="locName" :clickInput="(data) => {isShowLocSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>부서</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="groupName" :clickInput="(data) => {isShowGroupSearch = true;}"></CommonInput> 
                    </template>
                </LabelPanel>
            </div>
            <div style="display:flex;margin-top:12px;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label>사용상태</template>
                    <template v-slot:content>
                        <CommonSelect :list="states" :detectChange="detectStateChange"></CommonSelect>
                    </template>
                </LabelPanel>
                 <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>
                        <div style=" position: absolute;top: -5px;left: -2px;"><CommonCheckbox :flag="isBuyDateChecked" :click-check="toggleBuyDateCheck" color="#e6e7ec"></CommonCheckbox></div><span style="padding:13px;"></span>수리일자
                    </template>
                    <template v-slot:content>
                        <CommonInput :click-input="showCalendar" v-model="buyDate"></CommonInput>
                        <div v-if="isShowCalendar" ref="periodWrap" style="position:fixed; z-index:2; background-color:white; border:1px solid #e6e7ec; border-radius:4px; ">
                            <PeriodCalendar :complete-callback="completSelectDate" :init-start-date="startDate" :init-end-date="endDate" :cancel-callback="hideCalendar"></PeriodCalendar>
                        </div>
                    </template>
                </LabelPanel>
                <LabelPanel style="width:233px; margin-left:19px;">
                    <template v-slot:label>검색어</template>
                    <template v-slot:content>
                        <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:5px;">
                    <template v-slot:label>&nbsp;</template>
                    <template v-slot:content>
                        <button class="search_btn" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
                    </template>
                </LabelPanel>
            </div>
        </template>
         <template v-slot:table_panel>
            <BatchBtns 
                :is-show-first-checkbox="true"
                :is-show-delete-btn="true" 
                :delete-action="deleteList"
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :downloadExcel="downloadExcel"
                :isShowExcelDownload="isAllowedForExcel"
                ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :plist="list" :pcolumn="column" :pcustom="custom" :paging="paging" :change-page="detectChangePage" :isShowCheckbox="true" 
                    :isShowNumbering="true"
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="repairIdx"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>

         </template>

        <template v-slot:etc>
            <div><LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {locName = (data.text !== '위치코드' ?  data.text : ''); locCode = data.value}"></LocSearch></div>
            <div><GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {groupName = (data.text !== '부서코드' ?  data.text : ''); groupCode = data.value}"></GroupSearch></div>
            <div><ItemSearch v-if="isShowItemSearch" :hideModal="() => {isShowItemSearch = false}" :completeSave="(data) => {itemName =  (data.text !== '재산구분코드' ? data.text : ''); itemCode = data.value}"></ItemSearch></div>
        </template>
    </TableSearchPanel>
</template>

<script>

import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import PeriodCalendar from '@/components/common/PeriodCalendar.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import ItemSearch from '@/components/modal/ItemSearch.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import moment from 'moment';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {states, AUTH, NUMBER_STYLE, SIZE_PER_PAGE} from '@/util/Constants.js';

export default {
    name: 'AssetRepairHistory',
    components : {
        PageHeader, TableSearchPanel, CommonTable, ColumnSetting, BatchBtns, LabelPanel, CommonInput, CommonSelect, PeriodCalendar, CommonCheckbox, LocSearch, 
        GroupSearch, ItemSearch, ConditionInput
    },
    data(){
        return {
            AUTH : AUTH,
            isShowColumnSettings : false,
            startDate : '',
            endDate : '',
            buyDate : '',
            isShowCalendar : false,
            isBuyDateChecked : false,

            locName : '',
            locCode : '',
            groupName : '',
            groupCode : '',
            itemName : '',
            itemCode : '',
            lastPage : 1,
            lastSizePerPage: SIZE_PER_PAGE,
            lastWithoutSearchWord : '',
            isShowLocSearch : false,
            isShowGroupSearch : false,
            isShowItemSearch : false,
            stateCode : '',
            conditionCode : '',
            searchWord : '',

            savedSelectedList : {},
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },

            list: [],
            custom : {},
            column: [
                { key: "itemName", name: "재산구분", w: "120px"},
                { key: "assetName", name: "자산명칭", w: "150px"},
                { key: "repairCompany", name: "수리업체", w: "100px" },
                { key: "requestDate", name: "수리의뢰일자", w: "100px", dateLength10 : true},
                { key: "requestContents", name: "수리의뢰내용", w: "200px" },
                { key: "repairDate", name: "수리완료일자", w: "100px", dateLength10 : true },
                { key: "repairContents", name: "수리내용", w: "200px" },
                { key: "repairCost", name: "수리금액", w: "100px", comma : true, style :NUMBER_STYLE },
                { key: "modelName", name: "모델명", w: "100px" },
                { key: "groupName", name: "부서", w: "100px" },
                { key: "locName", name: "위치", w: "100px" },
                { key: "baseName", name: "사용상태", w: "100px" },
                { key: "standard", name: "규격", w: "100px" },
                { key: "buyPrice", name: "취득가액", w: "100px", comma: true, style :NUMBER_STYLE },
                { key: "buyDate", name: "취득일자", w: "100px", dateLength10 : true },
                { key: "barcode", name: "바코드", w: "100px" },
                { key: "field1", name: "추가항목1", w: "100px" },
                { key: "field2", name: "추가항목2", w: "100px" },
                { key: "field3", name: "추가항목3", w: "100px" },
                { key: "userName", name: "사용자", w: "100px" },
                { key: "chargeName", name: "담당자", w: "100px" },
                { key: "selling", name: "구매처", w: "100px" },
                { key: "serial", name: "시리얼", w: "100px" },
                { key: "scrapPrice", name: "잔존가액", w: "100px", comma: true, style :NUMBER_STYLE },
                { key: "field4", name: "추가항목4", w: "100px" },
                { key: "field5", name: "추가항목5", w: "100px" },
                { key: "field6", name: "추가항목6", w: "100px" },
                { key: "field7", name: "추가항목7", w: "100px" },
                { key: "field8", name: "추가항목8", w: "100px" },
            ],
            paging : {},
            states : [],
            searchConditions: [
                {name : '자산명칭', key : 'assetName', checked: true},
                {name : '자산코드', key : 'assetCode', checked: true},
                {name : '모델명', key : 'modelName', checked: true},
                {name : '시리얼', key : 'serial', checked: true},
                {name : '규격', key : 'standard', checked: true},
                {name : '취득가액/자존가액', key : 'np_price', checked : true},
                {name : '담당자/사용자', key : 'userName', checked : true},
                {name : '구매처', key : 'companyName', checked: true},
                {name : '바코드', key : 'barcode', checked: true},
                {name : '추가항목1', key : 'field1', checked: true},
                {name : '추가항목2', key : 'field2', checked: true},
                {name : '추가항목3', key : 'field3', checked: true},
                {name : '추가항목4', key : 'field4', checked: true},
                {name : '추가항목5', key : 'field5', checked: true},
                {name : '추가항목6', key : 'field6', checked: true},
                {name : '추가항목7', key : 'field7', checked: true},
                {name : '추가항목8', key : 'field8', checked: true},
                {name : '수리의뢰내용', key : 'requestContents', checked: true},
                {name : '수리내용', key : 'repairContents', checked: true},
                {name : '수리업체', key : 'repairCompany', checked: true},
                {name : '수리금액', key : 'np_repairCost', checked: true},
            ]
        }
    },
    methods : {
        showCalendar(){
            this.isShowCalendar = true;
        },
        hideCalendar(){
            this.isShowCalendar = false;
        },
        completSelectDate(startDate, endDate){
            this.isBuyDateChecked = true;
            this.startDate = startDate;
            this.endDate = endDate;
            this.buyDate = startDate + ' ~ ' + endDate;
            this.hideCalendar();
        },
        toggleBuyDateCheck(){
            this.isBuyDateChecked = !this.isBuyDateChecked;
        },
        getRepairHistory(page, withoutSearchWord){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            
            const parameters = this.getParameters();

            axios.get('/api/asset/repairhistory', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const assetRepairHistory = data.assetRepairHistory.content;
                    this.searchCnt = data.assetRepairHistory.totalElements;
                    data.assetRepairHistory.content = null;
                    // const assetSum = data.assetSum;
                    this.list = assetRepairHistory;
                    this.paging = data.assetRepairHistory;   
                }
            }).catch(res => {
                console.log(res);
            });
        },
        detectConditionChange(data){
            this.conditionCode = data
        },
        detectStateChange(data){
            this.stateCode = data;
        },
        search(){
            this.getRepairHistory(1, this.lastWithoutSearchWord);
        },
        detectChangePage(page){
            this.getRepairHistory(page, this.lastWithoutSearchWord);
        },
        getParameters(){
            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;
            parameters.deleted = 0;

            let conditions = [];
            let searchWords = [];

            if(this.stateCode !== ''){
                conditions.push('stateCode');
                searchWords.push(this.stateCode);
            }

            // if(this.conditionCode !== ''){
            //     conditions.push(this.conditionCode);
            //     searchWords.push(this.searchWord);
            // }

            if(this.locName != ''){
                conditions.push('np_locName');
                searchWords.push(this.locName);
            }

            if(this.itemName != ''){
                conditions.push('np_itemName');
                searchWords.push(this.itemName);
            }

            if(this.groupName != ''){
                conditions.push('np_groupName');
                searchWords.push(this.groupName);
            }

            if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            if(this.isBuyDateChecked){
                parameters.searchBeginDate = this.startDate;
                parameters.searchEndDate = this.endDate;
            }
            
            return parameters;
        },
        deleteList(){
            const parameters = this.getParameters();

            let deleteCnt = this.searchCnt;

            if(!this.btnState.isSelectAll){
                const keys = Object.keys(this.savedSelectedList);
                for(const index in keys){
                    if(!this.savedSelectedList[keys[index]].repairDate){
                        alert("[" + keys.length + "]개의 완료되지않은 수리내역은 삭제할 수 없습니다. 완료 후 삭제해주세요.");
                        return;
                    }
                } 
                deleteCnt = keys.length;
                parameters.repairIdxes = Object.keys(this.savedSelectedList);
            }

            if(!confirm('[' + deleteCnt + ']개의 수리내역을 삭제하시겠습니까?')){
                return;
            }

            axios.post('/api/asset/repairhistory/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.getRepairHistory(1, this.lastWithoutSearchWord)
                }
            }).catch(res => {
                console.log(res);
            });

        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/asset/repairhistory/excel?" + queryStr);
            location.href = this.$versionPath + "/api/asset/repairhistory/excel?" + queryStr
        },
        enter(){
            this.search();
        },
    },
    computed : {
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.자산현황조회, AUTH.EXCEL)
        },
    },
    created(){
        const today = moment().format('YYYY-MM-DD');
        this.startDate = today;
        this.endDate = today;
        this.buyDate = today + ' ~ ' + today;

        this.states = states;

        this.getRepairHistory(1, false);

        this.$commonFunc.setFieldNames(this.searchConditions);
        ConditionManager.setConditions(this.$options.name, this.searchConditions);
        this.$commonFunc.setFieldNames(this.column);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
    
}
</script>

<style scoped>
.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>