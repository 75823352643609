<template>
  <TableSearchPanel :is-show-left-panel="true" :is-show-search-panel="true" :is-show-right-panel="true" :is-show-table-panel="true">
    <template v-slot:pageheader>
      <PageHeader title="자산관리">
        <span v-if="isAllowedForInsert" style="float:right;height: 0px;position:relative;">
          <button class="add_btn" @click="showAssetDetail(MODE.INSERT, {})">+ 자산등록</button>
        </span>
      </PageHeader>
    </template>

    <template v-slot:left_panel>
      <div class="tab_wrap">
        <a class="tab" :class="[curTab === 0 ? 'active' : '']" @click="changeTab(0)" :style="{}">재산구분</a>
        <a class="tab" :class="[curTab === 1 ? 'active' : '']" @click="changeTab(1)" :style="{}">위치별</a>
        <a class="tab" :class="[curTab === 2 ? 'active' : '']" @click="changeTab(2)" :style="{}">부서별</a>
      </div>
      <CommonTree 
          v-show="curTab === 0" 
          :key="0" 
          :p-tree-data="treeData[0]" 
          :detectSelect="(data) => {detectTreeSelect(data, CODE_TYPE.ITEM)}" 
          :multiple="false" 
          :isFirstSelected="true"
          :isDragWork="true"
          :isShowHoverBtns="true"
          :detectAddTree="(data) => {detectAddTree('item', data)}"
          :detectRemoveTree="(data) => {detectRemoveTree('item', data)}"
          :detectEditTree="(data) => {detectEditTree('item', data)}"
          :detectDragComplete="(movedTarget, hoveredTarget, isBetween) => {detectDragComplete('item', movedTarget, hoveredTarget, isBetween)}"
          :isUseExcelDownload="true"
          :detectExcelDownload="() => {downloadCodeExcel(CODE_TYPE.ITEM)}"
          ref="itemTree"
          ></CommonTree>
      <CommonTree 
          v-show="curTab === 1" 
          :key="1" 
          :p-tree-data="treeData[1]" 
          :detectSelect="(data) => {detectTreeSelect(data, CODE_TYPE.LOC)}" 
          :isDragWork="true"
          :isShowHoverBtns="true"
          :detectAddTree="(data) => {detectAddTree('loc', data)}"
          :detectRemoveTree="(data) => {detectRemoveTree('loc', data)}"
          :detectEditTree="(data) => {detectEditTree('loc', data)}"
          :detectDragComplete="(movedTarget, hoveredTarget, isBetween) => {detectDragComplete('loc', movedTarget, hoveredTarget, isBetween)}"
          ref="locTree"
          :isUseExcelDownload="true"
          :detectExcelDownload="() => downloadCodeExcel(CODE_TYPE.LOC)"
          ></CommonTree>
      <CommonTree 
          v-show="curTab === 2" 
          :key="2" 
          :p-tree-data="treeData[2]" 
          :detectSelect="(data) => {detectTreeSelect(data, CODE_TYPE.GROUP)}" 
          :isDragWork="true"
          :isShowHoverBtns="true"
          :detectAddTree="(data) => {detectAddTree('group', data)}"
          :detectRemoveTree="(data) => {detectRemoveTree('group', data)}"
          :detectEditTree="(data) => {detectEditTree('group', data)}"
          :detectDragComplete="(movedTarget, hoveredTarget, isBetween) => {detectDragComplete('group', movedTarget, hoveredTarget, isBetween)}"
          ref="groupTree"
          :isUseExcelDownload="true"
          :detectExcelDownload="() => {downloadCodeExcel(CODE_TYPE.GROUP)}"
          ></CommonTree>
    </template>

    <template v-slot:search_panel>
      <div style="display:flex;">
        <LabelPanel style="width:233px;">
          <template v-slot:label>검색어</template>
          <template v-slot:content>
            <ConditionInput v-model="searchWord" :searchList="conditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
          </template>
        </LabelPanel>
        <LabelPanel style="margin-left:19px;width:260px;">
          <template v-slot:label>
            <div style=" position: absolute;top: -5px;left: -2px;"><CommonCheckbox :flag="isBuyDateChecked" :click-check="toggleBuyDateCheck" color="#e6e7ec"></CommonCheckbox></div><span style="padding:13px;"></span>취득일자
          </template>
          <template v-slot:content>
            <CommonInput :click-input="showCalendar" v-model="buyDate" icon="calendar" style="width:272px;"></CommonInput>
            <!-- <input type="text" class="search_input right_icon" style="width:272px;" v-model="buyDate" @click="showCalendar"> -->
            <div v-if="isShowCalendar" style="position:fixed; z-index:11; background-color:white; border:1px solid #e6e7ec; border-radius:4px;">
              <PeriodCalendar :complete-callback="completSelectDate" :init-start-date="startDate" :init-end-date="endDate" :cancel-callback="hideCalendar"></PeriodCalendar>
            </div> 
          </template>
        </LabelPanel>
        <button class="search_btn" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
      </div>

    </template>

    <template v-slot:table_panel>
        <CommonTableTab :list="tabList" :detect-tab-change="detectTabChange" :signalTabReset="signalTabReset"></CommonTableTab>
        
        <div class="data_table_wrap">
          <BatchBtns 
              :is-show-first-checkbox="true"
              :is-show-delete-btn="true && isAllowedForDelete" 
              :delete-action="deleteList" 
              :is-show-modify-btn="true && isAllowedForUpdate"
              :is-show-print-btn="true"
              :is-show-select-all-btn="true"
              :show-column-setting-action="() => {isShowColumnSettings = true}"
              :savedSelectedList="savedSelectedList"
              :btnState="btnState"
              :searchCnt="searchCnt"
              :downloadExcel="downloadExcel"
              :modifyAction="() => {showAssetDetail(MODE.BATCH)}"
              :isShowExcelDownload="isAllowedForExcel"
              :printAction="print"
              >
          </BatchBtns>
          <div style="flex:1; overflow:hidden">
            <CommonTable 
              :plist="list" 
              :pcolumn="column" 
              :pcustom="custom" 
              :is-show-numbering="true" 
              :paging="paging" 
              :change-page="detectChangePage" 
              :isShowCheckbox="true" 
              :savedSelectedList="savedSelectedList" 
              savedSelectedListKey="assetCode"
              :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
              :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
              :btnState="btnState"
              :clickDoubleRow="(data) => {if(!isAllowedForUpdate) return; showAssetDetail(MODE.UPDATE, data);}"
              ></CommonTable>
          </div>
        </div>

        <ColumnSetting v-if="isShowColumnSettings" 
              :vue-name="$options.name" 
              :default-settings="column" 
              :closeSettings="() => {isShowColumnSettings = false}" 
              :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
        </ColumnSetting>
    </template>
 
    <template v-slot:etc>
      <div><AssetDetail v-if="isShowAssetDetail" :hide-asset-detail="hideAssetDetail" :transferData="transferData"></AssetDetail></div>
      <TreeSubcodeRegister v-if="isShowTreeSubcodeRegister" :hideModal="() => {isShowTreeSubcodeRegister = false}" :curMode="treeSubcodeRegisterMode" :transferData="treeSubcodeTransferData" :completeFunc="completeSubcodeUpdate" :curCodeType="treeSubcodeCurCodeType"></TreeSubcodeRegister>
      <LabelPrinting v-if="isShowLabelPrinting" :hideModal="() => {isShowLabelPrinting = false}" :transferData="printData"></LabelPrinting>
    </template>
    
  </TableSearchPanel>
</template>

<script>

import PageHeader from '@/components/common/PageHeader.vue';
import CommonTree from '@/components/common/CommonTree.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import PeriodCalendar from '@/components/common/PeriodCalendar.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ConditionManager from '@/modules/ConditionManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import AssetDetail from '@/components/modal/AssetDetail.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import CommonTableTab from '@/components/common/CommonTableTab.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import TreeSubcodeRegister from '@/components/modal/TreeSubcodeRegister.vue';
import LabelPrinting from '@/components/modal/LabelPrinting.vue';
import moment from 'moment';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, states, CODE_TYPE, AUTH, NUMBER_STYLE, SIZE_PER_PAGE, BROWSER_NAME} from '@/util/Constants.js';
import TTP_PRINTER from '@/modules/TTP_PRINTER.js'
let showAssetDetail = null;
let deleteOne = null;

const menu = {
  template : `
    <div style="position:absolute;top: 2px;right: 0px;">
      <button style="border:0px; cursor:pointer; padding: 15px 15px;background-color:transparent;" @click="$event.stopPropagation();toggle($event)" @blur="blured"><font-awesome-icon icon="fa-solid fa-ellipsis" style="color:#494a4f;" /></button>
      <div v-if="isShow" style="position:fixed;width:145px; right:110px;background-color:white;border:1px solid #e6e7ec;border-radius:5px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);" :style="{top : top}">
        <div v-if="isAllowedForUpdate" style="padding:18px 30px; color:#494a4f;cursor:pointer;" @click="modifyAsset($event)">
          <font-awesome-icon icon="fa-solid fa-file-pen" style="color:#a7a8ba;width:20px;text-align:center;"/>
          <span style="margin-left:10px;" >수정</span>
        </div>
        <div v-if="isAllowedForDelete" style="padding:18px 30px;color:#494a4f; border-top:1px solid #e6e7ec; cursor:pointer;" @click="deleteAsset($event)">
          <font-awesome-icon icon="fa-regular fa-trash-can" style="color:#a7a8ba;width:20px;text-align:center;"/>
          <span style="margin-left:10px;" >삭제</span>
        </div>
        <div v-if="isAllowedForInsert" style="padding:18px 30px; color:#494a4f; border-top:1px solid #e6e7ec;cursor:pointer;" @click="copyAsset($event)">
          <font-awesome-icon icon="fa-regular fa-copy" style="color:#a7a8ba;width:20px;text-align:center;"/>
          <span style="margin-left:10px;" >복사</span>
        </div>
        <div style="padding:18px 30px; color:#494a4f; border-top:1px solid #e6e7ec;cursor:pointer;">
          <font-awesome-icon icon="fa-solid fa-print" style="color:#a7a8ba;width:20px;text-align:center;"/>
          <span style="margin-left:10px;">출력</span>
        </div>
      </div>
    </div>
  `,
  props : ['data', 'column', 'index'],
  data(){
    return {
      AUTH : AUTH,
      isShow : false,
      top : '0px',
    }
  },
  computed : {
    isAllowedForInsert(){
        return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.INSERT)
    },
    isAllowedForUpdate(){
        return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.UPDATE)
    },
    isAllowedForDelete(){
        return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.DELETE)
    },
  },
  methods : {
    blured(){
      setTimeout(() => {
        this.isShow = false;
      }, 100)
    },
    toggle(e){
      const flag = !this.isShow;
      if(flag){
        const rect = e.currentTarget.getBoundingClientRect();
        this.top = (rect.y + 27) + 'px';
      }
      this.isShow = flag;
    },
    modifyAsset(e){
      e.stopPropagation();
      showAssetDetail(MODE.UPDATE, this.data);
    },
    copyAsset(e){
      e.stopPropagation();
      showAssetDetail(MODE.COPY, this.data);
    },
    deleteAsset(e){
      e.stopPropagation();
      
      if(!confirm('선택한 [' + this.data.assetName + ', ' + this.data.barcode + '] 자산을 삭제하시겠습니까?')){
        return;
      }

      deleteOne(this.data.assetCode);
    },
  }
}

const assetName = {
  template : `<div @click.stop="modifyAsset" style='cursor:pointer;'>{{data.assetName}}</div>`,
  props : ['data', 'index', 'column'],
  methods : {
    modifyAsset(){
      showAssetDetail(MODE.UPDATE, this.data);
    }
  }
}

export default {
  name: 'AssetMng',
  components : {PageHeader, CommonTree, CommonTable, PeriodCalendar, ColumnSetting, AssetDetail, BatchBtns, 
      TableSearchPanel, CommonTableTab, LabelPanel, ConditionInput, CommonCheckbox, CommonInput,
      TreeSubcodeRegister, LabelPrinting
      },
  computed : {
    isAllowedForInsert(){
        return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.INSERT)
    },
    isAllowedForUpdate(){
        return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.UPDATE)
    },
    isAllowedForDelete(){
        return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.DELETE)
    },
    isAllowedForExcel(){
        return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.EXCEL)
    },
    signalRefresh(){
      return this.$GlobalStore.state.signalAssetMngRefresh;
    } 
  },
  data(){
    return {
      MODE : MODE,
      AUTH : AUTH,
      CODE_TYPE : CODE_TYPE,
      curCode : '',
      curCodeType : '',
      curCodeName : '',
      isShowAssetDetail : false,
      isBuyDateChecked : false,
      isShowTreeSubcodeRegister : false,
      isShowLabelPrinting : false,
      printData : [],
      transferData : {},
      curTab : 0,
      curStateTab : 0,
      selectedList : [],
      lastWithoutSearchWord : false,
      lastPage : 1,
      lastSizePerPage : SIZE_PER_PAGE,
      lastTabValue : '',
      signalTabReset : false,
      treeSubcodeRegisterMode : -1,
      treeSubcodeTransferData : {},
      treeSubcodeCurCodeType : '',
      isShowCalendar : false,
      startDate : '',
      endDate : '',
      buyDate : '',
      isShowColumnSettings : false,
      searchWord : '',
      curSearchTreeNames : ['', '', ''],
      isFirstTreeGet : true,
      searchCnt : 0,
      savedSelectedList : {},
      btnState:{
        isSelectAll : false,
        isSelectedOneAtLeast : false,
        signalSelectCurPageAll : false,
        signalUnselectCurPageAll : false,
      },
      treeData : {
          0 : [],
          1 : [],
          2 : [],
      },
      tabList : [
        {name : '전체', cnt : 0, value : 'code0'},
        {name : '사용', cnt : 0, value : 'code0'},
        {name : '수리', cnt : 0, value : 'code0'},
        {name : '대여', cnt : 0, value : 'code0'},
        {name : '불용', cnt : 0, value : 'code0'},
        {name : '분실', cnt : 0, value : 'code0'},
        {name : '폐기', cnt : 0, value : 'code0'},
        {name : '보관', cnt : 0, value : 'code0'}
      ],
      list: [],
      custom : {
        assetName : assetName,
        column100 : menu
      },
      column : [
        { key: "assetName", name: "자산명칭", w: "150px", fixed : true},
        { key: "itemName", name: "재산구분", w: "100px"},
        { key: "modelName", name: "모델명", w: "140px"},
        { key: "standard", name: "규격", w: "100px" },
        { key: "buyDate", name: "취득일자", w: "100px" },
        { key: "buyPrice", name: "취득가액", w: "100px", comma: true, style : NUMBER_STYLE  },
        { key: "groupName", name: "부서", w: "100px" },
        { key: "locName", name: "위치", w: "100px" },
        { key: "baseName", name: "사용상태", w: "100px" },
        { key: "barcode", name: "바코드", w: "130px" },
        { key: "field1", name: "추가항목1", w: "100px" },
        { key: "field2", name: "추가항목2", w: "100px" },
        { key: "field3", name: "추가항목3", w: "100px" },
        { key: "get_days", name: "취득경과일", w: "100px" },
        { key: "serial", name: "시리얼", w: "200px" },
        { key: "scrapPrice", name: "잔존가액", w: "100px", comma: true, style : NUMBER_STYLE },
        { key: "charge", name: "담당자", w: "100px" },
        { key: "user", name: "사용자", w: "100px" },
        { key: "selling", name: "구매처", w: "100px" },
        { key: "field4", name: "추가항목4", w: "100px" },
        { key: "field5", name: "추가항목5", w: "100px" },
        { key: "field6", name: "추가항목6", w: "100px" },
        { key: "field7", name: "추가항목7", w: "100px" },
        { key: "field8", name: "추가항목8", w: "100px" },
        { key: "insert_user", name: "등록자", w: "100px" },
        { key: "insert_date", name: "등록일시", w: "180px" },
        { key: "update_user", name: "수정자", w: "100px" },
        { key: "update_date", name: "수정일시", w: "180px" },
        { key: "column100", name: '', w: "40px", style : {right : '0px'}, fixed : true}
      ],
      paging : {},
      conditions : [
        {name : '자산명칭', key : 'assetName', checked : true},
        {name : '재산구분', key : 'itemName', checked : true},
        {name : '부서명', key : 'groupName', checked : true},
        {name : '위치명', key : 'locName', checked : true},
        {name : '모델명', key : 'modelName', checked : true},
        {name : '시리얼', key : 'serial', checked : true},
        {name : '규격', key : 'standard', checked : true},
        {name : '담당자', key : 'charge', checked : true},
        {name : '사용자', key : 'user', checked : true},
        {name : '취득가액', key : 'buyPrice', checked : true},
        {name : '취득일자', key : 'buyDate', checked : true},
        {name : '등록일자', key : 'insertDate', checked : true},
        {name : '구매처', key : 'selling', checked : true},
        {name : '바코드', key : 'barcode', checked : true},
        {name : '추가항목1', key : 'field1', checked : true},
        {name : '추가항목2', key : 'field2', checked : true},
        {name : '추가항목3', key : 'field3', checked : true},
        {name : '추가항목4', key : 'field4', checked : true},
        {name : '추가항목5', key : 'field5', checked : true},
        {name : '추가항목6', key : 'field6', checked : true},
        {name : '추가항목7', key : 'field7', checked : true},
        {name : '추가항목8', key : 'field8', checked : true}
      ],
    }
  },
  methods : {
    toggleBuyDateCheck(){
      this.isBuyDateChecked = !this.isBuyDateChecked;
    },
    enter(){
      this.search();
    },
    selectDateFunc(datestr){

    },
    changeTab(index){
        this.savedSelectedList = {};
        this.curTab = index;
        this.getAssets(1, false);
        // if(this.treeData[index].length > 0) return;
        this.getTree(index)
    },
    detectChangePage(page){
      this.getAssets(page, this.lastWithoutSearchWord);
    },
    showCalendar(){
      this.isShowCalendar = true;
    },
    hideCalendar(){
      this.isShowCalendar = false;
    },
    completSelectDate(startDate, endDate){
      this.isBuyDateChecked = true;
      this.startDate = startDate;
      this.endDate = endDate;
      this.buyDate = startDate + ' ~ ' + endDate;
      this.hideCalendar();
    },
    hideAssetDetail(){
      this.isShowAssetDetail = false;
    },
    showAssetDetail(mode, data){

      if(mode === this.MODE.BATCH){
        const batchData = {
          savedSelectedList : this.savedSelectedList,
          isSelectAll : this.btnState.isSelectAll,
          queryParameters : this.getParameters()
        }

        this.transferData = {
          mode : mode,
          data : batchData,
          codeType : this.curCodeType,
          codeName : this.curCodeName,
          code : this.curCode,
          completeFunc : () => {
            this.savedSelectedList = {};
            this.getTree(this.curTab);
            this.search()
          }
        }
       
      }else {
        this.transferData = {
          mode : mode,
          data : data,
          codeType : this.curCodeType,
          codeName : this.curCodeName,
          code : this.curCode,
          completeFunc : () => {
            this.savedSelectedList = {};
            this.getTree(this.curTab);
            this.search()
          }
        }
      }
      
      this.isShowAssetDetail = true;
    },
    detectTabChange(data, index){
      this.lastTabValue = data.value;

      this.getAssets(1, this.lastWithoutSearchWord);
    },
    getTree(type, treeSearchWord){
      const parameters = {};
      parameters.topName = '전체';
      parameters.isShowDeletedCode = true;
      let path = "";
      //품목별
      if(type === 0){
        path = "itemCode";
      //위치별
      }else if(type === 1){
        path = "locCode";
      //부서별
      }else if(type === 2){
        path = "groupCode";
      }

      axios.get('/api/asset/cate/' + path, {params : parameters}).then(res => {
        if(res.data.code == ResCode.Success){
            const tree = res.data.data.tree
            if(this.isFirstTreeGet) {
                tree[0].selected = true;
                this.isFirstTreeGet = false;
            }
            this.treeData[type] = tree;
            if(treeSearchWord){
              if(type === 0){
                this.$refs['itemTree'].__setSelectWithSearchWord(treeSearchWord);
              }else if(type === 1){
                this.$refs['locTree'].__setSelectWithSearchWord(treeSearchWord);
              }else if(type === 2){
                this.$refs['groupTree'].__setSelectWithSearchWord(treeSearchWord);
              }
            }
        }
      }).catch(res => {
          console.log(res);
      });
    },
    getAssets(page, withoutSearchWord){
      this.lastPage = page;
      this.lastWithoutSearchWord = withoutSearchWord; 
      const parameters = this.getParameters();

        axios.get('/api/asset/list', {params : parameters}).then(res => {
            if(res.data.code == ResCode.Success){
              const data = res.data.data
              const assets = data.assets.content;
              this.searchCnt = data.assets.totalElements;
              data.assets.content = null;
              const assetSum = data.assetSum;
              this.list = assets;
              this.paging = data.assets;   

              
              const newStates = [
                {name : '전체', cnt : assetSum.cnt, value : ''},
                {name : '사용', cnt : assetSum.useCnt, value : 'B001001000'},
                {name : '수리', cnt : assetSum.repairCnt, value : 'B001002000'},
                {name : '대여', cnt : assetSum.rentCnt, value : 'B001003000'},
                {name : '불용', cnt : assetSum.nouseCnt, value : 'B001004000'},
                {name : '분실', cnt : assetSum.lossCnt, value : 'B001005000'},
                {name : '폐기', cnt : assetSum.disposalCnt, value : 'B001006000'},
                {name : '보관', cnt : assetSum.keepCnt, value : 'B001007000'}
              ]

              newStates[1].name = states[1].name;
              newStates[2].name = states[2].name;
              newStates[3].name = states[3].name;
              newStates[4].name = states[4].name;
              newStates[5].name = states[5].name;
              newStates[6].name = states[6].name;
              newStates[7].name = states[7].name;

              this.tabList = newStates;

            }
        }).catch(res => {
            console.log(res);
        });
    },
    getParameters(){
        const parameters = {};
        parameters.page = this.lastPage;
        parameters.sizePerPage = this.lastSizePerPage;
        parameters.deleted = 0;

        let conditions = [];
        let searchWords = [];

        // if(this.searchWord !== '' && !this.lastWithoutSearchWord){
        //     conditions.push(this.lastCondition);
        //     searchWords.push(this.searchWord);
        // }

        if(this.curSearchTreeNames[this.curTab] != '' && this.curSearchTreeNames[this.curTab] != '전체'){
            let treeName = '';
            if(this.curTab === 0){
                treeName = 'np_itemTree';
            }else if(this.curTab === 1){
                treeName = 'np_locTree';
            }else if(this.curTab === 2){
                treeName = 'np_groupTree';
            }
            conditions.push(treeName);
            searchWords.push(this.curSearchTreeNames[this.curTab]);
        }

        if(this.lastTabValue != ''){
          conditions.push('stateCode');
          searchWords.push(this.lastTabValue);
        }

        if(this.searchWord != ''){
          ConditionManager.addConditions(conditions, searchWords, this.conditions, this.searchWord);
        }

        if(this.isBuyDateChecked){
            parameters.searchBeginDate = this.startDate;
            parameters.searchEndDate = this.endDate;
        }

        if(conditions.length > 0){
            parameters.conditions = conditions.join('::');
            parameters.searchWords = searchWords.join("::");
        }

        return parameters;
    },
    search(){
        this.getAssets(1, false);
    },
    detectTreeSelect(data, codeType){
        this.curSearchTreeNames[this.curTab] = data.text;
        this.curCodeType = codeType;
        this.curCode = data.value;
        this.curCodeName = data.text;
        this.lastTabValue = '';
        this.signalTabReset = !this.signalTabReset;
        this.getAssets(1, this.lastWithoutSearchWord);
        // this.getTabList()
    },
    getTabList(){
      const parameters = this.getParameters();

      axios.get('/api/asset/cnt', {params : parameters}).then(res => {
          if(res.data.code == ResCode.Success){
            const data = res.data.data;
            const newStates = [
              {name : '전체', cnt : data.totalCnt, value : ''},
              {name : '사용', cnt : data.useCnt, value : 'B001001000'},
              {name : '수리', cnt : data.repairCnt, value : 'B001002000'},
              {name : '대여', cnt : data.rentCnt, value : 'B001003000'},
              {name : '불용', cnt : data.nouseCnt, value : 'B001004000'},
              {name : '분실', cnt : data.lossCnt, value : 'B001005000'},
              {name : '폐기', cnt : data.disposalCnt, value : 'B001006000'},
              {name : '보관', cnt : data.keepCnt, value : 'B001007000'}
            ]

            newStates[1].name = states[1].name;
            newStates[2].name = states[2].name;
            newStates[3].name = states[3].name;
            newStates[4].name = states[4].name;
            newStates[5].name = states[5].name;
            newStates[6].name = states[6].name;
            newStates[7].name = states[7].name;

            this.tabList = newStates;
          }
      }).catch(res => {
          console.log(res);
      });
    },
    downloadExcel(){
      let queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
      console.log("/api/asset/list/excel?" + queryStr);
      location.href = this.$versionPath + "/api/asset/list/excel?" + queryStr
    },
    deleteList(){
      const parameters = this.getParameters();
      parameters.isAll = this.btnState.isSelectAll;
      parameters.deleteChange = true;
      parameters.deleted = 1;
      let deleteCnt = this.searchCnt;

      if(!this.btnState.isSelectAll){
          const keys = Object.keys(this.savedSelectedList);
          // for(const index in keys){
          //     if(this.savedSelectedList[keys[index]].stateCode == 'B001002000' || this.savedSelectedList[keys[index]].stateCode == 'B001003000'){
          //         alert("자산상태가 수리/대여 중인 자산은 '사용상태'를 변경 할 수 없습니다. (" + keys.length + "개 자산)");
          //         return;
          //     }
          // } 
          deleteCnt = keys.length;
          parameters.assetCodes = Object.keys(this.savedSelectedList);
      }

      let msg = "";
      const firstAsset = this.savedSelectedList[(Object.keys(this.savedSelectedList)[0])];
      if(deleteCnt > 1) msg = '선택한 [' + firstAsset.assetName + (firstAsset.barcode ? ', ' + firstAsset.barcode : '') + '] 자산 외 ' + (deleteCnt - 1) + '개의 자산을 삭제하시겠습니까?'
      if(deleteCnt === 1) msg = '선택한 [' + firstAsset.assetName + (firstAsset.barcode ? ', ' + firstAsset.barcode : '') + '] 자산을 삭제하시겠습니까?'; 

      if(!confirm(msg)){
          return;
      }

      axios.post('/api/asset/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          // alert('삭제되었습니다.');
          this.savedSelectedList = {};
          this.getTree(this.curTab);
          this.getAssets(1, this.lastWithoutSearchWord)
          // this.getTabList();
        }
      }).catch(res => {
          console.log(res);
      });
    },
    deleteOne(assetCode){
      const parameters = this.getParameters();
      parameters.isAll = false;
      parameters.deleteChange = true;
      parameters.deleted = 1;
      parameters.assetCodes = [assetCode];

      axios.post('/api/asset/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          // alert('삭제되었습니다.');
          this.savedSelectedList = {};
          this.getAssets(1, this.lastWithoutSearchWord)
          // this.getTabList();
        }
      }).catch(res => {
          console.log(res);
      });
    },
    detectAddTree(type, data){
      const parameters = {};
      parameters.level = data.level;
      parameters.parentCode = data.parentCode;
      parameters.text = data.text;
      parameters.type = type;

      axios.post('/api/base/tree/add', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Fail){
            alert(res.data.message);
        }
        this.getTree(this.curTab);
      }).catch(res => {
          console.log(res);
      });
    },
    detectRemoveTree(type, data){
      // if(!confirm('[' + data.text + ']을(를) 삭제하시겠습니까?')){
      //   return;
      // }

      let title = '';
      let additionalInfo = "";
      if(type === CODE_TYPE.ITEM){
        title = '재산구분';
      }else if(type === CODE_TYPE.LOC){
        title = '위치';
      }else if(type === CODE_TYPE.GROUP){
        title = '부서';
        additionalInfo = " 및 사원";
      }

      if(!confirm('[' + data.text + ']에 속하는 모든 자산' + additionalInfo + '은\n삭제된 ' + title + '(으)로 이동하고\n[' + data.text + ']은 삭제됩니다.\n정말로 삭제하시겠습니까?')){
        return;
      }

      const parameters = {};
      parameters.value = data.value;
      parameters.type = type;

      axios.post('/api/base/tree/remove', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          this.getTree(this.curTab);
        }else if(res.data.code == ResCode.Fail){
          alert(res.data.data.msg);
        }
      }).catch(res => {
          console.log(res);
      });
    },
    detectEditTree(type, data){
      const parameters = {};
      parameters.value = data.value;
      parameters.type = type;
      parameters.newName = data.text;

      axios.post('/api/base/tree/rename', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Fail){
          alert(res.data.message);
        }
        this.getTree(this.curTab);
      }).catch(res => {
          console.log(res);
      });
    },
    getChildrenValueList(movedTarget){
      const recursiveAddValue = (treeData, valueList) => {
          for(let i = 0; i < treeData.length; i++){
              valueList.push(treeData[i].value);

              if(treeData[i].children != null && treeData[i].children.length > 0){
                  recursiveAddValue(treeData[i].children, valueList);
              }
          }
      }

      const valueList = [];
      recursiveAddValue(movedTarget, valueList);
      return valueList;
    },
    detectDragComplete(type, movedTarget, hoveredTarget, isBetween){
      if(!hoveredTarget) return;
      if(movedTarget.value === hoveredTarget.value ) return;
      if(movedTarget.value === 0 || hoveredTarget.value === 0){
        let title = "";
        if(type === CODE_TYPE.ITEM){
          title = "재산구분";
        }else if(type === CODE_TYPE.LOC){
          title = "위치";
        }else if(type === CODE_TYPE.GROUP){
          title = "부서";
        }

        setTimeout(() => {
          alert('삭제된 ' + title + '는 변경할 수 없습니다.');
        }, 300);
        
        return;
      }
      const valueList = this.getChildrenValueList([movedTarget]);
      if(valueList.indexOf(hoveredTarget.value) > -1) return;

      const parameters = {};
      parameters.value = movedTarget.value;
      parameters.parentCode = hoveredTarget.value;
      parameters.type = type;
      parameters.isBetween = isBetween;

      axios.post('/api/base/tree/move', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          this.getTree(this.curTab);
        }else if(res.data.code == ResCode.Fail){
          alert(res.data.data.msg);
        }
      }).catch(res => {
          console.log(res);
      });
    },
    print(){
      if(this.$commonFunc.getBrowser() !== BROWSER_NAME.IE ){
          alert('라벨 프린트는 익스플로어, 엣지 익스플로어 모드에서만 지원합니다.');
          return;
      }

      try{
          TTP_PRINTER.init();    
      }catch(e){
          alert('프린터 출력설정이 필요합니다.\n관련문의는 유비플러스고객센터로 문의 바랍니다. (1544-3486)');
          return;
      }

      this.showLabelPrinting();
    },
    showLabelPrinting(){
      const parameters = this.getParameters();
      if(this.btnState.isSelectAll){
        const parameters = this.getParameters();
        parameters.showAll = true;

        axios.get('/api/asset/list', {params : parameters}).then(res => {
          if(res.data.code == ResCode.Success){
            const data = res.data.data
            const assets = data.assets.content;
            this.printData = assets;
            this.isShowLabelPrinting = true;
          }
        }).catch(res => {
            console.log(res);
        })

      } else {
        const newList = [];

        for(const key in this.savedSelectedList){
            newList.push(this.savedSelectedList[key]);
        }

        this.printData = newList;
        this.isShowLabelPrinting = true;
      }
    },
    showSubcodeRegister(mode, treeData){
      this.treeSubcodeRegisterMode = mode;
      this.treeSubcodeTransferData = treeData;
      this.isShowTreeSubcodeRegister = true;
      this.treeSubcodeCurCodeType = CODE_TYPE.LOC;
    },
    completeSubcodeUpdate(searchWord){
      console.log('searchWord : ' + searchWord);
      this.isShowTreeSubcodeRegister = false;
      this.getTree(this.curTab, searchWord);
    },
    downloadCodeExcel(codeType){
      let queryStr = "";
      if(codeType === CODE_TYPE.ITEM){
        queryStr = "&columnKeys=name::temp_code&columnNames=재산구분::위치코드";
      }else if(codeType === CODE_TYPE.LOC){
        queryStr = "&columnKeys=name::temp_code&columnNames=위치::위치코드";
      }else if(codeType === CODE_TYPE.GROUP){
        queryStr = "&columnKeys=name::temp_code&columnNames=부서::부서코드";
      }

      location.href = this.$versionPath + "/api/base/tree/list/excel?type=" + codeType + queryStr;
    },
    getPrintList(){

    }
  },

  watch : {
    signalRefresh(){
      this.savedSelectedList = {};
      this.getTree(this.curTab);
      this.search()
    }
  },
  created(){
    showAssetDetail = this.showAssetDetail;
    deleteOne = this.deleteOne;
   
    const today = moment().format('YYYY-MM-DD');
    this.startDate = today;
    this.endDate = today;
    this.buyDate = today + ' ~ ' + today;
    this.getTree(0);
    this.getAssets(1, false);
    // this.getTabList();

    this.$commonFunc.setFieldNames(this.conditions);
    ConditionManager.setConditions(this.$options.name, this.conditions);
    this.$commonFunc.setFieldNames(this.column);
    this.column = ColumnManager.getSettings(this.$options.name, this.column );
  }
  
}


</script>

<style scoped>
.left_panel{padding:24px 20px; background-color:white;width:318px;border-radius:4px; border:1px solid #e6e7ec;display:flex; flex-direction: column;;}

.tab_wrap{
  display:flex;
}
.tab{flex:1;text-align:center;padding:10px; border-top:1px solid #e6e7ec;border-bottom:1px solid #e6e7ec;color:#494a4f;font-size:16px;cursor:pointer;}
.tab + .tab{border-left:1px solid #e6e7ec}
.tab.active{
  background-color:#0066fd;
  border-color:#0066fd;
  color:white;
}
.tab:nth-of-type(1){
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left:1px solid #e6e7ec;
}
.tab.active:nth-of-type(1){
    border-left:1px solid #0066fd;
}

.tab:nth-last-of-type(1){
    border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right:1px solid #e6e7ec;
}

.tab.active:nth-last-of-type(1){
border-right:1px solid #0066fd;
}


.search_con{display:inline-block;position:relative;}
.search_input{
    border: 1px solid #e6e7ec;
    display: inline-block;
    font-size: 16px;
    border-radius:4px;
    flex:1;
    min-width: 0px;
    padding: 6px 10px 7px 30px;
}

.search_input.right_icon{
    padding: 6px 30px 7px 10px;
}

.search_label{font-size:15px;font-weight:bold; color:#969696;}
.search_btn{
  text-align:left;margin-top:21px;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
.add_btn{text-align:left; position:absolute;;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:4px; cursor:pointer;
      padding: 11px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom;
    width: 100px;
    right: 0px;
    top: -7px;
}


.data_table_wrap{flex:1;  overflow:hidden; display:flex; flex-direction:column;}


.vertical_line{
    display: inline-block;
    margin-top:2px;
    height: 18px;;
    width:1px;
    
 background-color:#a7a8ba;
}


</style>
