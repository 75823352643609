<template >
    <ModalBack>
        <div class="company_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px 8px 0px; font-weight: bold;" >이미지 크게보기</div>
            <img :src="imgSrc">
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
export default {
    name : 'LargeImageViewer',
    components : {
        ModalBack, CommonModalCloseBtn
    },
    props : {
        hideModal : Function,
        imgSrc : String
    },
}
</script>
<style scoped>
    .company_register_wrap{
    background-color:white;
    max-width:100%;
    max-height:100%;
    min-width:300px; 
    min-height:300px;
    padding:38px;
    display:flex;
    overflow:auto;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
    
}
</style>