<template>
    <ModalBack :closeAction="hideModal">
        <div class="setting_wrap" style="">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >기초정보설정</div>
            <!-- <div class="tab_wrap">
                <div class="tab" :class="[curTab === 0 ? 'active' : '']" @click="changeTab(0)">기초정보설정</div>
                <div class="tab" :class="[curTab === 1 ? 'active' : '']" @click="changeTab(1)">라벨출력설정</div>
            </div> -->

            <div class="contents">
                <template v-if="curTab === 0">
                    <div class="default_settings_wrap">
                        <!-- <div class="section">사용자 정보</div>
                        <div style="display:flex;" class="section_border">
                            <LabelPanel style="width:233px;">
                                <template v-slot:label>회사명</template>
                                <template v-slot:content>
                                    <CommonInput ></CommonInput>
                                </template>
                            </LabelPanel>
                            <LabelPanel style="width:233px;margin-left:41px;">
                                <template v-slot:label>로고 (최대사이즈: 300*45)</template>
                                <template v-slot:content>
                                    <CommonInput style="flex:1;"></CommonInput>
                                    <MaginifyingGlassButton style="margin-left:5px;"></MaginifyingGlassButton>
                                </template>
                            </LabelPanel>
                        </div> -->

                        <div style="display:flex; ">
                            <div style="width:503px;">
                                <div class="section">자산 상세항목 추가</div>
                                <div class="section_border">
                                    <div style="display:flex;">
                                        <LabelPanel style="flex:1;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField1" :click-check="() => {form.checkedField1 = !form.checkedField1}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목1
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field1"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="flex:1; margin-left:25px;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField2" :click-check="() => {form.checkedField2 = !form.checkedField2}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목2
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field2"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                    </div>
                                    <div style="display:flex; margin-top:10px;">
                                        <LabelPanel style="flex:1;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField3" :click-check="() => {form.checkedField3 = !form.checkedField3}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목3
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field3"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="flex:1; margin-left:25px;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField4" :click-check="() => {form.checkedField4 = !form.checkedField4}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목4
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field4"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                    </div>
                                    <div style="display:flex; margin-top:10px;">
                                        <LabelPanel style="flex:1;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField5" :click-check="() => {form.checkedField5 = !form.checkedField5}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목5
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field5"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="flex:1; margin-left:25px;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField6" :click-check="() => {form.checkedField6 = !form.checkedField6}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목6
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field6"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                    </div>
                                    <div style="display:flex; margin-top:10px;">
                                        <LabelPanel style="flex:1;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField7" :click-check="() => {form.checkedField7 = !form.checkedField7}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목7
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field7"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="flex:1; margin-left:25px;">
                                            <template v-slot:label>
                                                <div style=" position: absolute;top: -5px;left: -2px;">
                                                    <CommonCheckbox :flag="form.checkedField8" :click-check="() => {form.checkedField8 = !form.checkedField8}" color="#e6e7ec"></CommonCheckbox>
                                                </div>
                                                <span style="padding:13px;"></span>항목8
                                            </template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form.field8"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                    </div>
                                </div>
                            </div>
                            
                            <div style="margin-left:20px;" >
                                <div class="section">자산 상태 설정</div>
                                <div class="section_border">
                                    <div style="display:flex;">
                                        <LabelPanel style="width:100px;">
                                            <template v-slot:label>사용</template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form[STATE_KEY.사용]"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="width:100px; margin-left:25px;">
                                            <template v-slot:label>수리</template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form[STATE_KEY.수리]"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                    </div>
                                    <div style="display:flex;margin-top:10px;">
                                        <LabelPanel style="width:100px;">
                                            <template v-slot:label>대여</template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form[STATE_KEY.대여]"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="width:100px; margin-left:25px;">
                                            <template v-slot:label>불용</template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form[STATE_KEY.불용]"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                    </div>
                                    <div style="display:flex;margin-top:10px;">
                                        <LabelPanel style="width:100px;">
                                            <template v-slot:label>분실</template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form[STATE_KEY.분실]"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="width:100px; margin-left:25px;">
                                            <template v-slot:label>폐기</template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form[STATE_KEY.폐기]"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                    </div>
                                    <div style="display:flex;margin-top:10px;">
                                        <LabelPanel style="width:100px;">
                                            <template v-slot:label>보관</template>
                                            <template v-slot:content>
                                                <CommonInput v-model="form[STATE_KEY.보관]"></CommonInput>
                                            </template>
                                        </LabelPanel>
                                        <LabelPanel style="width:100px; margin-left:25px;">
                                            <template v-slot:label>&nbsp;</template>
                                            <template v-slot:content>&nbsp;</template>
                                        </LabelPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="curTab === 1">
                    <div>
                        <div style="height:20px;"></div>
                        <div style="display:flex;">
                            <LabelPanel style="width:270px;">
                                <template v-slot:label>프린터 종류</template>
                                <template v-slot:content>
                                    <CommonSelect></CommonSelect>
                                </template>
                            </LabelPanel>
                            <LabelPanel style="width:150px; margin-left:25px;">
                                <template v-slot:label>포트</template>
                                <template v-slot:content>
                                    <CommonSelect></CommonSelect>
                                </template>
                            </LabelPanel>
                        </div>
                        <div>
                            <div class="section">라벨출력 설정</div>
                            <div class="section_border">
                                <div style="display:flex;">
                                    <div>
                                        <div style="display:flex;">
                                            <LabelPanel style="width:190px;">
                                                <template v-slot:label>바코드유형</template>
                                                <template v-slot:content>
                                                    <CommonSelect></CommonSelect>
                                                </template>
                                            </LabelPanel>
                                            <LabelPanel style="width:190px; margin-left:20px;">
                                                <template v-slot:label>라벨간격</template>
                                                <template v-slot:content><CommonInput ></CommonInput></template>
                                            </LabelPanel>
                                        </div>
                                        <div style="display:flex; margin-top:10px;">
                                            <LabelPanel style="width:190px;">
                                                <template v-slot:label>라벨Y 시작점</template>
                                                <template v-slot:content><CommonInput ></CommonInput></template>
                                            </LabelPanel>
                                            <LabelPanel style="width:190px; margin-left:20px;">
                                                <template v-slot:label>라벨X 시작점</template>
                                                <template v-slot:content><CommonInput ></CommonInput></template>
                                            </LabelPanel>
                                        </div>
                                        <div style="display:flex;margin-top:10px;">
                                            <LabelPanel style="width:190px;">
                                                <template v-slot:label>폰트</template>
                                                <template v-slot:content><CommonInput ></CommonInput></template>
                                            </LabelPanel>
                                            <LabelPanel style="width:190px; margin-left:20px;">
                                                <template v-slot:label>폰트크기</template>
                                                <template v-slot:content><CommonInput ></CommonInput></template>
                                            </LabelPanel>
                                        </div>
                                        <div style="margin-top:20px; display:flex;">
                                            <LabelPanel style="width:190px;">
                                                <template v-slot:label>
                                                    <div style=" position: absolute;top: -5px;left: -2px;">
                                                        <CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox>
                                                    </div>
                                                    <span style="padding:13px;"></span>가이드라인 표시
                                                </template>
                                                <template v-slot:content></template>
                                            </LabelPanel>
                                            <LabelPanel style="width:190px; margin-left:20px;">
                                                <template v-slot:label>
                                                    <div style=" position: absolute;top: -5px;left: -2px;">
                                                        <CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox>
                                                    </div>
                                                    <span style="padding:13px;"></span>바코드 텍스트 표시
                                                </template>
                                                <template v-slot:content></template>
                                            </LabelPanel>
                                        </div>
                                        <div style="margin-top:20px; display:flex;">
                                            <LabelPanel style="width:190px;">
                                                <template v-slot:label>
                                                    <div style=" position: absolute;top: -5px;left: -2px;">
                                                        <CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox>
                                                    </div>
                                                    <span style="padding:13px;"></span>라벨 이미지 사용
                                                </template>
                                                <template v-slot:content></template>
                                            </LabelPanel>
                                        </div>
                                        <div style="display:flex; margin-top:10px;">
                                            <div style="border:1px solid #e6e7ec; width:82px; height:82px; border-radius:5px;">
                                                <img src="">
                                            </div>
                                            <div style="flex:1;font-size: 14px; margin-left:8px; line-height:1.2;">
                                                <div class="image_sen">1. 이미지크기를 입력한 후에 이미지를 불러옵니다.</div>
                                                <div class="image_sen">2. 적용버튼을 누르면 프린터의 LED가 깜박거립니다.</div>
                                                <div class="image_sen">3. 깜박거림이 멈추면 이미지 전송이 완료됩니다.</div>
                                                <div style="margin-top:10px;">
                                                    <span>이미지크기</span> <div style="display:inline-block; width:50px;"><SmallInput ></SmallInput></div> X <div style="display:inline-block; width:50px;"><SmallInput ></SmallInput></div>
                                                    <button style="margin-left:5px;border:0px; background-color:#0066fd; border-radius:5px; height:25px;"><font-awesome-icon icon="fa-solid fa-magnifying-glass" class="icon" style="font-size:14px; color:white;"/></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="height:50px;"></div>
                                    </div>
                                    <div style="position:relative;">
                                        <img :src="require('@/assets/label.png')" style="position: absolute; top: 16px;left: 25px;">
                                        <div style="position:absolute; width: 30px;top: -2px;left: 145px;"><SmallInput ></SmallInput></div>
                                        <div style="position:absolute; width: 30px; top: 50px;left: 82px;"><SmallInput ></SmallInput></div>
                                        <div style="position:absolute; width: 30px;top: 135px;left: 60px;"><SmallInput ></SmallInput></div>
                                        <div style="position:absolute; width: 30px;top: 136px;left: 280px;"><SmallInput ></SmallInput></div>
                                        <div style="position:absolute; width: 30px;top: 194px;left: 69px;"><SmallInput ></SmallInput></div>

                                        <div style="position:absolute; top: 265px;">
                                            <div style="display:flex;">
                                                <LabelPanel style="width:170px;">
                                                    <template v-slot:label>출력항목설정</template>
                                                    <template v-slot:content>
                                                        <div style="width:40px; display:flex; justify-content:center; align-items:center;"><CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox></div>
                                                        <CommonSelect :list="list"></CommonSelect>
                                                    </template>
                                                </LabelPanel>
                                                <LabelPanel style="width:170px; margin-left:10px;">
                                                    <template v-slot:label>&nbsp;</template>
                                                    <template v-slot:content>
                                                        <div style="width:40px; display:flex; justify-content:center; align-items:center;"><CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox></div>
                                                        <CommonSelect :list="list"></CommonSelect>
                                                    </template>
                                                </LabelPanel>
                                            </div>
                                            <div style="display:flex;">
                                                <LabelPanel style="width:170px;">
                                                    <template v-slot:label></template>
                                                    <template v-slot:content>
                                                        <div style="width:40px; display:flex; justify-content:center; align-items:center;"><CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox></div>
                                                        <CommonSelect :list="list"></CommonSelect>
                                                    </template>
                                                </LabelPanel>
                                                <LabelPanel style="width:170px; margin-left:10px;">
                                                    <template v-slot:label></template>
                                                    <template v-slot:content>
                                                        <div style="width:40px; display:flex; justify-content:center; align-items:center;"><CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox></div>
                                                        <CommonSelect :list="list"></CommonSelect>
                                                    </template>
                                                </LabelPanel>
                                            </div>
                                            <div style="display:flex;">
                                                <LabelPanel style="width:170px;">
                                                    <template v-slot:label></template>
                                                    <template v-slot:content>
                                                        <div style="width:40px; display:flex; justify-content:center; align-items:center;"><CommonCheckbox :flag="isCheckedField10" :click-check="() => {isCheckedField10 = !isCheckedField10}" color="#e6e7ec"></CommonCheckbox></div>
                                                        <CommonSelect :list="list"></CommonSelect>
                                                    </template>
                                                </LabelPanel>
                                                <LabelPanel style="width:170px; margin-left:10px;">
                                                    <template v-slot:label></template>
                                                    <template v-slot:content>&nbsp;</template>
                                                </LabelPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="asset_bottom_btns">
                <button class="btn_canel" @click="hideModal" >취소</button>
                <!-- <button class="btn_apply" @click="save('apply')" style="margin-left:7px;">적용</button> -->
                <button class="btn_ok" @click="() => {save('save')}" style="margin-left:7px;">저장</button>
            </div>
        </div>       
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
// import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import SmallInput from '@/components/common/SmallInput.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {STATE_KEY, setStates, setFields} from '@/util/Constants.js';

export default {
    name : 'Settings',
    components: {
        ModalBack, CommonModalCloseBtn, LabelPanel, CommonInput, CommonCheckbox, CommonSelect, SmallInput
    },
    props : {
        hideModal : Function
    },
    data(){
        return {
            STATE_KEY : STATE_KEY,
            curTab : 0,

            list : [
                {name : '재산구분', value : '0'},
                {name : '자산명칭', value : '1'},
                {name : '모델명', value : '2'},
                {name : '취득일자', value : '3'},
                {name : '취득가액', value : '4'},
            ],
            form : {
                field1 : '',
                field2 : '',
                field3 : '',
                field4 : '',
                field5 : '',
                field6 : '',
                field7 : '',
                field8 : '',
                checkedField1 : false,
                checkedField2 : false,
                checkedField3 : false,
                checkedField4 : false,
                checkedField5 : false,
                checkedField6 : false,
                checkedField7 : false,
                checkedField8 : false,
                isCheckedField10 : false,
                B001001000 : '',
                B001002000 : '',
                B001003000 : '',
                B001004000 : '',
                B001005000 : '',
                B001006000 : '',
                B001007000 : '',
            }
        }
    }, 
    methods : {
        changeTab(index){
            this.curTab = index;
        },
        save(clickType){
            let formData = new FormData();
            for(let key in this.form) {
                formData.append(key, this.form[key]);
            }

            axios.post('/api/base/settings/save', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data;
                    setStates(data.stateCodes);
                    setFields(data.fields);

                    if(clickType === 'apply'){
                        alert('저장되었습니다.');
                    } else if(clickType === 'save'){
                        this.hideModal();
                    }
                   
                } 
            }).catch(res => {
                console.log(res);
            });
        },
        getSettings(){
            const parameters = {};
            axios.get('/api/base/settings', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const fields = data.fields;
                    const stateCodes = data.stateCodes;

                    this.form.field1 = fields[0].name;
                    this.form.field2 = fields[1].name;
                    this.form.field3 = fields[2].name;
                    this.form.field4 = fields[3].name;
                    this.form.field5 = fields[4].name;
                    this.form.field6 = fields[5].name;
                    this.form.field7 = fields[6].name;
                    this.form.field8 = fields[7].name;

                    this.form.checkedField1 = fields[0].used == '1';
                    this.form.checkedField2 = fields[1].used == '1';
                    this.form.checkedField3 = fields[2].used == '1';
                    this.form.checkedField4 = fields[3].used == '1';
                    this.form.checkedField5 = fields[4].used == '1';
                    this.form.checkedField6 = fields[5].used == '1';
                    this.form.checkedField7 = fields[6].used == '1';
                    this.form.checkedField8 = fields[7].used == '1';

                    for(const i in stateCodes){
                        const stateCode = stateCodes[i];
                        this.form[stateCode.baseCode] = stateCode.baseName;
                    }
                 
                }
            }).catch(res => {
                console.log(res);
            });
        }
    },
    created(){
        this.getSettings();
    }
}
</script>
<style scoped>
    .setting_wrap{
    background-color:white; width:870px; height:90%;
    max-height:770px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.tab_wrap{
    display:flex;
}
.contents{
    flex:1;
    overflow: hidden;
}
.tab{font-size:16px; padding: 12px 20px;cursor:pointer; border-top:1px solid #e6e7ec;font-weight:bold; border-left:1px solid #e6e7ec; color:#494a4f;}
.tab.active{background-color:#0066fd; color:white;}
.tab:nth-of-type(1){border-top-left-radius: 5px;;}
.tab:nth-last-of-type(1){border-right:1px solid #e6e7ec; border-top-right-radius: 5px;;}
.line{height:1px; background-color:#e6e7ec;}



.section_border{padding:22px; border:1px solid #e6e7ec; border-radius:4px;background-color:white;}
.section{margin-top:20px; margin-bottom:5px;font-weight:bold;}
.image_sen{line-height:1.3}
.asset_bottom_btns{text-align:right;}
.btn_apply{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #0066fd;font-weight:bold;color:#0066fd;}
.btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>