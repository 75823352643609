<template >
    <TableSearchPanel :is-show-right-panel="true" :is-show-search-panel="true" :is-show-table-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="자산이력카드"></PageHeader>
        </template>
        <template v-slot:search_panel>
            <div style="display:flex;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label>재산구분</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="itemName" :clickInput="(data) => {isShowItemSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>위치</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="locName" :clickInput="(data) => {isShowLocSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>부서</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="groupName" :clickInput="(data) => {isShowGroupSearch = true;}"></CommonInput> 
                    </template>
                </LabelPanel>
            </div>
            <div style="display:flex;margin-top:12px;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label>
                        <div style=" position: absolute;top: -5px;left: -2px;"><CommonCheckbox :flag="isBuyDateChecked" :click-check="() => {isBuyDateChecked = !isBuyDateChecked}" color="#e6e7ec"></CommonCheckbox></div><span style="padding:13px;"></span>취득일자
                    </template>
                    <template v-slot:content>
                        <CommonInput :click-input="() => {isShowCalendar1 = true}" v-model="buyDate"></CommonInput>
                        <div v-if="isShowCalendar1" ref="periodWrap" style="position:fixed;;z-index:2; background-color:white; border:1px solid #e6e7ec; border-radius:4px; ">
                            <PeriodCalendar :complete-callback="completeBuyDateSelect" :init-start-date="buyStartDate" :init-end-date="buyEndDate" :cancel-callback="() => {isShowCalendar1 = true}"></PeriodCalendar>
                        </div>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>
                        <div style=" position: absolute;top: -5px;left: -2px;"><CommonCheckbox :flag="isRegDateChecked" :click-check="() => {isRegDateChecked = !isRegDateChecked}" color="#e6e7ec"></CommonCheckbox></div><span style="padding:13px;"></span>등록일자
                    </template>
                    <template v-slot:content>
                        <CommonInput :click-input="() => {isShowCalendar2 = true}" v-model="regDate"></CommonInput>
                        <div v-if="isShowCalendar2" ref="periodWrap" style="position:fixed;;z-index:2; background-color:white; border:1px solid #e6e7ec; border-radius:4px; ">
                            <PeriodCalendar :complete-callback="completeRegDateSelect" :init-start-date="regStartDate" :init-end-date="regEndDate" :cancel-callback="() => {isShowCalendar2 = true}"></PeriodCalendar>
                        </div>
                    </template>
                </LabelPanel>
                <LabelPanel style="width:233px; margin-left:19px;">
                    <template v-slot:label>검색어</template>
                    <template v-slot:content>
                        <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:5px;">
                    <template v-slot:label>&nbsp;</template>
                    <template v-slot:content>
                        <button class="search_btn" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
                    </template>
                </LabelPanel>
            </div>
        </template>
        <template v-slot:table_panel>
            <BatchBtns 
                :is-show-first-checkbox="true"
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :downloadExcel="downloadExcel"
                :isShowExcelDownload="isAllowedForExcel"
                :isShowSelectAllBtn="true"
                :isShowRightBtn1="true"
                :searchCnt="searchCnt"
                rightBtnText1="이력카드출력"
                :rightBtnAction1="() => {showCardPreviewMulti()}"
                ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :plist="list" :pcolumn="column" :pcustom="custom" :paging="paging" :change-page="detectChangePage" :isShowCheckbox="true" 
                    :isShowNumbering="true"
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="assetCode"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>
        </template>

        <template v-slot:etc>
            <LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {locName = (data.text !== '위치코드' ?  data.text : ''); locCode = data.value}"></LocSearch>
            <GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {groupName = (data.text !== '부서코드' ?  data.text : ''); groupCode = data.value}"></GroupSearch>
            <ItemSearch v-if="isShowItemSearch" :hideModal="() => {isShowItemSearch = false}" :completeSave="(data) => {itemName =  (data.text !== '재산구분코드' ? data.text : ''); itemCode = data.value}"></ItemSearch>
            <CardPreview v-if="isShowCardPreview" :hideModal="() => {isShowCardPreview = false}" :transferData="transferData" :column="column"></CardPreview>
        </template>
    </TableSearchPanel>
</template>
<script>
import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import PeriodCalendar from '@/components/common/PeriodCalendar.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import ItemSearch from '@/components/modal/ItemSearch.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import CardPreview from '@/components/modal/CardPreview.vue';
import moment from 'moment';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import { states, AUTH, NUMBER_STYLE, SIZE_PER_PAGE, FONT_AWESOME} from '@/util/Constants.js';

let showCardPreview;


const previewBtn = {
    template : `<div style='text-align:left; color:#9b9cb6;;' @click="showPreview"><font-awesome-icon :icon="FONT_AWESOME.MAGNIFYING_GLASS_PLUS" :isHoverColorApply="true"></font-awesome-icon></div>`,
    props : ['data', 'column', 'index'],
    data(){
        return {
            FONT_AWESOME : FONT_AWESOME
        }
    },
    methods : {
        showPreview(){
            showCardPreview(this.data);
        }
    }
}

export default {
    name : 'AssetHistoryCard',
    components : {
        PageHeader, TableSearchPanel, CommonTable, ColumnSetting, BatchBtns, LabelPanel, CommonInput, 
        PeriodCalendar, CommonCheckbox, LocSearch, GroupSearch, ItemSearch, ConditionInput, CardPreview
    },
    data(){
        return {
            itemName : '',
            itemCode : '',
            groupName : '',
            groupCode : '',
            locName : '',
            locCode : '',
            isShowColumnSettings : false,
            isBuyDateChecked : false,
            isRegDateChecked : false,
            isShowLocSearch : false,
            isShowGroupSearch : false,
            isShowItemSearch : false,
            isShowCalendar1 : false,
            isShowCalendar2 : false,
            isShowCardPreview : false,
            searchCnt : 0,
            buyStartDate : '',
            buyEndDate : '',
            regStartDate : '',
            regEndDate : '',
            conditionCode : '',
            searchWord : '',
            buyDate : '',
            regDate : '',
            lastPage : 1,
            lastSizePerPage: SIZE_PER_PAGE,
            lastWithoutSearchWord : '',
            savedSelectedList : {},
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },
            searchConditions: [
                {name : '자산명칭', key : 'assetName', checked : true},
                {name : '자산코드', key : 'assetCode', checked : true},
                {name : '모델명', key : 'modelName', checked : true},
                {name : '시리얼', key : 'serial', checked : true},
                {name : '규격', key : 'standard', checked : true},
                {name : '취득가액/자존가액', key : 'np_price', checked : true},
                {name : '담당자/사용자', key : 'userName', checked : true},
                {name : '구매처', key : 'selling', checked : true},
                {name : '바코드', key : 'barcode', checked : true},
                {name : '추가항목1', key : 'field1', checked : true},
                {name : '추가항목2', key : 'field2', checked : true},
                {name : '추가항목3', key : 'field3', checked : true},
                {name : '추가항목4', key : 'field4', checked : true},
                {name : '추가항목5', key : 'field5', checked : true},
                {name : '추가항목6', key : 'field6', checked : true},
                {name : '추가항목7', key : 'field7', checked : true},
                {name : '추가항목8', key : 'field8', checked : true},
            ],
            list: [],
            custom : {
                preview : previewBtn
            },
            column: [
                { key: "preview", name: "", w: "30px"},
                { key: "itemName", name: "재산구분", w: "120px"},
                { key: "assetName", name: "자산명칭", w: "150px"},
                { key: "modelName", name: "모델명", w: "130px" },
                { key: "groupName", name: "부서", w: "100px" },
                { key: "locName", name: "위치", w: "100px" },
                { key: "stateName", name: "사용상태", w: "100px" },
                { key: "standard", name: "규격", w: "140px" },
                { key: "barcode", name: "바코드", w: "130px" },
                { key: "buyDate", name: "취득일자", w: "100px" },
                { key: "buyPrice", name: "취득가액", w: "100px", comma : true, style : NUMBER_STYLE },
                { key: "scrapPrice", name: "잔존가액", w: "100px", comma : true, style : NUMBER_STYLE },
                { key: "memodate", name: "최근메모일자", w: "100px" },
                { key: "memo", name: "최근메모내역", w: "100px" },
                { key: "serial", name: "시리얼", w: "100px" },
                { key: "user", name: "사용자", w: "100px" },
                { key: "charge", name: "담당자", w: "100px" },
                { key: "selling", name: "구매처", w: "100px" },
                { key: "field1", name: "추가항목1", w: "100px" },
                { key: "field2", name: "추가항목2", w: "100px" },
                { key: "field3", name: "추가항목3", w: "100px" },
                { key: "field4", name: "추가항목4", w: "100px" },
                { key: "field5", name: "추가항목5", w: "100px" },
                { key: "field6", name: "추가항목6", w: "100px" },
                { key: "field7", name: "추가항목7", w: "100px" },
                { key: "field8", name: "추가항목8", w: "100px" },
            ],
            paging : {},
        }
    },
    methods : {
        completeBuyDateSelect(startDate, endDate){
            this.isBuyDateChecked = true;
            this.buyStartDate = startDate;
            this.buyEndDate = endDate;
            this.buyDate = startDate + ' ~ ' + endDate;
            this.isShowCalendar1 = false;
        },
        completeRegDateSelect(startDate, endDate){
            this.isRegDateChecked = true;
            this.regStartDate = startDate;
            this.regEndDate = endDate;
            this.regDate = startDate + ' ~ ' + endDate;
            this.isShowCalendar2 = false;
        },
        enter(){
            this.search();
        },
        search(){
            this.getHistoryCard(1, this.lastWithoutSearchWord);
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/asset/current/excel?" + queryStr);
            location.href = this.$versionPath + "/api/asset/current/excel?" + queryStr
        },
        getHistoryCard(page, withoutSearchWord){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            
            const parameters = this.getParameters();
            parameters.isWithImage = true;
            axios.get('/api/asset/current', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const assetCurrent = data.assetCurrents.content;
                    this.searchCnt = data.assetCurrents.totalElements;
                    data.assetCurrents.content = null;
                    // const assetSum = data.assetSum;
                    this.list = assetCurrent;
                    this.paging = data.assetCurrents;   
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;
            parameters.deleted = 0;

            let conditions = [];
            let searchWords = [];

            if(this.locName != ''){
                conditions.push('np_locName');
                searchWords.push(this.locName);
            }

            if(this.itemName != ''){
                conditions.push('np_itemName');
                searchWords.push(this.itemName);
            }

            if(this.groupName != ''){
                conditions.push('np_groupName');
                searchWords.push(this.groupName);
            }

           if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            if(this.isBuyDateChecked){
                parameters.searchBeginDate = this.buyStartDate;
                parameters.searchEndDate = this.buyEndDate;
            }

            if(this.isRegDateChecked){
                parameters.searchBeginDate2 = this.regStartDate;
                parameters.searchEndDate2 = this.regEndDate;
            }


            return parameters;
        },
        detectChangePage(page){
            this.getHistoryCard(page, this.lastWithoutSearchWord);
        },
        showCardPreview(data){
            this.transferData = [data];
            this.isShowCardPreview = true;
        },
        showCardPreviewMulti(){
            if(this.btnState.isSelectAll){
                const parameters = this.getParameters();
                parameters.showAll = true;
                parameters.isWithImage = true;
                axios.get('/api/asset/current', {params : parameters}).then(res => {
                    if(res.data.code == ResCode.Success){
                        const data = res.data.data
                        const assetCurrents = data.assetCurrents.content;
                        this.transferData = assetCurrents;
                        this.isShowCardPreview = true;
                    }
                }).catch(res => {
                    console.log(res);
                })

            }else {
                if(Object.keys(this.savedSelectedList).length === 0){
                    alert('출력할 자산을 선택해주세요.');
                    return;
                }
                const newList = [];
                for(const key in this.savedSelectedList){
                    newList.push(this.savedSelectedList[key]);
                }
                this.transferData = newList;
                this.isShowCardPreview = true;
            }
        }
    },
    computed : {
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.자산이력카드, AUTH.EXCEL)
        },
    },
    created(){
        showCardPreview = this.showCardPreview;
        const today = moment().format('YYYY-MM-DD');
        this.buyStartDate = today;
        this.buyEndDate = today;
        this.buyDate = today + ' ~ ' + today;
        this.regStartDate = today;
        this.regEndDate = today;
        this.regDate = today + ' ~ ' + today;

        this.getHistoryCard(1, false);

        this.$commonFunc.setFieldNames(this.searchConditions);
        ConditionManager.setConditions(this.$options.name, this.searchConditions);
        this.$commonFunc.setFieldNames(this.column);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }

}
</script>
<style scoped>
    .search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>