<template >
    <ModalBack :closeAction="hideModal">
        <div class="company_register_wrap">
        <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
        <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >
            <template v-if="curMode === MODE.INSERT">수리요청등록</template>
            <template v-if="curMode === MODE.UPDATE">수리요청수정</template>
            <template v-if="curMode === MODE.COMPLETE">수리완료등록</template>
        </div>
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                    <col>
                </colgroup>
                <template v-if="!(curMode === MODE.COMPLETE)">
                    <tr>
                        <td>
                            <div class="label required">요청일자</div>
                            <CalendarInput v-model="form.requestDate" :isReadonly="curMode === MODE.COMPLETE"></CalendarInput>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">수리업체</div>
                            <div class="row_flex">
                                <CommonInput style="flex:1;" v-model="form.repairCompany" :isReadonly="curMode === MODE.COMPLETE" :clickInput="() => {isShowCompanySearch = true}"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowCompanySearch = true}"></MaginifyingGlassButton>
                            </div>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">요청내용</div>
                            <CommonTextarea height="90px" v-model="form.requestContents" :isReadonly="curMode === MODE.COMPLETE"></CommonTextarea>
                        </td>
                    </tr>
                </template>
                
                <template v-if="!(curMode === MODE.INSERT || (curMode === MODE.UPDATE && !isAfterRepair))">
                    <tr class="margin"><td ></td></tr>
                    <tr >
                        <td>
                            <div class="label required">수리일자</div>
                            <CalendarInput v-model="form.repairDate" :isReadonly="curMode === MODE.INSERT || (curMode === MODE.UPDATE && !isAfterRepair)"></CalendarInput>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">수리금액</div>
                            <CommonInput v-model="form.repairCost" mode="number" :isReadonly="curMode === MODE.INSERT || (curMode === MODE.UPDATE && !isAfterRepair)"></CommonInput>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">수리내용</div>
                            <CommonTextarea height="90px" v-model="form.repairContents" :isReadonly="curMode === MODE.INSERT || (curMode === MODE.UPDATE && !isAfterRepair)"></CommonTextarea>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
            <button class="asset_bottom_btn_ok" @click="save">{{curMode === MODE.INSERT ? '요청' : (curMode === MODE.UPDATE ? '수정' : '완료')}}</button>
        </div>

        <CompanySearch v-if="isShowCompanySearch" :hideModal="() => {isShowCompanySearch = false}" :completeSave="(data) => {this.form.companyCode = data.companyCode; this.form.repairCompany = data.companyName}"></CompanySearch>

    </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonTextarea from '@/components/common/CommonTextarea.vue';
import CompanySearch from '@/components/modal/CompanySearch.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import axios from 'axios';
import moment from 'moment';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';
export default {
    name : 'AssetRepairRegister',
    props : {
        hideModal : Function,
        data : Object,
        curMode : Number,
        completeAction : {
            type : Function,
            default : () => {}
        },
        assetCode : String
    },
    components : {
        CommonInput,
        MaginifyingGlassButton,
        ModalBack,
        CommonModalCloseBtn,
        CompanySearch,
        CalendarInput,
        CommonTextarea
    },
    data(){
        return {
            MODE : MODE,
            isAfterRepair : false,
            isShowCompanySearch : false,
            form : {
                requestDate: '',
                repairCompany : '',
                requestContents : '',
                companyCode : '',
                repairDate : '',
                repairCost : '0',
                repairContents : '',
            },
        }
    },
    methods : {
        insertRepairHistory(){
            if(this.form.requestDate === ''){
                alert('요청일자를 입력해주세요.');
                return;
            }

            if(this.form.companyCode === '' || this.form.repairCompany === ''){
                alert('수리업체를 선택해주세요.');
                return;
            }

            if(this.form.requestContents === ''){
                alert('요청내용을 입력해주세요.');
                return;
            }

            if(!confirm('자산수리하시겠습니까?')){
                return;
            }

            const parameters = {};
            parameters.assetCode = this.assetCode;
            parameters.requestDate = this.form.requestDate;
            parameters.companyCode = this.form.companyCode;
            parameters.requestContents = this.form.requestContents;
                        
            axios.post('/api/asset/repairhistory/insert', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.$GlobalStore.refreshAssetMng();
                    this.completeAction();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });
        },
        updateRepairHistory(){
            if(this.form.requestDate === ''){
                alert('요청일자를 입력해주세요.');
                return;
            }

            if(this.form.companyCode === '' || this.form.repairCompany === ''){
                alert('수리업체를 선택해주세요.');
                return;
            }

            if(this.form.requestContents === ''){
                alert('요청내용을 입력해주세요.');
                return;
            }

            if(this.form.repairDate === ''){
                alert('수리일자를 입력해주세요.');
                return;
            }

            if(this.form.repairCost === ''){
                alert('수리금액을 입력해주세요.');
                return;
            }

            if(this.form.repairContents === ''){
                alert('수리내용을 입력해주세요.');
                return;
            }

            const parameters = {};
            parameters.repairIdx = this.data.repairIdx;
            parameters.assetCode = this.assetCode;
            parameters.requestDate = this.form.requestDate;
            parameters.companyCode = this.form.companyCode;
            parameters.requestContents = this.form.requestContents;
            parameters.repairDate = this.form.repairDate;
            parameters.repairCost = this.$commonFunc.uncomma(this.form.repairCost);
            parameters.repairContents = this.form.repairContents;
            parameters.isComplete = this.curMode === MODE.COMPLETE;
                        
            axios.post('/api/asset/repairhistory/update', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.$GlobalStore.refreshAssetMng();
                    this.completeAction();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });
        },
        save(){
            if(this.curMode === MODE.UPDATE || this.curMode === MODE.COMPLETE){
                this.updateRepairHistory();
            }else {
                this.insertRepairHistory();
            }
        }
    },
    created(){
        if(this.curMode === MODE.UPDATE || this.curMode === MODE.COMPLETE){
            this.form.requestDate = this.data.requestDate.substr(0, 10);
            this.form.repairCompany = this.data.repairCompany;
            this.form.companyCode = this.data.companyCode;
            this.form.requestContents = this.data.requestContents;

            if(this.data.repairDate){
                this.isAfterRepair = true;
            }

            this.form.repairDate = this.data.repairDate ? this.data.repairDate.substr(0, 10) : '';
            this.form.repairCost = this.$commonFunc.comma(this.data.repairCost) || '0';
            this.form.repairContents = this.data.repairContents;
        }else if(this.curMode === MODE.INSERT) {
            this.form.requestDate = moment().format('YYYY-MM-DD');
        }
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 400px;
    max-height: 90%;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right; padding-top: 14px;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>