<template>
    <ModalBack :closeAction="hideModal">
        <div class="depreciation_setting_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">감가상각 환경설정</div>
            <div class="asset_info_contents">
                <table class="asset_info_tb" style="margin-top:25px;">
                    <colgroup>
                        <col>
                    </colgroup>
                    <tr>
                        <td>
                            <div class="label required">잔존가액기본값</div>
                            <CommonRadio name="scrapPrice" v-model="scrap_price" initVal="0" label="상각법의 기본값 (정액법: 0원, 정률법: 취득가액의 5%)" style="margin-top:10px;" ></CommonRadio>
                            <CommonRadio name="scrapPrice" v-model="scrap_price" initVal="1" label="사용자 입력 금액 적용" style="margin-top:10px; margin-left:0px;"></CommonRadio>
                            
                        </td>
                    </tr>
                    <tr class="margin" style="height:20px;"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">감가상각범위</div>
                            <CommonRadio name="depreciation_range" v-model="depreciation_range" initVal="0" label="내용연수까지 감가상각" style="margin-top:10px;"></CommonRadio>
                            <CommonRadio name="depreciation_range" v-model="depreciation_range" initVal="3" label="내용연수까지 감가상각 + 장부가액 0원까지 감가상각" style="margin-top:10px;margin-left:0px;"></CommonRadio>
                            <CommonRadio name="depreciation_range" v-model="depreciation_range" initVal="1" label="1,000원까지 감가상각" style="margin-top:10px;margin-left:0px;"></CommonRadio>
                        </td>
                    </tr>
                    <tr class="margin" style="height:20px;"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label required">감가상각 시작월</div>
                            <CommonSelect :list="months" :value="start_month" :detectChange="detectMonthChange" style="width:70px;"></CommonSelect> ({{start_month != '1' ? parseInt(start_month) - 1 : '12'}}월 법인)
                        </td>
                    </tr>
                </table>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="save">수정</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonRadio from '@/components/common/CommonRadio.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';

export default {
    name : 'DepreciationSetting',
    components : {
        ModalBack, CommonModalCloseBtn, CommonRadio, CommonSelect
    },
    props: {
        hideModal : Function
    },
    data(){
        return {
            scrap_price : '',
            depreciation_range : '',
            start_month : '',
            months : [
                {name : '1', value : '1'},
                {name : '2', value : '2'},
                {name : '3', value : '3'},
                {name : '4', value : '4'},
                {name : '5', value : '5'},
                {name : '6', value : '6'},
                {name : '7', value : '7'},
                {name : '8', value : '8'},
                {name : '9', value : '9'},
                {name : '10', value : '10'},
                {name : '11', value : '11'},
                {name : '12', value : '12'}
            ]
        }
    },
    methods : {
        save(){
            const parameters = {};
            parameters.scrap_price = this.scrap_price;
            parameters.start_month = this.start_month;
            parameters.depreciation_range = this.depreciation_range;
            axios.post('/api/depreciation/setting/save', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });
        },
        detectMonthChange(value){
            this.start_month = value;
        },
        getDepreciationSettings(){
            const parameters = {};
            axios.get('/api/depreciation/setting/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const deprecationSettings = data.deprecationSettings;
                    this.scrap_price = deprecationSettings.scrap_price;
                    this.start_month = deprecationSettings.start_month;
                    this.depreciation_range = deprecationSettings.depreciation_range;

                }
            }).catch(res => {
                console.log(res);
            });
        }
    },
    created(){
        this.getDepreciationSettings();
    }
}   
</script>
<style scoped>
.depreciation_setting_wrap{
    background-color:white;     width: 500px;
        max-height: 90%;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
    .asset_bottom_btns{text-align:right; padding-top: 14px;}
    .asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>