<template>
<div style="width:100vw; height:100vh;	display:flex;	justify-content: center;align-items: center;background-color:#ebeff8;">
  <div style="width:1200px; height:710px;display:flex; background-color:white; border-radius:15px;  box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.2);">
    <div style="flex:1;width:710px; display:flex; justify-content: center;align-items:center;">
      <div style="width:270px;">
        <h2 class="login_title">
          <img :src="require('@/assets/login_logo2.png')" style="width:130px;">
        </h2>
        <!-- <div style="font-size:25px;font-weight:bold;margin-top:30px;">로그인</div> -->
        <form name="frm_login" id="frm_login" class="form-signin">
          <div class="login" style="margin-top:40px;">
            <label style="color:#969696;font-size:15px;font-weight:bold;">아이디</label>
            <input type="text" v-model="username" placeholder="" autofocus style="width: 100%; margin-top:12px;">
            <label style="margin-top:15px;display:inline-block;color:#969696;font-size:15px;font-weight:bold;">비밀번호</label>
            <input type="password" v-model="password" placeholder="" style="width: 100%; margin-top:12px;" @keyup="enter">

            <div class="ckbox_row" style="  margin-top: 5px;">
              <div class="inputset ckbox" style="padding:17px 0px;">
                <CommonCheckbox :flag="isAutoLogin" color="#e6e7ec" :clickCheck="toggleAutoLogin" style="cursor:pointer;"></CommonCheckbox>
                <label style="cursor: pointer;color:#969696; font-size:15px;font-weight:bold;display:inline-block; margin-left:10px;line-height: 22px;" @click="toggleAutoLogin">자동로그인</label>
                <!-- <input type="checkbox" id="remember" v-model="isAutoLogin" > -->
              </div>
            </div>
            <button class="btn_login" type="button" @click="onLogin">로그인</button>
            <!-- <button class="btn_server" type="button" >서버 설정</button> -->
            <div style="height:40px; margin-top:13px;"></div>
          </div>
        </form>
      </div>

    </div>
    <div style="flex:1;position:relative;">
      <img :src="require('@/assets/login_right.jpg')" style="border-top-right-radius: 15px;border-bottom-right-radius: 15px;">
      <div style="position:absolute; top: 164px; left:50%; transform:translateX(-50%)">
        <div style="text-align:center;color:#040848; font-size:22px;font-weight:bold;">자산관리시스템</div>
        <div style="text-align:center; color:#9597bb; font-size:13px;font-weight:bold;margin-top:8px;">Asset Management System</div>
      </div>
    </div>
  </div>
</div>
  <!-- <div class="" style="width:100vw; height:100vh;		display:flex;	justify-content: center;align-items: center;">
    <div class="login_wrap">
      <h2 class="login_title">
        <img :src="require('@/assets/login_logo.png')" style="width:250px;">
      </h2>

      <form name="frm_login" id="frm_login" class="form-signin">
        <div class="login">
          <input type="text" v-model="username" placeholder="사업자번호(-제외)" autofocus style="width: 100%; margin-top:20px;">
          <input type="password" v-model="password" placeholder="비밀번호" style="width: 100%; margin-top:20px;">

          <button class="btn_login" type="button" @click="onLogin">로그인</button>

          <div class="ckbox_row" style="  margin-top: 5px;">
            <span class="inputset ckbox">
              <input type="checkbox" id="remember" v-model="isAutoLogin" >
              <label for="remember" style="cursor: pointer;">
                자동로그인
              </label>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div> -->
</template>

<script>
import axios from "axios";
import CommonCheckbox from '@/components/common/CommonCheckbox';
import ResCode from '@/util/ResponseCode.js';
export default {
  name: 'Login',
  props: {
    msg: String
  },
  data() {
    return {username: '', password: '', result: '', token: '', isAutoLogin: false}
  },
  components: {
    CommonCheckbox
  },
  methods: {
    toggleAutoLogin(){
      this.isAutoLogin = !this.isAutoLogin;
    },
    onGetMembers : async function() {
      try {
        // const config = {
        //   headers: {
        //     Authorization:`Bearer ${this.token}`,
        //     // withCredentials: true
        //   }
        // };
        const res = await axios.get("/api/member/list");
        this.result = JSON.stringify(res);
      }catch(e) {
        alert(e.message);
      }
    },
    onLogin: function () {
      if(this.username === ''){
        alert('아이디를 입력해주세요.');
        return;
      }

      // this.$router.push({ path: '/view/assetMng' });
      const parameters = {};
      parameters.username = this.username;
      parameters.password = this.password;
      parameters.autologin = this.isAutoLogin;

      axios.post('/auth/login', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          this.token = res.data.data;
            
          if(res.data.data){
            localStorage.setItem('token', res.data.data);
          }

          // setTimeout(() => {
            this.$router.push({ path: '/view/assetMng' });
          // }, 1000)

        }else {
          alert(res.data.message);
        }
      }).catch(res => {
          console.log(res);
          console.log(res);
      });

      // try {
      //   const res = await axios.get("/auth/login?username=" + this.username + "&password=" + this.password + "&autologin=" + this.isAutoLogin);
      //   this.result = JSON.stringify(res);
      //   if(result.code == ResCode.Success){
      //     this.token = res.data.data;
             
      //       if(res.data.data){
      //         localStorage.setItem('token', res.data.data);
              
      //       }

      //     setTimeout(() => {
      //       this.$router.push({ path: '/view/assetMng' });
      //     }, 1000)
          
      //     // alert("login success");
      //   }
      // } catch (e) {
      //   console.log(e);
      //   alert(e.message);
      // }
    },
    increase(){
      console.log('증가');
      this.$store.commit('increaseCount');
    },
    enter(e){
      if(e.keyCode === 13){
        this.onLogin();
      }
    }
  }
}
</script>

<style scoped>
	.login_wrap{
		width:400px;height: 480px;background-color: white;border:1px solid #dddddd;border-radius:4px;
	}
	.login_title{
		text-align:center;
	}

	.login_wrap{
		padding:20px;}

	input[type=text], input[type=password]{
		border-radius:5px;
		height:40px;
		border:1px solid #e6e7ec;
		font-size: 15px;
		padding: 5px 10px;
	}

	.btn_login{
		margin-top: 20px;
		height:40px;
		width:100%;
		font-size:16px;
		background-color: #0066fd;
		border:0px;
		border-radius:5px;
		color:white;
		cursor:pointer;
		font-weight:bold;
	}

  
	.btn_server{
		margin-top: 13px;
		height:40px;
		width:100%;
		font-size:16px;
		background-color: white;
		border:0px;
		border-radius:5px;
		color:white;
		cursor:pointer;
		font-weight:bold;
    border:1px solid #e6e7ec;
    color:#222536;
  
	}
</style>
