<template>
    <div style="padding:12px;display:flex;margin-top: 3px;" >
        <div class="checkbox" v-if="isShowFirstCheckbox" @click="toggleBatchBtn" :style="{borderColor : isShowBatchBtns ? 'black' : ''}">
            <font-awesome-icon v-if="isShowBatchBtns && btnState.isSelectedOneAtLeast" icon="fa-solid fa-check" :isHoverColorApply="true" :style="{color : isShowBatchBtns ? 'black' : ''}" style="margin-left:1px;font-size:19px;color:#a7a8ba;"/>
        </div>
        <slot name="left_panel"></slot>
        <div v-if="isShowBatchBtns" style="padding-left:22px">
            <div v-if="isShowDeleteBtn" class="icon_wrap" style="margin-right:22px;"  @click="deleteAction"><font-awesome-icon icon="fa-regular fa-trash-can" :isHoverColorApply="true" class="icon" /></div>
            <div v-if="isShowRestoreBtn" class="icon_wrap" style="margin-right:22px;" @click="restoreAction"><font-awesome-icon icon="fa-solid fa-trash-can-arrow-up" :isHoverColorApply="true" class="icon" /></div>
            <div v-if="isShowModifyBtn" class="icon_wrap" style="margin-right:18px;" @click="modifyAction"> <font-awesome-icon icon="fa-regular fa-pen-to-square" :isHoverColorApply="true" class="icon"/></div>
            <div v-if="isShowPrintBtn" class="icon_wrap" style="margin-right:22px;" @click="printAction"><font-awesome-icon icon="fa-solid fa-print" :isHoverColorApply="true"  class="icon"/></div>
            <div v-if="isShowDepreciationDo" class="checkbox" @click="toggleDepreHidden" :isHoverColorApply="true" style="vertical-align:top;" >
                <font-awesome-icon v-if="btnState.isNoDepreciationHidden" icon="fa-solid fa-check" style="margin-left:1px;font-size:19px;"/>
            </div>
            <label v-if="isShowDepreciationDo" style="margin-left:11px; vertical-align: top;margin-top: 4px;display: inline-block; margin-right:18px;">상각안함 자산 숨기기</label>

            <div v-if="isShowSelectAllBtn" class="checkbox"  style="vertical-align:top;" @click="toggleSelectAll" :style="{borderColor : btnState.isSelectAll ? 'black' : ''}">
                <font-awesome-icon v-if="btnState.isSelectAll" icon="fa-solid fa-check" :isHoverColorApply="true" style="margin-left:1px;font-size:19px;"/>
            </div>
            
            <label v-if="isShowSelectAllBtn" style="margin-left:11px; vertical-align: top;margin-top: 4px;display: inline-block;">검색된 <span style="color:#0066fd;">{{$commonFunc.comma(searchCnt)}}</span>개 모두 선택</label>
            <div v-if="isShowSelectAllBtn" class="vertical_line" style="margin-left:26px;vertical-align:top;"></div>
        </div>
        <div style="margin-left:auto;">
            <div class="icon_wrap" style="position:relative;">
                <div style="position: absolute; white-space: nowrap;right: 0px;right: 0px;top: -12px;">
                    <button v-if="isShowRightBtn1" class="btn_save" @click="rightBtnAction1">{{rightBtnText1}}</button>
                    <button v-if="isShowRightBtn2" class="btn_save" @click="rightBtnAction2">{{rightBtnText2}}</button>
                    <button v-if="isShowRightBtn3" class="btn_save" @click="rightBtnAction3">{{rightBtnText3}}</button>
                </div>
            </div>
            <div v-if="isShowFirstCheckbox" class="vertical_line" style=" margin-left: 27px;margin-right:27px;"></div>
            <div class="icon_wrap" v-if="isShowExcelDownload" @click="downloadExcel"><font-awesome-icon icon="fa-regular fa-file-excel" :isHoverColorApply="true" class="icon"/></div>
            <div class="icon_wrap" v-if="isShowColumnSetting" @click="showColumnSettingAction" style="margin-left:22px;"><font-awesome-icon icon="fa-solid fa-table-columns" :isHoverColorApply="true" class="icon"/></div>
        </div>
    </div>
</template>
<script>
export default {
    name : 'BatchBtns',
    props: {
        isShowFirstCheckbox : {
            type : Boolean,
            default : false,
        },
        isShowDeleteBtn : {
            type : Boolean,
            default : false
        },
        isShowModifyBtn : {
            type : Boolean,
            default : false
        },
        isShowPrintBtn : {
            type : Boolean,
            default : false
        },
        isShowSelectAllBtn : {
            type : Boolean,
            default : false
        },
        isShowDepreciationDo : {
            type : Boolean,
            default : false
        },
        isShowSaveBtn : {
            type : Boolean,
            default : false
        },
        isShowRestoreBtn : {
            type : Boolean,
            default : false
        },
        deleteAction : Function,
        showColumnSettingAction : Function,
        downloadExcel : {
            type : Function,
            default : () => {}
        },
        savedSelectedList : {
            type : Object,
            default : () => {}
        },
        btnState : {
            type : Object,
            default : () => {
                return {
                    isSelectedOneAtLeast : true,
                    isSelectAll : false,
                    signalSelectCurPageAll : false,
                    signalUnselectCurPageAll : false,
                }
            }
        },
        searchCnt : Number,
        restoreAction : {
            type : Function,
            default : () => {}
        },
        modifyAction : {
            type : Function,
            default : () => {}
        },
        printAction : {
            type : Function,
            default : () => {}
        },
        isShowExcelDownload : {
            type : Boolean,
            default : true
        },
        isShowRightBtn1 : {
            type : Boolean,
            default : false
        },
        rightBtnText1 : {
            type : String,
            default : ''
        },
        rightBtnAction1 : {
            type : Function,
            default : () => {}
        },
        isShowRightBtn2 : {
            type : Boolean,
            default : false
        },
        rightBtnText2 : {
            type : String,
            default : ''
        },
        rightBtnAction2 : {
            type : Function,
            default : () => {}
        },
        isShowRightBtn3 : {
            type : Boolean,
            default : false
        },
        rightBtnText3 : {
            type : String,
            default : ''
        },
        rightBtnAction3 : {
            type : Function,
            default : () => {}
        },
        isShowColumnSetting : {
            type : Boolean,
            default : true
        }
    },
    data(){
        return {
            isShowBatchBtns : false,
            isSelectAll : false,
            isNoDepreciationHidden : false
        }
    },
    watch: {
        savedSelectedList(){
            if(!this.savedSelectedList) return
            if(Object.keys(this.savedSelectedList).length > 0){
                this.isShowBatchBtns = true;
            }else {
                this.isShowBatchBtns = false;
            }
        },
    },
    methods : {
        toggleBatchBtn(){
            this.isShowBatchBtns = !(this.isShowBatchBtns && this.btnState.isSelectedOneAtLeast);
            //현재 페이지 전체 선택
            if(this.isShowBatchBtns){
                this.btnState.signalSelectCurPageAll = !this.btnState.signalSelectCurPageAll;
            }else {
                this.btnState.signalUnselectCurPageAll = !this.btnState.signalUnselectCurPageAll;
            }
        },
        toggleSelectAll(){
            this.btnState.isSelectAll = !this.btnState.isSelectAll;
        },
        toggleDepreHidden(){
            this.btnState.isNoDepreciationHidden = !this.btnState.isNoDepreciationHidden;
        }
    }
}
</script>

<style scoped>
.icon_wrap{display:inline-block;vertical-align:top;}
.vertical_line{
    display: inline-block;
    margin-top:2px;
    height: 18px;;
    width:1px;
    
 background-color:#a7a8ba;
}
.checkbox{
border:1px solid #a7a8ba; border-radius:4px; width:22px; height:22px;display:inline-block;cursor:pointer;
}
/* .checkbox.active{
  background-color:black;
} */
.icon{font-size:22px;color:#a7a8ba;}
.btn_save{background-color:white; color: #0066fd; border:1px solid #0066fd; border-radius:5px; cursor:pointer;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: bold;}

.btn_save + .btn_save{
    margin-left:8px;
}
</style>