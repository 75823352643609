let responseCode = {
    Success : 0,
    Fail : -1,
    SignWithUsernameException : 1000,
    UserNotFoundOrInvalidPassword : 1001,
    InvalidParam : 1002,
    SignWithGoogleTokenException : 1003,
    AuthTokenVerifyFail : 1004,
    JwtParseException : 1005,
    GoogleTokenVerifyFail : 1006,
    GoogleTokenVerifyException : 1007,
    AuthTokenExpired : 1008,
    InvalidToken : 1009,
    Unauthorized : 1010,
    NotImplements : 1011,
    LoginDuplication : 1012,
    Exception : 9998,
    Unknown : 9999
}

responseCode = Object.freeze(responseCode);

export default responseCode;
