<template >
    <ModalBack :closeAction="hideModal">
        <div class="address_search_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">사원조회</div>
    
            <div class="search_panel" >
                <LabelPanel style="width:233px; ">
                    <template v-slot:label>검색어</template>
                    <template v-slot:content>
                        <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter" :isFocus="true"></ConditionInput>
                    </template>
                </LabelPanel>
                 <button class="search_btn" @click="getUsers"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
            </div>
            <div style="height:10px;"></div>
            <div style="overflow:hidden; flex:1;display:flex; flex-direction:column;">
                <div style="flex:1; overflow:hidden">
                    <CommonTable :plist="list" :pcolumn="column" :clickRow="selectUser" :isShowNumbering="true" :clickDoubleRow="(data) => {curSelectedUser = data; complete();}"></CommonTable>
                </div>

                <ColumnSetting v-if="isShowColumnSettings" 
                    :vue-name="$options.name" 
                    :default-settings="column" 
                    :closeSettings="() => {isShowColumnSettings = false}" 
                    :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
                </ColumnSetting>
            </div>
            <div style="height:10px;"></div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="complete">확인</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import ConditionInput from '@/components/common/ConditionInput.vue';

import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';

export default {
    name : 'UserSearch',
    props : {
        hideModal : Function,
        completeSave : {
            type : Function,
            default : ()=>{}
        }
    },
    components : {
        ModalBack,
        CommonModalCloseBtn,
        LabelPanel,
        CommonTable,
        ColumnSetting, ConditionInput
    },
    data(){
        return {
            isShowColumnSettings : false,
            lastSizePerPage : 100,
            lastPage : 1,
            curSelectedUser : {userName : '', userCode : ''},
            conditionCode : '',
            searchWord : '',
            list: [],
            custom : {
                
            },
            column: [
                { key: "userName", name: "사원명", w: "150px"},
                { key: "groupName", name: "부서", w: "150px"},
                { key: "userClass", name: "직급", w: "100px" },
                { key: "barcode", name: "바코드", w: "100px" },
            ],
            paging : {},
            searchConditions: [
                {name : '사원명', key : 'userName', checked : true},
                {name : '부서', key : 'groupName', checked : true},
                {name : '직급', key : 'userClass', checked : true},
                {name : '바코드', key : 'barcode', checked : true},
            ]
        }
        
    },
    methods : { 
        complete(){
            this.completeSave(this.curSelectedUser);
            this.hideModal();
        },
        selectUser(data){
            this.curSelectedUser = data;
        },
        detectChange(value){
            this.conditionCode = value
        },
        getUsers(){
            const parameters = this.getParameters();

            axios.get('/api/user/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    this.list = data.users.content;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};

            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;

            let conditions = [];
            let searchWords = [];

            conditions.push('np_used');
            searchWords.push('1');

            if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }

            // if(this.conditionCode !== ''){
            //     conditions.push(this.conditionCode);
            //     searchWords.push(this.searchWord);
            // }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            return parameters;
        },
        enter(){
            this.getUsers();
        },
        
    },
    created(){
        this.getUsers();

        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
.address_search_wrap{
    background-color:white; width:630px; height:830px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
.asset_bottom_btns{text-align:right;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}


.search_panel{display:flex; background-color:white; margin-top:10px;}
.search_btn{
  text-align:left;margin-top:21px;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>