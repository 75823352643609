<template>
 <div style="height: 100%;">
    <!-- 헤드 -->
    <div class="header">
      <div class="header_left">
        <div class="left_menu_toggle" @click="toggleLeftMenu">
          <font-awesome-icon icon="fa-solid fa-bars" style="font-size:20px;" />
        </div>
        <div style="margin-left:15px;">
          <img :src="require('@/assets/log_send_front.png')" style="width:250px; transform: translate(0px, -4px);">
          <!-- <img :src="require('@/assets/logo_left_top.png')" style="    height: 100%;"> -->
        </div>
        <!-- <div class="vertical_line" style="margin: 0px 14px 0px 20px; "></div>
        <div class="company_name">화성시인재육성재단</div> -->
      </div>
      <div class="header_center" stlye="">

      </div>
      <div class="header_right">
        <div >{{levelName}}</div>
        <div class="vertical_line" style="margin:0px 13px;"></div>
        <div >{{userName}}</div>
        <div @click="logout"><font-awesome-icon icon="fa-solid fa-right-from-bracket" class="icon"  /></div>
      </div>
    </div>
    <!-- 왼쪽메뉴 -->
    <div class="layout_contents" >
      <div class="left_menu" v-show="isLeftMenuOpen" ref="scrollWrap">
        <div class="menu_wrap">
          <div class="menu" @click="toggleSubMenu('asset')">
            <font-awesome-icon icon="fa-solid fa-laptop" class="icon" />
            <span class="menu_label">자산관리</span>
            <img class="arrow_img" :src="require('@/assets/menu_arrow.png')" :style="{transform : menuOpen.asset ? 'rotate(90deg)' : 'rotate(0deg)'}">
          </div>
          <div class="sub_menu_wrap" :style="{height : $GlobalStore.state.openMenuKey === 'asset' || menuOpen.asset ? 36 * 3 + 'px' : '0px'}">
            <div class="sub_menu" :class="자산관리 ? '' : 'disabled'" @click="() => {if(!자산관리) return; goPage('/view/assetMng')}" >자산관리</div>
            <div class="sub_menu" :class="재물조사 ? '' : 'disabled'" @click="() => {if(!재물조사) return; goPage('/view/survey/mng')}" >재물조사</div>
            <div class="sub_menu" :class="자산라벨출력 ? '' : 'disabled'" @click="() => {if(!자산라벨출력) return; goPage('/view/asset/labelprint')}">자산라벨출력</div>
          </div>
        </div>
  
        <div class="menu_wrap">
          <div class="menu" @click="toggleSubMenu('assetCurrent')">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="icon" />
            <span class="menu_label">자산현황조회</span>
            <img class="arrow_img" :src="require('@/assets/menu_arrow.png')" :style="{transform : menuOpen.assetCurrent ? 'rotate(90deg)' : 'rotate(0deg)'}">
          </div>
          <div class="sub_menu_wrap" :style="{height : menuOpen.assetCurrent ? 36 * 6 + 'px' : '0px'}">
            <div class="sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="() => {if(!자산현황조회) return; goPage('/view/asset/current')}">자산현황</div>
            <div class="sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="() => {if(!자산현황조회) return; goPage('/view/asset/change')}">변경내역</div>
            <div class="sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="() => {if(!자산현황조회) return; goPage('/view/asset/repair')}">수리내역</div>
            <div class="sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="() => {if(!자산현황조회) return; goPage('/view/asset/rental')}">대여내역</div>
            <div class="sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="() => {if(!자산현황조회) return; goPage('/view/asset/memo')}">메모내역</div>
            <div class="sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="() => {if(!자산현황조회) return; goPage('/view/asset/delete')}">삭제내역</div>
          </div>
          
        </div>

        <div class="menu_wrap">
          <div class="menu" @click="toggleSubMenu('report')">
            <font-awesome-icon icon="fa-regular fa-file-lines" class="icon" />
            <span class="menu_label">보고서</span>
            <img class="arrow_img" :src="require('@/assets/menu_arrow.png')" :style="{transform : menuOpen.report ? 'rotate(90deg)' : 'rotate(0deg)'}">
          </div>
          <div class="sub_menu_wrap" :style="{height : menuOpen.report ? 36 * 2 + 'px' : '0px'}">
            <div class="sub_menu" :class="자산이력카드 ? '' : 'disabled'" @click="() => {if(!자산이력카드) return; goPage('/view/asset/historycard')}">자산이력카드</div>
            <div class="sub_menu" :class="자산현황보고서 ? '' : 'disabled'" @click="() => {if(!자산현황보고서) return; goPage('/view/report/asset')}">자산현황보고서</div>
          </div>
        </div>

        <div class="menu_wrap">
          <div class="menu" @click="toggleSubMenu('depreciation')">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="icon" />
            <span class="menu_label">감가상각</span>
            <img class="arrow_img" :src="require('@/assets/menu_arrow.png')" :style="{transform : menuOpen.depreciation ? 'rotate(90deg)' : 'rotate(0deg)'}">
          </div>
          <div class="sub_menu_wrap" :style="{height : menuOpen.depreciation ? 36 * 3 + 'px' : '0px'}">
            <div class="sub_menu" :class="감가상각 ? '' : 'disabled'" @click="() => {if(!감가상각) return; goPage('/view/depreciation/mng')}">감가상각관리</div>
            <div class="sub_menu" :class="감가상각 ? '' : 'disabled'" @click="() => {if(!감가상각) return; goPage('/view/depreciation/spec')}">감가상각명세서</div>
            <div class="sub_menu" :class="감가상각 ? '' : 'disabled'" @click="() => {if(!감가상각) return; goPage('/view/depreciation/total')}">분류별 감가상각 총괄표</div>
            <!-- <div class="sub_menu">계정과목관리</div> -->
          </div>
        </div>

        <div class="menu_wrap">
          <div class="menu" @click="toggleSubMenu('baseCode')">
            <font-awesome-icon icon="fa-regular fa-pen-to-square" class="icon"/>
            <span class="menu_label">기초정보관리</span>
            <img class="arrow_img" :src="require('@/assets/menu_arrow.png')" :style="{transform : menuOpen.baseCode ? 'rotate(90deg)' : 'rotate(0deg)'}">
          </div>
          <div class="sub_menu_wrap" :style="{height : menuOpen.baseCode ? 36 * 4 + 'px' : '0px'}">
            <!-- <div class="sub_menu" :class="기초코드관리 ? '' : 'disabled'" @click="() => {if(!기초코드관리) return; goPage('/view/basecode/mng')}">기초코드관리</div> -->
            <div class="sub_menu" :class="거래처관리 ? '' : 'disabled'" @click="() => {if(!거래처관리) return; goPage('/view/company/mng')}">거래처관리</div>
            <div class="sub_menu" :class="사원관리 ? '' : 'disabled'" @click="() => {if(!사원관리) return; goPage('/view/user/mng')}">사원관리</div>
            <!-- <div class="sub_menu">사용권한관리</div> -->
            <div class="sub_menu" :class="로그인관리 ? '' : 'disabled'" @click="() => {if(!로그인관리) return; goPage('/view/login/mng')}">로그인관리</div>
            <div class="sub_menu" :class="환경설정 ? '' : 'disabled'" @click="() => {if(!환경설정) return; isShowSettings = true}">환경설정</div>
          </div>
        </div>
      </div>

      <div class="left_menu_small" v-if="!isLeftMenuOpen">
        <div class="icon_wrap">
          <font-awesome-icon icon="fa-solid fa-laptop" class="small_icon" />
          <div class="float_sub_wrap">
            <div class="float_menu" >자산관리</div>
            <div class="float_sub_menu" :class="자산관리 ? '' : 'disabled'" @click="(e) => {if(!자산관리) return; goPageSub(e, '/view/assetMng')}" >자산관리</div>
            <div class="float_sub_menu" :class="재물조사 ? '' : 'disabled'" @click="(e) => {if(!재물조사) return; goPageSub(e, '/view/survey/mng')}" >재물조사</div>
            <div class="float_sub_menu" :class="자산라벨출력 ? '' : 'disabled'" @click="(e) => {if(!자산라벨출력) return; goPageSub(e, '/view/asset/labelprint')}" >자산라벨출력</div>
          </div>
        </div>
        <div class="icon_wrap">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="small_icon" />
          <div class="float_sub_wrap">
            <div class="float_menu">자산현황조회</div>
            <div class="float_sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="(e) => {if(!자산현황조회) return; goPageSub(e, '/view/asset/current')}">자산현황</div>
            <div class="float_sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="(e) => {if(!자산현황조회) return; goPageSub(e, '/view/asset/change')}">변경내역</div>
            <div class="float_sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="(e) => {if(!자산현황조회) return; goPageSub(e, '/view/asset/repair')}">수리내역</div>
            <div class="float_sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="(e) => {if(!자산현황조회) return; goPageSub(e, '/view/asset/rental')}">대여내역</div>
            <div class="float_sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="(e) => {if(!자산현황조회) return; goPageSub(e, '/view/asset/memo')}">메모내역</div>
            <div class="float_sub_menu" :class="자산현황조회 ? '' : 'disabled'" @click="(e) => {if(!자산현황조회) return; goPageSub(e, '/view/asset/delete')}">삭제내역</div>
          </div>
        </div>
        <div class="icon_wrap">
          <font-awesome-icon icon="fa-regular fa-file-lines" class="small_icon" />
          <div class="float_sub_wrap">
            <div class="float_menu">보고서</div>
            <div class="float_sub_menu" :class="자산이력카드 ? '' : 'disabled'" @click="(e) => {if(!자산이력카드) return; goPageSub(e, '/view/asset/historycard')}">자산이력카드</div>
            <div class="float_sub_menu" :class="자산현황보고서 ? '' : 'disabled'" @click="(e) => {if(!자산현황보고서) return; goPageSub(e, '/view/report/asset')}">자산현황보고서</div>
          </div>
        </div>
        <div class="icon_wrap">
          <font-awesome-icon icon="fa-solid fa-coins" class="small_icon"/>
          <div class="float_sub_wrap">
            <div class="float_menu">감가상각</div>
            <div class="float_sub_menu" :class="감가상각 ? '' : 'disabled'" @click="(e) => {if(!감가상각) return; goPageSub(e, '/view/depreciation/mng')}">감가상각관리</div>
            <div class="float_sub_menu" :class="감가상각 ? '' : 'disabled'" @click="(e) => {if(!감가상각) return; goPageSub(e, '/view/depreciation/spec')}">감가상각명세서</div>
            <div class="float_sub_menu" :class="감가상각 ? '' : 'disabled'" @click="(e) => {if(!감가상각) return; goPageSub(e, '/view/depreciation/total')}">분류별 감가상각 총괄표</div>
            <!-- <div class="float_sub_menu">계정과목관리</div> -->
          </div>
        </div>
        <div class="icon_wrap">
          <font-awesome-icon icon="fa-regular fa-pen-to-square" class="small_icon"/>
          <div class="float_sub_wrap">
            <div class="float_menu">기초정보관리</div>
            <!-- <div class="float_sub_menu" :class="기초코드관리 ? '' : 'disabled'" @click="(e) => {if(!기초코드관리) return; goPageSub(e, '/view/basecode/mng')}">기초코드관리</div> -->
            <div class="float_sub_menu" :class="거래처관리 ? '' : 'disabled'" @click="(e) => {if(!거래처관리) return; goPageSub(e, '/view/company/mng')}">거래처관리</div>
            <div class="float_sub_menu" :class="사원관리 ? '' : 'disabled'" @click="(e) => {if(!사원관리) return; goPageSub(e, '/view/user/mng')}">사원관리</div>
            <!-- <div class="float_sub_menu">사용권한관리</div> -->
            <div class="float_sub_menu" :class="로그인관리 ? '' : 'disabled'" @click="(e) => {if(!로그인관리) return; goPageSub(e, '/view/login/mng')}">로그인관리</div>
            <div class="float_sub_menu" :class="환경설정 ? '' : 'disabled'" @click="(e) => {if(!환경설정) return; isShowSettings = true}">환경설정</div>
          </div>
        </div>
      </div>
      <div class="contents_wrap">
        <router-view :key="$route.fullPath" v-if="isShow"></router-view>
      </div>
    
    </div>
    <AuthorityRegister v-if="isShowAuthority" :hideModal="() => {isShowAuthority = false}"></AuthorityRegister>
    <Settings v-if="isShowSettings" :hideModal="() => {isShowSettings = false}"></Settings>
    <portal-target name="modalArea"></portal-target>
  </div>
</template>

<script>
import AuthorityRegister from '@/components/modal/AuthorityRegister.vue';
import Settings from '@/components/modal/Settings.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, setStates, setFields, AUTH} from '@/util/Constants.js';
import ColumnManager from '@/modules/ColumnManager.js'

export default {
  name: 'PageFrame',
  components : {
    AuthorityRegister, Settings
  },
  data(){
    return {
      AUTH : AUTH,
      isLeftMenuOpen : false,
      menuOpen : {
        asset : true,
        assetCurrent : true,
        report : true,
        depreciation : true,
        baseCode : true
      },
      isShowAuthority : false,
      isShowSettings : false,
      isShow : false,
    }
  },
  computed : {
    userName(){
      return this.$GlobalStore.getLoginUserName();
    },
    levelName(){
      return this.$GlobalStore.getLoginLevelName();
    },
    기초코드관리(){
      return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.SELECT);
    },
    거래처관리(){
      return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.SELECT);
    },
    자산관리(){
      return this.$GlobalStore.isAllowedFor(AUTH.자산관리, AUTH.SELECT);
    },
    재물조사(){
      return this.$GlobalStore.isAllowedFor(AUTH.재물조사, AUTH.SELECT);
    },
    자산라벨출력(){
      return this.$GlobalStore.isAllowedFor(AUTH.자산라벨출력, AUTH.SELECT);
    },
    자산현황조회(){
      return this.$GlobalStore.isAllowedFor(AUTH.자산현황조회, AUTH.SELECT);
    },
    자산이력카드(){
      return this.$GlobalStore.isAllowedFor(AUTH.자산이력카드, AUTH.SELECT);
    },
    자산현황보고서(){
      return this.$GlobalStore.isAllowedFor(AUTH.자산현황보고서, AUTH.SELECT);
    },
    사원관리(){
      return this.$GlobalStore.isAllowedFor(AUTH.사원관리, AUTH.SELECT);
    },
    로그인관리(){
      return this.$GlobalStore.isAllowedFor(AUTH.로그인관리, AUTH.SELECT);
    },
    환경설정(){
      return this.$GlobalStore.isAllowedFor(AUTH.환경설정, AUTH.SELECT);
    },
    감가상각(){
      return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.SELECT);
    },
  },
  methods : {
    goPage(path){
      this.$GlobalStore.setLeftMenuScrollTop(this.$refs['scrollWrap'].scrollTop);
      this.$GlobalRouter.push(path + '?' + new Date().getTime());
    },
    goPageSub(e, path){
      this.hide(e);
      this.$GlobalStore.setLeftMenuScrollTop(this.$refs['scrollWrap'].scrollTop);
      this.$GlobalRouter.push(path + '?' + new Date().getTime());
    },
    toggleLeftMenu(){
      this.isLeftMenuOpen = !this.isLeftMenuOpen;
      localStorage.setItem('isLeftMenuOpen', this.isLeftMenuOpen);
    },
    toggleSubMenu(menuKey){
      this.menuOpen[menuKey] = !this.menuOpen[menuKey];
      localStorage.setItem('menuOpen', JSON.stringify(this.menuOpen));
    },
    test(){
      this.$GlobalRouter.push('/view/survey/mng' + '?' + new Date().getTime());
    },
    hide(e){
      const target = e.currentTarget.parentNode;
      target.classList.add('temp_hidden'); 
      setTimeout(() => {
        target.classList.remove('temp_hidden');
      }, 300)
    },
    getDefaultData(){
      const parameters = {};

      axios.get('/api/base/defaultdata', {params : parameters}).then(res => {
          if(res.data.code == ResCode.Success){
              const data = res.data.data;
      
              setStates(data.stateCodes);
              setFields(data.fields);
              ColumnManager.setColumnSettings(data.columnSettings);
              this.$GlobalStore.setLoginInfo(data.userName, data.levelName)
              this.$GlobalStore.setAuthorities(data.authorities);

              switch(location.pathname){
                case '/view/assetMng' : 
                  if(!this.자산관리) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/survey/mng' : 
                  if(!this.재물조사) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/asset/current' : 
                  if(!this.자산현황조회) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/asset/change' : 
                  if(!this.자산현황조회) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/asset/repair' : 
                  if(!this.자산현황조회) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/asset/rental' : 
                  if(!this.자산현황조회) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/asset/memo' : 
                  if(!this.자산현황조회) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/asset/delete' : 
                  if(!this.자산현황조회) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/asset/labelprint' : 
                  if(!this.자산라벨출력) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/company/mng' : 
                  if(!this.거래처관리) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/user/mng' : 
                  if(!this.사원관리) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/login/mng' : 
                  if(!this.로그인관리) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/depreciation/mng' : 
                  if(!this.감가상각) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/depreciation/spec' : 
                  if(!this.감가상각) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/depreciation/total' : 
                  if(!this.감가상각) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                case '/view/report/asset' : 
                  if(!this.자산현황보고서) this.$GlobalRouter.push('/view/home' + '?' + new Date().getTime());
                  break;
                default : 

                break;
              }

              // if(this.자산관리) this.$GlobalRouter.push('/view/assetMng' + '?' + new Date().getTime());
              // if(this.자산관리) this.$GlobalRouter.push('/view/survey/mng' + '?' + new Date().getTime());

              this.isShow = true;
              // console.log(data.fields);
              // console.log(data.stateCodes);
          }
      }).catch(res => {
          console.log(res);
      });

    },
    logout(){
      const parameters = {};
      axios.post('/api/login/logout', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          localStorage.removeItem('token');
          this.$GlobalRouter.push('/view/login' + '?' + new Date().getTime());
        }
      }).catch(res => {
          console.log(res);
      });
    }
  },
  mounted(){
    this.$refs['scrollWrap'].scrollTop = this.$GlobalStore.state.leftMenuScrollTop;
  },
  created(){
    this.getDefaultData();

    let isLeftMenuOpen = true;
    if(Object.prototype.hasOwnProperty.call(localStorage, 'isLeftMenuOpen')){
      isLeftMenuOpen = localStorage.isLeftMenuOpen === 'true' ? true : false;
    }

    if(Object.prototype.hasOwnProperty.call(localStorage, 'menuOpen')){
      let menuOpen = JSON.parse(localStorage.menuOpen);
      for(const key in menuOpen){
        this.menuOpen[key] = menuOpen[key] === true ? true : false;
      }
    }
    this.isLeftMenuOpen = isLeftMenuOpen;
  }    
}
</script>
<style scoped>
.header{height:62px; display:flex;padding:14px; padding-top:17px;border-bottom:1px solid #e6e7ec;}
.header_left{display:flex;}
.header_center{flex:1;}
.header_right{display:flex;    padding: 6px;;}
.company_name{font-size:20px; font-weight:bold;line-height: 1.7;}
.vertical_line{width:1px; background-color:#e6e6e6}
.left_menu{
  width:278px;
  height: 100%;
  padding:20px 0px;
  background-color:#040848;
  overflow-y:auto;
}
.left_menu_small{  width:70px;;
  height: 100%;
  padding:20px 0px;
  background-color:#040848;
 }
.menu_wrap + .menu_wrap{margin-top:10px;}
.menu:hover, .sub_menu:hover{
  background-color:#12286c;
  cursor:pointer;
  color:white;
}
.sub_menu_wrap{
  overflow:hidden;transition:height 0.3s ease-in; height:0px;
}

.menu{font-size:17px;color:#9b9cb6;font-weight:bold; padding: 12px 20px;}
.sub_menu{font-size:16px;color:#9b9cb6;font-weight:bold;padding:10px 20px 10px 72px;}
.menu_label{margin-left:12px;}
.left_menu_toggle{height:100%;     padding: 2px 6px; cursor:pointer;}
.layout_contents{display:flex;height: calc(100% - 62px)}
.contents_wrap{flex:1;overflow:auto;background-color:#f5f6fa }
.arrow_img{float:right;margin-top: 3px; transition: transform 0.3s ease-in; transform: rotate(90deg); }
.icon{font-size:19px; float:left;width:25px;}
.small_icon{font-size:20px;color:#9b9cb6;}

.icon_wrap{ padding: 15px 20px;;text-align:center;position:relative;height:52px;}

.icon_wrap:hover { background-color:#12286c;cursor:pointer;}
.icon_wrap:hover > .small_icon{color:white;}

.float_sub_wrap{
  display:none;
  position:absolute;
  left:70px; top:0px;
  width:200px;
  z-index:50;
  padding-bottom:10px;;
}
.float_menu{
  height:52px;
  font-size:17px;
   background-color:#12286c;
   color:white;
   font-weight:bold;
   padding:18px 20px;
   width:100%;
   text-align:left;
   
}

.float_sub_menu{text-align:left; font-size:16px; color:#9b9cb6;font-weight:bold;  padding:10px 20px; background-color:#040848}
.float_sub_menu:hover{background-color:#12286c;color:white;}

.icon_wrap:hover .float_sub_wrap{display:block;}
.temp_hidden{
  display:none !important;
}

.sub_menu.disabled{
  color:#515151;
}

.float_sub_menu.disabled{
  color:#515151;
}
</style>
