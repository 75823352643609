<template >
    <div style="position:relative;height:100%;">
        <CommonInput :clickInput="select" v-model="model" :isReadonly="isReadonly" mode="date"></CommonInput>
        <div v-if="isShowCalendar" style="position:fixed; left:0px; top:0px; width:100vw; height:100vw" @click="closeCalendar"></div>
        <div v-if="isShowCalendar" style="position:fixed;background-color:white;border:1px solid #e6e7ec;border-radius:5px; z-index:10;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;" :style="{top : top}">
            <Calendar :select-date-callback="selectDateFunc" :startDate="value"></Calendar>
        </div>
    </div>
</template>
<script>
import Calendar from '@/components/common/Calendar.vue';
import CommonInput from '@/components/common/CommonInput.vue';

export default {
    name : 'CalendarInput',
    components : {
        Calendar, CommonInput
    },
    props: {
        value : {
            type : String,
            default : ''
        },
        isReadonly : {
            type : Boolean,
            default : false,
        }
    },
    data(){
        return {
            isShowCalendar : false,
            top : '0px'
        }
    },
    computed : {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods : {
        select(e){
            e.currentTarget.select();
            const rect = e.currentTarget.getBoundingClientRect();
            this.top = (rect.y + 47) + 'px';
            this.isShowCalendar = true;
        },
        selectDateFunc(selectedDate){
            this.$emit("input", selectedDate);
            // this.$set(this.data, 'column7', selectedDate)
            this.isShowCalendar = false;
        },
        closeCalendar(){
            this.isShowCalendar = false;
        }
    }
}
</script>
<style>
    
</style>