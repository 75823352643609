<template >
    <ModalBack>
        <div class="subcode_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px 8px 0px; font-weight: bold;" >위치코드 {{curMode === MODE.INSERT ? '추가' : '수정'}}</div>
            <div class="asset_info_contents">
                <table class="asset_info_tb" style="margin-top:25px;">
                    <colgroup>
                        <col>
                    </colgroup>
                    <tr>
                        <td>
                            <div class="label required">위치명</div>
                            <CommonInput v-model="curName" :signalFocus="signalFocus" ></CommonInput>
                        </td>
                    </tr>
                    <tr class="margin"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label">위치코드</div>
                            <CommonInput v-model="curSubcode"></CommonInput>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="save">저장</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';
export default {
    name : 'TreeSubcodRegister',
    components : {
        ModalBack, CommonModalCloseBtn, CommonInput
    },
    props : {
        hideModal : Function,
        curMode : Number,
        transferData : Object,
        curCodeType : String,
        completeFunc : Function
    },
    data(){
        return {
            MODE : MODE,
            curName : '',
            curSubcode : '',
            prevSubcode : '',
            prevName : '',
            signalFocus : false,
        }
    },
    methods : {
        async save(){
            if(this.curName === ''){
                alert('위치명을 입력해주세요.');
                return;
            }

            if(this.curMode === MODE.INSERT){
                this.addCode();
            }else if(this.curMode === MODE.UPDATE){
                this.updateCode();
            }
        },
        addCode(){          
            const parameters = {};
            const data = this.transferData;
            parameters.level = data.level;
            parameters.parentCode = data.parentCode;
            parameters.text = this.curName;
            parameters.type = this.curCodeType;
            parameters.tempCode = this.curSubcode;

            axios.post('/api/base/tree/add', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Fail){
                    alert(res.data.message);
                }else {
                    this.completeFunc(this.curName);
                }
                // this.getTree(this.curTab, searchWord);
            }).catch(res => {
                console.log(res);
            });
        },
        async updateCode(){
			let isSuccess = true;

            if(this.prevSubcode !== this.curSubcode) isSuccess = await this.updateTempCode();
			if(isSuccess && this.curName !== this.prevName) await this.renameCode();
            if(isSuccess) this.completeFunc(this.curName);
        },
        async renameCode(){
			const parameters = {};
			parameters.value = this.transferData.value;
			parameters.type = this.curCodeType;
			parameters.newName = this.curName;

			return new Promise((resolve, reject) => {
				axios.post('/api/base/tree/rename', this.$commonFunc.convertToForm(parameters)).then(res => {
					if(res.data.code == ResCode.Fail){
						alert(res.data.message);
					}
					resolve();
				}).catch(res => {
					console.log(res);
					reject();
				});
			});
		},
        async updateTempCode(){
            return new Promise((resolve, reject) => {
                console.log('updateTempCocde');
                const parameters = {};
                parameters.value = this.transferData.value;
                parameters.tempCode = this.curSubcode;
                parameters.type = this.curCodeType;

                axios.post('/api/base/tree/save', this.$commonFunc.convertToForm(parameters)).then(res => {
                    if(res.data.code == ResCode.Fail){
                        alert(res.data.message);
                        resolve(false);
                    }else {
                        resolve(true);
                    }
                    
                }).catch(res => {
                    resolve(false);
                    console.log(res);
                });
            });
        },

    },
    mounted(){
        setTimeout(() => { 
            this.signalFocus = !this.signalFocus;
        }, 300);
        
    },
    created(){
        if(this.curMode === MODE.INSERT){
            console.log('insert');
        }else if(this.curMode === MODE.UPDATE){
            this.curName = this.transferData.text;
            this.prevName = this.transferData.text;
            this.curSubcode = this.transferData.tempCode;
            this.prevSubcode = this.transferData.tempCode;
        }
        
    }
}
</script>
<style scoped>
    .subcode_register_wrap{
    background-color:white;
    max-width:100%;
    max-height:100%;
    width:400px;
    height:350px;
    min-width:300px; 
    min-height:300px;
    padding:38px;
    display:flex;
    overflow:auto;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
    
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right; padding-top: 14px;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>