<template >
    <ModalBack :closeAction="hideModal">
        <div class="company_register_wrap">
        <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
        <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >변경수정</div>
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                    <col>
                </colgroup>
                <tr>
                    <td>
                        <div class="label">변경일자</div>
                        <CalendarInput v-model="form.changeDate" ></CalendarInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">변경구분</div>
                         <CommonInput v-model="form.changeType" :isReadonly="curMode === MODE.UPDATE"></CommonInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">변경전</div>
                         <CommonInput v-model="form.beforeName" :isReadonly="curMode === MODE.UPDATE"></CommonInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">변경후</div>
                         <CommonInput v-model="form.afterName" :isReadonly="curMode === MODE.UPDATE"></CommonInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">변경자</div>
                         <CommonInput v-model="form.changeName" :isReadonly="curMode === MODE.UPDATE"></CommonInput>
                    </td>
                </tr>
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
            <button class="asset_bottom_btn_ok" @click="updateChangeHistory">저장</button>
        </div>

    </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';

export default {
    name : 'AssetChangeRegister',
    props : {
        hideModal : Function,
        data : Object,
        curMode : Number,
        completeAction : {
            type : Function,
            default : () => {}
        }
    },
    components : {
        CommonInput,
        ModalBack,
        CommonModalCloseBtn,
        CalendarInput
    },
    data(){
        return {
            MODE : MODE,
            form : {
                changeDate : '',
                changeType : '',
                beforeName : '',
                afterName : '',
                changeName : '',
            },
        }
    },
    methods : {
        updateChangeHistory(){
            const parameters = {};
            parameters.historyIdx = this.data.historyIdx;
            parameters.changeDate = this.form.changeDate;
                        
            axios.post('/api/asset/changehistory/update', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeAction();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });
        },
    },
    created(){
        if(this.curMode === MODE.UPDATE ){
            this.form.changeDate = this.data.changeDate;
            this.form.changeType = this.data.changeType;
            this.form.beforeName = this.data.beforeName;
            this.form.afterName = this.data.afterName;
            this.form.changeName = this.data.changeName;
        }
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 400px;
    height: 550px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>