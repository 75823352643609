import moment from 'moment';

function calendar(){
    var todayYear;
    var todayMonth;
    var todayDate;
    var selectedYear;
    var selectedMonth;
    var selectedDate;
    var monthData;
    var selectedWeekIndex;
    var defaultForamt = 'YYYY-MM-DD';
    var thisMonthWeekRowCnt = 0;
    var selectedColIndex = 0;
    var activeStartDateTime;
    var activeEndDateTime;

    // var eachDateInfo;
    function setSelectedDateTime(dateStr){
        var now;
        if(dateStr){
            now = dateStr;
        } else {
            now = moment().format(defaultForamt);
        }

        selectedYear = now.substr(0, 4);
        selectedMonth = now.substr(5, 2);
        selectedDate = now.substr(8, 2);
    }

    function setToday(){
        todayYear = selectedYear;
        todayMonth = selectedMonth;
        todayDate = selectedDate;
    }

    function createSelectedMonthData(){
        //선택된 달 끝날짜
        var lastDate = getCurMoment().add(1, 'months').date(1).add(-1, 'days').format('DD');

        //타겟 시작날짜요일
        var firstDay =  getTargetMoment(selectedYear, selectedMonth, '01').day();
        //var lastDay = getTargetMoment(selectedYear, selectedMonth, lastDate).day();
        var lastDateInt = parseInt(lastDate);
        //초기화
        monthData = [];
        var curDate = 1;

        //첫째주
        let firstWeek = [{date : 0}, {date : 0}, {date : 0}, {date : 0}, {date : 0}, {date : 0}, {date : 0}];

        //지난달
        let minus = 0;
        for(let i = firstDay - 1; i > -1; i--){
            minus++;
            let curMoment = moment(selectedYear + '-' + selectedMonth + '-01', defaultForamt).subtract(minus, 'days').format(defaultForamt);
            let tempDate = curMoment.substr(8, 2);
            firstWeek[i].date = parseInt(curMoment.substr(8, 2));
            firstWeek[i].key = curMoment.substr(0, 4) + '-' + curMoment.substr(5, 2) + '-' + tempDate;
            checkInactive(firstWeek[i]);
        }

        //지난달 ~ 이번달이 이어지는 첫째쭈
        let curSelectedYear = selectedYear;
        let curSelectedMonth = selectedMonth;
        for(let i = firstDay; i < 7; i++){
            firstWeek[i].date = curDate;
            let dateStr = curDate;
            if(curDate < 10) dateStr = "0" + dateStr;
            firstWeek[i].key = selectedYear + '-' + selectedMonth + '-' + dateStr;

            if(curSelectedMonth === selectedMonth && curDate === parseInt(selectedDate)){
                selectedWeekIndex = 0;
                selectedColIndex = i;
            }

            checkInactive(firstWeek[i]);

            curDate++;
        }

        monthData.push(firstWeek);

        //이후
        var isEnd = false;
        for(var i = 1; i < 6; i++){
            const curWeek = [{date : 0},{date : 0},{date : 0},{date : 0},{date : 0},{date : 0},{date : 0}];
            for(var j = 0; j < 7; j++){
                curWeek[j].date = curDate;
                let dateStr = curDate;
                if(curDate < 10) dateStr = "0" + dateStr;
                curWeek[j].key = curSelectedYear + '-' + curSelectedMonth + '-' + dateStr;

                if(curDate === parseInt(selectedDate) && curSelectedMonth === selectedMonth){
                    selectedWeekIndex = i;
                    selectedColIndex = j;
                }

                checkInactive(curWeek[j]);

                //다음달 처리
                if(curDate === lastDateInt) {
                    let curMoment = moment(curWeek[j].key, defaultForamt).add(1, 'days').format(defaultForamt);
                    curSelectedYear = curMoment.substr(0, 4);
                    curSelectedMonth = curMoment.substr(5, 2);
                    curDate = 0;
                }

                //마지막 인지 체크
                if(i === 5 && j === 6) {
                    isEnd = true;
                    break;
                }

                curDate++;
            }
            monthData.push(curWeek);
            if(isEnd){
                thisMonthWeekRowCnt = i + 1;
                break;
            }
        }
    }

    function checkInactive(data){
        if(activeStartDateTime != null && activeEndDateTime != null){
            const curDateTime = new Date(data.key).getTime();
            if(activeStartDateTime <= curDateTime && activeEndDateTime >= curDateTime){
                data.active = true;
            }else {
                data.active = false;
            }
        }
    }

    function setNextMonth(){
        var nextMonthDate =  getCurMoment().add(1, 'months').format(defaultForamt);
        setSelectedDateTime(nextMonthDate);
        createSelectedMonthData();
    }

    function setPrevMonth(){
        var prevMonthDate =  getCurMoment().add(-1, 'months').format(defaultForamt);
        setSelectedDateTime(prevMonthDate);
        createSelectedMonthData();
    }

    function setTargetMonth(year, month){
        var targetMonthDate =  getCurMoment().set('year', year).set('month', parseInt(month) - 1).set('date', 1).format(defaultForamt);
        setSelectedDateTime(targetMonthDate);
        createSelectedMonthData();
    }


    function getCurMoment(){
        return moment(selectedYear + "-" + selectedMonth + "-" + selectedDate, defaultForamt);
    }

    function getTargetMoment(year, month, date){
        if(parseInt(month) < 10) month = '0' + parseInt(month);
        if(parseInt(date) < 10) date = '0' + parseInt(date);
        return moment(year + "-" + month + "-" + date, defaultForamt);
    }

    function getMonthData(){
        return monthData;
    }

    function getYear(){
        return selectedYear;
    }

    function getMonth(){
        return selectedMonth;
    }

    function getSelectedWeekIndex(){
        return selectedWeekIndex;
    }

    function getWeekCnt(){
        return thisMonthWeekRowCnt;
    }

    function isTodayMonth(){
        return todayYear + todayMonth == selectedYear + selectedMonth;
    }

    function getDateStrBy(i, j){
        // var date = monthData[i][j].date;
        // if(date < 10) date = '0' + date;
        // return selectedYear + '-' + selectedMonth + '-' + date;
        return monthData[i][j].key;
    }
    function getSelectedColIndex(){
        return selectedColIndex;
    }

    function init(startDate, p_activeStartDate, p_activeEndDate){
        if(p_activeStartDate != null) activeStartDateTime = new Date(p_activeStartDate).getTime();
        if(p_activeEndDate != null) activeEndDateTime = new Date(p_activeEndDate).getTime();

        setSelectedDateTime(startDate);
        setToday();
        createSelectedMonthData();
    }

    function getDisplayMonthFirstStr(){
        return monthData[0][0].key;
    }

    function getDisplayMonthLastStr(){
        const monthDataRowIndex = monthData.length - 1;
        return monthData[monthDataRowIndex][monthData[monthDataRowIndex].length - 1].key;
    }

    // function setEachDateInfo(eachDateInfo){
    //     this.eachDateInfo = eachDateInfo;
    // }

    this.setTargetMonth = setTargetMonth;
    this.setPrevMonth = setPrevMonth;
    this.setNextMonth = setNextMonth;
    this.getMonthData = getMonthData;
    this.getYear = getYear;
    this.getMonth = getMonth;
    this.getSelectedWeekIndex = getSelectedWeekIndex;
    this.getWeekCnt = getWeekCnt;
    this.isTodayMonth = isTodayMonth;
    this.getDateStrBy = getDateStrBy;
    this.getSelectedColIndex = getSelectedColIndex;
    this.getDisplayMonthFirstStr = getDisplayMonthFirstStr;
    this.getDisplayMonthLastStr = getDisplayMonthLastStr;

    // this.setEachDateInfo = setEachDateInfo;

    this.init = init;

}


export default calendar;