<template >
    <div>
        <ModalBack :closeAction="hideModal">
            <div class="survey_register_wrap">
                <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
                <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">실사등록</div>
                <div style="flex:1; overflow:hidden; display:flex; flex-direction:column;">
                    <div style="display:flex;margin-top: 19px;">
                        <LabelPanel style="width:233px;">
                            <template v-slot:label>실사일자</template>
                            <template v-slot:content>
                                <CalendarInput v-model="buyDate"></CalendarInput>
                            </template>
                        </LabelPanel>
                        <LabelPanel style="margin-left:19px;">
                            <template v-slot:label>실사담당자</template>
                            <template v-slot:content>
                                <CommonInput v-model="chargeName" :clickInput="() => { isShowUserSearch = true; }"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => { isShowUserSearch = true; }"></MaginifyingGlassButton>
                                <div style="display:flex; justify-content:center; align-items:center; margin-left:5px;">
                                    <CommonCheckbox :flag="isWorkerOnlyShow" color="#e6e7ec" :clickCheck="toggleWorkerOnlyShow" ></CommonCheckbox>&nbsp;담당자 자산만 보기
                                </div>
                            </template>
                        </LabelPanel>
                    </div>
                    <div style="display:flex; overflow:hidden; flex:1;;margin-top: 12px;">
                        <div style="flex:1;display:flex;overflow:hidden; flex-direction:column; border: 1px solid #e6e7ec;padding: 10px; border-radius:5px;">
                            <div style="display:flex;align-items:center;"><span class="horizontal_label">재산구분</span><span style="color:#0066fd; margin-left:10px;">{{target}}</span></div>
                            <div style="flex:1; overflow:hidden;display:flex; flex-direciton:column;">
                                <CommonTree :isShowSearchPanel="false" :detectSelect="detectItemSelect" :p-tree-data="treeData[0]" :signal-complete="signalItemComplete" :complete-callback="completeItemCallback" :multiple="false"></CommonTree>
                            </div>
                        </div>
                        <div style="width:20px;"></div>
                        <div style="flex:1;display:flex;overflow:hidden; flex-direction:column; border: 1px solid #e6e7ec;padding: 10px;border-radius:5px;">
                            <div style="display:flex;align-items:center;"><CommonSelect :list="treeType" style="width: 70px;" :detectChange="detectChange" :value="curTree"></CommonSelect><span style="color:#0066fd;margin-left:10px;">{{secondTarget}}</span></div>
                            <div style="flex:1; overflow:hidden; display:flex; flex-direciton:column;">
                                <CommonTree v-if="curTree === '0'" :key="0" :detectSelect="detectSelect" :isShowSearchPanel="false" :p-tree-data="treeData[1]" :multiple="false"></CommonTree>
                                <CommonTree v-if="curTree === '1'" :key="1" :detectSelect="detectSelect" :isShowSearchPanel="false" :p-tree-data="treeData[2]" :multiple="false"></CommonTree>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="asset_bottom_btns">
                    <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                    <button class="asset_bottom_btn_ok" @click="save">저장</button>
                </div>
            </div>
        </ModalBack>
        <UserSearch v-if="isShowUserSearch" :hideModal="() => {isShowUserSearch = false}"  :completeSave="(data) => { chargeName = data.userName; chargeCode = data.userCode; isWorkerOnlyShow = true;}"></UserSearch>
    </div>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import CommonTree from '@/components/common/CommonTree.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import UserSearch from '@/components/modal/UserSearch.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import moment from 'moment';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
export default {
    name : 'SurveyRegister',
    components : {ModalBack, CommonModalCloseBtn, LabelPanel, CommonInput, CommonCheckbox, CommonTree, MaginifyingGlassButton, CommonSelect, UserSearch, CalendarInput},
    props : {
        hideModal : Function,
        completeSave : Function
    },
    data (){
        return {
            isShowUserSearch : false,
            isShowCalendar : false,
            buyDate : '',
            isWorkerOnlyShow :false,
            signalItemComplete : false,
            curTree : '0',
            target : '전체',
            secondTarget :  '전체',
            chargeName : '',
            chargeCode : 'U00007',
            itemLevel : '0',
            targetLevel : '0',
            chkCharge : '0',
            itemCode : '',
            targetCode : '',
            treeData : {
                0 : [],
                1 : [],
                2 : [],
            },
            treeType : [
                {name : '위치', value : '0'},
                {name : '부서', value : '1'},
            ],
            
        }
    },
    methods : {
        showCalendar(){
            this.isShowCalendar = true;
        },
        selectDateFunc(selectedDate){
            this.buyDate = selectedDate
            this.isShowCalendar = false;
        },
        toggleWorkerOnlyShow(){
            this.isWorkerOnlyShow = !this.isWorkerOnlyShow;
        },
        completeItemCallback(data){
    
        },
        detectItemSelect(data){
            this.target = data.text;
            this.itemCode = (data.value == '-1' ? "" : data.value);
            this.itemLevel = (data.value == '-1' ? "0" : data.level);
        },
        detectSelect(data){
            this.secondTarget = data.text;
            this.targetLevel = (data.value == '-1' ? "0" : data.level);
            this.targetCode = (data.value == '-1' ? "" : data.value);
        },
        detectChange(selectedType){

            this.curTree = selectedType;
        },
        save(){
            const parameters = {};
            parameters.surveyDateStr = this.buyDate;
            parameters.itemLevel = this.itemLevel;
            parameters.targetType = this.curTree ;
            parameters.targetLevel = this.targetLevel;
            parameters.ChargeCode = this.chargeCode;
            parameters.chkCharge = this.isWorkerOnlyShow ? '1' : '0';
            parameters.itemCode = this.itemCode;
            parameters.targetCode = this.targetCode;

            axios.post('/api/survey/save', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeSave();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });


        },
        getTree(type){
            const parameters = {};
            parameters.topName = '전체';

            let path = "";
            //품목별
            if(type === 0){
                path = "itemCode";
            //위치별
            }else if(type === 1){
                path = "locCode";
            //부서별
            }else if(type === 2){
                path = "groupCode";
            }

            axios.get('/api/asset/cate/' + path, {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const tree = res.data.data.tree
                    if(type === 0 || type === 1){
                        tree[0].selected = true;
                    }
               
                    this.treeData[type] = tree;
                }
            }).catch(res => {
                console.log(res);
            });
        },

    },
    created(){
        this.buyDate = moment().format('YYYY-MM-DD')
        this.getTree(0);
        this.getTree(1);
        this.getTree(2);
    }

}
</script>
<style scoped>
    .survey_register_wrap{
    background-color:white; width:950px; height:90%;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
.line{height:1px; background-color:#e6e7ec;}
.horizontal_label{line-height:40px; font-weight:bold; color : #969696; margin-right:5px; }
.asset_bottom_btns{text-align:right;padding-top: 10px;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>