<template>
    <ModalBack :closeAction="hideModal">
        <div class="item_search_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">재산구분조회</div>
            <div style="flex:1; overflow:hidden; display:flex; flex-direction:column;">
                <CommonTree 
                    :key="2" 
                    :p-tree-data="treeData" 
                    :detectSelect="detectTreeSelect" 
                    :multiple="false" 
                    :isFirstSelected="true"
                    :isFocus="true"
                    :clickDouble="clickDouble"
                    ></CommonTree>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="save">확인</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import CommonTree from '@/components/common/CommonTree.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import ModalBack from '@/components/common/ModalBack.vue';

export default {
    name : 'itemSearch',
    components : {CommonTree, CommonModalCloseBtn, ModalBack},
    props : {
        hideModal : {
            type : Function,
            default : () => {}
        },
        completeSave : {
            type : Function,
            default : () => {}
        }
    },
    data(){
        return {
            treeData : [],
            selectedData : {}
        }
    },
    methods : {
        getTree(){
            const parameters = {};
            parameters.topName = '재산구분코드';
            let path = "itemCode";

            axios.get('/api/asset/cate/' + path, {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const tree = res.data.data.tree
                    tree[0].selected = true;
                    this.treeData = tree;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        detectTreeSelect(data){
            this.selectedData = {value : data.value, text : data.text, level : data.level};
        },
        save(){
            this.completeSave(this.selectedData);
            this.hideModal();
        },
        clickDouble(data){
            this.selectedData = {value : data.value, text : data.text, level : data.level};
            this.save();
        }
    },
    created(){
        this.getTree();
    }
}
</script>
<style scoped>
.item_search_wrap{
    background-color:white; width:500px; height:90%;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
    transform: translate3d(0,0,0);
}
.line{height:1px; background-color:#e6e7ec;}
.horizontal_label{line-height:40px; font-weight:bold; color : #969696; margin-right:5px; }
.asset_bottom_btns{text-align:right;padding-top: 10px;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>