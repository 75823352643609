<script>
import { Bar} from 'vue-chartjs'

export default {
    name : 'BarChart',
    extends : Bar,
    props : {
        chartData : {
            type : Object,
            default : () => {
                return {}
            }
        },
        chartOption : {
            type : Object,
            default : () => {
                return {}
            }
        }
    },
    watch : {
        chartData(){
            
            
        }
    },
    mounted(){
        this.renderChart(this.chartData, this.chartOption);
    }
}
</script>
<style scoped>
    
</style>