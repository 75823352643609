<template >
    <textarea class="common_textarea" v-model="model" :style="{height : height}" :readonly="isReadonly"></textarea>
</template>
<script>
export default {
    name : 'CommonTextarea',
    props: {
        value : String,
        height : {
            type : String,
            default : '40px'
        },
        isReadonly : {
            type : Boolean,
            default : false
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
}
</script>
<style scoped>
.common_textarea{
    resize:none;
    border: 1px solid #e6e7ec;
    display: inline-block;
    font-size: 16px;
    border-radius:5px;
    flex:1;
    min-width: 0px;
    width:100%;
    padding: 6px 10px 7px 10px;
    line-height:1.4;
}

.common_textarea:read-only{
    background-color: #efefef;
}

</style>