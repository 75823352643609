/*global ActiveXObject*/
var customSettings = {
    LabelWidth : 60,
    LabelHeight : 40,
    IsLines : false,
    barcode : {
        BarXpoint : 8,
        BarcodeScope : 15,
        BarHeight : 5,
        FontSize : 3,
        FontType : 0,
        FontName : '굴림',
        CodeType : 'Code 128',
        BarWidthLevel : '1',
        HeaderWidth : 15,
        IsBarTextView : true //'1' true , '0' false
    },
    ViewCount : 6,
}

var TTP_PRINTER = (function(customSettings){

    var _obj = {};
     
    var TSCObj = null;

    function init(){
        TSCObj = new ActiveXObject("TSCActiveX.TSCLIB");
    }

    var WidthMargin = 16; // 단위 point, ※좌측 여백 2mm, 우측 여백은 1mm
    var HeightMargin = 8; // 단위 point, 상하 여백 1mm
    var DotConvertValue = 8; // 1mm = 8 dots
    // var BarcodeFontHeight = 24; // 단위 point, 바코드아래에 출력되는 Font 높이 3mm
    var ImagePostionWidth = 0; // 단위 point, 이미지 표시 영역 가로 72point = 9mm
    // var SampleDrawImgRowSideValue = 80;
    var ImageDir = null;
    var barcodeNarrow = null;
    var barcodeWideValue = null;
    // var barcodeOriginalData = null;
    // public Bitmap tagBackImge = new Bitmap(70, 70);
    var userSettingLeftMargin = 0; // 유저가 설정한 좌측 여백(경계선)
    var leftMagin = 0; // 좌측여백
    var returnBarcodeArea = 0; // 바코드 영역
    var barcodeHeight = 0; // 바코드 영역 높이.
    var imageCheckValue = false; // 이미지 여부 체크.
    var leftLinePosition = 0; // 세로 라인 위치.
    var rowGabSize = 0; // 행 넓이 폭.
    var barcodeColumnHeight = 0; // 바코드 컬럼 높이
    var barcodeType = null; // 바코드 코드 타입
    var fontHeight = 0; //폰트 크기(int). mm 단위(높이 기준)
    var fontType = 0; // 폰트 타입(int). 0 = Normal, 1 = Italic, 2 = Bold, 3 = Italic and Bold
    var fontName = "맑은 고딕"; // 폰트이름(string). 굴림, 맑은고딕, 궁서체
    var fontStyle = 0; // 0 = Without Underline, 1 = With Underline // 0 고정값 정용

    var publicUpperMargin = 2; // 공용 상위 여백 단위는 mm
    var blankMargin = 0; // 공용 하위 여백 단위는 mm
    var inLineWidth = 2; // 인 라인 굵기 단위는 mm
    var outLineWidth = 2; // 아웃 라인 굵기 단위는 mm

    var eachHeight = 33;
    var _line_1 = 35; // 3mm //글자가 찍히는 세로 위치
    var _line_2 = _line_1 + eachHeight ;
    var _line_3 = _line_1 + eachHeight * 2 ;
    var _line_4 = _line_1 + eachHeight * 3 ;
    var _line_5 = _line_1 + eachHeight * 4 ;
    var _line_6 = _line_1 + eachHeight * 5 ;

    var _settings = {
        LabelWidth : 50,
        LabelHeight : 30,
        LabelGap : 3,
        IsLines : true, // '1' true, '0' false
        barcode : {
            BarXpoint : 8,
            BarcodeScope : 9,
            BarHeight : 5,
            FontSize : 3,
            FontType : 0,
            FontName : '굴림',
            CodeType : 'Code 128',
            BarWidthLevel : '1',
            HeaderWidth : 15,
            IsBarTextView : true //'1' true , '0' false
        },
        ItemList : [
            {name : '부서'},
            {name : '재산구분'},
            {name : '물품번호'},
            {name : '물품명'},
            {name : '제조사/모델'},
            {name : '구입일'}

        ],
        logoImage : {
            IsImgView : false,
            ImgWidth : 5,
            ImgHeight : 30,
        },
        ViewCount : 5,
        workPath : 'C:\\testImg\\',

    };

    if(customSettings != null){
        var mergeTarget = [_settings, customSettings];
        _settings = mergeTarget.reduce(function (r, o) {
            Object.keys(o).forEach(function (k) {
                r[k] = o[k];
            });
            return r;
        }, {});
    }

    function Print(items){
        var chartHeight = 0;

        // try{
            for(var i = 0; i < items.length; i++){
                ConnectPrint();

                PrintBufferClear();

                PrintSetting(_settings.LabelWidth, _settings.LabelHeight, 3, 8, 0, _settings.LabelGap, 0);

                PrintDirection(1);

                if(_settings.IsLines == true){
                    MakeBox(_settings.LabelWidth, _settings.LabelHeight, outLineWidth);
                }

                userSettingLeftMargin = _settings.barcode.BarXpoint;
                if (_settings.ViewCount != 0) {
                    barcodeColumnHeight = MakeBarcodeLine_Data(_settings.LabelWidth, _settings.LabelHeight, _settings.barcode.BarHeight, _settings.barcode.BarcodeScope, inLineWidth);
                }else{
                    barcodeColumnHeight = 0;
                }

                if (_settings.logoImage.IsImgView == true)
                {
                    imageCheckValue = true;
                    ImagePostionWidth = _settings.logoImage.ImgWidth;
                } else {
                    imageCheckValue = false;
                    ImagePostionWidth = 0;
                }

                // Row 칸 만들기.
                if (_settings.ViewCount != 0)
                {
                    chartHeight = MakeRow(_settings.ViewCount, barcodeColumnHeight, _settings.LabelWidth, _settings.LabelHeight, imageCheckValue);
                    rowGabSize = chartHeight / _settings.ViewCount;
                }else{
                    chartHeight = 0;
                    rowGabSize = 0;
                }

                // 이미지 인쇄
                if (_settings.logoImage.IsImgView)
                {
                    ImageDir = _settings.workPath + "\\LOGO.bmp";

                    // 이미지 세로축 정렬
                    var y = (chartHeight - _settings.logoImage.ImgHeight) / 2;

                    InputImage(16, 24 + y, ImageDir, "IMAGE");
                }

                // 세로 영역 만들기
                if (_settings.ViewCount != 0) {
                    leftLinePosition = 164//MakeColumn(_settings.barcode.HeaderWidth, chartHeight, imageCheckValue);
                }else{
                    leftLinePosition = 0;
                }

                // 폰트설정
                fontHeight = _settings.barcode.FontSize;
                fontType = _settings.barcode.FontType;
                fontName = _settings.barcode.FontName;

                // Row칸에 내용 좌측 행 채우기. (텍스트)
                // 2013.10.11
                switch (_settings.ViewCount){
                    case 1:
                        if (imageCheckValue == true) {
                            MakeLeftContents(28 + ImagePostionWidth, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                        } else {
                            MakeLeftContents(28, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                        }
                        break;
                    case 2:
                        if (imageCheckValue == true) {
                            MakeLeftContents(28 + ImagePostionWidth, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                        } else {
                            MakeLeftContents(28, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                        }
                        break;
                    case 3:
                        if (imageCheckValue == true) {
                            MakeLeftContents(28 + ImagePostionWidth, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                        } else {
                            MakeLeftContents(28, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(28, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                        }
                        break;
                    case 4:
                        if (imageCheckValue == true) {
                            MakeLeftContents(28 + ImagePostionWidth, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[3].name));
                        } else {
                            MakeLeftContents(28, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(28, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                            MakeLeftContents(28, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[3].name));
                        }
                        break;
                    case 5:
                        if (imageCheckValue == true) {
                            MakeLeftContents(28 + ImagePostionWidth, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[3].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[4].name));
                        } else {
                            MakeLeftContents(28, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(28, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                            MakeLeftContents(28, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[3].name));
                            MakeLeftContents(28, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[4].name));
                        }
                        break;
                    case 6:
                        if (imageCheckValue == true) {
                            MakeLeftContents(28 + ImagePostionWidth, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[3].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[4].name));
                            MakeLeftContents(28 + ImagePostionWidth, _line_6 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[5].name));
                        } else {
                            MakeLeftContents(24, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[0].name));
                            MakeLeftContents(24, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[1].name));
                            MakeLeftContents(24, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[2].name));
                            MakeLeftContents(24, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[3].name));
                            MakeLeftContents(24, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[4].name));
                            MakeLeftContents(24, _line_6 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, ChageEnglishChar(_settings.ItemList[5].name));
                        }
                        break;
                }

                // 바코드 데이터 생성.
                // 2013.10.11 확인해야할 사항.
                // 1. 바코드 타입 입력방법. => 콤보 박스에서 선택하면 해당 입력된 정보가 들어오는 방식인지 확인 필요.
                // 2. 가로폭 단계는 현재 API에서는 숫자를 String으로 입력받게끔 되어있으므로, 수정 필요. 1,2,3
                // 3. 바코드 넓이 폭(string) 추가 필요. (1~15단계) 대부분의 데이터타입에서 영향을 받지 않음.
                var fontView = 0;
                if (_settings.barcode.IsBarTextView == true) {
                    fontView = 2;
                } else {
                    fontView = 0;
                }

                // 바코드 타입에 따라 비율 조정
                BarcodeTagRatio(_settings.barcode.CodeType);

                barcodeType = GetBarcodeType(_settings.barcode.CodeType);
                barcodeHeight = _settings.barcode.BarHeight;
                leftMagin = ((userSettingLeftMargin) * DotConvertValue) + WidthMargin;

                var barcodeHeightPositon = 0;

                if (_settings.barcode.IsBarTextView == true) {
                    barcodeHeightPositon = returnBarcodeArea + ((_settings.barcode.BarcodeScope * DotConvertValue) / 2) - ((barcodeHeight * DotConvertValue) / 2) - 12;
                } else {
                    barcodeHeightPositon = returnBarcodeArea + ((_settings.barcode.BarcodeScope * DotConvertValue) / 2) - ((barcodeHeight * DotConvertValue) / 2);
                }

                MakeQRCode((_settings.LabelWidth - 12) * DotConvertValue, (_settings.LabelHeight - 9) * DotConvertValue - 6, 3, items[i].barcode);
                // MakeBarcode(leftMagin, barcodeHeightPositon, barcodeType, (barcodeHeight * DotConvertValue), fontView, barcodeNarrow, barcodeWideValue, items[i].barcode);

                // 우측 칼럼 채우기.
                switch (_settings.ViewCount){
                    case 1:
                        if (imageCheckValue == true){
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                        } else {
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                        }
                        break;
                    case 2:
                        if (imageCheckValue == true){
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                        }else {
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                        }
                        break;
                    case 3:
                        if (imageCheckValue == true) {
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                        } else {
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                        }
                        break;
                    case 4:
                        if (imageCheckValue == true) {
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                            MakeRightContents(leftLinePosition + 12, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[3]);
                        } else{
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                            MakeRightContents(leftLinePosition + 12, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[3]);
                        }
                        break;
                    case 5:
                        if (imageCheckValue == true){
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                            MakeRightContents(leftLinePosition + 12, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[3]);
                            MakeRightContents(leftLinePosition + 12, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[4]);
                        } else{
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                            MakeRightContents(leftLinePosition + 12, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[3]);
                            MakeRightContents(leftLinePosition + 12, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[4]);
                        }
                        break;
                    case 6:
                        if (imageCheckValue == true){
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                            MakeRightContents(leftLinePosition + 12, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[3]);
                            MakeRightContents(leftLinePosition + 12, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[4]);
                            MakeRightContents(leftLinePosition + 12, _line_6 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[5]);
                        } else{
                            MakeRightContents(leftLinePosition + 12, _line_1 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[0]);
                            MakeRightContents(leftLinePosition + 12, _line_2 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[1]);
                            MakeRightContents(leftLinePosition + 12, _line_3 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[2]);
                            MakeRightContents(leftLinePosition + 12, _line_4 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[3]);
                            MakeRightContents(leftLinePosition + 12, _line_5 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[4]);
                            MakeRightContents(leftLinePosition + 12, _line_6 + (rowGabSize / 2) - ((fontHeight / 2) * DotConvertValue) - 2, fontHeight, fontType, fontStyle, fontName, items[i].items[5]);
                        }
                        break;
                }

                // 인쇄 라벨 및 복수 수량.
                PrintOutputSetting(1, 1);

                // 프린트 연결 해제
                DisconnectPrint();

            }

        //
        // } catch(e){
        //     console.log(e);
        // }

        return true;
    }

    function ConnectPrint() {
        // 프린트 USB 및 Port 연결
        TSCObj.ActiveXopenport("TSC TTP-247");
    }

    function DisconnectPrint() {
        // 프린트 USB 및 Port 연결 해제
        TSCObj.ActiveXcloseport();
    }

    function PrintSetting(tagWidth, tagHeight, tagSpeed, printConcentration, sensorType, gabHeight, gabDistance) {
        TSCObj.ActiveXsetup(tagWidth + "", tagHeight + "", tagSpeed + "", printConcentration + "", sensorType + "", gabHeight + "", gabDistance + "");
    }

    function PrintBufferClear() {
        // 프린트 메모리 초기화
        TSCObj.ActiveXclearbuffer();
    }

    function PrintDirection(direction){
        // 태그 방향
        TSCObj.ActiveXsendcommand("DIRECTION " + direction);
    }

    function MakeBox(tagWidth, tagHeight, boxLineWidth){
        var calWidth, calHeight, lineWidth, upperMargin;

        calWidth = (tagWidth * DotConvertValue) - WidthMargin;
        calHeight = (tagHeight * DotConvertValue) - HeightMargin;
        lineWidth = boxLineWidth;
        upperMargin = publicUpperMargin * DotConvertValue;

        // 2013.10.21 추가
        calHeight = calHeight - (blankMargin * DotConvertValue);

        // 태그 박스 생성
        // TSCObj.ActiveXsendcommand("BOX 16," + upperMargin + ", " + calWidth + ", " + calHeight + "," + lineWidth);
    }

    function MakeBarcodeLine_Data(tagWidth, tagHeight, barcodeHeight, barcodeBoxHeight, rowWidth) {
        var barWidth = 0;
        var tempBoxHeight = 0;
        var tempHeight = 0;

        tempHeight = tagHeight - blankMargin;
        tempBoxHeight = (barcodeBoxHeight * DotConvertValue) - (HeightMargin);

        returnBarcodeArea = ((tempHeight * DotConvertValue) - tempBoxHeight) - (HeightMargin * 2);
        barWidth = (tagWidth * DotConvertValue) - (WidthMargin) - (WidthMargin);

        // TSCObj.ActiveXsendcommand("BAR 16, " + returnBarcodeArea + ", " + barWidth + ", " + rowWidth);

        return barcodeBoxHeight * DotConvertValue;
    }

    function MakeRow(rowValue, barcodeColumnHeight, tagWidth, tagHeight, imagePositionCheck) {
        var gabSize, chartHeight, lineWidth = 0;

        var tempHeight = 0;
        var gabSizeSum = 0;
        var tempReminder = 0;
        var resultReminder = 0;

        tempHeight = tagHeight - blankMargin - publicUpperMargin;

        // 2013.10.25 수정
        chartHeight = (tempHeight * DotConvertValue) - barcodeColumnHeight - HeightMargin;

        if (rowValue != 0) {
            gabSize = parseInt(chartHeight / rowValue);
            gabSizeSum = chartHeight % rowValue;
        } else {
            gabSize = 0;
            gabSizeSum = 0;
        }


        if (gabSizeSum != 0 && (gabSizeSum < rowValue)) {
            gabSizeSum = gabSizeSum * 10;
            tempReminder = parseInt(gabSizeSum / rowValue);
        }

        if (imagePositionCheck == true) {
            lineWidth = (tagWidth * DotConvertValue) - (WidthMargin) - ImagePostionWidth - (WidthMargin);
        } else {
            lineWidth = (tagWidth * DotConvertValue) - (WidthMargin) - (WidthMargin);
        }

        var rowLinePositionY = parseInt(publicUpperMargin * DotConvertValue);

        for (var i = 1; i < rowValue; i++){
            if (i == 1) {
                rowLinePositionY = parseInt(rowLinePositionY + gabSize);
                // if (imagePositionCheck == true) {
                //     TSCObj.ActiveXsendcommand("BAR " + (ImagePostionWidth + WidthMargin) + ", " + rowLinePositionY + "." + tempReminder + ", " + lineWidth + ", " + inLineWidth);
                // } else {
                //     TSCObj.ActiveXsendcommand("BAR " + WidthMargin + ", " + rowLinePositionY + "." + tempReminder+ ", " + lineWidth + ", " + inLineWidth);
                // }
            } else {
                rowLinePositionY = parseInt(rowLinePositionY + gabSize);

                // if (imagePositionCheck == true) {
                //     TSCObj.ActiveXsendcommand("BAR " + (ImagePostionWidth + WidthMargin) + ", " + rowLinePositionY + "." + tempReminder + ", " + lineWidth + ", " + inLineWidth);
                // } else {
                //     TSCObj.ActiveXsendcommand("BAR " + WidthMargin + ", " + rowLinePositionY + "." + tempReminder + ", " + lineWidth + ", " + inLineWidth);
                // }
            }
            //
            // if (i == 1) {
            //     _line_2 = rowLinePositionY;
            // } else if (i == 2) {
            //     _line_3 = rowLinePositionY;
            // } else if (i == 3) {
            //     _line_4 = rowLinePositionY;
            // } else if (i == 4) {
            //     _line_5 = rowLinePositionY;
            // }

            // console.log('i :' + i + ', rowLinePositionY : ' + rowLinePositionY + ", tempReminder : " + tempReminder);
        }

        return chartHeight;
    }

    function MakeColumn(columnPosition, chartHeight, imagePositionCheck)
    {
        var leftMargin = 0, upperMargin = 0;
        leftMargin = (columnPosition * DotConvertValue) + (WidthMargin);
        // 2013.10.25 수정.
        upperMargin = HeightMargin + (publicUpperMargin * DotConvertValue) - 8;

        if (imagePositionCheck == true) {
            leftMargin = leftMargin + ImagePostionWidth;
            // TSCObj.ActiveXsendcommand("BAR " + (ImagePostionWidth + 16) + ", " + upperMargin + ", " + inLineWidth + ", " + chartHeight + "");
            //
            // TSCObj.ActiveXsendcommand("BAR " + leftMargin + ", " + upperMargin + ", " + inLineWidth + ", " + chartHeight + "");
        } else {
            // TSCObj.ActiveXsendcommand("BAR " + leftMargin + ", " + upperMargin + ", " + inLineWidth + ", " + chartHeight + "");
        }

        return leftMargin;
    }

    function PrintOutputSetting(countOfLabel, countOfCpopies)
    {
        TSCObj.ActiveXprintlabel(countOfLabel + "", countOfCpopies + "");
    }

    function MakeBarcode(leftMargin, upperMargin, type, barcodeHeight, textView, narrow, wide, data) {
        TSCObj.ActiveXbarcode(leftMargin + "", upperMargin + "", type, barcodeHeight + "", textView + "", "0", narrow, wide, data);
    }

    function MakeQRCode(leftMargin, upperMargin, cellWidth, data){
        TSCObj.ActiveXsendcommand("QRCODE " + leftMargin + "," + upperMargin + ",H," + cellWidth + ",A,0,M2,S7,\"" + data + "\"")
    }

    function MakeLeftContents(leftMargin, upperMargin, fontHeight, fontStyle, fontUnderline, fontType, data) {
        TSCObj.ActiveXwindowsfont(leftMargin, upperMargin, (fontHeight * DotConvertValue), 0, fontStyle, fontUnderline, fontType, data);
    }

    function MakeRightContents(leftMargin, upperMargin, fontHeight, fontStyle, fontUnderline, fontType, data) {
        TSCObj.ActiveXwindowsfont(leftMargin, upperMargin, (fontHeight * DotConvertValue), 0, fontStyle, fontUnderline, fontType, data);
    }

    function SetUserSettingLeftMargin(inputLeftMargin) {
        userSettingLeftMargin = inputLeftMargin;
    }

    function GetUserSettingLeftMargin(){
        return userSettingLeftMargin;
    }

    function GetImageDir(){
        return ImageDir;
    }

    function setImageDir(imageDir){
        ImageDir = imageDir;
    }

    function InputImage(XPosition, YPosition, imageDir, imageName) {
        // 태그가 50X30기준 X=24, Y=64
        TSCObj.ActiveXdownloadpcx(imageDir, imageName);
        TSCObj.ActiveXsendcommand("PUTBMP " + XPosition + ", "+ YPosition +",\"" + imageName + "\"");
    }

    function BarcodeTagRatio(codeType){
        switch (codeType) {
            case "Code 128":
            case "EAN-13":
            case "EAN-8":
            case "UPC-A": {
                switch (_settings.barcode.BarWidthLevel)
                {
                    case "1":
                        barcodeNarrow = "2";
                        barcodeWideValue = "2";
                        break;
                    case "2":
                        barcodeNarrow = "3";
                        barcodeWideValue = "3";
                        break;
                    case "3":
                        barcodeNarrow = "4";
                        barcodeWideValue = "4";
                        break;
                }
            }
                break;
            case "Code 128-B": {
                switch (_settings.barcode.BarWidthLevel) {
                    case "1":
                        barcodeNarrow = "1";
                        barcodeWideValue = "1";
                        break;
                    case "2":
                        barcodeNarrow = "2";
                        barcodeWideValue = "2";
                        break;
                    case "3":
                        barcodeNarrow = "3";
                        barcodeWideValue = "3";
                        break;
                    }
                }
                break;
            case "Code 39":
            case "Code 39 Extended": {
                switch (_settings.barcode.BarWidthLevel)
                {
                    case "1":
                        barcodeNarrow = "1";
                        barcodeWideValue = "3";
                        break;
                    case "2":
                        barcodeNarrow = "2";
                        barcodeWideValue = "4";
                        break;
                    case "3":
                        barcodeNarrow = "2";
                        barcodeWideValue = "6";
                        break;
                    }
                }
                break;
            case "Standard 2 of 5":
            case "Interleaved 2 of 5": {
                switch (_settings.barcode.BarWidthLevel)
                {
                    case "1":
                        barcodeNarrow = "2";
                        barcodeWideValue = "5";
                        break;
                    case "2":
                        barcodeNarrow = "3";
                        barcodeWideValue = "6";
                        break;
                    case "3":
                        barcodeNarrow = "3";
                        barcodeWideValue = "9";
                        break;
                    }
                }
                break;
            case "Code 93":
            case "MSI": {
                switch (_settings.barcode.BarWidthLevel)
                {
                    case "1":
                        barcodeNarrow = "1";
                        barcodeWideValue = "3";
                        break;
                    case "2":
                        barcodeNarrow = "2";
                        barcodeWideValue = "6";
                        break;
                    case "3":
                        barcodeNarrow = "3";
                        barcodeWideValue = "9";
                        break;
                }
            }
                break;
            default:
                barcodeNarrow = "1";
                barcodeWideValue = "1";
                break;
        }
    }

    function GetBarcodeType(inputType) {
        var codeType = null;

        switch (inputType) {
            case "Code 128": {
                codeType = "128";
                break;
            }
            case "EAN-13": {
                codeType =  "EAN13";
                break;
            }
            case "EAN-8": {
                codeType = "EAN8";
                break;
            }
            case "UPC-A": {
                codeType = "UPCA";
                break;
            }
            case "Code 128-B": {
                codeType = "128M";
                break;
            }
            case "Code 39": {
                codeType = "39";
                break;
            }
            case "Code 39 Extended": {
                codeType =  "39C";
                break;
            }
            case "Standard 2 of 5": {
                codeType = "25";
                break;
            }
            case "Interleaved 2 of 5": {
                codeType =  "25C";
                break;
            }
            case "Code 93": {
                codeType = "93";
                break;
            }
            case "MSI": {
                codeType = "MSI";
                break;
            }
        }

        return codeType;
    }

    function ChageEnglishChar(input) {
        var returnValue = "";

        if (input.indexOf("Date Purchased") > -1) {
            returnValue = "Purchased";
        } else if (input.indexOf("Asset Name") > -1) {
            returnValue = "Asset";
        } else if (input.indexOf("Item Name") > -1) {
            returnValue = "Item";
        } else if (input.indexOf("Serial No.") > -1) {
            returnValue = "Serial";
        } else {
            returnValue = input;
        }

        return returnValue;
    }

    _obj.init = init;
    _obj.Print = Print;

    return _obj;
})(customSettings);

export default TTP_PRINTER;