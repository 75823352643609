<template >
    <div class='tree_sub_wrap noselect' 
            :class="[level === 1 ? 'active' : '']" 
            :style="{paddingLeft : (level === 1 ? '0px' : '')}" 
            >
            <div class='tree_each_wrap'>
                <div class='tree_between' dragt drag2 :data-idx='treeData.index' :data-parentcode='treeData.parentCode'>
                    <div class='tree_between_line' :data-idx='treeData.index' :data-parentcode='treeData.parentCode'></div>
                </div>
                <div v-if="treeData.isAddMode">
                    <div class="tree_new_each">
                        <input class="tree_new_input" type="text" @blur="removeAddModeTree($event, treeData)" id="curNewTree" @keyup="enter($event)">
                    </div>
                </div>
                <div v-else-if="treeData.isEditMode">
                    <div class="tree_new_each">
                        <input class="tree_new_input" type="text" @blur="restoreEditModeTree($event, treeData, treeData.text)" :value="treeData.text" id="curEditTree" @keyup="enter($event)">
                    </div>
                </div>
                <div v-else class='tree_each' dragt drag1 :data-idx='treeData.index' :data-parentcode='treeData.parentCode'
                    :class="[treeData.selected === true ? 'active' : '' ]" 
                    @dblclick="clickDouble(treeData)"
                    @click="select(treeData)" 
                    @mousedown="mouseDown(treeData, $event)" 
                    @mouseover="mouseOver"
                    @mouseleave="mouseLeave"
                    >
                    <template v-if="treeData.children && treeData.children.length > 0 && level !== 1">
                        <div class='tree_plus_minus noselect' @click="toggleTree(treeData.value)">{{treeData.active === false ? '+' : '-'}}</div>
                    </template>
                    <div class='tree_icon_wrap noselect' :style="{color : treeData.value === 0 ? '#fe7681' : ''}">{{treeData.text}}<span class='tree_cnt'>({{treeData.count | comma}})</span></div>
                    <div v-if="isShowHoverBtns && isOver && treeData.value !== 0" class='right_btns_wrap' style='position:absolute; right:5px; top: 9px;' >
                        <div v-if="moduleShare.isAllowedForInsert" class="right_btn" @click="$event.stopPropagation(); addAddModeTree(treeData)">
                            <font-awesome-icon :icon="FONT_AWESOME.PLUS" :isHoverColorApply="true" style="font-size:14px; margin-top:-2px; color:#9b9cb0; width:12px;"/>
                        </div>
                        <div v-if="moduleShare.isAllowedForUpdate" class="right_btn" :style="{visibility : level === 1 ? 'hidden' : ''}" @click="$event.stopPropagation(); convertIntoAddModeTree(treeData)">
                            <font-awesome-icon :icon="FONT_AWESOME.PEN" :isHoverColorApply="true" style="font-size:11px; color:#9b9cb0;width:12px;"/>
                        </div>
                        <div v-if="moduleShare.isAllowedForDelete" class="right_btn" :style="{visibility : level === 1 ? 'hidden' : ''}" @click="$event.stopPropagation(); moduleShare.detectRemoveTree(treeData)" >
                            <font-awesome-icon :icon="FONT_AWESOME.MINUS" :isHoverColorApply="true" style="font-size:14px; margin-top:-2px; color:#9b9cb0;width:12px;"/>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="treeData.children && treeData.children.length > 0">
                <template v-for="each, i in treeData.children" >
                    <component 
                        :key="'treeEach' + i" 
                        :is="tree" 
                        :tree-data="each" 
                        :tree="tree" 
                        :level="nextLevel" 
                        :module-share="moduleShare" 
                        :detectSelect="detectSelect" 
                        :class="[treeData.active === false ? '' : 'active']" 
                        :isDragWork="isDragWork"
                        :isShowHoverBtns="isShowHoverBtns"
                        :clickDouble="clickDouble"
                    ></component>
                </template>
            </template>
        </div>
</template>
<script>
import {AUTH, FONT_AWESOME, MODE} from '@/util/Constants.js';

export default {
    name: "CommonTreeEach",
    props : {
        treeData : Object,
        tree : Object,
        level : Number,
        multiple : Boolean,
        moduleShare : Object,
        detectSelect : {
            type : Function,
            default : () => {}
        },
        isDragWork : {
            type : Boolean,
            default : false
        },
        isShowHoverBtns : {
            type : Boolean,
            default : false
        },
        clickDouble : {
            type : Function,
            default : () => {}
        }
    },
    data(){
        return {
            AUTH : AUTH,
            FONT_AWESOME : FONT_AWESOME,
            nextLevel : 0,
            isOver : false
        }
    },
    methods : {
        mouseLeave(){
            this.isOver = false;
        },
        mouseOver(){
            this.isOver = true;
        },
        select(pTreeData){
            //드래그 가능할때는 외부 select 함수사용
            if(this.isDragWork) return;
            //드래그 중일때는 선택 안되도록.
            if(this.moduleShare.isDragging) {
                // this.moduleShare.isDragging = false;
                return;
            }
            //단일 선택일때
            if(!this.moduleShare.isMultiple && this.moduleShare.prevSelectedTreeData) this.$set(this.moduleShare.prevSelectedTreeData, 'selected', false);
            
            // const flag = !(pTreeData.selected === true ? true : false);
            this.$set(pTreeData, 'selected', true)
            this.moduleShare.prevSelectedTreeData = pTreeData;
            this.moduleShare.lastSelectedValue = pTreeData.value;
            this.detectSelect(pTreeData);
        },
        toggleTree(value){
            const flag = !(this.treeData.active === false ? this.treeData.active : true);
            this.$set(this.treeData, 'active', flag);
            this.moduleShare.foldList[value] = flag;
            
        },
        mouseDown(pTreeData, e){
            if(!this.isDragWork) return;
            this.moduleShare.isDragging = true;
            this.moduleShare.selectedVueEl = e.currentTarget
            this.moduleShare.mouseDownTreeData = pTreeData;

            this.moduleShare.moveTarget = e.currentTarget;
            this.moduleShare.moveTarget.style.zIndex = "1";
            this.moduleShare.dragTargetParentCode = parseInt(this.moduleShare.moveTarget.getAttribute('data-parentcode'));
            this.moduleShare.dragTargetIdx = parseInt(this.moduleShare.moveTarget.getAttribute('data-idx'));

            this.moduleShare.startX = e.clientX;
            this.moduleShare.startY = e.clientY;

            this.moduleShare.curScrollTop = this.moduleShare.treeScrollWrap.scrollTop;
        },
        addAddModeTree(treeData){
            const newTree = {
                    count: 0,
                    level: treeData.level + 1,
                    parentCode: treeData.value,
                    text: "",
                    value: 0,
                    isAddMode: true,
                    parent : treeData
                }
            if(this.moduleShare.isUseSubcode){
                this.moduleShare.showSubcodeRegister(MODE.INSERT, newTree);
                return;
            }
            if(!treeData.children) this.$set(treeData, 'children', []); 
            this.$set(treeData, 'active', true);
            this.moduleShare.foldList[treeData.value] = true;
            this.$set(treeData.children, treeData.children.length, newTree)
            this.$nextTick(() => {
                document.getElementById('curNewTree').focus()
            })
        },
        convertIntoAddModeTree(treeData){
            if(this.moduleShare.isUseSubcode){
                this.moduleShare.showSubcodeRegister(MODE.UPDATE, treeData);
                return;
            }
            this.$set(treeData, 'isEditMode', true);
            this.$set(treeData, 'parent', treeData);
            this.$nextTick(() => {
                document.getElementById('curEditTree').focus()
                document.getElementById('curEditTree').select()
            })
        },
        restoreEditModeTree(e, treeData, prevValue){
            if(e.currentTarget.value !== '' && e.currentTarget.value !== prevValue){
                treeData.text = e.currentTarget.value;
                this.moduleShare.detectEditTree(treeData);
            }else {
                this.$delete(treeData.parent, 'isEditMode');
            }
        },
        removeAddModeTree(e, treeData){
            if(e.currentTarget.value !== ''){
                treeData.text = e.currentTarget.value;
                this.moduleShare.detectAddTree(treeData);
            }else {
                if(treeData.isAddMode) {
                    const index = treeData.parent.children.length - 1;
                    this.$delete(treeData.parent.children, index);
                }       
            }
        },
        enter(e){
            if(e.keyCode === 13){
                e.currentTarget.blur();
            }else if(e.keyCode === 27){
                e.currentTarget.value = '';
                e.currentTarget.blur();
            }
        },
    },
    filters :{
        comma(value){
            if(value == null) return '';
            if(value === '.0000') value = 0;
            var parts = value.toString().split(".");
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
    mounted(){
    },
    created(){
        //처음 시작 선택 필요시
        if(this.treeData.selected){
            this.moduleShare.prevSelectedTreeData = this.treeData;
        }
        this.nextLevel = parseInt(this.level) + 1;
    }
};
</script>
<style scoped>
    
</style>