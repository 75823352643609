<template >
    <ModalBack :closeAction="hideModal">
        <div class="address_search_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">거래처조회</div>
    
            <div class="search_panel">
                <LabelPanel style="width:233px; ">
                    <template v-slot:label>검색어</template>
                    <template v-slot:content>
                        <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter" :isFocus="true"></ConditionInput>
                    </template>
                </LabelPanel>
                <button class="search_btn" @click="getCompanies"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
                <button v-if="isAllowedForInsert" class="search_btn" @click="() => {curMode = MODE.INSERT; transferData = {}; isShowCompanyRegister = true; }">+ 거래처등록</button>
            </div>
            <div style="height:10px;"></div>
            <div style="overflow:hidden; flex:1;display:flex; flex-direction:column;">
                <div style="flex:1; overflow:hidden">
                    <CommonTable :plist="list" :pcolumn="column" :clickRow="selectCompany" :clickDoubleRow="(data) => {curSelectedCompany = data; complete();}"></CommonTable>
                </div>

                <ColumnSetting v-if="isShowColumnSettings" 
                    :vue-name="$options.name" 
                    :default-settings="column" 
                    :closeSettings="() => {isShowColumnSettings = false}" 
                    :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
                </ColumnSetting>
            </div>
            <div style="height:10px;"></div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="complete">확인</button>
            </div>
        </div>
        <CompanyRegister v-if="isShowCompanyRegister" :hideModal="() => {isShowCompanyRegister = false}" :curMode="MODE.INSERT" :data="{}" :completeAction="(data) => {this.searchWord = data.companyName; getCompanies();}"></CompanyRegister>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import CompanyRegister from '@/components/modal/CompanyRegister.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, AUTH} from '@/util/Constants.js';
export default {
    name : 'CompanySearch',
    props : {
        hideModal : Function,
        completeSave : {
            type : Function,
            default : ()=>{}
        }
    },
    components : {
        ModalBack,
        CommonModalCloseBtn,
        LabelPanel,
        CommonTable,
        ColumnSetting,
        ConditionInput,
        CompanyRegister
    },
    data(){
        return {
            MODE : MODE,
            isShowCompanyRegister : false,
            isShowColumnSettings : false,
            lastSizePerPage : 100,
            lastPage : 1,
            list: [],
            curSelectedCompany : {companyName : '', companyCode : ''},
            conditionCode : '',
            searchWord : '',
            custom : { },
            column: [
                { key: "column0", name: "", w: "10px"},
                { key: "companyName", name: "업체명", w: "100px"},
                { key: "chief", name: "대표", w: "100px"},
                { key: "tel", name: "전화번호", w: "130px" },
                { key: "person", name: "담당자", w: "100px" },
                { key: "businessNumber", name: "사업자번호", w: "100px" },
                { key: "email", name: "이메일", w: "100px" },
                { key: "memo", name: "메모", w: "100px" },
            ],
            searchConditions: [
                {name : '업체명', key : 'companyName', checked : true},
                {name : '대표', key : 'chief', checked : true},
                {name : '전화/팩스/휴대폰', key : 'contact', checked : true},
                {name : '사업자번호', key : 'businessNumber', checked : true},
                {name : '주소', key : 'address', checked : true},
                {name : '담당자', key : 'person', checked : true},
                {name : '메모', key : 'memo', checked : true},
            ]
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.INSERT)
        },
    },
    methods : { 
        selectCompany(data){
            this.curSelectedCompany = data;
        },
        complete(){
            this.completeSave(this.curSelectedCompany);
            this.hideModal();
        },
        getCompanies(){
            const parameters = this.getParamters();

            axios.get('/api/base/company/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    this.list = data.companies.content;
                }
            }).catch(res => {
                console.log(res);
            });

        },
        getParamters(){
           const parameters = {};

            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;

            let conditions = [];
            let searchWords = [];

            if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }
            
            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            return parameters;
        },
        detectChange(value){
            this.conditionCode = value;
        },
        enter(){
            this.getCompanies();
        },
        
    },
    created(){
        this.getCompanies();
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
.address_search_wrap{
    background-color:white; width:630px; height:830px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
.asset_bottom_btns{text-align:right;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}


.search_panel{display:flex; background-color:white; margin-top:10px;}
.search_btn{
  text-align:left;margin-top:21px;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>