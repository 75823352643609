<template>
    <div class="state_tab_wrap">
        <div v-for="each, i in list" :key="i" class="state_tab" @click="changeStateTab(i)">
            <div class="state_text_wrap"><span class="state_text" :class="[curStateTab === i ? 'active' : '']">{{each.name}}</span><div class="state_cnt">{{each.cnt | comma}}</div></div>
            <div class="underline" :class="[curStateTab === i ? 'active' : '']"></div>
        </div>
    </div>
</template>
<script>
export default {
    name : 'CommonTableTab',
    props : {
        list : Array,
        detectTabChange : Function,
        signalTabReset : {
            type : Boolean,
            default : false
        },
    },
    watch : {
        signalTabReset(){
            this.curStateTab = 0;
        },
    },
    data(){
        return {
            curStateTab : 0,
            
        }
    },
    methods : {
        changeStateTab(index){
            this.curStateTab = index;
            if(this.detectTabChange) this.detectTabChange(this.list[index], index);
        },
    }
}
</script>
<style scoped>
.state_tab_wrap{
  border-bottom:1px solid #e6e7ec; display:flex;
  color:#494a4f;
}
.state_text_wrap{
  cursor:pointer;
 padding: 8px 8px 0px 8px;
}
.state_tab + .state_tab{
    margin-left: 5px;
}

.state_cnt{margin-left:8px; display:inline-block;font-size:14px; border-radius:4px; padding: 5px 5px 2px 5px;background-color:#e6efff;font-weight:bold;}
.state_text.active{color:#0066fd}
.underline{margin-top:14px;transform:translateY(1px); height:2px;}
.underline.active{background-color:#0066fd;}
</style>
