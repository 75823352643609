<template >
    <div>
        <ModalBack>
            <div class="company_register_wrap">
                <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
                
                <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">사용자 권한 관리</div>
                <CommonAddBtn title="권한등록" style="margin-top:10px;" :clickAction="() => {registerMode = MODE.INSERT; isShowAuthorityRegister = true}"></CommonAddBtn>
                <div style="height:40px;"></div>
                <div class="asset_info_contents">
                    <div style="width:200px; overflow:hidden; padding:20px; border:1px solid #e6e7ec; border-radius:5px">
                        <CommonTable ref="cTable" :plist="authorityList" :pcolumn="authorityColumn" :clickRow="selectRow" :pcustom="listCustom"></CommonTable>
                    </div>
                    <div style="width:10px;"></div>
                    <div style="flex:1; overflow:hidden; display:flex; flex-direction:column;border:1px solid #e6e7ec; padding: 20px;">
                        <!-- <div >
                            <LabelPanel style="width:233px;">
                                <template v-slot:label>권한명</template>
                                <template v-slot:content>
                                    <CommonInput v-model="levelName" :isReadonly="userLevel === 'LV001'"></CommonInput> 
                                </template>
                            </LabelPanel>
                        </div>
                        <div style="height:10px;"></div> -->
                        <div style="overflow:hidden; flex:1;">
                            <CommonTable :plist="authorityDetails" :pcolumn="authorityDetailsColumn" :pcustom="custom" ></CommonTable>
                        </div>
                    </div>
                </div>
                <div class="asset_bottom_btns">
                    <!-- <button class="asset_bottom_btn_ok" @click="add">{{curMode === MODE.INSERT ? '취소' : '신규'}}</button> -->
                    <!-- <button class="asset_bottom_btn_canel" @click="deleteAuthority" style="margin-left:7px;">삭제</button> -->
                    <button class="asset_bottom_btn_canel" @click="hideModal" style="margin-left:7px;">닫기</button>
                    <!-- <button class="asset_bottom_btn_ok" @click="save">저장</button> -->
                </div>
            </div>
        </ModalBack>
        <AuthorityRegister v-if="isShowAuthorityRegister" :transferData="transferData" :hideModal="() => {isShowAuthorityRegister = false}" :startMode="registerMode" :completeFunc="completeFunc"></AuthorityRegister>
    </div>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
// import CommonInput from '@/components/common/CommonInput.vue';
// import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import AuthorityRegister from '@/components/modal/AuthorityRegister.vue';


import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, FONT_AWESOME} from '@/util/Constants.js';
let changeOriginalData = null;
let showAuthorityRegister = null;
let deleteAuthority = null;
const checkbox = {
    template : `<CommonCheckbox v-if="isShow" :flag="flag" color="#e6e7ec" style="margin-left:4px" :isCursorPointer="false" :isHideBorder="true"></CommonCheckbox>`,
    props : ['data', 'column', 'index'],
    components : {
        CommonCheckbox
    },
    data(){
        return {
            flag : false,
            isShow : false,
        }
    },
    methods : {
        toggleCheck(){
            const nextFlag = !this.flag;
            this.flag = nextFlag
            this.$set(this.data, this.column.key, nextFlag);

            const value = nextFlag ? 1 : 0
            changeOriginalData(this.index, this.column.key, value);
        }
    },
    created(){
        const value = this.data[this.column.key];
        if(value == '1'){
            this.flag = true;
            this.isShow = true;
        }else if(value == '0'){
            this.flag = false;
            this.isShow = true;
        }else {
            this.flag = false;
            this.isShow = false;
        }
    }
}

const defaultAuthority =  {
    frmAsset: "1;1;1;1;1",
    frmAssetCardReport: "1;-1;-1;-1;1",
    frmAssetPresentStatus: "1;-1;-1;-1;1",
    frmAuthority: "1;1;1;1;-1",
    frmBarcodePrint: "1;1;-1;-1;-1",
    frmBaseInfo: "1;1;1;1;1",
    frmCondition: "1;-1;-1;-1;1",
    frmDepreciation: "1;1;1;1;1",
    frmDepreciationSetting: "1;1;-1;-1;-1",
    frmEmployee: "1;1;1;1;1",
    frmExcelLoad: "1;1;-1;-1;-1",
    frmExportPDA: "1;1;-1;-1;-1",
    frmExportSmartPhone: "1;1;-1;-1;-1",
    frmGenuinePDA: "1;1;-1;-1;-1",
    frmGenuineSmartPhone: "1;1;-1;-1;-1",
    frmImportPDA: "1;1;-1;-1;-1",
    frmImportSmartPhone: "1;1;-1;-1;-1",
    frmInitDB: "1;1;-1;-1;-1",
    frmRFIDPrint: "1;1;-1;-1;-1",
    frmSetting: "1;1;-1;-1;-1",
    frmSurvey: "1;1;1;1;1",
    frmUserLogin: "1;1;1;1;-1",
    levelName: "",
    limitGroup: 0,
    userAuth: null,
    userLevel: "",
}

const rightBtns = {
    template : `
        <div style="display:flex;">
            <div @click.stop="showAuthRegister">
                <font-awesome-icon :icon="FONT_AWESOME.PEN" :isHoverColorApply="true" style="font-size:15px; color:#9b9cb0;" />
            </div>
            <div style="width:10px;"></div>
            <div @click.stop="deleteAuth">
                <font-awesome-icon icon="fa-regular fa-trash-can" :isHoverColorApply="true" class="icon" style="color:#9b9cb0; " />
            </div>
        </div>`,
    props : ['index', 'data', 'column'],
    data(){
        return {
            FONT_AWESOME :FONT_AWESOME
        }
    },
    methods : {
        deleteAuth(){
            deleteAuthority(this.data.levelName, this.data.userLevel);
        },
        showAuthRegister(){
            showAuthorityRegister(this.data, this.index);
        }
    }
}



export default {
    name : 'AuthorityMng',
    props : {
        hideModal : Function,
        startMode : {
            type : Number,
            default : MODE.UPDATE
        }
    },
    components : {
        ModalBack,
        CommonModalCloseBtn,
        CommonTable,
        CommonAddBtn,
        AuthorityRegister
    },
    data(){
        return {
            MODE : MODE,
            registerMode : -1,
            curMode : -1,
            lastIndex : -1,
            levelName : '',
            userLevel : '',
            stateOffice : '',
            isShowImageMenu : false,
            lastSelectedIndex : -1,
            isShowAuthorityRegister : false,
            transferData : {},
            stateList : [
                {name : '수리', value : '1'},
                {name : '대여', value : '2'},
                {name : '불용', value : '3'},
                {name : '분실', value : '4'},
                {name : '폐기', value : '5'},
                {name : '보관', value : '6'}
            ],
            authorityList : [],
            authorityDetails : [],
            listCustom : {
                column1 : rightBtns
            },
            custom : {
                col1 : checkbox,
                col2 : checkbox,
                col3 : checkbox,
                col4 : checkbox,
                col5 : checkbox,
            },
            authorityColumn : [
                { key: "column0", name: "", w: "10px"},
                { key: "levelName", name: "권한명", w: "100px"},
                { key: "column1", name: "", w: "45px"},
            ],
            authorityDetailsColumn: [
                { key: "screenType", name: "화면구분", w: "150px"},
                { key: "screenName", name: "화면명칭", w: "150px"},
                { key: "col1", name: "조회", w: "50px"},
                { key: "col2", name: "등록", w: "50px"},
                { key: "col3", name: "수정", w: "50px"},
                { key: "col4", name: "삭제", w: "50px"},
                { key: "col5", name: "엑셀", w: "50px"},
            ],

        }
    },
    methods : {
        getAuthorities(mode){
            const parameters = {};

            axios.get('/api/login/authority/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const list = res.data.data.authorities;
                    this.authorityList = list;

                    if(mode === MODE.INSERT){
                        if(list.length > 0){
                            this.setData(list[list.length - 1]);
                            this.$nextTick(() => {
                                this.$refs.cTable.__setClickedIndex(list.length - 1);    
                            })
                        }
                    }else if(mode === MODE.UPDATE){
                        this.setData(list[this.lastIndex]);
                        this.$nextTick(() => {
                            this.$refs.cTable.__setClickedIndex(this.lastIndex);    
                        })
                       
                    }else if(mode === MODE.DELETE){
                        if(list.length > 0){
                            this.setData(list[0]);
                            this.$nextTick(() => {
                                this.$refs.cTable.__setClickedIndex(0);    
                            })
                        } 
                    }

      
                }
            }).catch(res => {
                console.log(res);
            });
        },
        changeOriginalData(index, key, value){
            this.authorityDetails[index][key] = value;
        },
        selectRow(data){
            this.curMode = MODE.UPDATE;
            this.setData(data);
        },
        setData(data){
            this.levelName = data.levelName;
            this.userLevel = data.userLevel;

            const authorities = [
                this.makeObject(data, 'frmBaseInfo', 5, '기초정보관리', '기초코드관리'),
                this.makeObject(data, 'frmEmployee', 0, null, '사원관리'),
                this.makeObject(data, 'frmAuthority', 0, null, '사용권한관리'),
                this.makeObject(data, 'frmUserLogin', 0, null, '로그인관리'),
                this.makeObject(data, 'frmSetting', 0, null, '환경설정'),
                this.makeObject(data, 'frmAsset', 2, '자산관리', '자산관리'),
                this.makeObject(data, 'frmBarcodePrint', 0, null, '자산라벨출력'),
                this.makeObject(data, 'frmCondition', null, '자산현황조회', '자산현황조회'),
                this.makeObject(data, 'frmAssetCardReport', 2, '보고서', '자산이력카드'),
                this.makeObject(data, 'frmAssetPresentStatus', 0, null, '자산현황보고서'),
                this.makeObject(data, 'frmDepreciation', 2, '감가상각', '감가상각'),
                this.makeObject(data, 'frmDepreciationSetting', 0, null, '감가상각 환경설정'),
                this.makeObject(data, 'frmSurvey', null, '재물조사', '재물조사')
            ]

            this.authorityDetails = []//authorities;
            this.$nextTick(() => {
                this.authorityDetails = authorities;  
            })
        },

        makeObject(data, key, rowspan, value, screenName){
            let tempScreenType = {};
            if(rowspan === null){
                tempScreenType = value;
            }else {
                tempScreenType.rowspan = rowspan;
                if(value !== null) tempScreenType.value = value;
            }

            const colArr = data[key].split(";");
             
            return {
                screenType : tempScreenType,
                screenName,
                key,
                col1 : colArr[0],
                col2 : colArr[1],
                col3 : colArr[2],
                col4 : colArr[3],
                col5 : colArr[4],
            }
        },
        // save(){
        //     const parameters = window.copyObject(defaultAuthority);
        //     for(const i in this.authorityDetails){
        //         const detail = this.authorityDetails[i];
        //         parameters[detail.key] = detail.col1 + ";" + detail.col2 + ";" + detail.col3 + ";" + detail.col4 + ";" + detail.col5;
        //     }

        //     parameters.levelName = this.levelName;

        //     if(this.curMode === MODE.INSERT){
        //         delete parameters.userLevel;
        //     }else {
        //         parameters.userLevel = this.userLevel;
        //     }

        //     axios.post('/api/login/authority/save', this.$commonFunc.convertToForm(parameters)).then(res => {
        //         if(res.data.code == ResCode.Success){
        //             this.getAuthorities(false);
        //             this.curMode = MODE.UPDATE;
        //             alert("[" + this.levelName + "]을(를) 저장하였습니다.");

        //         }
        //     }).catch(res => {
        //         console.log(res);
        //     });

        // },
        // add(){
        //     if(this.curMode === MODE.INSERT){
        //         this.curMode = MODE.UPDATE;
        //         if(this.authorityList.length > 0){
        //             this.setData(this.authorityList[0]);
        //         }   
        //         return;
        //     }

        //     this.curMode = MODE.INSERT;

        //     const newAthoritiyDetail = window.copyObject(defaultAuthority);
        //     this.setData(newAthoritiyDetail);
        // },
        deleteAuthority(levelName, userLevel){
            if(!confirm("[" + levelName + "]을(를) 삭제하시겠습니까?")){
                return;
            }

            const parameters = {};
            parameters.userLevel = userLevel;

            axios.post('/api/login/authority/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.getAuthorities(MODE.DELETE);
                    // this.curMode = MODE.UPDATE;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        showAuthorityRegister(data, index){
            this.transferData = data;
            this.registerMode = MODE.UPDATE;
            this.lastIndex = index;
            this.isShowAuthorityRegister = true;
        },
        completeFunc(){
            if(this.registerMode === MODE.INSERT){
                this.getAuthorities(MODE.INSERT)
            }else if(this.registerMode === MODE.UPDATE){
                this.getAuthorities(MODE.UPDATE);
            }
           
        }
    },
    created(){
        deleteAuthority = this.deleteAuthority;
        showAuthorityRegister = this.showAuthorityRegister;
        changeOriginalData = this.changeOriginalData;

        if(this.startMode === MODE.INSERT){
            // this.add();
            this.getAuthorities(MODE.DELETE);
        }else {
            this.getAuthorities(MODE.UPDATE);
        }


        // this.authorityDetails = [
        //     {screenType : {rowspan : 5, value : '기초정보관리'}, screenName : '기초코드관리',  col1 : 1,  col2 : 1,  col3 : 1,  col4 : 1,  col5 : 1},
        //     {screenType : {rowspan : 0}, screenName : '사원관리',  col1 : 1,  col2 : 0,  col3 : 0,  col4 : 0,  col5 : 0},
        //     {screenType : {rowspan : 0}, screenName : '사용권한관리',  col1 : 1,  col2 : 1,  col3 : 1,  col4 : 1,  col5 : -1},
        //     {screenType : {rowspan : 0}, screenName : '로그인관리',  col1 : 1,  col2 : 1,  col3 : 1,  col4 : 1,  col5 : -1},
        //     {screenType : {rowspan : 0}, screenName : '환경설정',  col1 : 1,  col2 : 1,  col3 : -1,  col4 : -1,  col5 : -1},

        //     {screenType : {rowspan : 2, value : '자산관리'}, screenName : '자산관리',  col1 : 1,  col2 : 1,  col3 : 1,  col4 : 1,  col5 : 1},
        //     {screenType : {rowspan : 0}, screenName : '자산라벨출력',  col1 : 1,  col2 : 1,  col3 : -1,  col4 : -1,  col5 : -1},

        //     {screenType : '자산현황조회', screenName : '자산현황조회',  col1 : 1,  col2 : -1,  col3 : -1,  col4 : -1,  col5 : 1},

        //     {screenType : {rowspan : 2, value : '보고서'}, screenName : '자산이력카드',  col1 : 1,  col2 : -1,  col3 : -1,  col4 : -1,  col5 : 1},
        //     {screenType : {rowspan : 0}, screenName : '자산이력카드',  col1 : 1,  col2 : -1,  col3 : -1,  col4 : -1,  col5 : 1},

        //     {screenType : {rowspan : 2, value : '감가상각'}, screenName : '감가상각',  col1 : 1,  col2 : 1,  col3 : 1,  col4 : 1,  col5 : 1},
        //     {screenType : {rowspan : 0}, screenName : '감가상각 환경설정',  col1 : 1,  col2 : 1,  col3 : -1,  col4 : -1,  col5 : -1},

        //     {screenType : '재물조사', screenName : '재물조사',  col1 : 1,  col2 : 1,  col3 : 1,  col4 : 1,  col5 : 1},
        // ]

    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 900px;
    height:90%;
    max-height: 920px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:hidden;
    display:flex;
    

}
.asset_bottom_btns{text-align:right;margin-top:15px;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>