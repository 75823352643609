<template >
    <ModalBack :closeAction="hideModal">
        <div class="company_register_wrap">
        <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
        <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >실사일괄변경</div>
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                    <col>
                </colgroup>
                <tr>
                    <td>
                        <div class="label">실사결과</div>
                        <CommonSelect :list="surveyResults" :detectChange="(data) => { form.surveyResult = data}"></CommonSelect>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">사용상태</div>
                        <CommonSelect :list="states" :detectChange="(data) => {form.stateCode = data}"></CommonSelect>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">위치명</div>
                        <div class="row_flex">
                            <CommonInput style="flex:1;" v-model="form.locName"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowLocSearch = true}"></MaginifyingGlassButton>
                        </div>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">부서명</div>
                        <div class="row_flex">
                            <CommonInput style="flex:1;" v-model="form.groupName"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowGroupSearch = true}"></MaginifyingGlassButton>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
            <button class="asset_bottom_btn_ok" @click="save" >저장</button>
        </div>

        <div><LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {form.locName = (data.text !== '위치코드' ?  data.text : ''); form.locCode = data.value}"></LocSearch></div>
        <div><GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {form.groupName = (data.text !== '부서코드' ?  data.text : ''); form.groupCode = data.value}"></GroupSearch></div>
    </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, states} from '@/util/Constants.js';

export default {
    name : 'SurveyDetailRegister',
    props : {
        hideModal : Function,
        data : Object,
        completeAction : {
            type : Function,
            default : () => {}
        },
        transferData : {
            type : Object,
            default : () => {
                return {}
            }
        }
    },
    components : {
        CommonInput,
        ModalBack,
        CommonModalCloseBtn,
        CommonSelect, LocSearch, GroupSearch, MaginifyingGlassButton
    },
    data(){
        return {
            MODE : MODE,
            states : states,
            isShowLocSearch : false,
            isShowGroupSearch : false,
            form : {
                surveyResult : '',
                stateCode : '',
                locName : '',
                locCode : '',
                groupName : '',
                groupCode : '',
            },
            surveyResults : [
                {name : '실사전' , value : '0'},
                {name : '일치' , value : '1'},
                {name : '불일치' , value : '2'},
            ]
        }
    },
    methods : {
        save(){
            const parameters = {};
            parameters.surveyIdx = this.transferData.surveyIdx;
            parameters.isAll = this.transferData.data.isSelectAll;

            const queryParameters = this.transferData.data.queryParameters;
            for(let key in queryParameters) {
                parameters[key] = queryParameters[key];
            }

            const assetCodes = [];
            const savedSelectedListKeys = Object.keys(this.transferData.data.savedSelectedList);
            for(let key in savedSelectedListKeys) {
                assetCodes.push(savedSelectedListKeys[key]);
            }

            if(assetCodes.length > 0) parameters.assetCodes = assetCodes;
            
            if(this.form.groupCode !== '') parameters.groupCode = this.form.groupCode;
            if(this.form.locCode !== '') parameters.locCode = this.form.locCode;
            if(this.form.surveyResult !== '') parameters.surveyResult = this.form.surveyResult
            if(this.form.stateCode !== '') parameters.stateCode = this.form.stateCode

            axios.post('/api/survey/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.hideModal();
                    this.transferData.completeFunc();
                } 
            }).catch(res => {
                console.log(res);
            });

        }
    },
    created(){
        this.states.splice(2, 2);
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 400px;
    height: 500px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>