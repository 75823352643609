<template >
    <ModalBack :closeAction="hideModal">
        <div class="company_register_wrap">
        <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
        <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >계정과목등록</div>
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                    <col>
                </colgroup>
                <tr>
                    <td>
                        <div class="label required">계정코드</div>
                        <div class="row_flex" >
                            <CommonInput v-model="accountCode"></CommonInput>
                        </div>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label required">계정명</div>
                        <div class="row_flex" >
                            <CommonInput v-model="accountName"></CommonInput>
                        </div>
                        <div class="row_flex" style="margin-top:5px;;">
                            <CommonCheckbox :flag="defaultAccount === '0' ? false : true" :click-check="toggleDefault" color="#e6e7ec"></CommonCheckbox>
                            <div @click="toggleDefault" style="cursor:pointer;display:inline-block; line-height: 1.4;margin-left: 5px;">기본계정과목으로 설정</div>
                        </div>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label required">감가상각방법</div>
                        <div style="height:40px; display: flex;align-items: center;">
                            <CommonRadio name="depreciation" v-model="depreciation" initVal="0" label="정액법" ></CommonRadio>
                            <CommonRadio name="depreciation" v-model="depreciation" initVal="1" label="정률법" style="margin-left:10px;"></CommonRadio>
                        </div>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label required">내용연수(기본값)</div>
                        <div class="row_flex">
                            <CommonInput v-model="durableYear"></CommonInput>
                            <button class="search_btn" @click="() => {isShowItemHistoryGuide = true}">내용연수표</button>
                        </div>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label">상각율 소수점처리</div>
                        <div><CommonRadio name="ratePoint" v-model="ratePoint" initVal="0" label="소수점 4째자리 반올림 (예시: 0.450719728 -> 0.451)" style="margin-top:10px; color:gray;" :isReadonly="depreciation === '0'"></CommonRadio></div>
                        <div><CommonRadio name="ratePoint" v-model="ratePoint" initVal="1" label="소수점 4째자리 올림 (예시: 0.527129195 -> 0.528)" style="margin-top:10px; color:gray;" :isReadonly="depreciation === '0'"></CommonRadio></div>
                        <div><CommonRadio name="ratePoint" v-model="ratePoint" initVal="2" label="소수점 4째자리 버림 (예시: 0.450719728 -> 0.450)" style="margin-top:10px; color:gray;" :isReadonly="depreciation === '0'"></CommonRadio></div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
            <button class="asset_bottom_btn_ok" @click="save">저장</button>
        </div>

        <ItemHistoryGuide v-if="isShowItemHistoryGuide" :hideModal="() => {isShowItemHistoryGuide = false}"></ItemHistoryGuide>

    </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import CommonRadio from '@/components/common/CommonRadio.vue';
import ItemHistoryGuide from '@/components/modal/ItemHistoryGuide.vue';
import {MODE, AUTH} from '@/util/Constants.js';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
export default {
    name : 'AccountRegister',
    props : {
        hideModal : Function,
        curMode : Number,
        transferData : {
            type : Object,
            default : () => {}
        },
        completeFunc : {
            type : Function,
            default : () => {}
        }
    },
    components : {
        CommonInput,
        ModalBack,
        CommonModalCloseBtn,
        CommonCheckbox,
        CommonRadio,
        ItemHistoryGuide
    },

    data(){
        return {
            accountCode: '',
            accountName : '',
            defaultAccount : '0',
            viewAuth : '',
            modifyAuth : '',
            isShowItemHistoryGuide : false,
            durableYear : '',
            ratePoint : '0',
            depreciation : '0',
            stateList : [
                {name : '수리', value : '1'},
                {name : '대여', value : '2'},
                {name : '불용', value : '3'},
                {name : '분실', value : '4'},
                {name : '폐기', value : '5'},
                {name : '보관', value : '6'}
            ],
            authrityList : [
                {name : '관리자', value : '1'},
                {name : '사용자', value : '2'},
            ]
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.EXCEL)
        },
    },
    methods : {
        save(){
            if(this.accountCode === ''){
                alert('[계정코드]는 필수입력 사항입니다.');
                return;
            }

            if(this.accountName === ''){
                alert('[계정명]는 필수입력 사항입니다.');
                return;
            }

            if(this.durableYear === ''){
                alert('[내용연수]는 필수입력 사항입니다.');
                return;
            }

            const parameters = {};
            parameters.accountCode = this.accountCode;
            parameters.accountName = this.accountName;
            parameters.defaultAccount = this.defaultAccount;
            parameters.depreciation = this.depreciation;
            parameters.durableYear = this.durableYear;
            parameters.ratePoint = this.ratePoint;

            if(this.curMode === MODE.UPDATE){
                parameters.accountIdx = this.transferData.accountIdx;
            }

            axios.post('/api/account/save', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeFunc();
                    this.hideModal();
                }else if(res.data.code == ResCode.Fail){
                    alert(res.data.message);
                }
            }).catch(res => {
                console.log(res);
            });

        },
        toggleDefault(){
            if(this.defaultAccount === '0'){
                this.defaultAccount = '1'
            }else {
                this.defaultAccount = '0'
            }
            // this.isDefault = !this.isDefault;
        }
    },
    created(){
        if(this.curMode === MODE.UPDATE){
            const curData = this.transferData;
            this.accountCode = curData.accountCode + '';
            this.accountName = curData.accountName;
            this.defaultAccount = curData.defaultAccount + '';
            this.depreciation = curData.depreciation + '';
            this.durableYear = curData.durableYear + '';
            this.ratePoint = curData.ratePoint + '';
        }

    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 460px;
    height: 650px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>