<template>
    <div class="asset_info_wrap">
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                <col>
                <col style="width:41px;">
                <col>
                <col style="width:41px;">
                <col>
                </colgroup>
                <tr>
                    <td>
                        <div class="label required"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.itemCode" :clickCheck="() => {formChanged.itemCode = !formChanged.itemCode}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>재산구분</div>
                        <div class="row_flex">
                            <CommonInput class="max_width" v-model="form.itemName" :isReadonly="true" :clickInput="() => {isShowItemSearch = true}"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowItemSearch = true}"></MaginifyingGlassButton>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="label required"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.assetName" :clickCheck="() => {formChanged.assetName = !formChanged.assetName}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>자산명칭</div>
                        <CommonInput v-model="form.assetName" ></CommonInput>
                    </td>
                    <td></td>
                    <td rowspan="5">
                        <div class="label" style="position:relative;">이미지
                            <span style="float:right;cursor:pointer;" @click="isShowImageMenu = !isShowImageMenu">
                                <font-awesome-icon icon="fa-solid fa-ellipsis" style="font-size:18px;padding-right:8px;" />
                            </span>
                            <div v-if="isShowImageMenu" style="position:fixed;left:0px; height:0px; top:0px;width:100vw; height:100vh;" @click="isShowImageMenu = false;"></div>
                            <div v-if="isShowImageMenu" class="image_menu_wrap">
                                <div class="image_menu" @click="showImageLarge">보기</div>
                                <div class="image_menu" @click="deleteImage">삭제</div>
                                <div class="image_menu" @click="rotateImage">회전</div>
                               
                            </div>
                        </div>
                        <div style="height:184px; border:1px solid #e6e7ec; border-radius:5px;cursor:pointer;" @click="setImage">
                            <img style="width:100%; height:100%;border:0px;display:none;border-radius: 5px;" ref="assetImg">
                            <input ref="imgFile" type="file" style="display:none;" @change="readURL($event)">
                        </div>
                        
                    </td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="4"></td></tr>
                <tr>
                    <td>
                        <div class="label required"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.groupCode" :clickCheck="() => {formChanged.groupCode = !formChanged.groupCode}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>부서</div>
                         <div class="row_flex">
                            <CommonInput class="max_width" v-model="form.groupName" :isReadonly="true" :clickInput="() => {isShowGroupSearch = true}"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowGroupSearch = true}"></MaginifyingGlassButton>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="label required"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.locCode" :clickCheck="() => {formChanged.locCode = !formChanged.locCode}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>위치</div>
                        <div class="row_flex">
                            <CommonInput class="max_width" v-model="form.locName" :isReadonly="true" :clickInput="() => {isShowLocSearch = true}"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowLocSearch = true}"></MaginifyingGlassButton>
                        </div>
                    </td>
                    <td></td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="4"></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.modelName" :clickCheck="() => {formChanged.modelName = !formChanged.modelName}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>모델명</div>
                        <CommonInput v-model="form.modelName"></CommonInput>
                    </td>
                    <td></td>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.serial" :clickCheck="() => {formChanged.serial = !formChanged.serial}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>시리얼</div>
                        <CommonInput v-model="form.serial"></CommonInput>
                    </td> 
                    <td></td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="5"></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.standard" :clickCheck="() => {formChanged.standard = !formChanged.standard}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>규격</div>
                        <CommonInput v-model="form.standard"></CommonInput>
                    </td>
                    <td></td>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.buyDate" :clickCheck="() => {formChanged.buyDate = !formChanged.buyDate}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>취득일자</div>
                        <CalendarInput v-model="form.buyDate"></CalendarInput>
                    </td>
                    <td></td>
                    <td v-if="fields.field1.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field1" :clickCheck="() => {formChanged.field1 = !formChanged.field1}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field1.name}}</div>
                        <CommonInput v-model="form.field1"></CommonInput>
                    </td>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="5"></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.buyPrice" :clickCheck="() => {formChanged.buyPrice = !formChanged.buyPrice}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>취득가액</div>
                        <CommonInput v-model="form.buyPrice" mode="number"></CommonInput>
                    </td>
                    <td></td>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.scrapPrice" :clickCheck="() => {formChanged.scrapPrice = !formChanged.scrapPrice}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>잔존가액</div>
                        <div class="row_flex">
                            <CommonInput class="max_width" v-model="form.scrapPrice" :isReadonly="true" mode="number"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {assetDeprePriceDetail = transferData.data; isShowAssetDeprePriceDetail = true;}"></MaginifyingGlassButton>
                        </div>
                    </td>
                    <td></td>
                    <td v-if="fields.field2.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field2" :clickCheck="() => {formChanged.field2 = !formChanged.field2}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field2.name}}</div>
                        <CommonInput v-model="form.field2"></CommonInput>
                    </td>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="5"></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.chargeCode" :clickCheck="() => {formChanged.chargeCode = !formChanged.chargeCode}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>담당자</div>
                        <div class="row_flex">
                            <CommonInput class="max_width" v-model="form.chargeName" :isReadonly="true" :clickInput="() => {userSearchMode = 'charge'; isShowUserSearch = true; }"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {userSearchMode = 'charge'; isShowUserSearch = true; }"></MaginifyingGlassButton>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.userCode" :clickCheck="() => {formChanged.userCode = !formChanged.userCode}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>사용자</div>
                        <div class="row_flex">
                            <CommonInput class="max_width" v-model="form.userName" :isReadonly="true" :clickInput="() => {userSearchMode = 'user'; isShowUserSearch = true; }"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {userSearchMode = 'user'; isShowUserSearch = true; }"></MaginifyingGlassButton>
                        </div>
                    </td>
                    <td></td>
                    <td v-if="fields.field3.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field3" :clickCheck="() => {formChanged.field3 = !formChanged.field3}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field3.name}}</div>
                        <CommonInput v-model="form.field3"></CommonInput>
                    </td>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="5"></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.sellingCompany" :clickCheck="() => {formChanged.sellingCompany = !formChanged.sellingCompany}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>구매처</div>
                        <div class="row_flex">
                            <CommonInput class="max_width" v-model="form.companyName" :isReadonly="true" :clickInput="() => {isShowCompanySearch = true; }"></CommonInput>
                            <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowCompanySearch = true; }"></MaginifyingGlassButton>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="label required"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.stateCode" :clickCheck="() => {formChanged.stateCode = !formChanged.stateCode}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>사용상태</div>
                        <CommonSelect :list="states" :value="form.stateCode" :detectChange="detectStateChange"></CommonSelect>
                    </td>
                    <td></td>
                    <td v-if="fields.field4.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field4" :clickCheck="() => {formChanged.field4 = !formChanged.field4}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field4.name}}</div>
                        <CommonInput v-model="form.field4"></CommonInput>
                    </td>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="5"></td></tr>
                <tr>
                    <template v-if="curMode === MODE.BATCH">
                        <td>
                            <div class="label">&nbsp;</div>
                            <div class="row_flex">&nbsp;</div>
                        </td>
                    </template>
                    <template v-else>
                        <td>
                            <div class="label">바코드</div>
                            <div class="row_flex">
                                <CommonInput class="max_width" v-model="form.barcode"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="getNewBarcode"></MaginifyingGlassButton>
                            </div>
                        </td>
                    </template>
                    <td></td>
                    <template v-if="curMode === MODE.BATCH">
                        <td>
                            <div class="label">&nbsp;</div>
                            <div class="row_flex">&nbsp;</div>
                        </td>
                    </template>
                    <template v-else>
                        <td>
                            <div class="label">RFID코드</div>
                            <CommonInput v-model="form.rfid" :isReadonly="true"></CommonInput>
                        </td>
                    </template>
                   
                    <td></td>
                    <td v-if="fields.field5.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field5" :clickCheck="() => {formChanged.field5 = !formChanged.field5}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field5.name}}</div>
                        <CommonInput v-model="form.field5"></CommonInput>
                    </td>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                </tr>
                <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="5"></td></tr>
                <tr>
                    <td v-if="fields.field6.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field6" :clickCheck="() => {formChanged.field6 = !formChanged.field6}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field6.name}}</div>
                        <CommonInput v-model="form.field6"></CommonInput>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                    <td></td>
                    <td v-if="fields.field7.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field7" :clickCheck="() => {formChanged.field7 = !formChanged.field7}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field7.name}}</div>
                        <CommonInput v-model="form.field7"></CommonInput>
                    </td>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                    <td></td>
                    <td v-if="fields.field8.used === '1'">
                        <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.field8" :clickCheck="() => {formChanged.field8 = !formChanged.field8}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>{{fields.field8.name}}</div>
                        <CommonInput v-model="form.field8"></CommonInput>
                    </td>
                    <td v-else>
                        <div class="label">&nbsp;</div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideAssetDetail">취소</button>
            <button class="asset_bottom_btn_ok" @click="save">저장</button>
        </div>

        <portal to="modalArea">
        <div><LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {form.locName = (data.text !== '위치코드' ?  data.text : ''); form.locCode = data.value}"></LocSearch></div>
        <div><GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {form.groupName = (data.text !== '부서코드' ?  data.text : ''); form.groupCode = data.value}"></GroupSearch></div>
        <div><ItemSearch v-if="isShowItemSearch" :hideModal="() => {isShowItemSearch = false}" :completeSave="(data) => {form.itemName =  (data.text !== '재산구분코드' ? data.text : ''); form.itemCode = data.value}"></ItemSearch></div>
        <div>
            <UserSearch v-if="isShowUserSearch" 
                :hideModal="() => {isShowUserSearch = false}" 
                :completeSave="(data) => {
                    if(userSearchMode === 'charge'){
                        form.chargeName = data.userName; form.chargeCode = data.userCode
                    }else {
                        form.userName = data.userName; form.userCode = data.userCode
                    }
                }">
            </UserSearch>
        </div>
        <div><CompanySearch v-if="isShowCompanySearch" :hideModal="() => {isShowCompanySearch = false}" :completeSave="(data) => {form.companyName = data.companyName; form.sellingCompany = data.companyCode}"></CompanySearch></div>
        <LargeImageViewer v-if="isShowLargeImageViewer" :hideModal="() => {isShowLargeImageViewer = false}" :imgSrc="imgSrc"></LargeImageViewer>
        <AssetDeprePriceDetail v-if="isShowAssetDeprePriceDetail" :hideModal="() => {isShowAssetDeprePriceDetail = false}" :transferData="assetDeprePriceDetail"></AssetDeprePriceDetail>
        </portal>
    </div>
</template>
<script>
import CommonInput from '@/components/common/CommonInput.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import ItemSearch from '@/components/modal/ItemSearch.vue';
import UserSearch from '@/components/modal/UserSearch.vue';
import AssetDeprePriceDetail from '@/components/modal/AssetDeprePriceDetail.vue';
import CompanySearch from '@/components/modal/CompanySearch.vue';
import LargeImageViewer from '@/components/modal/LargeImageViewer.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import {MODE, states, fields, CODE_TYPE} from '@/util/Constants.js';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import moment from 'moment'

let globalImage = null;

export default {
    name : 'assetInfo',
    props : {
        hideAssetDetail : Function,
        transferData : {
            type : Object,
            default : () => {
                return {}
            }
        },
        changeTab : {
            type : Function,
            default : () => {}
        }
    },
    components : {
        CommonInput, MaginifyingGlassButton, CommonSelect, LocSearch, GroupSearch, ItemSearch, 
        CalendarInput, UserSearch, CompanySearch, LargeImageViewer, CommonCheckbox, AssetDeprePriceDetail
    },
    data(){
        return {
            MODE : MODE,
            CODE_TYPE : CODE_TYPE,
            curMode : MODE.INSERT,
            states : states.slice(1),
            fields : fields,
            assetDeprePriceDetail : {},
            isShowImageMenu : false,
            isShowLocSearch : false,
            isShowGroupSearch : false,
            isShowItemSearch : false,
            isShowUserSearch : false,
            isShowCompanySearch : false,
            isShowLargeImageViewer : false,
            isShowAssetDeprePriceDetail : false,
            form : {
                assetCode : '',
                locName : '',
                locCode : '',
                groupName : '',
                groupCode : '',
                itemName : '',
                itemCode : '',
                assetName : '',
                modelName : '',
                serial : '',
                standard : '',
                buyDate : '',
                buyPrice : '0',
                scrapPrice : '0',
                chargeName : '',
                chargeCode : '',
                userName : '',
                userCode : '',
                companyName : '',
                sellingCompany : '',
                stateCode : 'B001001000',
                prevStateCode : 'B001001000',
                barcode : '',
                rfid : '',
                field1 : '',
                field2 : '',
                field3 : '',
                field4 : '',
                field5 : '',
                field6 : '',
                field7 : '',
                field8 : '',
            },
            formChanged : {
                assetCode : false,
                locName : false,
                locCode : false,
                groupName : false,
                groupCode : false,
                itemName : false,
                itemCode : false,
                assetName : false,
                modelName : false,
                serial : false,
                standard : false,
                buyDate : false,
                buyPrice : false,
                scrapPrice : false,
                chargeName : false,
                chargeCode : false,
                userName : false,
                userCode : false,
                companyName : false,
                sellingCompany : false,
                stateCode : false,
                prevStateCode : false,
                barcode : false,
                rfid : false,
                field1 : false,
                field2 : false,
                field3 : false,
                field4 : false,
                field5 : false,
                field6 : false,
                field7 : false,
                field8 : false,
            },
            userSearchMode : 'charge',
            image : {},
            curImageDegree : 0,
        }
    },
    watch : {
        'form.assetCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.assetCode = true;},
        'form.locCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.locCode = true;},
        'form.groupCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.groupCode = true;},
        'form.itemCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.itemCode = true;},
        'form.assetName' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.assetName = true;},
        'form.modelName' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.modelName = true;},
        'form.serial' : function(newVal, oldVal){if(this.curMode !== MODE.BATCH) return; this.formChanged.serial = true;},
        'form.standard' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.standard = true;},
        'form.buyDate' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.buyDate = true;},
        'form.buyPrice' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.buyPrice = true;},
        'form.scrapPrice' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.scrapPrice = true;},
        'form.chargeCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.chargeCode = true;},
        'form.userCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.userCode = true;},
        'form.sellingCompany' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.sellingCompany = true;},
        'form.stateCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.stateCode = true;},
        'form.rfid' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.rfid = true;},
        'form.field1' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field1 = true;},
        'form.field2' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field2 = true;},
        'form.field3' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field3 = true;},
        'form.field4' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field4 = true;},
        'form.field5' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field5 = true;},
        'form.field6' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field6 = true;},
        'form.field7' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field7 = true;},
        'form.field8' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.field8 = true;},
    },
    methods : {
        getNewBarcode(){
            axios.get('/api/asset/newbarcode').then(res => {
                if(res.data.code == ResCode.Success){
                    this.form.barcode = res.data.data.barcode;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        async save(){
            let parameters = {};
            parameters = Object.assign(parameters, this.form);
            parameters.buyPrice = this.$commonFunc.uncomma(parameters.buyPrice);
            parameters.scrapPrice = this.$commonFunc.uncomma(parameters.scrapPrice);

            let path = '';
            if(this.curMode === MODE.INSERT || this.curMode === MODE.COPY){
                path = '/api/asset/save';
                parameters.mode = 'insert';
                parameters.assetCode = '';

                //필수항목 체크
                if(this.form.itemName === ''){
                    alert('[재산구분]은(는) 필수입력 사항입니다.');
                    return;
                }

                if(this.form.assetName === ''){
                    alert('[자산명칭]은(는) 필수입력 사항입니다.');
                    return;
                }

                if(this.form.groupName === ''){
                    alert('[부서]은(는) 필수입력 사항입니다.');
                    return;
                }

                if(this.form.locName === ''){
                    alert('[위치]은(는) 필수입력 사항입니다.');
                    return;
                }
            }

            let formData = new FormData();


            if(this.curMode === MODE.UPDATE || this.curMode === MODE.BATCH){
                path = '/api/asset/savebatch';

                const hasOwnProperty = Object.prototype.hasOwnProperty;
                for(let key in parameters) {
                    if(this.curMode === MODE.UPDATE){
                        formData.append(key, parameters[key]);
                    }else if(this.curMode === MODE.BATCH) {
                        if(hasOwnProperty.call(this.formChanged, key) && this.formChanged[key]){
                            formData.append(key, parameters[key]);
                        }else {
                            if(parameters[key]) formData.append(key, parameters[key]);
                        }
                    }
                }

                if(this.curMode === MODE.BATCH){
                    //필수항목 체크
                    if(this.formChanged.itemCode && this.form.itemName === ''){
                        alert('[재산구분]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    if(this.formChanged.assetName && this.form.assetName === ''){
                        alert('[자산명칭]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    if(this.formChanged.groupCode && this.form.groupName === ''){
                        alert('[부서]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    if(this.formChanged.locCode && this.form.locName === ''){
                        alert('[위치]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    if(this.formChanged.stateCode && this.form.stateCode === ''){
                        alert('상태는 필수선택 사항입니다.');
                        return;
                    }

                    const queryParameters = this.transferData.data.queryParameters;
                    const isAll = this.transferData.data.isSelectAll;

                    formData.append('deleteChange', false);
                    formData.append('isAll', isAll);
                    if(isAll){
                        for(let key in queryParameters) {
                            formData.append(key, queryParameters[key]);
                        }
                    }else {
                        const savedSelectedListKeys = Object.keys(this.transferData.data.savedSelectedList);
                        const tempSavedSelectedList = this.transferData.data.savedSelectedList;
                        for(let key in savedSelectedListKeys) {
                            formData.append('assetCodes[]', savedSelectedListKeys[key]);
                        }

                        //체크된것만 수리, 대여중인 자산의 상태를 변경하는지 체크
                        if(this.form.stateCode !== ''){
                            let stateRepairOrRentalCnt = 0;
                            for(let key in savedSelectedListKeys) {
                                if(tempSavedSelectedList[savedSelectedListKeys[key]].stateCode === 'B001002000' || tempSavedSelectedList[savedSelectedListKeys[key]].stateCode === 'B001003000'){
                                    stateRepairOrRentalCnt++;
                                }
                            }

                            if(stateRepairOrRentalCnt > 0){
                                alert('자산상태가 수리/대여 중인 자산은 사용상태를 변경 할 수 없습니다.(' + stateRepairOrRentalCnt + '개 자산)');
                                return;
                            }
                        }
                       
                    }
                }else if(this.curMode === MODE.UPDATE){
                    //필수항목 체크
                    if(this.form.itemName === ''){
                        alert('[재산구분]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    if(this.form.assetName === ''){
                        alert('[자산명칭]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    if(this.form.groupName === ''){
                        alert('[부서]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    if(this.form.locName === ''){
                        alert('[위치]은(는) 필수입력 사항입니다.');
                        return;
                    }

                    formData.append('assetCodes[]', parameters.assetCode);
                }
            }else {
                for(let key in parameters) {
                    formData.append(key, parameters[key]);
                }
            }

            //이미지
            if(this.curMode === MODE.INSERT || this.curMode === MODE.COPY || this.curMode === MODE.BATCH){
                if(globalImage){
                    formData.append('file', await this.getFile());                    
                }
            }

            axios.post(path, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
                if(res.data.code == ResCode.Success){
                    this.hideAssetDetail();
                    this.transferData.completeFunc();
                }else if(res.data.code == ResCode.Fail){
                    alert(res.data.message);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        detectStateChange(value){
            let prevValue = value + '';
 
            if(this.curMode === MODE.UPDATE){

                //이전 상태가 수리, 대여일때
                if(this.form.prevStateCode === 'B001002000'){
                    if(confirm('수리상태를 변경하시려면 수리완료를 해야합니다. 수리내역 탭으로 이동하시겠습니까?')){
                        this.changeTab(2);
                    }
                    prevValue = this.form.prevStateCode;
                }else if(this.form.prevStateCode === 'B001003000'){
                    if(confirm('대여상태를 변경하시려면 대여완료를 해야합니다. 대여내역 탭으로 이동하시겠습니까?')){
                        this.changeTab(3);
                    }
                    prevValue = this.form.prevStateCode;
                } else {
                    //수리
                    if(value === 'B001002000'){
                        if(confirm('수리상태로 변경하시려면 수리요청을 해야합니다. 수리내역 탭으로 이동하시겠습니까?')){
                            this.changeTab(2);
                        }

                        prevValue = this.form.prevStateCode;
                    //대여
                    } else if(value === 'B001003000'){
                        if(confirm('대여상태로 변경하시려면 자산대여를 해야합니다. 대여내역 탭으로 이동하시겠습니까?')){
                            this.changeTab(3);
                        }

                        prevValue = this.form.prevStateCode;
                    }
                }

 
            }
            this.form.stateCode = value;
            this.form.prevStateCode = value;

            this.$nextTick(() => {
                this.form.stateCode = prevValue;
                this.form.prevStateCode = prevValue;
            })

        },
        getParameters(){

        },
        setImage(){
            this.$refs['imgFile'].click();
        },
        readURL(event){
            let input = event.currentTarget;  
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let imgElement = this.$refs['assetImg'];
                    imgElement.src = e.target.result
                    imgElement.style.display = 'block';

                    const tempImage = new Image();
                    tempImage.src = e.target.result;
                    globalImage = tempImage;
                    this.curImageDegree = 0;

                    if(this.curMode === MODE.UPDATE){
                        this.$nextTick(() => {
                            this.updateImage();
                        })
                    }
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        async updateImage(){
            const formData = new FormData();
            formData.append('assetCodes[]', [this.form.assetCode]);
            const file = await this.getFile();
            formData.append('file', file);
            formData.append('deleteChange', false);
            formData.append('isAll', false);
            formData.append('deleted', 0);

            axios.post('/api/asset/savebatch', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
                if(res.data.code == ResCode.Success){
                    console.log('완료');
                }
            }).catch(res => {
                console.log(res);
            });

        },
        getFile(){
            return new Promise((resolve, reject) => {
                const image = globalImage;
                const canvas = document.createElement('canvas');
                let width = image.width;
                let height = image.height;
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 1);
            });
        },
        showImageLarge(){
            if(globalImage == null) return;
            this.imgSrc = globalImage.src;
            this.isShowLargeImageViewer = true;
            this.isShowImageMenu = false;
        },
        deleteImage(){
            this.isShowImageMenu = false;

            //바로 삭제
            if(this.curMode === MODE.UPDATE){
                const parameters = {};
                parameters.assetCode = this.form.assetCode;

                axios.post('/api/asset/image/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                    // if(res.data.code == ResCode.Success){

                    // }
                }).catch(res => {
                    console.log(res);
                });
            }
          
            this.$refs['assetImg'].style.display = 'none';
            this.$refs['assetImg'].src = '';
            globalImage = null;
        },
        rotateImage(){
            this.isShowImageMenu = false;

            const image = globalImage;
            const degree = this.curImageDegree;
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;
            const ctx = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;
            switch(degree){
                case 0:
                    canvas.width = height;
                    canvas.height = width;
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break;
                case 90:
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break;
                case 180:
                    canvas.width = height;
                    canvas.height = width;
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break;
                case 270:
                    break;
            }
            
            this.curImageDegree = (degree + 90) % 360;

            ctx.drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL('image/jpeg');
            this.$refs['assetImg'].src = dataUrl;
            // globalImage.src = dataUrl;

            const newImage = new Image();
            newImage.src = dataUrl;
            globalImage = newImage;

            if(this.curMode === MODE.UPDATE){
                setTimeout(() => {
                    this.updateImage();
                }, 500)
                // this.$nextTick(() => {
                //     this.updateImage();
                // })
                
            }
        },
        getImage(){
            const parameters = {};
            parameters.assetCode = this.transferData.data.assetCode;

            axios.get('/api/asset/image', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    if(res.data.data.imageData){
                        let imgElement = this.$refs['assetImg'];
                        imgElement.src = 'data:image/png;base64,' + res.data.data.imageData;
                        imgElement.style.display = 'block';

                        const tempImage = new Image();
                        tempImage.src = 'data:image/png;base64,' + res.data.data.originImage;
                        globalImage = tempImage;
                        this.curImageDegree = 0;
                    }
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getAsset(){
            const parameters = {};
            const assetCode = this.transferData.data.assetCode;

            axios.get('/api/asset/one/' + assetCode, {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    this.setData(res.data.data.asset);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        setData(prevData){
            this.form.assetCode = prevData.assetCode || '';
            this.form.assetName = prevData.assetName || '';
            this.form.modelName = prevData.modelName || '';
            this.form.serial = prevData.serial || '';
            this.form.standard = prevData.standard || '';
            this.form.buyDate = prevData.buyDate || '';
            this.form.buyPrice = this.$commonFunc.comma(prevData.buyPrice) || '0';
            this.form.scrapPrice = this.$commonFunc.comma(prevData.scrapPrice) || '0';
            this.form.chargeName = prevData.charge || '';
            this.form.chargeCode = prevData.chargecode || '';
            this.form.userName = prevData.user || '';
            this.form.userCode = prevData.usercode || '';
            this.form.itemName = prevData.itemName || '';
            this.form.itemCode = prevData.itemCode || '';
            this.form.locName = prevData.locName || '';
            this.form.locCode = prevData.loccode || '';
            this.form.groupName = prevData.groupName || '';
            this.form.groupCode = prevData.groupcode || '';
            this.form.companyName = prevData.selling || '';
            this.form.sellingCompany = prevData.sellingcode || '';
            this.form.stateCode = prevData.stateCode || '';
            this.form.prevStateCode = prevData.stateCode || '';
            this.form.barcode = prevData.barcode || '';
            this.form.rfid = prevData.rfid || '';
            this.form.field1 = prevData.field1 || '';
            this.form.field2 = prevData.field2 || '';
            this.form.field3 = prevData.field3 || '';
            this.form.field4 = prevData.field4 || '';
            this.form.field5 = prevData.field5 || '';
            this.form.field6 = prevData.field6 || '';
            this.form.field7 = prevData.field7 || '';
            this.form.field8 = prevData.field8 || '';

            if(this.curMode === MODE.COPY) this.form.barcode = '';
        }
    },
    created(){
        globalImage = null;
        this.curMode = this.transferData.mode;

        //등록
        if(this.transferData.mode === MODE.INSERT){
            const codeType = this.transferData.codeType;
            const code = this.transferData.code;
            const codeName = this.transferData.codeName;

            this.form.buyDate = moment().format('YYYY-MM-DD');

            if(code !== -1 && code != ''){
                if(codeType === CODE_TYPE.ITEM){
                    this.form.itemCode = code
                    this.form.itemName= codeName;
                }else if(codeType === CODE_TYPE.LOC){
                    this.form.locCode = code
                    this.form.locName = codeName;
                }else if(codeType === CODE_TYPE.GROUP){
                    this.form.groupCode = code
                    this.form.groupName = codeName;
                }
            }

            this.states.splice(1, 2);
            
        //수정
        }else if(this.transferData.mode === MODE.UPDATE || this.transferData.mode === MODE.COPY){
            this.getAsset();
            this.getImage();
        }else if(this.transferData.mode === MODE.BATCH){
            this.form.buyPrice = '';
            this.form.scrapPrice = '';
            this.states.splice(1, 2);
            this.form.stateCode = '';
            this.form.prevStateCode = '';
            this.states.splice(0, 0, {name : '', value : ''});

            this.$nextTick(() => {
                this.formChanged.buyPrice = false;
                this.formChanged.scrapPrice = false;
                this.formChanged.stateCode = false;
            })
        }

        if(this.transferData.mode === MODE.COPY){
            this.states.splice(1, 2);
            this.form.stateCode = 'B001001000';
            this.form.prevStateCode = 'B001001000';
        }
    }
}
</script>

<style scoped>
.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;padding-top: 14px;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
    cursor:pointer;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.max_width{
    flex:1;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>