<template>
    <button class="btn" @click="clickAction"><font-awesome-icon icon="fa-solid fa-magnifying-glass" class="icon" /></button>
</template>
<script>
export default {
    name : "MaginifyingGlassButton",
    props : {
        clickAction : {
            type : Function,
            default : () => {}
        }
    }
}
</script>
<style scoped>
.btn{    border: 1px solid #e6e7ec;background-color:white; cursor:pointer;   padding: 0px 9px;
    border-radius: 5px;}
.icon{font-size:20px;color:#c5c5d1;}
</style>
