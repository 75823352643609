import VueRouter from 'vue-router';
import Home from '@/components/page/home';
import Login from '@/components/page/Login';
import assetMng from '@/components/page/AssetMng';
import SurveyMng from '@/components/page/SurveyMng';
import AssetCurrent from '@/components/page/AssetCurrent';
import AssetChangeHistory from '@/components/page/AssetChangeHistory';
import AssetRepairHistory from '@/components/page/AssetRepairHistory';
import AssetRentalHistory from '@/components/page/AssetRentalHistory';
import AssetMemoHistory from '@/components/page/AssetMemoHistory';
import AssetDeleteHistory from '@/components/page/AssetDeleteHistory';
import AssetLabelPrint from '@/components/page/AssetLabelPrint';
import AssetReport from '@/components/page/AssetReport';
import CompanyMng from '@/components/page/CompanyMng';
import DepreciationMng from '@/components/page/DepreciationMng';
import DepreciationSpec from '@/components/page/DepreciationSpec';
import DepreciationTotal from '@/components/page/DepreciationTotal';
import UserMng from '@/components/page/UserMng';
import LoginMng from '@/components/page/LoginMng';
import BaseCodeMng from '@/components/page/BaseCodeMng';
import AssetHistoryCard from '@/components/page/AssetHistoryCard';
import {AUTH} from '@/util/Constants.js';

import Test from '@/components/page/Test';
import PageFrame from '@/components/PageFrame';
var store;
let versionPath = "";

function getRouter(pVersionPath, pStore){
    store = pStore;
    versionPath = pVersionPath;

    const router = new VueRouter({
        mode: 'history',
        routes: [ 
            {path : versionPath + '/view/login', component : Login},
            {
                path : versionPath + '/view',
                component : PageFrame,
                children : [
                    {path : 'home', component : Home},
                    {path : 'assetMng', component : assetMng},
                    {path : 'survey/mng', component : SurveyMng},
                    {path : 'asset/current', component : AssetCurrent},
                    {path : 'asset/change', component : AssetChangeHistory},
                    {path : 'asset/repair', component : AssetRepairHistory},
                    {path : 'asset/rental', component : AssetRentalHistory},
                    {path : 'asset/memo', component : AssetMemoHistory},
                    {path : 'asset/delete', component : AssetDeleteHistory},
                    {path : 'asset/labelprint', component : AssetLabelPrint},
                    {path : 'company/mng', component : CompanyMng},
                    {path : 'user/mng', component : UserMng},
                    {path : 'login/mng', component : LoginMng},
                    {path : 'depreciation/mng', component : DepreciationMng},
                    {path : 'depreciation/spec', component : DepreciationSpec},
                    {path : 'depreciation/total', component : DepreciationTotal},
                    {path : 'report/asset', component : AssetReport},
                    {path : 'basecode/mng', component : BaseCodeMng},
                    {path : 'asset/historycard', component : AssetHistoryCard},
                    
                    {path : 'test', component : Test}
                ]
            },
          
            // {path: "*", component: pagenotfound }
        ]
    });
    
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    router.beforeEach((to, from, next) => {
        to.matched.some(route => {

            // if(route.parent != null){
            //     if(hasOwnProperty.call(store.state.authorities, AUTH.자산관리)){
            //         // console.log(store.state.authorities);
            //         console.log(route);
            //         if(store.state.authorities[route.meta.authKey][0] === 1){
            //             console.log('허용');
            //         }else {
            //             console.log('비허용');
            //         }
            //     }else {
    
            //     }
            // }

            // if(route.meta.openMenuKey){
                // setTimeout(() => {
                //     store.commit('changeOpenMenuKey', route.meta.openMenuKey)
                // }, 1)
            // }
        })
        next();
    });
    
    router.afterEach((to, from) => {
    
    });
    
    router.setStore = function(storeObj){
        store = storeObj;
    }
    
    router.setVersionPath = function(pVersionPath){
        versionPath = pVersionPath;
    }

    return router;
}


export default getRouter;