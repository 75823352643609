<template>
    <div class="modal_wrap" ref="modal_wrap" tabindex="-1" @keyup.stop="detectKeyup($event)">
        <div class="modal_back_shadow" ></div>
        <div class="modal_back modal_cnt" ref="modalBack"
            :style="{
                webkitTransform : 'translate(calc(' + (isWidthInteger ? '-50% + ' + (offsetX) + 'px' : '-50% + ' + parseFloat(0.5 + offsetX) + 'px') + '), calc(' + (isHeightInteger ? '-50% + ' + (offsetY) + 'px'  : '-50% + ' + (offsetY + 0.5) + 'px') + '))',
            }">
            <div class="move_area_wrap" :style="{width: moverWidth}" >
                <div class="move_area" :class="[isMoving ? 'move_active' : '']" style="width:calc(100% - 108px);margin:0px 70px 0px 38px" 
                    @mousedown="mouseDown($event)" @mousemove="mouseMove($event)" @mouseup="mouseUp($event)" @mouseleave="mouseLeave($event)">
                </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script>

export default {
    name : 'ModalBack',
    props: {
        signalClose : {
            type : Boolean, 
            default : false
        },
        closeAction : {
            type : Function,
            default : () => {}
        }
    },
    data(){
        return {
            isWidthInteger : false,
            isHeightInteger : false,
            isAbsoluteMode : false,
            offsetX : 0,
            offsetY : 0,
            baseX : 0,
            baseY : 0,
            startX : 0,
            startY : 0,
            moverWidth : '0px',
            isMoving : false
        }
    },
    methods : {
        detectKeyup(e){
            if(e.keyCode === 27) this.closeAction();
        },
        mouseDown(e){
            this.startX = e.clientX;
            this.startY = e.clientY;
            this.baseX = this.offsetX;
            this.baseY = this.offsetY
            this.isMoving = true;
        },
        mouseMove(e){
            if(!this.isMoving) return;
            this.offsetX = this.baseX + (e.clientX - this.startX); 
            this.offsetY = this.baseY + (e.clientY - this.startY);
            // this.isWidthInteger = window.innerWidth % 2 === 0;
            // this.isHeightInteger = window.innerHeight % 2 === 0;
        },
        mouseUp(e){
            this.isMoving = false;
        },
        mouseLeave(e){
            this.isMoving = false;
        }
    },
    destroyed(){
        const modalBacks = document.querySelectorAll('.modal_wrap');
        if(modalBacks.length > 0 ){
            modalBacks[modalBacks.length - 1].focus();
        }
    },
    mounted(){
        this.moverWidth = (this.$refs.modalBack.childNodes[1].offsetWidth) + 'px';
        this.$refs.modal_wrap.focus();
    },
    created(){
        this.isWidthInteger = window.innerWidth % 2 === 0;
        this.isHeightInteger = window.innerHeight % 2 === 0;
    }
}
</script>

<style scoped>
    .modal_back{
        outline:none;
        display:flex; 
        justify-content: center; 
        align-items: center;; 
        width:100%; 
        height:100%; 
        transform: translate(-50%, -50%);
        flex-direction: column;
    
        position:fixed;
        left:50%; top:50%;
        z-index:100;
    }

    .modal_back_shadow{
        left:0px;
        top:0px;
             width:100vw; 
        height:100vh; 
        position:fixed;
        z-index:100;
        background-color:rgba(0, 0, 0, 0.5); 
    }

    .move_area_wrap{
        background-color:black;display: block;position:relative;z-index: 100;
    }

    .move_area{position:absolute; height:38px;width:100px; background-color:transparent;cursor:move;}

    .move_active{
        position:fixed; 
        width:100vw;
        height:100vh;
        left:0px;
        top:0px;
    }


</style>