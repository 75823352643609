<template >
    <div>
        <ModalBack :closeAction="hideModal">
            <div class="company_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">거래처등록</div>
            <div class="asset_info_contents">
                <table class="asset_info_tb" style="margin-top:25px;">
                    <colgroup>
                        <col>
                        <col style="width:41px;">
                        <col>
                    </colgroup>
                    <tr>
                        <td>
                            <div class="label required">업체명</div>
                            <CommonInput v-model="form.companyName"></CommonInput>
                        </td>
                        <td></td>     
                        <td>
                            <div class="label">대표</div>
                            <CommonInput v-model="form.chief"></CommonInput>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="3"></td></tr>
                    <tr>
                        <td>
                            <div class="label">전화번호</div>
                            <CommonInput v-model="form.tel" mode="phone"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label">팩스번호</div>
                            <CommonInput v-model="form.fax" mode="phone"></CommonInput>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="3"></td></tr>
                    <tr>
                        <td>
                            <div class="label">휴대폰번호</div>
                            <CommonInput v-model="form.hp" mode="phone"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label">담당자</div>
                            <CommonInput v-model="form.person"></CommonInput>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="3"></td></tr>
                    <tr>
                        <td>
                            <div class="label">사업/주민번호</div>
                            <CommonInput v-model="form.businessNumber"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label">이메일</div>
                            <CommonInput v-model="form.email"></CommonInput>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="3"></td></tr>
                    <tr>
                        <td>
                            <div class="label">우편번호</div>
                            <div class="row_flex">
                                <CommonInput style="flex:1;" v-model="form.zipCode" :isReadonly="true" :clickInput="() => {isShowAddressSearch = true}"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowAddressSearch = true}"></MaginifyingGlassButton>
                            </div>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="3"></td></tr>
                    <tr >
                        <td colspan="3">
                            <div class="label">주소</div>
                            <CommonInput v-model="form.address"></CommonInput>
                        </td>
                    </tr>
                    <tr class="margin"><td colspan="3"></td></tr>
                    <tr>
                        <td colspan="3">
                            <div class="label">메모/계좌</div>
                            <CommonInput v-model="form.memo"></CommonInput>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="save">저장</button>
            </div>
        </div>
        </ModalBack>
        <AddressSearch v-if="isShowAddressSearch" :hideModal="() => {isShowAddressSearch = false}" :completeAction="setAddress"></AddressSearch>
    </div>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import AddressSearch from '@/components/modal/AddressSearch.vue';
// import CommonSelect from '@/components/common/CommonSelect.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';

export default {
    name : 'CompanyRegister',
    props : {
        hideModal : Function,
        data : Object,
        curMode : Number,
        completeAction : {
            type : Function,
            default : () => {}
        },
    },
    components : {
        CommonInput,
        MaginifyingGlassButton,
        ModalBack,
        CommonModalCloseBtn,
        AddressSearch
    },
    data(){
        return {
            MODE : MODE,
            isShowImageMenu : false,
            isShowAddressSearch : false,
            form : {
                companyCode : '',
                companyName : '',
                chief : '',
                tel : '',
                fax : '',
                hp : '',
                person : '',
                businessNumber : '',
                email : '',
                zipCode : '',
                address : '',
                memo : ''
            },
            stateList : [
                {name : '수리', value : '1'},
                {name : '대여', value : '2'},
                {name : '불용', value : '3'},
                {name : '분실', value : '4'},
                {name : '폐기', value : '5'},
                {name : '보관', value : '6'}
            ]
        }
    },
    methods : {
        setAddress(zipCode, address, detail){
            this.form.zipCode = zipCode;
            this.form.address = address + ' ' + detail;
        },
        save(){
            if(this.companyName === ''){
                alert('업체명을 입력해주세요.');
                return;
            }
            const parameters = {};
            Object.assign(parameters, this.form);

            if(this.curMode === MODE.INSERT){
                delete parameters.companyCode;
            }

            axios.post('/api/base/company/save', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeAction(this.form);
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });

        }
    },
    created(){
        if(this.curMode === MODE.UPDATE){
            console.log(this.data);
            this.form.companyCode = this.data.companyCode || '',
            this.form.companyName = this.data.companyName || '',
            this.form.chief = this.data.chief || '',
            this.form.tel = this.data.tel || '',
            this.form.fax = this.data.fax || '',
            this.form.hp = this.data.hp || '',
            this.form.person = this.data.person || '',
            this.form.businessNumber = this.data.businessNumber || '',
            this.form.email = this.data.email || '',
            this.form.zipCode = this.data.zipCode || '',
            this.form.address = this.data.address || '',
            this.form.memo = this.data.memo || '';
        }
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white; width:650px; height:700px;;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>