<template>
    <TableSearchPanel :is-show-right-panel="true" :is-show-top-panel="true" :is-show-table-panel="true" :is-show-search-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="로그인관리">
                <CommonAddBtn v-if="isAllowedForInsert" title="로그인등록" :clickAction="() => {curMode = MODE.INSERT; transferData = {}; isShowLoginRegister = true} " style="margin-right:140px;"></CommonAddBtn>
                <CommonAddBtn v-if="isAllowedForAuth" title="사용자 권한 관리" :clickAction="() => {isShowAuthority = true} "  :isShowPlus="false"></CommonAddBtn>
            </PageHeader>
        </template>
        <template v-slot:search_panel>
            <LabelPanel style="width:300px; ">
                <template v-slot:label>검색어</template>
                <template v-slot:content>
                    <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                    <CommonSearchBtn :clickAction="search" style="margin-left:4px;"></CommonSearchBtn>
                </template>
            </LabelPanel>
        </template>
        <template v-slot:table_panel>
            <BatchBtns 
                :is-show-first-checkbox="true && isAllowedForDelete" 
                :is-show-delete-btn="true && isAllowedForDelete" 
                :isShowSelectAllBtn="false"  
                :delete-action="deleteList" 
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :downloadExcel="downloadExcel"
                :isShowExcelDownload="true && isAllowedForExcel"
            ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :isShowNumbering="true"
                    :isShowCheckbox="true && isAllowedForDelete" 
                    :plist="list" 
                    :pcolumn="column" 
                    :pcustom="custom" 
                    :paging="paging" 
                    :change-page="detectChangePage"
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="idx"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    :clickDoubleRow="(data) => {if(!isAllowedForUpdate) return; curMode = MODE.UPDATE; transferData = data; isShowLoginRegister = true;}"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}"
                >
            </ColumnSetting>

        </template>

        <template v-slot:etc>
            <LoginRegister v-if="isShowLoginRegister" :hideModal="() => {isShowLoginRegister = false}" :curMode="curMode" :data="transferData" :completeAction="() => {search();}"></LoginRegister>
            <AuthorityMng v-if="isShowAuthority" :hideModal="() => {isShowAuthority = false}" :startMode="MODE.INSERT"></AuthorityMng>
        </template>
        
    </TableSearchPanel>
</template>
<script>
import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonSearchBtn from '@/components/common/CommonSearchBtn.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import LoginRegister from '@/components/modal/LoginRegister.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import ConditionInput from '@/components/common/ConditionInput.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import AuthorityMng from '@/components/modal/AuthorityMng.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, AUTH, SIZE_PER_PAGE} from '@/util/Constants.js';
export default {
    name : 'LoginMng',
    components : {PageHeader, TableSearchPanel, LabelPanel, CommonSearchBtn, CommonAddBtn, BatchBtns, CommonTable, ColumnSetting, LoginRegister, AuthorityMng, ConditionInput},
    data(){
        return {
            MODE : MODE,
            AUTH : AUTH,
            curMode : MODE.INSERT,
            isShowAuthority : false,
            isShowLoginRegister : false,
            lastPage : 1,
            lastSizePerPage: SIZE_PER_PAGE,
            lastWithoutSearchWord : '',
            searchCnt : 0,
            searchWord : '',
            savedSelectedList : {},
            transferData : {},
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },
            column: [
                { key: "userName", name: "사원명", w: "120px"},
                { key: "groupName", name: "부서", w: "100px"},
                { key: "userClass", name: "직급", w: "100px" },
                { key: "id", name: "ID", w: "100px" },
                { key: "levelName", name: "권한", w: "100px" },
            ],
            isShowColumnSettings : false,
            searchConditions: [
                {name : '사원명', key : 'userName', checked : true},
                {name : '부서', key : 'groupName', checked : true},
                {name : '직급', key : 'userClass', checked : true},
                {name : '권한', key : 'levelName', checked : true},
            ],
            list: [],
            custom : {},
            paging : {},
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.로그인관리, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.로그인관리, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.로그인관리, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.로그인관리, AUTH.EXCEL)
        },
        isAllowedForAuth(){
            return this.$GlobalStore.isAllowedFor(AUTH.사용권한관리, AUTH.SELECT)
        }
    },
    methods : {
        deleteList(){
            const parameters = {};
            const idxes = Object.keys(this.savedSelectedList);
            parameters.idxes = idxes;

            if(idxes.length === 0){
                return;
            }

            if(!confirm(idxes.length + ' 개의 아이디를 삭제하시겠습니까?')){
                return
            }

            axios.post('/api/login/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.savedSelectedList = {};
                    this.search();
                }else if(res.data.code == ResCode.Fail){
                    alert(res.data.message);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        search(){
            this.getLogins(1, this.lastWithoutSearchWord);
        },
        detectChangePage(page){
            this.getLogins(page, this.lastWithoutSearchWord);
        },
        getLogins(page, withoutSearchWord){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            
            const parameters = this.getParameters();

            axios.get('/api/login/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const logins = data.logins.content;
                    this.searchCnt = data.logins.totalElements;
                    data.logins.content = null;
                    // const assetSum = data.assetSum;
                    this.list = logins;
                    this.paging = data.logins;   
      
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;

            let conditions = [];
            let searchWords = [];

            if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }
            
            return parameters;
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/login/list/excel?" + queryStr);
            location.href = this.$versionPath + "/api/login/list/excel?" + queryStr
        },
        enter(){
            this.search();
        },
    },
    created(){
        this.getLogins(1, false);

        ConditionManager.setConditions(this.$options.name, this.searchConditions);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
    
</style>