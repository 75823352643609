<template>
    <select class="select" :value="value" :style="{backgroundImage : 'url(' + require('@/assets/triangle.png') + ')'}" @change="detectChange($event.currentTarget.value, list[$event.target.selectedIndex])" :disabled="disabled">
        <option v-for="each, i in list" :value="each[valueKey]" :key="'asdf' + i" :selected="each[valueKey] == value">{{each[nameKey]}}</option>
    </select>
</template>
<script>
    export default {
        name : 'CommonSelect',
        props : {
            list : Array,
            detectChange : {
                type : Function,
                default : () => {}
            },
            value : {
                type : [String, Number],
                default : ''
            },
            disabled : {
                type : Boolean,
                default : false,
            },
            valueKey : {
                type : String,
                default : 'value',

            },
            nameKey : {
                type : String,
                default : 'name',
            }
        },

    }
</script>
<style scoped>
    .select{
        appearance: none;;
        border: 1px solid #e6e7ec;
    padding: 7px 10px 6px 10px;
    width:100%;
    height:40px;
    font-size:16px;
    background-color:white;
            background-repeat: no-repeat;
    background-position: right 10px top 13px;
    border-radius:5px;
    }   
    .select:disabled {
        background-color:#efefef;
    }
</style>