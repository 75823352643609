export let states = [
    {name : '전체', value : ''},
    {name : '사용', value : 'B001001000'},
    {name : '수리', value : 'B001002000'},
    {name : '대여', value : 'B001003000'},
    {name : '불용', value : 'B001004000'},
    {name : '분실', value : 'B001005000'},
    {name : '폐기', value : 'B001006000'},
    {name : '보관', value : 'B001007000'}
]

export const NUMBER_STYLE = {textAlign: 'right', paddingRight: '20px'}
export const SIZE_PER_PAGE = 30;
export let STATE_KEY = {
    사용 : 'B001001000',
    수리 : 'B001002000',
    대여 : 'B001003000',
    불용 : 'B001004000',
    분실 : 'B001005000',
    폐기 : 'B001006000',
    보관 : 'B001007000'
}

export const setStates = function(newStates){
    states = newStates;
}

export let fields = {
    field1 : {name : '추가항목1', used : '1'},
    field2 : {name : '추가항목2', used : '1'},
    field3 : {name : '추가항목3', used : '1'},
    field4 : {name : '추가항목4', used : '1'},
    field5 : {name : '추가항목5', used : '1'},
    field6 : {name : '추가항목6', used : '1'},
    field7 : {name : '추가항목7', used : '1'},
    field8 : {name : '추가항목8', used : '1'},
}

export const setFields = function(newfields){
    fields = newfields;
}

export const CODE_TYPE = {
    ITEM : 'item',
    GROUP : 'group',
    LOC : 'loc',
    ACCOUNT : 'account',
}

export const MODE = {
    INSERT : 0,
    UPDATE : 1,
    DELETE : 2,
    BATCH : 3,
    COPY : 4,
    COMPLETE : 5,
}

export const GROUP_SEARCH_MODE = {
    ASSET : 0,
    USER : 1,
}

export const AUTH = {
    기초코드관리 : 'frmBaseInfo',
    사원관리 : 'frmEmployee',
    사용권한관리 : 'frmAuthority',
    로그인관리 : 'frmUserLogin',
    환경설정 : 'frmSetting',
    자산관리 : 'frmAsset',
    자산라벨출력 : 'frmBarcodePrint',
    자산현황조회 : 'frmCondition',
    자산이력카드 : 'frmAssetCardReport',
    자산현황보고서 : 'frmAssetPresentStatus',
    감가상각 : 'frmDepreciation',
    감가상각_환경설정 : 'frmDepreciationSetting',
    재물조사 : 'frmSurvey',
    SELECT : 0,
    INSERT : 1,
    UPDATE : 2,
    DELETE : 3,
    EXCEL : 4
}

export const FONT_AWESOME = {
    PEN : 0,
    PLUS : 1,
    MINUS : 2,
    CHECK : 3,
    MAGNIFYING_GLASS : 4,
    PRINT : 5,
    LAPTOP : 6,
    COLUMNS : 7,
    CALENDAR_MINUS : 8,
    TRASH_CAN : 9,
    PEN_TO_SQUARE : 10,
    COPY : 11,
    ELLIPSIS : 12,
    BARS : 13,
    FILE_EXCEL : 14,
    TRASH_CAN_ARROW_UP : 15,
    ANGLE_UP : 16,
    ANGLES_UP : 17,
    ANGLE_DOWN : 18,
    ANGLES_DOWN : 19,
    RIGHT_FROM_BRACKET : 20,
    COINS : 21,
    MAGNIFYING_GLASS_PLUS : 22,
}


export const BROWSER_NAME = {
    IE : 0,
    CHROME_EDGE : 1,
    ORIGINAL_EDGE : 2,
    OPERA : 3,
    FIREFOX : 4,
    SAFARI : 5,
    CHROME : 6,


    ETC : 9999   
}


// states = Object.freeze(states);


