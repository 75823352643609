const storeObj = {
    state: {
        openMenuKey : '',
        testCount : 0,
        testObject : {
            first : 1,
            second : 2,
        },
        leftMenuScrollTop : 0,
        authorities : {
            // frmBarcodePrint : [1,0,-1,-1,-1],
            // frmTest : [0,0,-1,-1,-1]
        },
        loginInfo : {
            userName : '',
            levelName : '',
        },
        signalAssetMngRefresh : false
    },
    mutations: {
        changeOpenMenuKey(state, openMenuKey){
            state.openMenuKey = openMenuKey;
        },
        increaseCount (state){
            state.testCount++;
        },
        changeObject(state, newObject){
            state.testObject = newObject;
        },
        setLeftMenuScrollTop(state, scrollTop){
            state.leftMenuScrollTop = scrollTop;
        },
        setAuthorities(state, newAuthorities){
            state.authorities = newAuthorities;
        },
        setLoginInfo(state, newLoginInfo){
            state.loginInfo = newLoginInfo;
        },
        refreshAssetMng(state){
            state.signalAssetMngRefresh = !state.signalAssetMngRefresh;
        }
        
    },
    actions : {

    },
    getters: {
        curObjectFirst(state){
            console.log('curObjectFirst');
            return state.testObject.first;
        },
    }
};
export default storeObj;