<template>
    <ModalBack>
        <div class="company_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px 8px 0px; font-weight: bold;" >기말장부가액</div>
            <div style="height:10px;"></div>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :plist="list" 
                    :pcolumn="column" 
                ></CommonTable>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import {NUMBER_STYLE} from '@/util/Constants.js';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import moment from 'moment';

export default {
    name : 'AssetDeprePriceDetail',
    props: {
        hideModal : Function,
        transferData : {
            type : Object,
            default : () => {}
        }
    },
    components : {
        ModalBack, CommonModalCloseBtn, CommonTable
    },
    data(){
        return {
            list : [],
            column: [
                { key: "column", name: "", w: "10px"},
                { key: "year", name: "연도", w: "110px"},
                { key: "endPrice", name: "기말장부가액", w: "110px", comma : true, style :NUMBER_STYLE },
            ],

        }
    },
    methods : {
        getDeprePriceDetail(){
            const parameters = {};
            parameters.conditions = "np_assetCode";
            parameters.searchWords = this.transferData.assetCode;
            parameters.page = 1;
            parameters.sizePerPage = 100;
            parameters.deleted = 0;
            parameters.dtEnd = moment().format('YYYY-MM-DD HH:mm:ss');

            axios.get('/api/depreciation/spec/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data.depreciations;
                    if(data.content.length === 0) {
                        this.list = [];
                        return;
                    }
                  
                    this.getDepreciationTotalHistory(data.content[0], parameters.dtEnd);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getDepreciationTotalHistory(data, dtEnd){
            const totalHistoryData = data;
            const parameters = {};
            parameters.buyDate =  totalHistoryData.buyDate.length === 10 ? totalHistoryData.buyDate + ' 00:00:00' : totalHistoryData.buyDate;
            parameters.buyPrice = totalHistoryData.buyPrice;
            parameters.scrapPrice = totalHistoryData.scrapPrice;
            parameters.depreciation = totalHistoryData.depreciation;
            parameters.durableYear = totalHistoryData.durableYear;
            parameters.depreRate = totalHistoryData.depreDrate;
            parameters.depreRate1 = totalHistoryData.depreSRate;
            parameters.ratePoint = totalHistoryData.ratePoint;
            parameters.dtEnd = dtEnd;

            axios.get('/api/depreciation/spec/totalhistory', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data.depreSpceTotalHistory;
                    this.list = data;
                    console.log(data);

                }
            }).catch(res => {
                console.log(res);
            });
        }
    },
    created(){
        this.getDeprePriceDetail();
    }
}
</script>
<style scoped>
        .company_register_wrap{
    background-color:white;
    max-width:100%;
    max-height:100%;
    min-width:300px; 
    min-height:300px;
    width:400px;
    height:500px;
    padding:38px;
    display:flex;
    overflow:auto;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
</style>