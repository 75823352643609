<template >
    <ModalBack>
        <div class="larbel_priting_wrap">
            <CommonModalCloseBtn :hideModal="closePrint"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px 8px 0px; font-weight: bold;" >프린터출력</div>

            <div class="asset_info_contents" style="height:205px;">
                <table class="asset_info_tb" style="margin-top:25px;">
                    <colgroup>
                        <col>
                    </colgroup>
                    <tr>
                        <td>
                            <div class="label required">장당출력매수</div>
                            <div style="display:flex; flex-direction:row;">
                                <CommonInput v-model="count" style="width:45px;" mode="number" :isReadonly="isReadOnly"></CommonInput>
                                <a href="javascript:void(0)" class="search_btn" @click="increase"><img :src="require('@/assets/btn_down_arrow.png')" style="transform:rotate(180deg);"></a>
                                <a href="javascript:void(0)" class="search_btn" @click="decrease"><img :src="require('@/assets/btn_down_arrow.png')"></a>
                            </div>
                        </td>
                    </tr>
                    <tr class="margin" style="height:20px;"><td ></td></tr>
                    <tr>
                        <td>
                            <div class="label">라벨출력 <span style="padding-left:10px;color:#0066fd;">{{displayText}}</span></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style="border:1px solid #cbdaf4; border-radius:5px; height:20px;width:267px;"><div style="background-color: #cbdaf4;height:100%;" :style="{width : completePercent}"></div></div>
                        </td>
                    </tr>
                </table>
                <div style="height:20px;"></div>
            </div>
            <div class="asset_bottom_btns">
                <button class="btn_ok" @click="print" v-if="!isComplete">{{isProgressing ? '일시정지' : '라벨출력'}}</button>
                <button class="btn_ok" v-else style="background-color:#efefef; color:black;">일시정지</button>
                <button class="btn_canel" @click="closePrint" style="margin-left:7px;">종료</button>
            </div>
        </div>

    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import TTP_PRINTER from '@/modules/TTP_PRINTER.js'

export default {
    name : 'LabelPrinting',
    components : {
        ModalBack, CommonModalCloseBtn, CommonInput
    },
    props : {
        hideModal : Function,
        transferData : {
            type : Array,
            default : () => []
        }
    },
    data(){
        return {
            isProgressing : false,
            count : '1',
            lastIndex : 0,
            lastCount : -1,
            isReadOnly : false,
            curCount : 0,
            totalCount : 0,
            isComplete : false
        }
    },
    computed : {
        displayText(){
            return this.curCount + "/" + this.totalCount;
        },
        completePercent(){
            const str = parseInt(this.curCount / parseInt(this.$commonFunc.uncomma(this.totalCount)) * 100) + "%";
            return str;
        }
    },
    methods : {
        increase(){
            if(this.isReadOnly) return;
            const nextCount = parseInt(this.$commonFunc.uncomma(this.count)) + 1;
            this.count = this.$commonFunc.comma(nextCount);
            this.totalCount = this.$commonFunc.comma(this.transferData.length * nextCount);
        },
        decrease(){
            if(this.isReadOnly) return;
            if(this.count == 1) {
                // alert('매수는 1장 이상만 가능합니다.');
                return 
            }
            const nextCount = parseInt(this.$commonFunc.uncomma(this.count)) - 1;
            this.count = this.$commonFunc.comma(nextCount);
            this.totalCount = this.$commonFunc.comma(this.transferData.length * nextCount);
        },
        async print(){
            this.isReadOnly = true;

            if(this.isProgressing){
                this.isProgressing = false;
                return; 
            }

            this.isProgressing = true;
            const repeatCnt = parseInt(this.$commonFunc.uncomma(this.count));

            for(let i = this.lastIndex; i < this.transferData.length; i++){
                this.lastIndex = i;
                for(let j = this.lastCount + 1; j < repeatCnt; j++){
                    if(!this.isProgressing) return;
                    this.lastCount = j;
                    this.curCount++;
                    if(!(await this.printDelayed(this.transferData[i]))){
                        alert('출력중 오류가 발생했습니다.');
                        // this.hideModal;
                        return 
                    }
                }
                this.lastCount = -1;
            }

            this.isComplete = true;
        },
        async printDelayed(item){
            const joinStr = [item.field2, item.modelName];
            let str = joinStr.join(',');
            if(str === ",") str = ''; 
            const items = [
                {
                    barcode : item.barcode,
                    items : [ 
                        item.groupName ? item.groupName : '', 
                        item.itemName ? item.itemName : '', 
                        item.assetCode ? item.assetCode : '', 
                        item.assetName ? item.assetName : '', 
                        str ? str : '', 
                        item.buyDate ? item.buyDate : ''
                    ]
                }
            ]
            
            // return new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         console.log(items);
            //         resolve(true);
            //     }, 500);
            // })

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(TTP_PRINTER.Print(items));
                }, 500);
            })
        },
        closePrint(){
            if(this.isProgressing){
                this.isProgressing = false;
                setTimeout(() => {
                    this.hideModal();
                }, 500)
            }else {
                this.isProgressing = false;
                this.hideModal();
            }
        }
    },
    created(){
        this.totalCount = this.$commonFunc.comma(this.transferData.length);

        
    }
}
</script>
<style scoped>
    .larbel_priting_wrap{
    background-color:white;
    width:350px;
    height:350px;
    padding:38px;
    display:flex;
    overflow:auto;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
    
}
.asset_info_contents{
    flex:1;
}

.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}

.asset_bottom_btns{text-align:right;border-top:1px solid #e6e7ec; padding-top:14px;}
.btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>