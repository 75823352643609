<template >
    <button class="common_search_btn" @click="clickAction"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
</template>
<script>
export default {
    name : 'CommonSearchBtn',
    props: {
        clickAction : {
            type : Function,
            default : () => {}
        }
    }
}
</script>
<style scoped>
    .common_search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; 
  cursor:pointer;
  width:150px;
  white-space: nowrap;
  padding: 10px 12px;
  border:1px solid #e6e7ec;
  display:inline-block;

  vertical-align:bottom
}
</style>