<template>
    <TableSearchPanel :is-show-right-panel="true" :is-show-search-panel="true" :is-show-table-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="분류별 감각상각총괄표">
                <CommonAddBtn title="계정과목관리" :isShowPlus="false" :clickAction="() => {isShowDepRegister = true}" :style="{marginRight: (isAllowedForSettingSelect ? '150px' : '')}"></CommonAddBtn>
                <CommonAddBtn v-if="isAllowedForSettingSelect" title="감가상각 환경설정" :clickAction="() => {isShowDepreSetting = true}" :isShowPlus="false"></CommonAddBtn>
            </PageHeader>
        </template>
        <template v-slot:search_panel>
            <div style="display:flex;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label><span style="color:#fe7681">감가상각 시행 월</span></template>
                    <template v-slot:content>
                        <CommonSelect :list="years" style="" :value="thisYear" :detectChange="(value) => {thisYear = value}"></CommonSelect>
                        <CommonSelect :list="months" style="margin-left:19px;width:130px;" :value="thisMonth" :detectChange="(value) => {thisMonth = value}"></CommonSelect>
                    </template>
                </LabelPanel>
                 <LabelPanel style="width:300px; margin-left:19px;">
                    <template v-slot:label>분류구분</template>
                    <template v-slot:content>
                        <div style="height:40px; display: flex;align-items: center;">
                            <CommonRadio name="depreType" v-model="depreType" :initVal="CODE_TYPE.ACCOUNT" label="계정과목" ></CommonRadio>
                            <CommonRadio name="depreType" v-model="depreType" :initVal="CODE_TYPE.ITEM" label="재산구분" style="margin-left:10px;"></CommonRadio>
                            <CommonRadio name="depreType" v-model="depreType" :initVal="CODE_TYPE.LOC" label="위치" style="margin-left:10px;"></CommonRadio>
                            <CommonRadio name="depreType" v-model="depreType" :initVal="CODE_TYPE.GROUP" label="부서" style="margin-left:10px;"></CommonRadio>
                        </div>
                    </template>
                </LabelPanel>
              
            </div>
            <div style="display:flex;margin-top:12px;">
                 <LabelPanel style="width:233px;">
                    <template v-slot:label>재산구분</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="itemName" :clickInput="(data) => {isShowItemSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>위치</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="locName" :clickInput="(data) => {isShowLocSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>부서</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="groupName" :clickInput="(data) => {isShowGroupSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:5px;">
                    <template v-slot:label>&nbsp;</template>
                    <template v-slot:content>
                        <button class="search_btn" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
                    </template>
                </LabelPanel>
            </div>
        </template>
         <template v-slot:table_panel>
            <BatchBtns 
                :isShowColumnSetting="false"
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :isShowExcelDownload="isAllowedForExcel"
                :downloadExcel="downloadExcel"
                >
                <template v-slot:left_panel><span style="font-size:15px; font-weight:bold;color:#969696;">상각기간</span><span style="font-size:15px;color:#0066fd; font-weight:bold; margin-left:10px;">{{deprePeriod}}</span></template>
            </BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable :plist="list" :pcolumn="column"></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>

         </template>
        <template v-slot:etc>
            <DepreciationRegister v-if="isShowDepRegister" :hideModal="hideDepRegister"></DepreciationRegister>
            <LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {locName = (data.text !== '위치코드' ?  data.text : ''); locCode = data.value}"></LocSearch>
            <GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {groupName = (data.text !== '부서코드' ?  data.text : ''); groupCode = data.value}"></GroupSearch>
            <ItemSearch v-if="isShowItemSearch" :hideModal="() => {isShowItemSearch = false}" :completeSave="(data) => {itemName =  (data.text !== '재산구분코드' ? data.text : ''); itemCode = data.value}"></ItemSearch>
            <DepreciationSetting v-if="isShowDepreSetting" :hideModal="() => {isShowDepreSetting = false}" ></DepreciationSetting>

        </template>
    
    </TableSearchPanel>
</template>

<script>

import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import ItemSearch from '@/components/modal/ItemSearch.vue';
import DepreciationRegister from '@/components/modal/DepreciationRegister.vue';
import CommonRadio from '@/components/common/CommonRadio.vue';
import DepreciationSetting from '@/components/modal/DepreciationSetting.vue';
import { states,AUTH,  CODE_TYPE, NUMBER_STYLE} from '@/util/Constants.js';

import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import moment from 'moment';
import _ from 'lodash'
export default {
    name: 'DepreciationTotal',
    components : {
        PageHeader, TableSearchPanel, CommonTable, ColumnSetting, BatchBtns, LabelPanel, CommonInput, CommonSelect, 
        CommonAddBtn, DepreciationRegister, CommonRadio, LocSearch, GroupSearch, ItemSearch, DepreciationSetting,
    },
    data(){
        return {
            CODE_TYPE : CODE_TYPE,
            itemName : '',
            locName : '',
            groupName : '',
            itemCode : '',
            locCode : '',
            groupCode : '',
            isShowItemSearch : false,
            isShowLocSearch : false,
            isShowGroupSearch : false,
            isShowColumnSettings : false,
            isShowDepreSetting : false,
            startDate : '',
            endDate : '',
            buyDate : '',
            isShowCalendar : false,
            isBuyDateChecked : false,
            isShowDepRegister : false,
            deprePeriod: '2022-01-01 ~ 2022-04-30',
            isShowDepTotalHistory : false,
            depreType : CODE_TYPE.ACCOUNT,
            thisYear : '',
            thisMonth : '',
            list: [],
            column: [
                { key: "col1", name: "대분류", w: "120px"},
                { key: "col2", name: "중분류", w: "100px"},
                { key: "col3", name: "소분류", w: "100px" },
                { key: "buyPrice", name: "취득가액", w: "100px", comma:true},
                { key: "startDeprePrice", name: "기초상각누계", w: "100px", comma:true },
                { key: "deprePrice", name: "상각액", w: "100px", comma:true },
                { key: "endDeprePrice", name: "기말상각누계", w: "100px", comma:true },
                { key: "startPrice", name: "기초장부가액", w: "100px", comma:true },
                { key: "endPrice", name: "기말장부가액", w: "100px", comma:true },
            ],
            paging : {},
            searchColumns: [
                {name : '선택', value : '0'},
                {name : '자산코드', value : '1'},
                {name : '자산명칭', value : '2'},
                {name : '모델명', value : '3'},
                {name : '시리얼', value : '4'},
                {name : '자산코드', value : '5'},
                {name : '규격', value : '6'},
                {name : '취득가액/자존가액', value : '7'},
                {name : '담당자/사용자', value : '8'},
                {name : '구매처', value : '9'},
                {name : '바코드', value : '10'},
                {name : '추가항목1', value : '11'},
                {name : '추가항목2', value : '12'},
                {name : '추가항목3', value : '13'},
                {name : '추가항목4', value : '14'},
                {name : '추가항목5', value : '15'},
                {name : '추가항목6', value : '16'},
                {name : '추가항목7', value : '17'},
                {name : '추가항목8', value : '18'},
            ],
            years : [],
            months : [
                {name : '1', value : '1'},
                {name : '2', value : '2'},
                {name : '3', value : '3'},
                {name : '4', value : '4'},
                {name : '5', value : '5'},
                {name : '6', value : '6'},
                {name : '7', value : '7'},
                {name : '8', value : '8'},
                {name : '9', value : '9'},
                {name : '10', value : '10'},
                {name : '11', value : '11'},
                {name : '12', value : '12'}
            ],
            totalHistoryData : {},
            startMonth : '',
    
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.EXCEL)
        },
        isAllowedForSettingSelect(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각_환경설정, AUTH.SELECT)
        },
        isAllowedForSettingInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각_환경설정, AUTH.INSERT)
        },
    },
    methods : {
        showCalendar(){
            this.isShowCalendar = true;
        },
        hideCalendar(){
            this.isShowCalendar = false;
        },
        completSelectDate(startDate, endDate){
            this.startDate = startDate;
            this.endDate = endDate;
            this.buyDate = startDate + ' ~ ' + endDate;
            this.hideCalendar();
        },
        toggleBuyDateCheck(){
            this.isBuyDateChecked = !this.isBuyDateChecked;
        },
        deleteList(){

        },
        hideDepRegister(){
            this.isShowDepRegister = false;
        },
        hideDepTotalHistory(){
            this.isShowDepTotalHistory = false;
        },
        search(){
            this.getDeprecationTotal();
        },
        getDeprecationTotal(){
            let searchName = "";

            const parameters = this.getParameters();
            axios.get('/api/depreciation/spec/total/cate', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data;

                    if(this.depreType === CODE_TYPE.ACCOUNT){
                        this.makeAccountStructure(data.reportCnt);
                    }else {
                        let [column, list] = this.$commonFunc.makeStatisStructure(data.itemKeys, data.reportStructure, data.reportCnt, searchName, data.parentCodes);
                        this.list = list;
                        this.column = column;
                    }
                    this.startMonth = data.startMonth;
                    const startMonthStr = (this.startMonth < 10 ? '0' + this.startMonth : this.startMonth)
                    const thisMonthLastMoment = moment(this.thisYear + (this.thisMonth < 10 ? '0' + this.thisMonth : this.thisMonth) + '01', 'YYYYMMDD').endOf('months');

                    if(this.thisMonth >= this.startMonth){
                        this.deprePeriod = this.thisYear + '-' + startMonthStr + '-' + '01' + ' ~ ' + thisMonthLastMoment.format('YYYY-MM-DD');
                    }else {
                        this.deprePeriod = (this.thisYear - 1) + '-' + startMonthStr + '-' + '01 ~ '+ thisMonthLastMoment.format('YYYY-MM-DD');
                    }
                   
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const thisMonthLastMoment = moment(this.thisYear + (this.thisMonth < 10 ? '0' + this.thisMonth : this.thisMonth) + '01', 'YYYYMMDD').endOf('months');

            const parameters = {};
            parameters.depreType = this.depreType;
            parameters.dtEnd = thisMonthLastMoment.format('YYYY-MM-DD HH:mm:ss')

            let conditions = [];
            let searchWords = [];

            if(this.locName != ''){
                conditions.push('np_locName');
                searchWords.push(this.locName);
            }

            if(this.itemName != ''){
                conditions.push('np_itemName');
                searchWords.push(this.itemName);
            }

            if(this.groupName != ''){
                conditions.push('np_groupName');
                searchWords.push(this.groupName);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            return parameters;
        },
        makeAccountStructure(reportCnt){
            const column = [
                { key: "accountCode", name: "계정코드", w: "120px"},
                { key: "accountName", name: "계정명", w: "100px"},
                { key: "depreciation", name: "감가상각방법", w: "100px" },
                { key: "buyPrice", name: "취득가액", w: "100px", comma:true, style : NUMBER_STYLE},
                { key: "startDeprePrice", name: "기초상각누계", w: "100px", comma:true, style : NUMBER_STYLE },
                { key: "deprePrice", name: "상각액", w: "100px", comma:true, style : NUMBER_STYLE },
                { key: "endDeprePrice", name: "기말상각누계", w: "100px", comma:true, style : NUMBER_STYLE },
                { key: "startPrice", name: "기초장부가액", w: "100px", comma:true, style : NUMBER_STYLE },
                { key: "endPrice", name: "기말장부가액", w: "100px", comma:true, style : NUMBER_STYLE },
            ]

            this.column = column;
            this.list = reportCnt;
        },
        downloadExcel(){
            this.$commonFunc.downloadExcel("분류별 감가상각 총괄표");
        }
        // makeStatisStructure(itemKeys, reportStructure, reportCnt, searchName, parentCodes){
        //     const reportCntHashed = {};
            
        //     let totalItemCnt = 0;
        //     const totalItemCntArr = {};
        //     for(let i = 0; i < itemKeys.length; i++){
        //         totalItemCntArr[itemKeys[i]] = 0;
        //     }

        //     for(const key in reportCnt){
        //         const each = reportCnt[key];
        //         reportCntHashed[each.code + each.name] = each.cnt;
        //         totalItemCntArr[each.name] += each.cnt;
        //         totalItemCnt += each.cnt;
        //     }

        //     const hasOwnProperty = Object.prototype.hasOwnProperty;

        //     //0. 최대 레벨
        //     const levelMaxObj = _.maxBy(reportStructure, 'level');
        //     if(!levelMaxObj){
        //         this.list = [];
        //         return;
        //     } 
        //      let maxLevel = levelMaxObj.level;
        //      //max level을 바꾸면 분류가 추가되서 나옴.
        //     // maxLevel = 3;

        //     //1. 값입력
        //     for(let i = 0; i < reportStructure.length; i++){
        //         const curRow = reportStructure[i];
        //         for(let i = 1; i < maxLevel + 1; i++){
        //             curRow['level' + i] = '';
        //         }
        //         curRow['level' + curRow.level] = curRow.name;

        //         let totalCnt = 0;
        //         for(let j = 0; j < itemKeys.length; j++){
        //             if(hasOwnProperty.call(reportCntHashed, curRow.code + itemKeys[j])){
        //                 curRow[itemKeys[j]] = reportCntHashed[curRow.code + itemKeys[j]];
        //             }else { 
        //                 curRow[itemKeys[j]] = 0
        //             }
        //             totalCnt += curRow[itemKeys[j]];
        //         }

        //         curRow['총계'] = totalCnt;
        //     }

        //     //2. 레벨별 소계 합계 행 추가.
        //     const insertList = []


        //     const insert = (index, level, cntArr) => {
        //         const row = {}
        //         row.index = index + 1;
        //         row.level = level; 
        //         for(let i = 1; i < maxLevel + 1; i++){
        //             if(i > level + 1){
        //                 row['level' + i] = {rowspan : 1, value : ''};
        //             } else {
        //                 row['level' + i] = '';
        //             }
        //         }

        //         if(level === 1){
        //             row['level' + (level + 1)] = '[합계]';
        //         }else {
        //             row['level' + (level + 1)] = '[소계]';
        //         }
        //         row['level' + (level)] = {rowspan : 0};
                
        //         insertList.push(row);


        //         let totalCnt = 0;
        //         for(let i = 0; i < itemKeys.length; i++){
        //             row[itemKeys[i]] = cntArr[i];
        //             totalCnt += cntArr[i];
        //             cntArr[i] = 0;
        //         }

        //         row['총계'] = totalCnt;
        //     }

        //     const cntArr = [];
        //     for(let i = 0; i < itemKeys.length; i++){
        //         cntArr.push(0);
        //     }

        //     for(let level = maxLevel - 1; level > 0; level--){

        //         let deepStart = false;
                
        //         for(let i = 0; i < reportStructure.length; i++){
        //             const curRow = reportStructure[i];

        //             for(const key in itemKeys){
        //                 cntArr[key] += curRow[itemKeys[key]];
        //             }

        //             //레벨변화하는 부분에 행을 넣어줌. 혹은 마지막 라인
        //             if(deepStart && (i === reportStructure.length - 1 || reportStructure[i + 1].level <= level)){
        //                 insert(i, level, cntArr);
        //                 deepStart = false;
        //                 continue;
        //             } 

        //             if(curRow.level < level){
        //                 //행만들어줌.
        //                 insert(i, level, cntArr);
        //             }else if(level === curRow.level){
        //                 //다음 행이 있고, 레벨이 높다면 이 그룹의 마지막에 넣어줌.
        //                 if(i !== reportStructure.length - 1 && reportStructure[i + 1].level > level){
        //                     deepStart = true;
        //                     continue;
        //                 //없다면 여기에 넣어줌. 마지막라인.
        //                 } else {
        //                     insert(i, level, cntArr);
        //                 }
        //             }
        //         }
        //     }

        //     let sortedInsertList = _.sortBy(insertList, ['index']).reverse();
        //     // console.log(sortedInsertList);
        //     // console.log(window.copyObject(insertList));

        //     //3. rowspan 입력
        //     for(let i = 0; i < sortedInsertList.length; i++){
        //         let curRow = sortedInsertList[i];
        //         reportStructure.splice(curRow.index, 0, curRow);
        //     }

        //     // console.log(reportStructure);
            

        //     if(searchName){
        //         let searchIndex = 0;
        //         for(let i = 0; i < reportStructure.length; i++){
        //             if(reportStructure[i].name === searchName) {
        //                 searchIndex = i;
        //                 break;
        //             }
        //         }

        //         reportStructure.splice(0, searchIndex) //찾은 부서의 index
        //     }

        //     for(let level = 1; level < maxLevel; level++){
        //         let deepStart = false;
        //         let index = -1;
        //         let cnt = 0;
        //         const levelName = 'level' + level;
        //         for(let i = 0; i < reportStructure.length; i++){
        //             const curRow = reportStructure[i];
        //             if(deepStart){
        //                 if(typeof curRow[levelName] === 'object'){
        //                     deepStart = false;
        //                     cnt++;
        //                     reportStructure[index][levelName] = {rowspan : cnt, value : reportStructure[index][levelName]} 
        //                     reportStructure[index].style = {borderRight : '1px solid #e6e7ec'};
        //                     index = -1;
        //                     cnt = 0;
        //                 }else {
        //                     cnt++;
        //                     reportStructure[i][levelName] = {rowspan : 0}
        //                 }
        //             }else {
        //                 deepStart = true;
        //                 if(typeof curRow[levelName] === 'string' && (curRow[levelName] === '[합계]' || curRow[levelName] === '[소계]')) deepStart = false;
        //                 if(typeof curRow[levelName] === 'object' && (curRow[levelName].rowspan === 1)) deepStart = false;
        //                 cnt = 1;
        //                 index = i;
        //             }
        //         }
        //     }

        //     //4. filter 있다면 일부 변경
        //     if(searchName){
        //         for(const key in parentCodes){
        //             const curParentCode = parentCodes[key];
        //             reportStructure[0]['level' + curParentCode.level].value = curParentCode.name;
        //         }
        //         // reportStructure[0].level1.value = '테스트부서0';
        //         // reportStructure[0].level2.value = '테스트부서1-2';
        //     }

        //     // console.log(reportStructure);
        //     //총계 마지막줄
        //     let totalData = {level1 : '[총계]', 총계 : totalItemCnt};
        //     for(const key of itemKeys){
        //         totalData[key] = totalItemCntArr[key];
        //     }
        //     reportStructure.push(totalData);

        //     this.column = [];
        //     for(let i = 1; i <= maxLevel; i++){
        //         this.column.push({key : 'level' + i, name : i + '차분류', w : '120px'});
        //     }

        //     for(const key of itemKeys){
        //         this.column.push({key : key, name : key, w : '120px', comma : true, style : NUMBER_STYLE});
        //     }
        //     this.column.push({key : '총계', name : '총계', w : '120px', comma : true, style : NUMBER_STYLE});

        //     this.list = reportStructure;
        // }
    },
    created(){
        const today = moment().format('YYYY-MM-DD');
        this.startDate = today;
        this.endDate = today;
        this.buyDate = today + ' ~ ' + today;

        const thisYear = parseInt(moment().format('YYYY'));
        this.thisYear = thisYear;
        this.thisMonth = parseInt(today.substr(5, 2)) + '';

        let years = [];
        for(let i = 0; i < 5; i++){
            years.push({name : thisYear - i, value : (thisYear - i) + ''});
        }

        this.years = years;

        this.getDeprecationTotal();

        // this.list = arr;
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
    
}
</script>

<style scoped>
    .search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>
