<template>
  <div class="period_cal_wrap" ref="periodWrap">
    <div class="left_panel">
      <div class="period" @click="setToday">오늘</div>
      <div class="period" @click="setThisWeek">이번주</div>
      <div class="period" @click="setThisMonth">이번달</div>
      <div class="period" @click="setLastDays(7)">지난7일</div>
      <div class="period" @click="setLastDays(30)">지난30일</div>
      <div class="period" @click="setLastWeek">지난주</div>
      <div class="period" @click="setLastMonth">지난달</div>
      <div class="period" @click="setThisYear">올해</div>
      <div class="period" @click="setLastYear">지난해</div>
    </div>
    <div class="right_panel">
      <div class="contents_wrap">
        <div style="width:296px;padding:20px 0px 20px 30px;"><Calendar :select-date-callback="detectStartDateChange" :start-date="startDate" :active-start-date="activeStartDate" :active-end-date="activeEndDate" :detect-change-target-date="signalTargetDateChange" mode="start"></Calendar></div>
        <div style="flex:1;"></div>
        <div style="width:296px;;padding:20px 30px 20px 0px;"><Calendar :select-date-callback="detectEndDateChange" :start-date="endDate" :active-start-date="activeStartDate" :active-end-date="activeEndDate" :detect-change-target-date="signalTargetDateChange" mode="end"></Calendar></div>
      </div>

      <div class="input_wrap">
        <input type="text" class="date_input" v-model="startDate"><span style="padding: 12px 8px; color:#a0a3b6">-</span><input type="text" class="date_input" v-model="endDate">
        <div style="margin-left:auto;"><button class="btn btn_cancel" @click="cancelCallback">취소</button><button class="btn btn_ok" @click="complete">확인</button></div>
      </div>
    </div>
  
  </div>
</template>

<script>
import moment from 'moment';
import Calendar from '@/components/common/Calendar.vue';
export default {
  name: 'PeriodCalendar',
  components : {Calendar},
  props: {
    completeCallback : Function,
    initStartDate : String,
    initEndDate : String,
    cancelCallback : Function,
    testname: Object
  },
  data (){
    return {
      today : '',
      activeStartDate : null,
      activeEndDate : null,
      signalTargetDateChange : false,
      startDate : '',
      endDate : '',
    }
  },
  methods : {
    detectStartDateChange(dateStr){
      this.activeStartDate = dateStr;
      this.signalTargetDateChange = !this.signalTargetDateChange
      this.startDate = dateStr;
    },
    detectEndDateChange(dateStr){
      this.activeEndDate = dateStr;
      this.signalTargetDateChange = !this.signalTargetDateChange
      this.endDate = dateStr;
    },
    setToday(){
      this.setPeriodDate(this.today, this.today)
    },
    setThisWeek(){
      const periodStart = moment().startOf('week').format('YYYY-MM-DD');
      const periodEnd = moment().endOf('week').format('YYYY-MM-DD');
      this.setPeriodDate(periodStart, periodEnd)
    },
    setThisMonth(){
      const periodStart = moment().startOf('month').format('YYYY-MM-DD');
      const periodEnd = moment().endOf('month').format('YYYY-MM-DD');
      this.setPeriodDate(periodStart, periodEnd)
    },
    setLastDays(days){
      const periodStart = moment().subtract(days, 'days').format('YYYY-MM-DD');
      const periodEnd =  moment().format('YYYY-MM-DD');
      this.setPeriodDate(periodStart, periodEnd)
    },
    setLastWeek(){
      const periodStart = moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DD');
      const periodEnd = moment().endOf('week').subtract(7, 'days').format('YYYY-MM-DD');
      this.setPeriodDate(periodStart, periodEnd)
    },
    setLastMonth(){
      const periodStart = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const periodEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      this.setPeriodDate(periodStart, periodEnd)
    },
    setThisYear(){
      const periodStart = moment().startOf('year').format('YYYY-MM-DD');
      const periodEnd = moment().endOf('year').format('YYYY-MM-DD');
      this.setPeriodDate(periodStart, periodEnd)
    },
    setLastYear(){
      const periodStart = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
      const periodEnd = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
      this.setPeriodDate(periodStart, periodEnd)
    },
    setPeriodDate(startDate, endDate){
      this.activeStartDate = startDate;
      this.activeEndDate = endDate;
      this.startDate = startDate;
      this.endDate = endDate;
      this.signalTargetDateChange = !this.signalTargetDateChange
    },
    complete(){
      this.completeCallback(this.startDate, this.endDate)
    }
  },
  mounted(){
    let parentEl = this.$refs['periodWrap'].parentElement;
    
    let offset = window.innerWidth - (parentEl.getBoundingClientRect().x + 770 + 10);
    if(offset < 0){
      parentEl.style.transform = 'translate(' + offset + 'px, 47px)' ;//offset + "px";
    } else {
      parentEl.style.transform = 'translateY(47px)' ;//offset + "px";
    }
  },
  created(){
     
    this.today = moment().format('YYYY-MM-DD')
    let pStartDate = this.today;
    let pEndDate = this.today;
    if(this.initStartDate != null && this.initEndDate != null){
      pStartDate = this.initStartDate;
      pEndDate = this.initEndDate;
    }

    this.activeStartDate = pStartDate;
    this.activeEndDate = pEndDate;
    this.startDate = pStartDate;
    this.endDate = pEndDate;
  }
}
</script>

<style scoped>
.period_cal_wrap{display:flex;width:770px; height:414px;box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.41) ; }
.left_panel{width:145px;border-right:1px solid #e6e7ec; padding:32px;}
.right_panel{display:flex; flex:1; flex-direction: column;;}
.contents_wrap{flex:1; display:flex;}
.input_wrap{display:flex; padding:15px;border-top:1px solid #e6e7ec}
.date_input{font-size:16px;;    padding: 4px;
    width: 128px;text-align:center;border-radius:4px; border:1px solid #e6e7ec;font-weight:bold;}
.btn{border:1px solid #e6e7ec;width:81px;height:41px; border-radius:4px;cursor:pointer;font-weight:bold;font-size: 16px;}
.btn_cancel{background-color:white;}
.btn_ok{background-color:#0066fd;color:white;margin-left:9px;}
.period{font-size:16px;font-weight:bold;color:#969696;cursor:pointer;}
.period + .period{
  margin-top:25px;
}

</style>
