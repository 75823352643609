<template>
    <div>
        <div class="label" :class="[isRequired ? 'required' : '']"><slot name="label" ></slot></div>
        <div class="row_flex">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name : 'LabelPanel',
    props:['isRequired'],
    created(){
    
    }
}
</script>

<style scoped>
.row_flex{display:flex;position:relative; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;position:relative;}
.required{color:#fe7681;;}
</style>