<template>
  <div style="font-size:24px;font-weight:bold;padding-bottom:20px;">{{title}}<slot></slot></div>
</template>

<script>

export default {
  name: 'PageHeader',
  props: ['title'],
  
}
</script>

<style scoped>
</style>