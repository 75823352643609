<template>
    <TableSearchPanel :is-show-right-panel="true" :is-show-top-panel="true" :is-show-table-panel="true" :is-show-search-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="거래처관리">
                <CommonAddBtn v-if="isAllowedForInsert" title="거래처등록" :clickAction="() => {curMode = MODE.INSERT; transferData = {}; isShowCompanyRegister = true; } "></CommonAddBtn>
            </PageHeader>
        </template>
        <template v-slot:search_panel>
            <LabelPanel style="width:300px; ">
                <template v-slot:label>검색어</template>
                <template v-slot:content>
                    <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                    <CommonSearchBtn :clickAction="search" style="margin-left:4px;"></CommonSearchBtn>
                </template>
            </LabelPanel>
        </template>
        <template v-slot:table_panel>
            <BatchBtns 
                :is-show-first-checkbox="true && isAllowedForDelete" 
                :is-show-delete-btn="true && isAllowedForDelete" 
                :delete-action="deleteList" 
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :downloadExcel="downloadExcel"
                :isShowExcelDownload="isAllowedForExcel"
            ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :isShowNumbering="true"
                    :isShowCheckbox="true && isAllowedForDelete" 
                    :plist="list" 
                    :pcolumn="column" 
                    :pcustom="custom" 
                    :paging="paging" 
                    :change-page="detectChangePage" 
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="companyCode"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    :clickDoubleRow="(data) => {if(!isAllowedForUpdate) return; curMode = MODE.UPDATE; transferData = data; isShowCompanyRegister = true;}"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>

        </template>

        <template v-slot:etc>
            <div><CompanyRegister v-if="isShowCompanyRegister" :hideModal="() => {isShowCompanyRegister = false}" :curMode="curMode" :data="transferData" :completeAction="() => {search()}"></CompanyRegister></div>
        </template>

    </TableSearchPanel>
</template>
<script>
import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import CommonSearchBtn from '@/components/common/CommonSearchBtn.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import CompanyRegister from '@/components/modal/CompanyRegister.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, AUTH, SIZE_PER_PAGE} from '@/util/Constants.js';

export default {
    name : 'CompanyMng',
    components : {PageHeader, TableSearchPanel, LabelPanel, CommonSearchBtn, CommonAddBtn, BatchBtns, CommonTable, ColumnSetting, CompanyRegister, ConditionInput },
    data(){
        return {
            curMode : -1,
            MODE : MODE,
            lastPage : 1,
            lastSizePerPage: SIZE_PER_PAGE,
            lastWithoutSearchWord : '',
            searchCnt : 0,
            searchWord : '',
            savedSelectedList : {},
            transferData : {},
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },
            column: [
                { key: "companyName", name: "업체명", w: "120px"},
                { key: "chief", name: "대표", w: "100px"},
                { key: "tel", name: "전화번호", w: "140px" },
                { key: "fax", name: "팩스번호", w: "140px" },
                { key: "hp", name: "휴대폰번호", w: "140px" },
                { key: "person", name: "담당자", w: "100px" },
                { key: "businessNumber", name: "사업자번호", w: "100px" },
                { key: "zipCode", name: "우편번호", w: "100px" },
                { key: "address", name: "주소", w: "100px" },
                { key: "email", name: "이메일", w: "100px" },
                { key: "memo", name: "메모", w: "100px" },
            ],
            isShowCompanyRegister : false,
            isShowColumnSettings : false,
            searchConditions : [
                {name : '업체명', key : 'companyName', checked : true},
                {name : '대표', key : 'chief', checked : true},
                {name : '전화/팩스/휴대폰', key : 'contact', checked : true},
                {name : '사업자번호', key : 'businessNumber', checked : true},
                {name : '주소', key : 'address', checked : true},
                {name : '담당자', key : 'person', checked : true},
                {name : '메모', key : 'memo', checked : true},
            ],
            list: [

            ],
            custom : {},
            paging : {},
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.EXCEL)
        }
    },
    methods : {
        deleteList(){
            const parameters = {};
            parameters.companyCodes = Object.keys(this.savedSelectedList);

            if(!confirm(parameters.companyCodes.length + ' 개의 업체를 삭제하시겠습니까?')){
                return
            }

            axios.post('/api/base/company/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.savedSelectedList = {};
                    this.search();
                }else if(res.data.code == ResCode.Fail){
                    alert(res.data.message);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getCompanies(page, withoutSearchWord){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            
            const parameters = this.getParameters();

            axios.get('/api/base/company/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const companies = data.companies.content;
                    this.searchCnt = data.companies.totalElements;
                    data.companies.content = null;
                    this.list = companies;
                    this.paging = data.companies;   
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;

            let conditions = [];
            let searchWords = [];

            if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }
            
            return parameters;
        },
        search(){
            this.getCompanies(1, this.lastWithoutSearchWord);
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/base/company/list/excel?" + queryStr);
            location.href = this.$versionPath + "/api/base/company/list/excel?" + queryStr
        },
        enter(){
            this.search();
        },
        detectChangePage(page){
            this.getCompanies(page, this.lastWithoutSearchWord);
        },
    },
    created(){
        this.getCompanies(1, false);

        ConditionManager.setConditions(this.$options.name, this.searchConditions);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
    
</style>