<template>
  <TableSearchPanel :is-show-left-panel="true" :is-show-right-panel="true" :isShowTablePanelWithoutTopMargin="true">
    <template v-slot:pageheader>
      <PageHeader title="기초코드관리">
        <span style="float:right;height: 0px;position:relative;" v-if="isAllowedForInsert">
          <button class="add_btn" @click="setInsertMode">+ {{titles[curTab]}}등록</button>
        </span>
      </PageHeader>
    </template>

    <template v-slot:left_panel>
      <div class="tab_wrap">
        <a class="tab" :class="[curTab === 0 ? 'active' : '']" @click="changeTab(0)" :style="{}">재산구분</a>
        <a class="tab" :class="[curTab === 1 ? 'active' : '']" @click="changeTab(1)" :style="{}">위치별</a>
        <a class="tab" :class="[curTab === 2 ? 'active' : '']" @click="changeTab(2)" :style="{}">부서별</a>
      </div>
      <div style="display:flex; flex:1; flex-direciton:column;overflow:hidden; position:relative;">
        <CommonTree 
            ref="item"
            v-show="curTab === 0" 
            :key="0" 
            :p-tree-data="treeData[0]" 
            :detectSelect="(data) => {detectTreeSelect(data, CODE_TYPE.ITEM)}" 
            :multiple="false" 
            :isFirstSelected="true"
             :isDragWork="true"
            :detectDragComplete="(movedTarget, hoveredTarget, isBetween) => {detectDragComplete(CODE_TYPE.ITEM, movedTarget, hoveredTarget, isBetween)}"
            ></CommonTree>
        <CommonTree 
            ref="loc"
            v-show="curTab === 1" 
            :key="1" 
            :p-tree-data="treeData[1]" 
            :detectSelect="(data) => {detectTreeSelect(data, CODE_TYPE.LOC)}" 
            :isDragWork="true"
            :detectDragComplete="(movedTarget, hoveredTarget, isBetween) => {detectDragComplete(CODE_TYPE.LOC, movedTarget, hoveredTarget, isBetween)}"
            ></CommonTree>
        <CommonTree 
            ref="group"
            v-show="curTab === 2" 
            :key="2" 
            :p-tree-data="treeData[2]" 
            :detectSelect="(data) => {detectTreeSelect(data, CODE_TYPE.GROUP)}" 
            :isDragWork="true"
            :detectDragComplete="(movedTarget, hoveredTarget, isBetween) => {detectDragComplete(CODE_TYPE.GROUP, movedTarget, hoveredTarget, isBetween)}"
            ></CommonTree>
        <!-- <div style="position:absolute; top :0px; left : 0px; width:25px; height:150px; display:flex; flex-direction:column; height:100%; justify-content:center;">
          <div class="arrow_back" @click="goTop">
            <font-awesome-icon icon="fa-solid fa-angles-up" />
          </div>
          <div class="arrow_back" @click="goUp">
            <font-awesome-icon icon="fa-solid fa-angle-up" />
          </div>
          <div class="arrow_back" @click="goDown">
            <font-awesome-icon icon="fa-solid fa-angle-down" />
          </div>
          <div class="arrow_back" @click="goBottom">
            <font-awesome-icon icon="fa-solid fa-angles-down" />
          </div>
        </div> -->
      </div>

    </template>

    <template v-slot:table_panel_without_top_margin>
      <div style="width:230px; margin-top:10px;">
        <div v-for="each, i in baseCodeDetailList" :key="i">
          <LabelPanel :style="{marginTop: i > 0 ? '10px' : ''}">
            <template v-slot:label>{{i + 1}}차 분류</template>
            <template v-slot:content>
              <CommonSelect :list="each" :nameKey="nameKey" :valueKey="valueKey" :value="selectedValues[i]" :detectChange="(value) => {detectChange(value, i)}"></CommonSelect>
            </template>
          </LabelPanel>
          <LabelPanel v-if="i === baseCodeDetailList.length - 1" :style="{marginTop:'10px' }">
            <template v-slot:label><span class="required">재산구분</span></template>
            <template v-slot:content>
              <CommonInput v-model="curName" :signalFocus="signalFocus"></CommonInput>
            </template>
          </LabelPanel>
          <LabelPanel v-if="i === baseCodeDetailList.length - 1 && curValue !== '-1'" :style="{marginTop:'10px' }">
            <template v-slot:label><span >코드</span></template>
            <template v-slot:content>
              <CommonInput v-model="curTempCode"></CommonInput>
            </template>
          </LabelPanel>
        </div>
      </div>

      <div class="asset_bottom_btns">
        <!-- <button class="asset_bottom_btn_ok" @click="addCode">등록</button> -->
        <button v-if="(curMode === MODE.INSERT && isAllowedForInsert) || (curMode === MODE.UPDATE && curValue != '-1' && isAllowedForUpdate)" class="btns asset_bottom_btn_ok" @click="save">저장</button>
        <button v-if="curMode === MODE.UPDATE && curValue != '-1' && isAllowedForDelete" class="btns asset_bottom_btn_ok" @click="deleteCode">삭제</button>
        <button v-if="curMode === MODE.INSERT && isAllowedForInsert" class="btns asset_bottom_btn_ok" @click="cancelInsert">취소</button>
      </div>
    
    </template>
 
  </TableSearchPanel>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue';
import CommonTree from '@/components/common/CommonTree.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';

import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, states, CODE_TYPE, AUTH} from '@/util/Constants.js';

export default {
    name: 'BaseCodeMng',
    components : {
        TableSearchPanel, CommonTree, PageHeader, CommonSelect, LabelPanel, CommonInput
    },
    data(){
        return {
          MODE : MODE,
          CODE_TYPE : CODE_TYPE,
          curTab : 0,
          titles : ['재산구분', '위치', '부서'],
          rootName : ['재산구분코드', '위치코드', '부서코드'],
          valueKeys : ['itemCode', 'locCode', 'groupCode'],
          nameKeys : ['itemName', 'locName', 'groupName'],
          curTempCode : '',
          prevTempCode : '',
          curCodeType : CODE_TYPE.ITEM,
          treeData : {
            0 : [],
            1 : [],
            2 : [],
          },
          isProgressing : false,
          curSelectedData : {},
          valueKey : 'itemCode',
          nameKey : 'itemName',
          isFirstTreeGet : true,
          signalFocus : false,
          baseCodeDetailList : [],
          selectedValues : {
            0 : -1,
            1 : -1,
            2 : -1,
            3 : -1,
            4 : -1,
            5 : -1,
            6 : -1,
            7 : -1,
            8 : -1,
            9 : -1,
          },
          curMode : '',
          curValue : '',
          curName : '',
          isCodeChanged : false,
        }
    },
    computed : {
      isAllowedForInsert(){
          return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.INSERT)
      },
      isAllowedForUpdate(){
          return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.UPDATE)
      },
      isAllowedForDelete(){
          return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.DELETE)
      },
      isAllowedForExcel(){
          return this.$GlobalStore.isAllowedFor(AUTH.기초코드관리, AUTH.EXCEL)
      }
    },
    methods : {
      detectChange(value, index){
        this.isCodeChanged = true;

        //상위 분류에서 하위 분류로 이동하는지 체크
        if(index + 1 == this.curSelectedData.level && value == this.curSelectedData.value){
          alert('상위 분류를 하위분류로 이동할 수 없습니다.');
          this.$nextTick(() => {
            this.selectedValues[index] = '-1';
          });
          return;
        }

        this.selectedValues[index] = value;

        this.baseCodeDetailList.splice(index + 1);
        const valueResetStartIndex = index + 1;
        for(let i = valueResetStartIndex; i < 10; i++){
          this.selectedValues[i] = -1;
        }

        // //분류 없음일때 하위 설렉트 모두 제거
        // if(value == -1){

        // //분류 없음 외 선택시 이후꺼 제거 후 새거 생성 
        // }else {

        const parameters = {};
        parameters.level = index + 1;
        parameters.value = value;

        axios.get('/api/asset/cate/sub/' + this.curCodeType, {params : parameters}).then(res => {
          if(res.data.code == ResCode.Success){
            this.baseCodeDetailList.push(res.data.data.codes);
            this.$nextTick(() => {
              this.selectedValues[index] = value;
            });

          }
        }).catch(res => {
          console.log(res);
        });

    // }
      },
      changeTab(index){
        this.curTab = index;
        this.valueKey = this.valueKeys[index];
        this.nameKey = this.nameKeys[index];
        this.getTree(index, this.rootName[this.curTab])
      },
      detectTreeSelect(data, codeType){
        this.curMode = MODE.UPDATE;
        this.curSelectedData = data;
        this.prevName = data.text;
        const parameters = {};
        parameters.level = data.level;
        parameters.value = data.value;
        this.curName = data.text;
        this.curValue = data.value + '';
        this.curTempCode = data.tempCode;
        this.prevTempCode = data.tempCode;
        this.isCodeChanged = false;

        axios.get('/api/asset/cate/parent/' + codeType, {params : parameters}).then(res => {
          if(res.data.code == ResCode.Success){
            const data = res.data.data;
            this.baseCodeDetailList = data.baseCodeDetailList;
            const values = data.values;

            for(let i = 0; i < 10; i++){
              this.selectedValues[i] = -1;
            }

            this.$nextTick(() => {
              for(let i = 0; i < values.length; i++){
                this.selectedValues[i] = values[i];
                if(i === values.length - 1) this.selectedValues[i] = -1;
              }
            });
    
          }
        }).catch(res => {
          console.log(res);
        });

      },
      getTree(type, searchWord){
          const parameters = {};

          let path = "";
          let topName = "";
          //품목별
          if(type === 0){
              topName = "재산구분코드";
              path = "itemCode";
              this.curCodeType = CODE_TYPE.ITEM;
          //위치별
          }else if(type === 1){
              topName = "위치코드";
              path = "locCode";
              this.curCodeType = CODE_TYPE.LOC;
          //부서별
          }else if(type === 2){
              topName = "부서코드";
              path = "groupCode";
              this.curCodeType = CODE_TYPE.GROUP;
          }

          parameters.topName = topName;

          axios.get('/api/asset/cate/' + path, {params : parameters}).then(res => {
              if(res.data.code == ResCode.Success){
                  const tree = res.data.data.tree
                  if(this.isFirstTreeGet) {
                      tree[0].selected = true;
                      this.isFirstTreeGet = false;
                  }
                  this.treeData[type] = tree;
                  if(searchWord){
                    this.isCodeChanged = false;
                    this.$nextTick(() => {
                      this.$refs[this.curCodeType].__setSelectWithSearchWord(searchWord);
                    })
                  }
              }
          }).catch(res => {
              console.log(res);
          });
      },
      addCode(){
        const parameters = {};
        const data = this.curSelectedData;
        parameters.level = data.level + 1;
        parameters.parentCode = data.value;
        parameters.text = this.curName;
        parameters.type = this.curCodeType;

        const searchWord = this.curName;

        axios.post('/api/base/tree/add', this.$commonFunc.convertToForm(parameters)).then(res => {
          if(res.data.code == ResCode.Fail){
              alert(res.data.message);
          }
          this.getTree(this.curTab, searchWord);
        }).catch(res => {
            console.log(res);
        });
      },
      deleteCode(){
        if(!confirm('[' + this.curName  + ']을(를) 삭제하시겠습니까?')) return;

        const data = this.curSelectedData;
        const parameters = {};
        parameters.value = data.value;
        parameters.type = this.curCodeType;

        axios.post('/api/base/tree/remove', this.$commonFunc.convertToForm(parameters)).then(res => {
          if(res.data.code == ResCode.Success){
            this.getTree(this.curTab, this.rootName[this.curTab]);
          }else if(res.data.code == ResCode.Fail){
            alert(res.data.data.msg);
          }
        }).catch(res => {
            console.log(res);
        });
      },
    async save(){
			if(this.curMode === MODE.INSERT){
				this.addCode();
			}else if(this.curMode === MODE.UPDATE){
				this.updateCode();
			}
    },
    async updateCode(){
			let isRenameSuccess = true;

      if(this.prevTempCode !== this.curTempCode) await this.updateTempCode();
     
			if(this.curName !== this.prevName) isRenameSuccess = await this.renameCode();
			if(isRenameSuccess){
        if(this.isCodeChanged){
          this.moveCode();
        } else {
          this.getTree(this.curTab, this.curName);
        }
      } 


    },
    async updateTempCode(){
      return new Promise((resolve, reject) => {
        console.log('updateTempCocde');
        const parameters = {};
        parameters.value = this.curSelectedData.value;
        parameters.tempCode = this.curTempCode;
        parameters.type = this.curCodeType;

        axios.post('/api/base/tree/save', this.$commonFunc.convertToForm(parameters)).then(res => {
          const tt = 1;
          resolve();
        }).catch(res => {
          resolve();
          console.log(res);
        });
      });
    },
		async moveCode(){
			if(this.curSelectedData.value == '-1'){
				alert(this.rootName[this.curTab] + '는 수정할 수 없습니다.');
				return;
			}

			let lastParentCode = -1;
			for(let i = 0; i < 10; i++){
				if(this.selectedValues[i] == -1){
					break;
				}
				lastParentCode = this.selectedValues[i];
			}

			const parameters = {};
			parameters.value = this.curSelectedData.value;
			parameters.parentCode = lastParentCode;
			parameters.type = this.curCodeType;
			parameters.isBetween = false;

			axios.post('/api/base/tree/move', this.$commonFunc.convertToForm(parameters)).then(res => {
				if(res.data.code == ResCode.Success){
					this.getTree(this.curTab, this.curName);
				}else if(res.data.code == ResCode.Fail){
					alert(res.data.data.msg);
				}
			}).catch(res => {
				console.log(res);
			});
			
		},
		async renameCode(){
			const parameters = {};
			parameters.value = this.curSelectedData.value;
			parameters.type = this.curCodeType;
			parameters.newName = this.curName;

			return new Promise((resolve, reject) => {
				axios.post('/api/base/tree/rename', this.$commonFunc.convertToForm(parameters)).then(res => {
					if(res.data.code == ResCode.Fail){
						alert(res.data.message);
					}
					resolve(true);
				}).catch(res => {
					console.log(res);
					reject(false);
				});
			});
		},
    setInsertMode(){
			this.curMode = MODE.INSERT; 
			if(this.curSelectedData.value === -1){
				this.prevName = this.curName;
				this.curName = '';
			} else {
				this.selectedValues[(this.curSelectedData.level - 1)] = this.curSelectedData.value;
				this.curName = '';
			}

			this.signalFocus = !this.signalFocus;
    },
    cancelInsert(){
      this.curName = this.prevName;
      this.curMode = MODE.UPDATE; 
    },
    goTop(){
      this.updateSequence('top');
    },
    goUp(){
      this.updateSequence('up');
    },
    goDown(){
      this.updateSequence('down');
    },
    goBottom(){
      this.updateSequence('bottom');
    },
    updateSequence(direction){
      if(this.curSelectedData.value == -1) return;
      if(this.isProgressing) return;
      const parameters = {};
      parameters.direction = direction;
      parameters.type = this.curCodeType;
      parameters.value = this.curSelectedData.value;
      parameters.parentCode = this.curSelectedData.parentCode;

      this.isProgressing = true;

      axios.post('/api/base/tree/move/sequece', this.$commonFunc.convertToForm(parameters)).then(res => {
        this.isProgressing = false;
        if(res.data.code == ResCode.Success){
          this.getTree(this.curTab, this.curName);
        }
      }).catch(res => {
        this.isProgressing = false;
        console.log(res);
      });
    },
    getChildrenValueList(movedTarget){
      const recursiveAddValue = (treeData, valueList) => {
          for(let i = 0; i < treeData.length; i++){
              valueList.push(treeData[i].value);

              if(treeData[i].children != null && treeData[i].children.length > 0){
                  recursiveAddValue(treeData[i].children, valueList);
              }
          }
      }

      const valueList = [];
      recursiveAddValue(movedTarget, valueList);
      return valueList;
    },
    detectDragComplete(type, movedTarget, hoveredTarget, isBetween){

      if(!hoveredTarget) return;
      if(movedTarget.value === hoveredTarget.value ) return;

      const valueList = this.getChildrenValueList([movedTarget]);
      if(valueList.indexOf(hoveredTarget.value) > -1) return;

      const parameters = {};
      parameters.value = movedTarget.value;
      parameters.parentCode = hoveredTarget.value;
      parameters.type = type;
      parameters.isBetween = isBetween;

      axios.post('/api/base/tree/move', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          this.getTree(this.curTab);
        }else if(res.data.code == ResCode.Fail){
          alert(res.data.data.msg);
        }
      }).catch(res => {
          console.log(res);
      });
    }
  },
  created(){
      this.getTree(0, this.rootName[this.curTab]);
  }
}

</script>
<style scoped>
.left_panel{padding:24px 20px; background-color:white;width:318px;border-radius:4px; border:1px solid #e6e7ec;display:flex; flex-direction: column;;}

.tab_wrap{
  display:flex;
}
.tab{flex:1;text-align:center;padding:10px; border-top:1px solid #e6e7ec;border-bottom:1px solid #e6e7ec;color:#494a4f;font-size:16px;cursor:pointer;}
.tab + .tab{border-left:1px solid #e6e7ec}
.tab.active{
  background-color:#0066fd;
  border-color:#0066fd;
  color:white;
}
.tab:nth-of-type(1){
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left:1px solid #e6e7ec;
}
.tab.active:nth-of-type(1){
    border-left:1px solid #0066fd;
}

.tab:nth-last-of-type(1){
    border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right:1px solid #e6e7ec;
}

.tab.active:nth-last-of-type(1){
border-right:1px solid #0066fd;
}


.search_con{display:inline-block;position:relative;}
.search_input{
    border: 1px solid #e6e7ec;
    display: inline-block;
    font-size: 16px;
    border-radius:4px;
    flex:1;
    min-width: 0px;
    padding: 6px 10px 7px 30px;
}

.search_input.right_icon{
    padding: 6px 30px 7px 10px;
}

.search_label{font-size:15px;font-weight:bold; color:#969696;}
.search_btn{
  text-align:left;margin-top:21px;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
.add_btn{text-align:left; position:absolute;;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:4px; cursor:pointer;
      padding: 11px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom;
    width: 100px;
    right: 0px;
    top: -7px;
}


.data_table_wrap{flex:1;  overflow:hidden; display:flex; flex-direction:column;}


.vertical_line{
    display: inline-block;
    margin-top:2px;
    height: 18px;;
    width:1px;
    
 background-color:#a7a8ba;
}

.required{color:#fe7681;}


.asset_bottom_btns{margin-top:20px;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}

.btns + .btns{
  margin-left:7px;
}

.arrow_back{
  border:1px solid #e6e7ec;
  display:flex; 
  justify-content: center;
  align-items: center;;
  padding:5px;
  color:#494a4f;
  cursor:pointer;
}

.arrow_back + .arrow_back{
  margin-top:7px;
}
</style>