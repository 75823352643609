<template >
    <ModalBack>
        <div class="dep_preciation_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">감가상각내역</div>
            
            <!-- <CommonAddBtn title="계정과목등록" style="margin-top:10px;"></CommonAddBtn> -->
            <div style="height:30px;"></div>
            <div style="border:1px solid #e6e7ec; border-radius:5px; display:flex;">
                <LabelPanel style="width:260px;padding:20px 20px">
                    <template v-slot:label><span style="font-size:15px;">상각기간</span></template>
                    <template v-slot:content><span style="font-size:15px;padding-left:2px;">{{deprePeriod}}</span></template>
                </LabelPanel>
                <LabelPanel style="width:150px;padding:20px 20px">
                    <template v-slot:label><span style="font-size:15px;">재산구분</span></template>
                    <template v-slot:content><span style="font-size:15px;padding-left:2px;">{{itemName}}</span></template>
                </LabelPanel>
                 <LabelPanel style="width:233px;padding:20px 20px">
                    <template v-slot:label><span style="font-size:15px;">자산명칭</span></template>
                    <template v-slot:content><span style="font-size:15px;padding-left:2px;">{{assetName}}</span></template>
                </LabelPanel>
            </div>
            <div style="flex:1; overflow:hidden; display:flex; flex-direction:column;">
                <BatchBtns :show-column-setting-action="() => {isShowColumnSettings = true}"></BatchBtns>
                <div style="flex:1; overflow:hidden">
                    <CommonTable :plist="list" :pcolumn="column" ></CommonTable>
                </div>
                <ColumnSetting v-if="isShowColumnSettings" 
                    :vue-name="$options.name" 
                    :default-settings="column" 
                    :closeSettings="() => {isShowColumnSettings = false}" 
                    :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
                </ColumnSetting>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">닫기</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';

import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import moment from 'moment';
import {NUMBER_STYLE} from '@/util/Constants.js';
export default {
    name : 'DepreciationTotalHistory',
    props : {
        hideModal : Function,
        transferData : {
            type : Object,
            default : () => {}
        }
    },
    components : {
        ModalBack, CommonModalCloseBtn,
         ColumnSetting, CommonTable, BatchBtns, LabelPanel
    },
   data(){
        return {
            deprePeriod : '',
            itemName : '',
            assetName : '',
            column: [
                { key: "column0", name: "", w: "10px"},
                { key: "year", name: "연도", w: "50px"},
                { key: "depreRate", name: "상각율", w: "80px"},
                { key: "depreRatePrice", name: "감가상각비", w: "110px", comma : true, style :NUMBER_STYLE},
                { key: "startPrice", name: "기초장부가액", w: "110px", comma : true, style :NUMBER_STYLE},
                { key: "lastsumPrice", name: "전기말상각누계", w: "110px", comma : true, style :NUMBER_STYLE },
                { key: "deprePrice", name: "당기상각액", w: "110px", comma : true, style :NUMBER_STYLE },
                { key: "endPrice", name: "기말장부가액", w: "110px", comma : true, style :NUMBER_STYLE },
                { key: "sumPrice", name: "당기말상각누계", w: "110px" , comma : true, style :NUMBER_STYLE},
            ],
            list : [],
            isShowColumnSettings : false,
        }
    },
    methods : {
        getDepreciationTotalHistory(){
            const parameters = this.getParameters();
            axios.get('/api/depreciation/spec/totalhistory', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data.depreSpceTotalHistory;
                    this.list = data;
                    console.log(data);

                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};

            const totalHistoryData = this.transferData.totalHistoryData;
            parameters.buyDate = totalHistoryData.buyDate + " 00:00:00";
            parameters.buyPrice = totalHistoryData.buyPrice;
            parameters.scrapPrice = totalHistoryData.scrapPrice;
            parameters.depreciation = totalHistoryData.depreciation;
            parameters.durableYear = totalHistoryData.durableYear;
            parameters.depreRate = totalHistoryData.depreDrate;
            parameters.depreRate1 = totalHistoryData.depreSRate;
            parameters.ratePoint = totalHistoryData.ratePoint;
            parameters.dtEnd = this.transferData.dtEnd;

            this.deprePeriod = this.transferData.deprePeriod;
            this.assetName = this.transferData.totalHistoryData.assetName;
            this.itemName = this.transferData.totalHistoryData.itemName;
            
            return parameters;
        }
    },
    created(){
        this.getDepreciationTotalHistory();

        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
.dep_preciation_register_wrap{
    background-color:white; width:900px; height:90%;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
    transform: translate3d(0,0,0);
}
.line{height:1px; background-color:#e6e7ec;}
.asset_bottom_btns{text-align:right;padding-top: 10px;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>