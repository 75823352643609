<template >
    <ModalBack :closeAction="hideModal">
        <div class="company_register_wrap">
        <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
        <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;" >감가상각 일괄변경</div>
        <div class="asset_info_contents">
            <table class="asset_info_tb" style="margin-top:25px;">
                <colgroup>
                    <col>
                </colgroup>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox :flag="formChanged.depreNotUsed" :clickCheck="() => {formChanged.depreNotUsed = !formChanged.depreNotUsed}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>상각여부</div>
                        <CommonSelect :list="depreYN" :detectChange="(data) => { form.depreNotUsed = data}"></CommonSelect>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox :flag="formChanged.buyDate" :clickCheck="() => {formChanged.buyDate = !formChanged.buyDate}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>취득일자</div>
                        <CalendarInput v-model="form.buyDate"></CalendarInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox :flag="formChanged.buyPrice" :clickCheck="() => {formChanged.buyPrice = !formChanged.buyPrice}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>취득가액</div>
                        <CommonInput v-model="form.buyPrice" mode="number"></CommonInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox :flag="formChanged.scrapPrice" :clickCheck="() => {formChanged.scrapPrice = !formChanged.scrapPrice}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>잔존가액</div>
                        <CommonInput v-model="form.scrapPrice" mode="number"></CommonInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label"><CommonCheckbox :flag="formChanged.accountIdx" :clickCheck="() => {formChanged.accountIdx = !formChanged.accountIdx}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>계정과목</div>
                        <CommonSelect :list="accounts" :detectChange="(value, data) => {depreciationName = (data.depreciation == '0' ? '정액법' : '정률법'); form.durableYear = data.durableYear + ''; form.accountIdx = value;}"></CommonSelect>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label" style="margin-top: 6px;">감가상각법</div>
                        <CommonInput v-model="depreciationName" :isReadonly="true"></CommonInput>
                    </td>
                </tr>
                <tr class="margin"><td ></td></tr>
                <tr>
                    <td>
                        <div class="label" style="margin-top: 6px;">내용연수</div>
                        <CommonInput v-model="form.durableYear" :isReadonly="true"></CommonInput>
                    </td>
                </tr>
            </table>
        </div>
        <div class="asset_bottom_btns">
            <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
            <button class="asset_bottom_btn_ok" @click="save" >저장</button>
        </div>
    </div>
    </ModalBack>
</template>

<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';

export default {
    name : 'DepreciationBatchRegister',
    props : {
        hideModal : Function,
        transferData : {
            type : Object,
            default : () => {}
        },
        completeFunc : {
            type : Function,
            default : () => {}
        }
    },
    components : {
        ModalBack, CommonModalCloseBtn, CommonSelect, CalendarInput, CommonInput, CommonCheckbox
    },
    watch : {
        'form.depreNotUsed' : function(newVal, oldVal) {this.formChanged.depreNotUsed = true;},
        'form.buyDate' : function(newVal, oldVal) {this.formChanged.buyDate = true;},
        'form.accountIdx' : function(newVal, oldVal) {this.formChanged.accountIdx = true;},
        'form.scrapPrice' : function(newVal, oldVal) {this.formChanged.scrapPrice = true;},
        'form.buyPrice' : function(newVal, oldVal) {this.formChanged.buyPrice = true;},
    },
    data(){
        return {
            form : {
                depreNotUsed : '',
                buyDate : '',
                scrapPrice : '',
                buyPrice : '',
                durableYear : '',
                accountIdx : '',
            },
            formChanged : {
                depreNotUsed : false,
                buyDate : false,
                scrapPrice : false,
                buyPrice : false,
            },
            depreYN : [
                {name : '상각함' , value : 'False'},
                {name : '상각안함' , value : 'True'},
            ],
            accounts : [],
            depreciationName : '',
            durableYear : ''
        }
    },
    methods : {
        save(){
            const parameters = {};
            if(this.formChanged.accountIdx){
                parameters.accountIdx = this.form.accountIdx;
                parameters.durableYear = this.form.durableYear;
            }

            if(this.formChanged.buyDate) parameters.buyDate = this.form.buyDate;
            if(this.formChanged.buyPrice) parameters.buyPrice = this.$commonFunc.uncomma(this.form.buyPrice);
            if(this.formChanged.scrapPrice) parameters.scrapPrice = this.$commonFunc.uncomma(this.form.scrapPrice);
            if(this.formChanged.depreNotUsed) parameters.depreNotUsed = this.form.depreNotUsed ? this.form.depreNotUsed : 'False';
            parameters.isAll = false;
            if(this.transferData.isSelectAll){
                parameters.isAll = true;
                const queryParameters = this.transferData.queryParameters;
                for(let key in queryParameters) {
                    parameters[key] = queryParameters[key];
                }
            }else {
                const savedSelectedListKeys = Object.keys(this.transferData.savedSelectedList);
                parameters.assetCodes = savedSelectedListKeys
            }

            console.log(parameters);

            axios.post('/api/depreciation/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.completeFunc();
                    this.hideModal();
                }
            }).catch(res => {
                console.log(res);
            });

        },
        getAccounts(){
            const parameters = this.getParameters();

            axios.get('/api/account/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const accounts = res.data.data.accounts;
                    const newList = [{name : '', value : '0', depreciation : '', durableYear : ''}];
                    for(const each of accounts){
                        newList.push({name : each.accountName, value : each.accountIdx, depreciation : each.depreciation, durableYear : each.durableYear})
                    }

                    this.accounts = newList;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.deleted = 0; 
            parameters.page = 1;
            parameters.sizePerPage = 1000;
            return parameters;
        },
    },
    created(){
        this.getAccounts();
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 400px;
    height: 700px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.margin{height:2px;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.asset_bottom_btns{text-align:right;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>