<template>
  <TableSearchPanel :is-show-left-panel="true" :is-show-right-panel="true" :is-show-top-panel="true" :is-show-table-panel="true">
    <template v-slot:pageheader>
      <PageHeader title="재물조사">
        <span v-if="isAllowedForInsert" style="float:right;height: 0px;position:relative;">
          <button class="add_btn" @click="() => {isShowSurveyRegister = true} ">+ 실사등록</button>
        </span>
      </PageHeader>
    </template>
    <template v-slot:left_panel>
  
      <LabelPanel >
        <template v-slot:label>실사기간</template>
        <template v-slot:content>
            <CommonInput :click-input="showCalendar" v-model="buyDate" icon="calendar" style="width:100%;"></CommonInput>
            <div v-if="isShowCalendar" ref="periodWrap" style="position:fixed; z-index:11; background-color:white; border:1px solid #e6e7ec; border-radius:4px; ">
                <PeriodCalendar :complete-callback="completSelectDate" :init-start-date="startDate" :init-end-date="endDate" :cancel-callback="hideCalendar"></PeriodCalendar>
            </div>
        </template>
      </LabelPanel>
      <div style="flex:1;overflow:hidden; margin-top:14px;">
        <CommonTable ref="cTable" :plist="summaryList" :pcolumn="summaryColumn" :clickRow="clickSummary" :pcustom="summaryCustom"></CommonTable>
      </div>
      
      <ColumnSetting v-if="isShowColumnSettings" 
          :vue-name="$options.name" 
          :default-settings="summaryColumn" 
          :closeSettings="() => {isShowColumnSettings = false}" 
          :complete-save="(newColumnSettings) => {isShowColumnSettings = false; summaryColumn = newColumnSettings}">
      </ColumnSetting>

    </template>

    <template v-slot:top_panel>
      <div style="width:220px;">
        <div class="tab_wrap">
          <div class="tab" :class="[curTab === 0 ? 'active' : '']" @click="changeTab(0)">실사내역</div>
          <div class="tab" :class="[curTab === 1 ? 'active' : '']" @click="changeTab(1)">실사통계</div>
        </div>
      </div>
      
      <!-- 실사통계 그래프 -->
      <div v-if="curTab === 1" style=" margin-top: 14px;flex:1; ">
        <div style="height:360px; display:flex;">
          <div style="position:relative; flex:1; display:flex; justify-content:center; align-items:centerp;padding:22px; border:1px solid #e6e7ec; border-radius:4px;background-color:white;">
            <div style="position:absolute; left:15px; top:15px; color: #494a4f;font-size: 16px;">실사결과</div>
            <DoughnutChart :chartData="chartData1" :chartOption="chartOptions1" :height="300"></DoughnutChart>
          </div>
          <div style="position:relative; margin-left:16px; flex:1;display:flex; justify-content:center; align-items:center;padding:22px; border:1px solid #e6e7ec; border-radius:4px;background-color:white;">
            <div style="position:absolute; left:15px; top:15px; color: #494a4f;font-size: 16px;">사용상태</div>
             <DoughnutChart :chartData="chartData2" :chartOption="chartOptions2" :height="300"></DoughnutChart>
          </div>
        </div>
      </div>

    </template>
    <template v-slot:table_panel>
       <!-- 실사내역 -->
      <div v-show="curTab === 0" style="flex:1; overflow:hidden; display:flex; flex-direction:column;">
        <CommonTableTab :key="0" :list="tabList" :detect-tab-change="detectTabChange" :signalTabReset="signalTabReset"></CommonTableTab>

        <div style="display:flex; width: 610px;margin-top:18px;margin-bottom:5px;">
           <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter" style="width:233px;"></ConditionInput>
          <!-- <CommonSelect :list="searchConditions" :detectChange="(value) => {lastCondition = value}" style="width:238px; margin-right:13px;"></CommonSelect> 
          <CommonInput v-model="searchWord" :enterAction="search"></CommonInput> -->
          <button class="search_btn" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
        </div>
        
        <BatchBtns 
            :is-show-first-checkbox="true"
            :is-show-delete-btn="true && isAllowedForDelete" 
            :delete-action="deleteList" 
            :is-show-modify-btn="true && isAllowedForUpdate"
            :is-show-print-btn="true"
            :is-show-select-all-btn="true"
            :show-column-setting-action="() => {isShowSurveyColumnSettings = true}"
            :downloadExcel="downloadExcel"
            :savedSelectedList="savedSelectedList"
            :btnState="btnState"
            :searchCnt="searchCnt"
            :modifyAction="showSurveyDetailRegister"
            :isShowExcelDownload="isAllowedForExcel"
            >
        </BatchBtns>
        <div style="flex:1; overflow:hidden">
          <CommonTable 
            :key="$options.name + 'survey'" 
            :plist="surveyList" 
            :pcolumn="surveyColumn" 
            :pcustom="surveyCustom" 
            :paging="surveyPaging" 
            :isShowNumbering="true"
            :change-page="detectChangePage" 
            :isShowCheckbox="true" 
            :savedSelectedList="savedSelectedList" 
            savedSelectedListKey="assetcode"
            :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
            :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
            :btnState="btnState"
            :isGoTopWhenRefresh="isGoTopWhenRefresh"
            ></CommonTable>
        </div>
        <ColumnSetting v-if="isShowSurveyColumnSettings" 
              :vue-name="$options.name + 'survey'" 
              :default-settings="surveyColumn" 
              :closeSettings="() => {isShowSurveyColumnSettings = false}" 
              :complete-save="(newColumnSettings) => {isShowSurveyColumnSettings = false; surveyColumn = newColumnSettings}">
        </ColumnSetting>
      </div>

      <!-- 실사통계 -->
      <div v-if="curTab === 1 && !isStatisticFirst" v-show="curTab === 1" style="flex:1; overflow:hidden; display:flex; flex-direction:column;">
        <CommonTableTab :key="1" :list="tabList2" :detect-tab-change="detectTabChange2"></CommonTableTab>
        <BatchBtns 
            :is-show-first-checkbox="true && isAllowedForDelete"
            :is-show-delete-btn="true && isAllowedForDelete" 
            :delete-action="deleteList2" 
            :show-column-setting-action="() => {isShowChangeColumnSettings = true}"
            :savedSelectedList="savedSelectedList2"
            :btnState="btnState2"
            :isShowExcelDownload="isAllowedForExcel"
            >
        </BatchBtns>
        <div style="flex:1; overflow:hidden">
          <CommonTable 
            :key="$options.name + 'change'" 
            :plist="changeList" 
            :pcolumn="changeColumn" 
            :pcustom="changeCustom" 
            :paging="null" 
            :isShowNumbering="true"
            :change-page="detectChangePage2" 
            :isShowCheckbox="true && isAllowedForDelete" 
            :savedSelectedList="savedSelectedList2" 
            savedSelectedListKey="historyIdx"
            :signalSelectCurPageAll="btnState2.signalSelectCurPageAll"
            :signalUnselectCurPageAll="btnState2.signalUnselectCurPageAll"
            :btnState="btnState2"

            ></CommonTable>
        </div>
        <ColumnSetting v-if="isShowChangeColumnSettings" 
              :vue-name="$options.name" 
              :default-settings="changeColumn" 
              :closeSettings="() => {isShowChangeColumnSettings = false}" 
              :complete-save="(newColumnSettings) => {isShowChangeColumnSettings = false; changeColumn = newColumnSettings}">
        </ColumnSetting>
      </div>

    </template>
  
    <template v-slot:etc>
      <div><SurveyRegister v-if="isShowSurveyRegister" :hideModal="hideSurveyRegister" :completeSave="completeSave"></SurveyRegister></div>
      <div><SurveyDetailRegister v-if="isShowSurveyDetailRegister" :hideModal="() => {isShowSurveyDetailRegister = false}" :transferData="transferData"></SurveyDetailRegister></div>
      <div><LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="completeSearch"></LocSearch></div>
      <div><GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="completeSearch"></GroupSearch></div>
      <AssetRepairRegister v-if="isShowRepairRegister" :hideModal="() => {isShowRepairRegister = false}" :assetCode="registerAssetCode" :data="{}" :curMode="MODE.INSERT" :completeAction="() => {lastSearch();}"></AssetRepairRegister>
      <AssetRentalRegister v-if="isShowRentalRegister" :hideModal="() => {isShowRentalRegister = false}" :assetCode="registerAssetCode" :data="{}" :curMode="MODE.INSERT" :completeAction="() => {lastSearch();}"></AssetRentalRegister>
    </template>
    

  </TableSearchPanel>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import PeriodCalendar from '@/components/common/PeriodCalendar.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonTableTab from '@/components/common/CommonTableTab.vue';
import SurveyRegister from '@/components/modal/SurveyRegister.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import SurveyDetailRegister from '@/components/modal/SurveyDetailRegister.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import DoughnutChart from '@/components/common/DoughnutChart.vue';
import AssetRepairRegister from '@/components/modal/AssetRepairRegister.vue';
import AssetRentalRegister from '@/components/modal/AssetRentalRegister.vue';

import moment from 'moment';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {states, AUTH, STATE_KEY, MODE, SIZE_PER_PAGE, NUMBER_STYLE} from '@/util/Constants.js';
// import { Doughnut } from 'vue-chartjs/legacy';
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
//   CategoryScale
// } from 'chart.js'
// ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
let lastSearch = null
let getSummary = null;
let search = null;
let showGroupSearch = null;
let showLocSearch = null;
let getTotalTabList = null;
let showRepairRegister = null;
let showRentalRegister = null;

const deleteBtn = {
  template : `<div style="padding-right:10px;" @click="deleteSurvey"><font-awesome-icon icon="fa-regular fa-trash-can" style="color:#a7a8ba;" /></div>`,
  props : ['data'],
  methods : {
    deleteSurvey(){
      let confirmStr = "";
      confirmStr += '실사일자 : ' + this.data.surveyDate.substr(0, 10) + "\n";
      confirmStr += '재산구분 : ' + this.data.itemname + "\n";
      confirmStr += '위치명 : ' + (this.data.targetType == '0' ? this.data.targetname : '전체')  + "\n";
      confirmStr += '부서명 : ' + (this.data.targetType == '1' ? this.data.targetname : '전체') + "\n";
      if(this.data.chargename) confirmStr += '담당자 : ' + this.data.chargename + "\n";
      confirmStr += "등록된 재물조사 내역을 삭제하시겠습니까?";

      if(!confirm(confirmStr)){
        return;
      }

      const parameters = {};
      parameters.surveyIdx = this.data.surveyIdx;

      axios.post('/api/survey/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          getSummary();
        } 
      }).catch(res => {
          console.log(res);
      });
    }
  }
}

const surveyResultMenu = {
  template : `
        <div style="position:relative;height:100%;">
          <div @click="clickText($event)" style="height:100%; display:flex; width:100%; align-items:center;" 
            :style="{
              color: data.surveyresult === '일치' ? '#45bbe0' 
                  : data.surveyresult === '불일치' ? '#f7531f' 
                  : data.surveyresult === '실사전' ? '#0066fd' : ''
            }"
            >
            {{data.surveyresult}}
          </div>
          <div v-if="isShow" style="position:fixed;width:145px;background-color:white;border:1px solid #e6e7ec;border-radius:5px; z-index:10;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;" :style="{top : top, left : left}">
            <div style="padding:18px 30px; color:#494a4f;cursor:pointer;" @click="saveSurveyResult('0')">
              <span style="margin-left:10px;" >실사전</span>
            </div>
            <div style="padding:18px 30px;color:#494a4f; border-top:1px solid #e6e7ec; cursor:pointer;" @click="saveSurveyResult('1')">
              <span style="margin-left:10px;" >일치</span>
            </div>
            <div style="padding:18px 30px;color:#494a4f; border-top:1px solid #e6e7ec; cursor:pointer;" @click="saveSurveyResult('2')">
              <span style="margin-left:10px;" >불일치</span>
            </div>
          </div>
          <input type='text' style="width:0px; height:0px; border:0px; appearance:none; outline:none;background-color: transparent;" ref='hiddenText' @blur="blurred">
        </div>
  `,
  props : ['data', 'column', 'index'],
  data(){
      return {
          isShow : false,
      }
  },
  methods : {
      clickText(e){
        const rect = e.currentTarget.getBoundingClientRect();
        this.left = rect.x + 'px';
        const docHeight = document.body.clientHeight;
        const selectorHeight = 53 * 3;
        if((docHeight - rect.bottom) > selectorHeight){
          this.top = (rect.y + 47) + 'px';
        }else {
          this.top = (rect.y - selectorHeight - 5)  + 'PX';
        }
        this.$refs['hiddenText'].focus();
        this.isShow = !this.isShow;
      },
      blurred(){
        setTimeout(() => {
          this.isShow = false;
        }, 100)
      },
      saveSurveyResult(surveyResult){
        const parameters = {};
        parameters.isAll = false;
        parameters.surveyIdx = this.data.surveyidx;
        parameters.assetCodes = [this.data.assetcode];
        parameters.surveyResult = surveyResult;

        axios.post('/api/survey/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
            if(res.data.code == ResCode.Success){
              getSummary(true, true)
              lastSearch();
              getTotalTabList();
            } 
        }).catch(res => {
            console.log(res);
        });
      }
      
  }
}

const stateMenu = {
  template : `
        <div style="position:relative;height:100%;">
          <div @click="clickText($event)" style="height:100%; display:flex; width:100%; align-items:center; color:#0066fd;">{{data.state}}</div>
          <div v-if="isShow" style="position:fixed;width:145px;background-color:white;border:1px solid #e6e7ec;border-radius:5px; z-index:10;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;" :style="{top : top, left : left}">
            <div v-for="each, i in states" style="padding:18px 30px; color:#494a4f;cursor:pointer;" :style="{borderTop : (i > 0 ? '1px solid #e6e7ec' : '')}" @click="changeState(each.value)" >
              <span style="margin-left:10px;" >{{each.name}}</span>
            </div>
          </div>
          <input type='text' style="width:0px; height:0px; border:0px; appearance:none; outline:none;background-color: transparent;" ref='hiddenText' @blur="blurred">
        </div>
  `,
  props : ['data', 'column', 'index'],
  data(){
      return {
          isShow : false,
          states : states.slice(1),
      }
  },
  methods : {
      clickText(e){
        const rect = e.currentTarget.getBoundingClientRect();
        this.left = rect.x + 'px';
        const docHeight = document.body.clientHeight;
        const selectorHeight = 53 * 7;
        if((docHeight - rect.bottom) > selectorHeight){
          this.top = (rect.y + 47) + 'px';
        }else {
          this.top = (rect.y - selectorHeight - 5)  + 'PX';
        }
        
        this.$refs['hiddenText'].focus();
        this.isShow = !this.isShow;
      },
      blurred(){
        setTimeout(() => {
          this.isShow = false;
        }, 100)
      },
      changeState(stateCode){
        const prevStateCode = this.data.statecode;

        if(prevStateCode === stateCode) return;

        //이번에 수리, 대여로 변경될경우 창띄움
        if(stateCode === STATE_KEY.수리){
          showRepairRegister(this.data.assetcode);
        }else if(stateCode === STATE_KEY.대여){
          showRentalRegister(this.data.assetcode);
        }
        
        if((prevStateCode === STATE_KEY.대여 && stateCode === STATE_KEY.수리) || (prevStateCode === STATE_KEY.수리 && stateCode === STATE_KEY.대여)){
          stateCode = STATE_KEY.사용;
        }else if(stateCode === STATE_KEY.수리 || stateCode === STATE_KEY.대여){
          return;
        }

        const parameters = {};
        parameters.isAll = false;
        parameters.surveyIdx = this.data.surveyidx;
        parameters.assetCodes = [this.data.assetcode];
        parameters.stateCode = stateCode;

        if(prevStateCode === STATE_KEY.대여 || prevStateCode === STATE_KEY.수리){
          parameters.prevStateCode = prevStateCode;
        }

        axios.post('/api/survey/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
            if(res.data.code == ResCode.Success){
              lastSearch();
            } 
        }).catch(res => {
            console.log(res);
        });
      },
  }
}

const groupMenu = {
  template : `
        <div style="position:relative;height:100%;" @click="showGroup">
          <div style="height:100%; display:flex; width:100%; align-items:center; color:#0066fd;">{{data.groupname}}</div>
        </div>
  `,
  props : ['data', 'column', 'index'],
  methods : {
    showGroup(){
      showGroupSearch(this.completeSave)
    },
    completeSave(data){
        const parameters = {};
        parameters.isAll = false;
        parameters.surveyIdx = this.data.surveyidx;
        parameters.assetCodes = [this.data.assetcode];
        parameters.groupCode = data.value;

        axios.post('/api/survey/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
            if(res.data.code == ResCode.Success){
              lastSearch();
            } 
        }).catch(res => {
            console.log(res);
        });
    }
  }
}

const locMenu = {
  template : `
        <div style="position:relative;height:100%;" @click="showLoc">
          <div style="height:100%; display:flex; width:100%; align-items:center; color:#0066fd;">{{data.locname}}</div>
        </div>
  `,
  props : ['data', 'column', 'index'],
  methods : {
    showLoc(){
      showLocSearch(this.completeSave)
    },
    completeSave(data){
        const parameters = {};
        parameters.isAll = false;
        parameters.surveyIdx = this.data.surveyidx;
        parameters.assetCodes = [this.data.assetcode];
        parameters.locCode = data.value;

        axios.post('/api/survey/savebatch', this.$commonFunc.convertToForm(parameters)).then(res => {
            if(res.data.code == ResCode.Success){
              lastSearch();
            } 
        }).catch(res => {
            console.log(res);
        });
    }
  }
}

export default {
  name: 'SurveyMng',
  props : {},
  components : {
    PageHeader, TableSearchPanel, LabelPanel, CommonInput, PeriodCalendar, CommonTable, ColumnSetting, CommonTableTab, BatchBtns, 
    SurveyRegister, ConditionInput, SurveyDetailRegister, LocSearch, GroupSearch,
    DoughnutChart, AssetRepairRegister, AssetRentalRegister
    //  Doughnut
  },
  computed : {
    isAllowedForInsert(){
        return this.$GlobalStore.isAllowedFor(AUTH.재물조사, AUTH.INSERT)
    },
    isAllowedForUpdate(){
        return this.$GlobalStore.isAllowedFor(AUTH.재물조사, AUTH.UPDATE)
    },
    isAllowedForDelete(){
        return this.$GlobalStore.isAllowedFor(AUTH.재물조사, AUTH.DELETE)
    },
    isAllowedForExcel(){
        return this.$GlobalStore.isAllowedFor(AUTH.재물조사, AUTH.EXCEL)
    }
  },
  data(){
    return {
      AUTH : AUTH,
      MODE : MODE,
      isShowCalendar : false,
      isShowColumnSettings : false,
      isShowSurveyColumnSettings : false,
      isShowChangeColumnSettings : false,
      isShowSurveyRegister : false,
      isStatisticFirst : false,
      signalTabReset : false,
      isShowSurveyDetailRegister : false,
      isShowGroupSearch : false,
      isShowLocSearch : false,
      isShowRepairRegister : false,
      isShowRentalRegister : false,
      isGoTopWhenRefresh : true,
      registerAssetCode : '',
      // signalSelectCurPageAll : false,
      // signalUnselectCurPageAll : false,
      startDate : '',
      endDate : '',
      buyDate : '',
      curTab : 0,
      lastSurveyIdx : -1,
      lastPage : -1,
      lastCondition : 'assetName',
      lastTabValue : '-1',
      lastStatisTabValue : '',
      searchWord : '',
      savedSelectedList : {},
      savedSelectedList2 : {},
      transferData : {},
      lastWithoutSearchWord : false,
      searchCnt : 0,
      lastSummaryIndex : 0,
      btnState:{
        isSelectAll : false,
        isSelectedOneAtLeast : false,
        signalSelectCurPageAll : false,
        signalUnselectCurPageAll : false,
      },
      btnState2:{
        isSelectAll : false,
        isSelectedOneAtLeast : false,
        signalSelectCurPageAll : false,
        signalUnselectCurPageAll : false,
      },
      tabList : [
        {name : '전체', cnt : 0, value : 'code0'},
        {name : '실사전', cnt : 0, value : 'code1'},
        {name : '일치', cnt : 0, value : 'code2'},
        {name : '불일치', cnt : 0, value : 'code3'},
      ],
      searchConditions : [
        {name : '자산명칭', key : 'assetName', checked : true},
        {name : '재산구분', key : 'itemName', checked : true},
        {name : '사용상태', key : 'state', checked : true},
        {name : '위치명', key : 'locName', checked : true},
        {name : '부서명', key : 'groupName', checked : true},
        {name : '모델명', key : 'modelName', checked : true},
        {name : '규격', key : 'standard', checked : true},
        {name : '담당자', key : 'charge', checked : true},
        {name : '사용자', key : 'user', checked : true},
        {name : '바코드', key : 'barcode', checked : true},
        {name : '취득가액', key : 'buyPrice', checked : true},
        {name : '취득일자', key : 'buyDate', checked : true}
      ],
      surveyCustom : {
        surveyresult : surveyResultMenu,
        state : stateMenu,
        groupname : groupMenu,
        locname : locMenu,
      },
      surveyList : [],
      surveyColumn: [
        { key: "surveyresult", name: "실사결과", w: "80px", style : {cursor : 'pointer'}},
        { key: "state", name: "사용상태", w: "70px", style : {cursor : 'pointer'}},
        { key: "assetname", name: "자산명칭", w: "180px" },
        { key: "barcode", name: "바코드", w: "140px" },
        { key: "itemname", name: "재산구분", w: "120px" },
        { key: "locname", name: "위치명", w: "120px", style : {cursor : 'pointer'} },
        { key: "groupname", name: "부서명", w: "120px", style : {cursor : 'pointer'} },
        { key: "modelname", name: "모델명", w: "100px" },
        { key: "standard", name: "규격", w: "130px" },
        { key: "buyDate", name: "취득일자", w: "100px" },
        { key: "serial", name: "시리얼", w: "100px" },
        { key: "buyPrice", name: "취득가액", w: "100px", comma: true, style : NUMBER_STYLE},
        { key: "scrapPrice", name: "잔존가액", w: "100px", comma: true, style : NUMBER_STYLE},
        { key: "charge", name: "담당자", w: "100px" },
        { key: "user", name: "사용자", w: "100px" },
        { key: "selling", name: "구매처", w: "100px" },
        { key: "field1", name: "추가항목1", w: "100px" },
        { key: "field2", name: "추가항목2", w: "100px" },
        { key: "field3", name: "추가항목3", w: "100px" },
        { key: "field4", name: "추가항목4", w: "100px" },
        { key: "field5", name: "추가항목5", w: "100px" },
        { key: "field6", name: "추가항목6", w: "100px" },
        { key: "field7", name: "추가항목7", w: "100px" },
        { key: "field8", name: "추가항목8", w: "100px" },

      ],
      surveyPaging : {},
      tabList2 : [
        {name : '전체', cnt : 0, value : ''},
        {name : '사용상태변경', cnt : 0, value : 'B002006000'},
        {name : '위치변경', cnt : 0, value : 'B002002000'},
        {name : '부서변경', cnt : 0, value : 'B002003000'},
      ],
      changeCustom : {},
      changeList : [],
      changeColumn: [
        { key: "changeDate", name: "변경일자", w: "105px", dateLength10 : true},
        { key: "typeName", name: "변경구분", w: "120px"},
        { key: "beforeName", name: "변경전", w: "150px" },
        { key: "afterName", name: "변경후", w: "150px" },
        { key: "userName", name: "변경자", w: "80px" },
      ],
      summaryCustom : {column4 : deleteBtn},
      summaryList : [],
      summaryColumn: [
        { key: "column0", name: "", w: "10px"},
        { key: "surveyDate", name: "실사일자", w: "105px", dateLength10 : true},
        { key: "surveyResult", name: "실사결과", w: "70px"},
        { key: "itemname", name: "재산구분", w: "60px" },
        { key: "locName", name: "위치", w: "60px" },
        { key: "groupName", name: "부서", w: "60px" },
        { key: "column4", name: "", w: "20px" },
      ],

      chartData1: {
        labels: ['실사전', '일치', '불일치'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
            data: [40, 20, 80]
          }
        ]
      },
      chartOptions1: {
        responsive: false,
      },
      chartData2: {
        labels: ['사용', '수리', '대여', '불용', '분실', '폐기', '보관'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', 'blue', 'yellow', 'green', 'pink'],
            data: [40, 20, 80, 40, 20, 80, 20]
          }
        ]
      },
      chartOptions2: {
        responsive: false
      }

    }
  },
  methods : {
    showSurveyDetailRegister(){
      const batchData = {
        savedSelectedList : this.savedSelectedList,
        isSelectAll : this.btnState.isSelectAll,
        queryParameters : this.getParameters()
      }

      this.transferData = {
        data : batchData,
        surveyIdx : this.lastSurveyIdx,
        completeFunc : () => {
          this.savedSelectedList = {};
          this.search();
          // this.savedSelectedList = {};
          // this.search()
        }
      };

      this.isShowSurveyDetailRegister = true;

    },
    enter(){
      this.search();
    },
    hideSurveyRegister(){
      this.isShowSurveyRegister = false;
    },
    showCalendar(){
      this.isShowCalendar = true;
    },
    hideCalendar(){
        this.isShowCalendar = false;
    },
    completSelectDate(startDate, endDate){
      this.startDate = startDate;
      this.endDate = endDate;
      this.buyDate = startDate + ' ~ ' + endDate;
      this.getSummary();
      this.hideCalendar();
    },
    changeTab(index){
      if(index === 1) this.isStatisticFirst = false;
      this.curTab = index;

      
      if(index === 0){
        this.getSurvey(this.lastSurveyIdx, 1);
        
      }else if(index === 1){
        this.getTotalTabList();
        this.getTabList2();
        this.getChangeList();
      }
    },
    detectTabChange(data){
        this.lastTabValue = data.value;
        this.getSurvey(this.lastSurveyIdx, 1, true);

    },
    detectTabChange2(data){
      this.lastStatisTabValue = data.value;
      this.getChangeList();
    },
    deleteList(){
      if(!confirm('정말로 삭제하시겠습니까?')){
        return;
      }

      const parameters = this.getParameters();
      parameters.isSelectAll = this.btnState.isSelectAll;

      if(!this.btnState.isSelectAll){
        parameters.assetCodes = Object.keys(this.savedSelectedList);
      }

      axios.post('/api/survey/detail/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          this.getSurvey(this.lastSurveyIdx, this.lastPage, this.lastWithoutSearchWord)
          this.getTotalTabList();
        }
      }).catch(res => {
          console.log(res);
      });
    },
    deleteList2(){
      if(!confirm('정말로 삭제하시겠습니까?')){
        return;
      }

      const parameters = {}
      parameters.historyIdxes = Object.keys(this.savedSelectedList2);

      axios.post('/api/survey/change/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
        if(res.data.code == ResCode.Success){
          this.getTabList2();
          this.getChangeList();
        }
      }).catch(res => {
          console.log(res);
      });
    },
    detectChangePage(page){
        this.getSurvey(this.lastSurveyIdx, page, this.lastWithoutSearchWord);
    },
    detectChangePage2(page){
       
    },
    getSummary(isOnlySummary = false, isSelectLastSurvey = false){
      const parameters = {};
      parameters.startDate = this.startDate;
      parameters.endDate = this.endDate;
      axios.get('/api/survey/list', {params : parameters}).then(res => {
        if(res.data.code == ResCode.Success){
            const data = res.data.data
            this.summaryList = data;
            if(data.length > 0){
              let surveyIdx = data[0].surveyIdx;
              let summaryIndex = 0;
              if(isSelectLastSurvey){
                surveyIdx = this.lastSurveyIdx;
                summaryIndex = this.$refs.cTable.__getClickedIndex()
              }else {
                this.lastSurveyIdx = surveyIdx;
              }

              this.$nextTick(() => {
                this.$refs.cTable.__setClickedIndex(summaryIndex);
              })

              if(!isOnlySummary){
                this.getSurvey(surveyIdx, 1);
                this.getTotalTabList();
              }
              
            }
        }
      }).catch(res => {
          console.log(res);
      });
    },
    getTotalTabList(){
        const parameters = {};
        parameters.surveyIdx = this.lastSurveyIdx;
        
        axios.get('/api/survey/details/totalcnt',  {params : parameters}).then(res => {
            if(res.data.code == ResCode.Success){
                const data = res.data.data;
                const surveySum = data.surveySum;

                this.changeTab1(surveySum.total_cnt, surveySum.before_cnt, surveySum.match_cnt, surveySum.nomatch_cnt);
                this.changeChart1(surveySum.before_cnt, surveySum.match_cnt, surveySum.nomatch_cnt);
                this.changeChart2(surveySum.useCnt, surveySum.repairCnt, surveySum.rentCnt, surveySum.nouseCnt, surveySum.lossCnt, surveySum.disposalCnt, surveySum.keepCnt);
            }
        }).catch(res => {
            console.log(res);
        });
    },
    getParameters(){
      // this.lastSurveyIdx = surveyIdx;
      // this.lastPage = page;


      const parameters = {};
      parameters.surveyIdx = this.lastSurveyIdx ;
      parameters.page = this.lastPage;
      parameters.sizePerPage = SIZE_PER_PAGE;

      let conditions = [];
      let searchWords = [];

      conditions.push('np_surveyResult');
      searchWords.push(this.lastTabValue);

      // if(this.searchWord !== '' && !this.lastWithoutSearchWord){
      //     conditions.push(this.lastCondition);
      //     searchWords.push(this.searchWord);
      // }

      if(this.searchWord != ''){
        ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
      }

      if(conditions.length > 0){
          parameters.conditions = conditions.join('::');
          parameters.searchWords = searchWords.join("::");
      }
      return parameters;
    },
    getSurvey(surveyIdx, page, withoutSearchWord, isGoTop = true){
        this.lastSurveyIdx = surveyIdx;
        this.lastPage = page;
        this.lastWithoutSearchWord = withoutSearchWord;

        const parameters = this.getParameters();

        axios.get('/api/survey/details', {params : parameters}).then(res => {
            if(res.data.code == ResCode.Success){
                const data = res.data.data

                this.surveyList = data.surveyDetails.content;
                this.searchCnt = data.surveyDetails.totalElements;
                data.surveyDetails.content = null;
                this.surveyPaging = data.surveyDetails;
                const surveySum = data.surveySum;
                if(!isGoTop){
                  this.isGoTopWhenRefresh = false;
                  this.$nextTick(() => {
                    this.isGoTopWhenRefresh = true;
                  })
                }
                // if(refreshTotalTab) this.changeTab1(surveySum.total_cnt, surveySum.before_cnt, surveySum.match_cnt, surveySum.nomatch_cnt);
               

            }
        }).catch(res => {
            console.log(res);
        });
    },
    clickSummary(data){
        this.lastSurveyIdx = data.surveyIdx;
        this.lastTabValue = -1;
        this.savedSelectedList = {};

        if(this.curTab === 0){
          this.resetTab();
          this.getTotalTabList()
          this.getSurvey(data.surveyIdx, 1, true);
          
        }else if(this.curTab === 1){
          this.getTotalTabList();
          this.getTabList2();
          this.getChangeList();
        }

    },
    search(){
      this.getSurvey(this.lastSurveyIdx, 1, false);
    },
    lastSearch(){
      this.getSurvey(this.lastSurveyIdx, this.lastPage, false, false);
    },
    resetTab(){
      this.signalTabReset = !this.signalTabReset;
    },
    downloadExcel(){
      const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.surveyColumn);
      console.log("/api/survey/details/excel?" + queryStr);
      location.href = this.$versionPath + "/api/survey/details/excel?" + queryStr
    },
    completeSave(){
      this.getSummary();
    },
    getTabList2(){
      const parameters = {};
      parameters.surveyIdx = this.lastSurveyIdx;

      axios.get('/api/survey/change/cnt', {params : parameters}).then(res => {
          if(res.data.code == ResCode.Success){
            const data = res.data.data
            this.tabList2 = [
              {name : '전체', cnt : data.totalCnt, value : ''},
              {name : '사용상태변경', cnt : data.stateCnt, value : 'B002006000'},
              {name : '위치변경', cnt : data.locCnt, value : 'B002002000'},
              {name : '부서변경', cnt : data.groupCnt, value : 'B002003000'},
            ]
          }
      }).catch(res => {
          console.log(res);
      });
    },
    getChangeList(){
      const parameters = {};
      parameters.surveyIdx = this.lastSurveyIdx;
      parameters.typeCode = this.lastStatisTabValue;

      axios.get('/api/survey/change/list', {params : parameters}).then(res => {
          if(res.data.code == ResCode.Success){
            const data = res.data.data
            this.changeList = data;
          }
      }).catch(res => {
          console.log(res);
      });

    },
    showGroupSearch(completeSave){
      this.completeSearch = completeSave;
      this.isShowGroupSearch = true;
    },
    showLocSearch(completeSave){
      this.completeSearch = completeSave;
      this.isShowLocSearch = true;
      
    },
    completeSearch(){},
    changeChart1(before_cnt, match_cnt, nomatch_cnt){
      this.chartData1 = {
        labels: ['실사전', '일치', '불일치'],
        datasets: [
          {
            backgroundColor: ['#348cd4', '#45bbe0', '#f7531f'],
            data: [before_cnt, match_cnt, nomatch_cnt]
          }
        ]
      }
    },
    changeTab1(total_cnt, before_cnt, match_cnt, nomatch_cnt){
      this.tabList = [
          {name : '전체', cnt : total_cnt, value : '-1'},
          {name : '실사전', cnt : before_cnt, value : '0'},
          {name : '일치', cnt : match_cnt, value : '1'},
          {name : '불일치', cnt : nomatch_cnt, value : '2'},
      ] 
    },
    changeChart2(useCnt, repairCnt, rentCnt, nouseCnt, lossCnt, disposalCnt, keepCnt){
      this.chartData2 = {
        labels: [states[1].name, states[2].name, states[3].name, states[4].name, states[5].name, states[6].name, states[7].name],
        datasets: [
          {
            backgroundColor: ['#348cd4', '#78c350', '#45bbe0', '#bdbdbd', '#675aa9', '#f7531f', '#ffc437'],
            data: [useCnt, repairCnt, rentCnt, nouseCnt, lossCnt, disposalCnt, keepCnt]
          }
        ]
      }
    },
    showRepairRegister(assetCode){
      this.registerAssetCode = assetCode;
      this.isShowRepairRegister = true;
    },
    showRentalRegister(assetCode){
      this.registerAssetCode = assetCode;
      this.isShowRentalRegister = true;
    },

  },

  created(){
    showRepairRegister = this.showRepairRegister;
    showRentalRegister = this.showRentalRegister;
    lastSearch = this.lastSearch;
    getSummary = this.getSummary;
    search = this.search;
    showGroupSearch = this.showGroupSearch;
    showLocSearch = this.showLocSearch;
    getTotalTabList = this.getTotalTabList;

    if(!this.isAllowedForDelete) this.summaryCustom = {};
    if(!this.isAllowedForUpdate) this.surveyCustom = {};

    const todayMoment = moment()
    const today = todayMoment.format('YYYY-MM-DD');
    this.startDate = todayMoment.subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
    this.endDate = today;
    this.buyDate = this.startDate + ' ~ ' + today;
 
    this.getSummary();

    ConditionManager.setConditions(this.$options.name, this.searchConditions);
    this.$commonFunc.setFieldNames(this.surveyColumn);
    this.summaryColumn = ColumnManager.getSettings(this.$options.name, this.summaryColumn);
    this.surveyColumn = ColumnManager.getSettings(this.$options.name + 'survey', this.surveyColumn);
    this.changeColumn = ColumnManager.getSettings(this.$options.name + 'change', this.changeColumn);

  }
  
}
</script>

<style scoped>
.left_panel{padding:24px 20px; background-color:white;width:318px;border-radius:4px; border:1px solid #e6e7ec;display:flex; flex-direction: column;;}


.tab_wrap{
  display:flex;
}
.tab{flex:1;text-align:center;padding:10px; border-top:1px solid #e6e7ec;border-bottom:1px solid #e6e7ec;color:#494a4f; font-size:16px;cursor:pointer; background-color:white;}
.tab + .tab{border-left:1px solid #e6e7ec}
.tab.active{
  background-color:#0066fd;
  border-color:#0066fd;
  color:white;
}
.tab:nth-of-type(1){
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left:1px solid #e6e7ec;
}
.tab.active:nth-of-type(1){
    border-left:1px solid #0066fd;
}

.tab:nth-last-of-type(1){
    border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right:1px solid #e6e7ec;
}

.tab.active:nth-last-of-type(1){
border-right:1px solid #0066fd;
}


.search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
  white-space: nowrap;
  padding: 10px 12px;
  border:1px solid #e6e7ec;
  display:inline-block;
  margin-left:4px;
  vertical-align:bottom
}

.add_btn{text-align:left; position:absolute;;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:4px; cursor:pointer;
  padding: 11px 12px;
  border:1px solid #e6e7ec;
  display:inline-block;
  margin-left:4px;
  vertical-align:bottom;
  width: 100px;
  right: 0px;
  top: -7px;
}
</style>
