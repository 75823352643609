<template>
    <div style="position:relative;">
        <input type="text" class="small_input" :class="icon ? 'left_icon' : ''" v-model="model" @click="click($event)" >
        <font-awesome-icon v-if="icon === 'glass'" icon="fa-solid fa-magnifying-glass" class="icon_style" style="position:absolute; left:10px; top: 11px;"/>
    </div>
</template>

<script>
export default {
    name : 'SmallInput',
    props: {
        icon : {
            type : String,
            default : ''
        },
        clickInput : {
            type : Function,
            default : null
        },
        value : String
    },
    methods : {
        click(e){
            if(this.clickInput != null) this.clickInput(e);
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
}
</script>
<style scoped>
.small_input{
border: 1px solid #e6e7ec;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
    flex: 1;
    min-width: 0px;
    width: 100%;
    padding: 0px 5px 0px 5px;
}

.icon_style{
 font-size:18px;color:#9b9cb6
}

.left_icon{
padding: 6px 10px 7px 30px;
}
</style>