<template>
    <div class="checkbox" :style="{borderColor : flag === true ? 'black' : (color ? color : ''), cursor: isCursorPointer ? 'pointer' : 'default', borderWidth : isHideBorder ? '0px' : '1px'}" @click="clickCheck">
        <font-awesome-icon v-if="flag" icon="fa-solid fa-check" style="color:black;margin-left:1px;font-size:19px;" :isCursorPointer="isCursorPointer" />
    </div>
</template>

<script>
export default {
    name: "CommonCheckbox",
    props: {
        flag :{
            type : Boolean,
            default : false   
        },
        clickCheck : {
            type : Function,
            default : () => {}
        },
        color : {
            type : String,
            default : ''
        },
        isCursorPointer : {
            type : Boolean,
            default : true
        },
        isHideBorder : {
            type : Boolean,
            default : false
        }
    },
    created(){

    }
};
</script>
<style scoped>
.checkbox{border:1px solid #a7a8ba; border-radius:4px; width:22px; height:22px;display:inline-block;vertical-align: top;;}
</style>