<template>
  <div style="min-width:800px;padding:28px;">
        <!-- survey Manage
        {{$GlobalStore.state.testCount}}
        <div>{{$store.getters.curObjectFirst}}</div>
    <button @click="test">클릭</button> -->
    <!-- <div >{{$GlobalStore.getters.curObjectFirst}}</div>
      <div >{{$GlobalStore.computedObjectFirst()}}</div>
            <div >{{$GlobalStore.computedObjectFirst()}}</div> -->
        <!-- <button @click="changeState">클릭</button> -->

    <!-- <div>{{$store.state.authorities}}</div> -->

    <!-- <div v-if="isAllowedFor">허용</div>
    <div v-else>안됨</div> -->
    <div v-if="isAllowedFor('FrmTest', 1)">허용</div>
    <div v-else>안됨</div>
  <div>{{variable2}}</div>
    <button @click="changeAuthorities">바꿈</button>
    <!-- <button @click="changeCount">바꿈2</button>-->
    <button @click="changeCount2">바꿈3</button> 
    <div>{{secondComputed}}</div>
      <!-- <div>{{getFirstValue}}</div> -->
  </div>

</template>

<script>

export default {
  name: 'Test',
  data(){
    return {
      count : 0,
      variable2 : 0,
    }
  },
  methods : {
    test(){
      this.$GlobalStore.changeCount();
    },
    changeState(){

      var newObject = {first : 1000, second : 1000}

      this.$GlobalStore.changeObject(newObject);
    },
    changeAuthorities(){  
      console.log('실행');
      this.$store.commit('setAuthorities', {FrmBarcodePrint : [0,1,-1,-1,-1], FrmTest : [0,1,-1,-1,-1]})
    },
    changeCount(){
      this.count = 1;
    },
    changeCount2(){
      this.variable2++;
    },
    called(){
      console.log('called');
    }

  },
  computed : {
    isAllowedFor(){
       console.log('computed isAllowed');
      // return this.$GlobalStore.isAllowedFor('FrmBarcodePrint', 1);
      // console.log('computed isAllowed');
      return (type, index)=>{console.log('내부실행');this.$GlobalStore.isAllowedFor(type, index)}
    },
    // getFirstValue(){
    //   console.log('first');
    //   return this.$GlobalStore.getters.curObjectFirst;
    // },
    secondComputed(){
      console.log('secondComputed');
    return this.$store.state.authorities['FrmBarcodePrint'];
  }
  },

  created(){
    console.log('surveyMng생성됨 ')

  }
  
}
</script>

<style scoped>
</style>
