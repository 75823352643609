<template >
    <ModalBack :closeAction="hideModal">
        <div class="address_search_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">우편번호검색</div>
            <iframe id="testIframe" src="http://www.ubiplus.co.kr/Address/AddressSearch.aspx" ref="ifr_address" style="border:none;height:100%;width:100%;"></iframe>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="complete">저장</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';

export default {
    name : 'AddressSearch',
    props : {
        hideModal : Function,
        completeAction : {
            type : Function,
            default : () => {}
        }
    },
    components : {
        ModalBack,
        CommonModalCloseBtn
    },
    methods : { 
        complete(){
            const handle = (e) => {
                if(e.data.mode === 'address'){
                    const oriAddress = e.data.address;
                    const detail = e.data.detail;
                    let addr_arr = oriAddress.split('    ');
                    let address = '';
                    let zipCode = '';

                    if(addr_arr.length > 0){
                        address = addr_arr[1].trim();
                        zipCode = addr_arr[0].trim();
                    }

                    this.hideModal();
                    this.completeAction(zipCode, address, detail);
                }

                window.removeEventListener('message', handle);
            }

            window.addEventListener('message', handle);
                
            this.$refs['ifr_address'].contentWindow.postMessage({}, '*');
        }
    },
}
</script>
<style scoped>
.address_search_wrap{
    background-color:white; width:760px; height:830px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}
.asset_bottom_btns{text-align:right;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>