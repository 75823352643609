<template >
    <div>
        <ModalBack :closeAction="hideModal">
            <div class="company_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px; font-weight: bold;">
                <template v-if="curMode === MODE.INSERT">사원등록</template>
                <template v-else-if="curMode === MODE.UPDATE">사원수정</template>
                <template v-else-if="curMode === MODE.BATCH">사원일괄변경</template>
            </div>
            <div class="asset_info_contents">
                <table class="asset_info_tb" style="margin-top:25px;">
                    <colgroup>
                        <col>
                        <col style="width:41px;">
                        <col>
                    </colgroup>
                    <tr>
                        <td>
                            <div class="label required"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.userName" :clickCheck="() => {formChanged.userName = !formChanged.userName}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>사원명</div>
                            <CommonInput v-model="form.userName"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.userClass" :clickCheck="() => {formChanged.userClass = !formChanged.userClass}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>직급</div>
                            <CommonInput v-model="form.userClass"></CommonInput>
                        </td>
                    </tr>
                    <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="2"></td></tr>
                    <tr>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.groupName" :clickCheck="() => {formChanged.groupName = !formChanged.groupName}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>부서</div>
                            <div class="row_flex">
                                <CommonInput style="flex:1;" v-model="form.groupName" :isReadonly="true" :clickInput="() => {isShowGroupSearch = true}"></CommonInput>
                                <MaginifyingGlassButton style="margin-left:5px;" :clickAction="() => {isShowGroupSearch = true}"></MaginifyingGlassButton>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.userMobile" :clickCheck="() => {formChanged.userMobile = !formChanged.userMobile}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>휴대폰</div>
                            <CommonInput v-model="form.userMobile" mode="phone"></CommonInput>
                        </td>
                    </tr>
                    <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="2"></td></tr>
                    <tr>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.userPhone" :clickCheck="() => {formChanged.userPhone = !formChanged.userPhone}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>전화번호</div>
                            <CommonInput v-model="form.userPhone" mode="phone"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.barcode" :clickCheck="() => {formChanged.barcode = !formChanged.barcode}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>바코드</div>
                            <CommonInput v-model="form.barcode"></CommonInput>
                        </td>
                    </tr>
                    <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="2"></td></tr>
                    <tr>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.userEmail" :clickCheck="() => {formChanged.userEmail = !formChanged.userEmail}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>이메일</div>
                            <CommonInput v-model="form.userEmail"></CommonInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.used" :clickCheck="() => {formChanged.used = !formChanged.used}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>재직상태</div>
                            <div style="height:40px; display: flex;align-items: center;">
                                <CommonRadio name="used" v-model="form.used" initVal="1" label="재직" ></CommonRadio>
                                <CommonRadio name="used" v-model="form.used" initVal="0" label="퇴사" style="margin-left:10px;"></CommonRadio>
                            </div>
                        </td>
                    </tr>
                    <tr :class="[curMode === MODE.BATCH ? '' : 'margin']"><td colspan="2"></td></tr>
                    <tr>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.hire_date" :clickCheck="() => {formChanged.hire_date = !formChanged.hire_date}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>입사일</div>
                            <CalendarInput v-model="form.hire_date"></CalendarInput>
                        </td>
                        <td></td>
                        <td>
                            <div class="label"><CommonCheckbox v-if="curMode === MODE.BATCH" :flag="formChanged.retire_date" :clickCheck="() => {formChanged.retire_date = !formChanged.retire_date}" style="vertical-align:bottom;margin-right:5px;"></CommonCheckbox>퇴사일</div>
                            <CalendarInput v-model="form.retire_date"></CalendarInput>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="save">저장</button>
            </div>

        </div>
        </ModalBack>
        <GroupSearch v-if="isShowGroupSearch" :mode="GROUP_SEARCH_MODE.USER" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {form.groupName = (data.text !== '부서코드' ?  data.text : ''); form.groupCode = data.value}"></GroupSearch>
    </div>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CalendarInput from '@/components/common/CalendarInput.vue';
import MaginifyingGlassButton from '@/components/common/MaginifyingGlassButton.vue';
import CommonRadio from '@/components/common/CommonRadio.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';

// import CommonSelect from '@/components/common/CommonSelect.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, GROUP_SEARCH_MODE} from '@/util/Constants.js';

export default {
    name : 'UserRegister',
    props : {
        hideModal : Function,
        data : Object,
        curMode : Number,
        completeAction : {
            type : Function,
            default : () => {}
        },
    },
    components : {
        CommonInput,
        MaginifyingGlassButton,
        ModalBack,
        CommonModalCloseBtn,
        CalendarInput,
        CommonRadio, GroupSearch,
        CommonCheckbox
    },
    data(){
        return {
            MODE : MODE,
            GROUP_SEARCH_MODE : GROUP_SEARCH_MODE,
            isShowGroupSearch : false,
            form : {
                userCode : '',
                userName : '',
                userClass : '',
                groupName : '',
                groupCode : '',
                userMobile : '',
                userPhone : '',
                barcode : '',
                userEmail : '',
                hire_date : '',
                retire_date : '',
                used : '1'
            },
            formChanged : {
                userCode : false,
                userName : false,
                userClass : false,
                groupName : false,
                groupCode : false,
                userMobile : false,
                userPhone : false,
                barcode : false,
                userEmail : false,
                hire_date : false,
                retire_date : false,
                used : false
            }
        }
    },
    watch : {
        'form.userCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.userCode = true;},
        'form.userName' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.userName = true;},
        'form.userClass' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.userClass = true;},
        'form.groupName' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.groupName = true;},
        'form.groupCode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.groupCode = true;},
        'form.userMobile' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.userMobile = true;},
        'form.userPhone' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.userPhone = true;},
        'form.barcode' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.barcode = true;},
        'form.userEmail' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.userEmail = true;},
        'form.hire_date' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.hire_date = true;},
        'form.retire_date' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.retire_date = true;},
        'form.used' :  function(newVal, oldVal) {if(this.curMode !== MODE.BATCH) return; this.formChanged.used = true;},
    },
    methods : {
        save(){
            const parameters = {};
            let path = "/api/user/save";

            if(this.curMode === MODE.INSERT){
                if(this.form.userName === ''){
                    alert('사원명을 입력해주세요.');
                    return
                }
                Object.assign(parameters, this.form);
                delete parameters.userCode;
            } else if(this.curMode === MODE.UPDATE){
                Object.assign(parameters, this.form);
            } else if(this.curMode === MODE.BATCH){
                path = '/api/user/savebatch';

                const queryParameters = this.data.queryParameters;
                const isAll = this.data.isSelectAll;

                for(let key in queryParameters) {
                    parameters[key] = queryParameters[key];
                }
                parameters.isAll = isAll;

                for(const key in this.form){
                    if(this.formChanged[key]){
                        parameters[key] = this.form[key];
                    }
                }
 
                parameters.userCodes = Object.keys(this.data.savedSelectedList);
            }

            axios.post(path, this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    if(this.curMode === MODE.BATCH) {
                        this.data.completeFunc();
                        this.hideModal();
                    } else {
                        this.completeAction();
                        this.hideModal();
                    }
             
                }
            }).catch(res => {
                console.log(res);
            });
        }
        
    },
    created(){
        if(this.curMode === MODE.UPDATE){
            this.form.userName = this.data.userName || '';
            this.form.userCode = this.data.userCode || '';
            this.form.userClass = this.data.userClass || '';
            this.form.groupName = this.data.groupName || '';
            this.form.groupCode = this.data.groupCode || '';
            this.form.userMobile = this.data.userMobile || '';
            this.form.userPhone = this.data.userPhone || '';
            this.form.barcode = this.data.barcode || '';
            this.form.userEmail = this.data.userEmail || '';
            this.form.hire_date = this.data.hire_date || '';
            this.form.retire_date = this.data.retire_date || '';
            this.form.used = this.data.usedCode || '1';
        } else if(this.curMode === MODE.BATCH){
            // this.form.used = '';
        } else if(this.curMode === MODE.INSERT){
            this.form.groupName = this.data.userGroupName;
            this.form.groupCode = this.data.userGroupCode;

        }
    }
}
</script>

<style scoped>
.company_register_wrap{
    background-color:white;     width: 650px;
    height: 560px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_info_wrap{display:flex; flex-direction: column;;height:100%;}
.asset_info_contents{
    flex:1;
    overflow:auto;
}
.asset_bottom_btns{text-align:right;}
.asset_info_tb{width:100%;table-layout: fixed;border-collapse: collapse;}
.margin{height:9px;}
.row_flex{display:flex; }
.label{margin-bottom:5px;font-weight:bold;color:#969696;margin-left:2px;}
.required{color:#fe7681;}
.image_menu_wrap{
    position:absolute;
    right:20px; 
    top:35px;
    background-color:white;   
    border:1px solid #e6e7ec;
    border-radius:5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) ;
}
.image_menu{
    padding:18px 30px;
    width:115px;
    color:#494a4f;    
}

.image_menu + .image_menu{
    border-top:1px solid #e6e7ec;
}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>