import queryString from 'query-string';
import {fields, BROWSER_NAME, NUMBER_STYLE} from '@/util/Constants.js';
import _ from 'lodash'
import XLSX from 'sheetjs-style';

const CommonFunc = (function(){
    const _obj = {}

    _obj.convertToForm = function(parameters){
        // return '';
        return queryString.stringify(parameters, {arrayFormat: 'bracket'});
    }

    _obj.setFieldNames = function(column){

        const hasOwnProperty = Object.prototype.hasOwnProperty;

        // for(const index in column){
        for(let index = column.length - 1; index >= 0; index--){
            const key = column[index].key

            if(hasOwnProperty.call(fields, key)){
                if(fields[key].used === '1'){
                    column[index].name = fields[key].name;
                }else {
                    column.splice(index, 1)
                    // delete column[index];
                }
            }
        }

    }

    _obj.uncomma = function(str){
        str = String(str);
        return str.replace(/[^\d]+/g, '');
    }

    _obj.comma = function(str){
        if(str == null) return '';
        if(str === '.0000') str = 0;
        var parts = str.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    _obj.fixed3 = function(str){
        if(str == null) return '';
        if(str === '.0000') str = 0.000;
        return str.toFixed(3);
    }   

    _obj.getBrowser = function(){
        const agent = window.navigator.userAgent.toLowerCase();
        let browser;
        switch (true) {
            case agent.indexOf("edge") > -1: 
            browser = BROWSER_NAME.ORIGINAL_EDGE; // MS 엣지
            break;
            case agent.indexOf("edg/") > -1: 
            browser = BROWSER_NAME.CHROME_EDGE; // 크롬 기반 엣지
            break;
            case agent.indexOf("opr") > -1 && !!window.opr: 
            browser = BROWSER_NAME.OPERA; // 오페라
            break;
            case agent.indexOf("chrome") > -1 && !!window.chrome: 
            browser = BROWSER_NAME.CHROME; // 크롬
            break;
            case agent.indexOf("trident") > -1: 
            browser = BROWSER_NAME.IE // 익스플로러
            break;
            case agent.indexOf("firefox") > -1: 
            browser = BROWSER_NAME.FIREFOX; // 파이어 폭스
            break;
            case agent.indexOf("safari") > -1: 
            browser = BROWSER_NAME.SAFARI; // 사파리
            break;
            default: 
            browser = BROWSER_NAME.ETC; // 기타
        }

        return browser;
    }

    _obj.makeStatisStructure = function(itemKeys, reportStructure, reportCnt, searchName, parentCodes){
        const reportCntHashed = {};
        
        let totalItemCnt = 0;
        const totalItemCntArr = {};
        for(let i = 0; i < itemKeys.length; i++){
            totalItemCntArr[itemKeys[i]] = 0;
        }

        for(const key in reportCnt){
            const each = reportCnt[key];
            reportCntHashed[each.code + each.name] = each.cnt;
            totalItemCntArr[each.name] += each.cnt;
            totalItemCnt += each.cnt;
        }

        const hasOwnProperty = Object.prototype.hasOwnProperty;

        //0. 최대 레벨
        const levelMaxObj = _.maxBy(reportStructure, 'level');
        if(!levelMaxObj){
            this.list = [];
            return;
        } 
            let maxLevel = levelMaxObj.level;
            //max level을 바꾸면 분류가 추가되서 나옴.
        // maxLevel = 3;

        //1. 값입력
        for(let i = 0; i < reportStructure.length; i++){
            const curRow = reportStructure[i];
            for(let i = 1; i < maxLevel + 1; i++){
                curRow['level' + i] = '';
            }
            curRow['level' + curRow.level] = curRow.name;

            let totalCnt = 0;
            for(let j = 0; j < itemKeys.length; j++){
                if(hasOwnProperty.call(reportCntHashed, curRow.code + itemKeys[j])){
                    curRow[itemKeys[j]] = reportCntHashed[curRow.code + itemKeys[j]];
                }else { 
                    curRow[itemKeys[j]] = 0
                }
                totalCnt += curRow[itemKeys[j]];
            }

            curRow['총계'] = totalCnt;
        }

        //2. 레벨별 소계 합계 행 추가.
        const insertList = []


        const insert = (index, level, cntArr) => {
            const row = {}
            row.index = index + 1;
            row.level = level; 
            for(let i = 1; i < maxLevel + 1; i++){
                if(i > level + 1){
                    row['level' + i] = {rowspan : 1, value : ''};
                } else {
                    row['level' + i] = '';
                }
            }

            if(level === 1){
                row['level' + (level + 1)] = '[합계]';
            }else {
                row['level' + (level + 1)] = '[소계]';
            }
            row['level' + (level)] = {rowspan : 0};
            
            insertList.push(row);


            let totalCnt = 0;
            for(let i = 0; i < itemKeys.length; i++){
                row[itemKeys[i]] = cntArr[i];
                totalCnt += cntArr[i];
                cntArr[i] = 0;
            }

            row['총계'] = totalCnt;
        }

        const cntArr = [];
        for(let i = 0; i < itemKeys.length; i++){
            cntArr.push(0);
        }

        for(let level = maxLevel - 1; level > 0; level--){

            let deepStart = false;
            
            for(let i = 0; i < reportStructure.length; i++){
                const curRow = reportStructure[i];

                for(const key in itemKeys){
                    cntArr[key] += curRow[itemKeys[key]];
                }

                //레벨변화하는 부분에 행을 넣어줌. 혹은 마지막 라인
                if(deepStart && (i === reportStructure.length - 1 || reportStructure[i + 1].level <= level)){
                    insert(i, level, cntArr);
                    deepStart = false;
                    continue;
                } 

                if(curRow.level < level){
                    //행만들어줌.
                    insert(i, level, cntArr);
                }else if(level === curRow.level){
                    //다음 행이 있고, 레벨이 높다면 이 그룹의 마지막에 넣어줌.
                    if(i !== reportStructure.length - 1 && reportStructure[i + 1].level > level){
                        deepStart = true;
                        continue;
                    //없다면 여기에 넣어줌. 마지막라인.
                    } else {
                        insert(i, level, cntArr);
                    }
                }
            }
        }

        let sortedInsertList = _.sortBy(insertList, ['index']).reverse();
        // console.log(sortedInsertList);
        // console.log(window.copyObject(insertList));

        //3. rowspan 입력
        for(let i = 0; i < sortedInsertList.length; i++){
            let curRow = sortedInsertList[i];
            reportStructure.splice(curRow.index, 0, curRow);
        }

        // console.log(reportStructure);
        

        if(searchName){
            let searchIndex = 0;
            for(let i = 0; i < reportStructure.length; i++){
                if(reportStructure[i].name === searchName) {
                    searchIndex = i;
                    break;
                }
            }

            reportStructure.splice(0, searchIndex) //찾은 부서의 index
        }

        for(let level = 1; level < maxLevel; level++){
            let deepStart = false;
            let index = -1;
            let cnt = 0;
            const levelName = 'level' + level;
            for(let i = 0; i < reportStructure.length; i++){
                const curRow = reportStructure[i];
                if(deepStart){
                    if(typeof curRow[levelName] === 'object'){
                        deepStart = false;
                        cnt++;
                        reportStructure[index][levelName] = {rowspan : cnt, value : reportStructure[index][levelName]} 
                        reportStructure[index].style = {borderRight : '1px solid #e6e7ec'};
                        index = -1;
                        cnt = 0;
                    }else {
                        cnt++;
                        reportStructure[i][levelName] = {rowspan : 0}
                    }
                }else {
                    deepStart = true;
                    if(typeof curRow[levelName] === 'string' && (curRow[levelName] === '[합계]' || curRow[levelName] === '[소계]')) deepStart = false;
                    if(typeof curRow[levelName] === 'object' && (curRow[levelName].rowspan === 1)) deepStart = false;
                    cnt = 1;
                    index = i;
                }
            }
        }

        //4. filter 있다면 일부 변경
        if(searchName){
            for(const key in parentCodes){
                const curParentCode = parentCodes[key];
                reportStructure[0]['level' + curParentCode.level].value = curParentCode.name;
            }
            // reportStructure[0].level1.value = '테스트부서0';
            // reportStructure[0].level2.value = '테스트부서1-2';
        }

        // console.log(reportStructure);
        //총계 마지막줄
        let totalData = {level1 : '[총계]', 총계 : totalItemCnt};
        for(const key of itemKeys){
            totalData[key] = totalItemCntArr[key];
        }
        reportStructure.push(totalData);

        const column = [];
        for(let i = 1; i <= maxLevel; i++){
            column.push({key : 'level' + i, name : i + '차분류', w : '120px', style : {paddingLeft : '5px'}});
        }

        for(const key of itemKeys){
            column.push({key : key, name : key, w : '120px', comma : true, style : NUMBER_STYLE});
        }
        column.push({key : '총계', name : '총계', w : '120px', comma : true, style : NUMBER_STYLE});
        
        return [column, reportStructure];
    }

    _obj.downloadExcel = function(fileName){
        const header = document.querySelectorAll('.common_tb')[0];
        const body = document.querySelectorAll('.common_tb')[1];

        var table = document.createElement("table");
        table.innerHTML = header.innerHTML + body.innerHTML;
        const wb = XLSX.utils.table_to_book(table);
        const ws = wb.Sheets.Sheet1;

        let wsKeys = Object.keys(ws);
        wsKeys = wsKeys.filter((val) => {
            return !(val.indexOf('!') > -1)
        })

        for(const key of wsKeys){
            ws[key].s = {
                font: {
                    name: 'Arial',
                    sz: 10,
                },
            }
            if(typeof ws[key].v === 'number'){
                ws[key].t = 's';
                ws[key].v = _obj.comma(ws[key].v);
                ws[key].s.alignment = {
                    horizontal : 'right'
                }
            } 
        }

        XLSX.writeFile(wb, fileName + '.xlsx');
    }

    return _obj;

})();


export default CommonFunc;