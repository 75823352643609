<template>
    <div style="height:100%; min-width:1085px;padding:28px; display:flex; flex-direction:column;">
        <slot name="pageheader"></slot>
        <div style="flex:1; display:flex;overflow: hidden;">
            <div v-if="isShowLeftPanel" class="left_panel">
                <slot name="left_panel"></slot>
            </div>
            <div v-if="isShowRightPanel" class="right_panel" :style="{marginLeft : isShowLeftPanel ? '16px' : ''}">
                <div class="top_panel" v-if="isShowTopPanel">
                    <slot name="top_panel"></slot>
                </div>
                
                <div class="search_panel" v-if="isShowSearchPanel">
                    <slot name="search_panel"></slot>
                </div>
                <div class="table_panel_above" v-if="isShowTablePanelAbove">
                    <slot name="table_panel_above" ></slot>
                </div>
                <div class="table_panel" v-if="isShowTablePanel">
                    <slot name="table_panel"></slot>
                </div>
                <div class="table_panel" style="margin-top:0px;" v-if="isShowTablePanelWithoutTopMargin">
                    <slot name="table_panel_without_top_margin"></slot>
                </div>
            </div>
        </div>
        <slot name="etc"></slot>
    </div>
</template>
<script>
export default{
    name : 'TableSearchPanel',
    props: {
        isShowLeftPanel : {
            type : Boolean,
            default : false
        },
        isShowRightPanel : {
            type : Boolean,
            default : false
        },
        isShowSearchPanel : {
            type : Boolean,
            default : false
        },
        isShowTablePanel : {
            type : Boolean,
            default : false
        },
        isShowTopPanel : {
            type : Boolean,
            default : false
        },
        isShowTablePanelAbove : {
            type : Boolean,
            default : false
        },
        isShowTablePanelWithoutTopMargin : {
            type : Boolean,
            default : false
        }
    }
}
</script>
<style scoped>
.left_panel{padding:24px 20px; background-color:white;width:430px;border-radius:4px; border:1px solid #e6e7ec;display:flex; flex-direction: column;;}
.right_panel{flex:1;display:flex;flex-direction:column; overflow: hidden;}
.search_panel{padding:22px; border:1px solid #e6e7ec; border-radius:4px;background-color:white;}
.table_panel{
  padding: 10px 20px 17px 20px;
  margin-top:14px;
  flex:1;;
  background-color:white;
  border-radius:4px;
  border:1px solid #e6e7ec;
  display:flex; 
  flex-direction: column;;
  overflow:hidden;
}

</style>
