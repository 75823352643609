const GlobalStore = (function(){
    let store;
    const _obj = {};

    const hasOwnProperty = Object.prototype.hasOwnProperty;

    _obj.init = function(pStore){
        store = pStore;
        _obj.state = store.state;
        _obj.getters = store.getters;

        // console.log('store init');
        // console.log(pStore.getters.curObjectFirst);
        // _obj.computedObjectFirst = pStore.getters.curObjectFirst;

    }

    _obj.changeCount = function(){
        store.commit('increaseCount');
    }

    _obj.changeObject = function(newObject){
        store.commit('changeObject', newObject);
    }

    _obj.computedObjectFirst = function(){
        return store.getters.curObjectFirst;
    }

    _obj.changeOpenMenuKey = function(key){
        store.commit('changeOpenMenuKey', key)        
    }

    _obj.setLeftMenuScrollTop = function(scrollTop){
        store.commit('setLeftMenuScrollTop', scrollTop);
    }

    _obj.isAllowedFor = function(type, index){
        return hasOwnProperty.call(store.state.authorities, type) && store.state.authorities[type][index] === 1;  
    }

    _obj.refreshAssetMng = function(){
        store.commit('refreshAssetMng');
    }

    _obj.setAuthorities = function(authorities){
        const newAuthorities = {};
        newAuthorities.frmAsset = authorities.frmAsset.split(';').map((each) => parseInt(each));
        newAuthorities.frmAssetCardReport = authorities.frmAssetCardReport.split(';').map((each) => parseInt(each));
        newAuthorities.frmAssetPresentStatus = authorities.frmAssetPresentStatus.split(';').map((each) => parseInt(each));
        newAuthorities.frmAuthority = authorities.frmAuthority.split(';').map((each) => parseInt(each));
        newAuthorities.frmBarcodePrint = authorities.frmBarcodePrint.split(';').map((each) => parseInt(each));
        newAuthorities.frmBaseInfo = authorities.frmBaseInfo.split(';').map((each) => parseInt(each));
        newAuthorities.frmCondition = authorities.frmCondition.split(';').map((each) => parseInt(each));
        newAuthorities.frmDepreciation = authorities.frmDepreciation.split(';').map((each) => parseInt(each));
        newAuthorities.frmDepreciationSetting = authorities.frmDepreciationSetting.split(';').map((each) => parseInt(each));
        newAuthorities.frmEmployee = authorities.frmEmployee.split(';').map((each) => parseInt(each));
        newAuthorities.frmExcelLoad = authorities.frmExcelLoad.split(';').map((each) => parseInt(each));
        newAuthorities.frmExportPDA = authorities.frmExportPDA.split(';').map((each) => parseInt(each));
        newAuthorities.frmExportSmartPhone = authorities.frmExportSmartPhone.split(';').map((each) => parseInt(each));
        newAuthorities.frmGenuinePDA = authorities.frmGenuinePDA.split(';').map((each) => parseInt(each));
        newAuthorities.frmGenuineSmartPhone = authorities.frmGenuineSmartPhone.split(';').map((each) => parseInt(each));
        newAuthorities.frmImportPDA = authorities.frmImportPDA.split(';').map((each) => parseInt(each));
        newAuthorities.frmImportSmartPhone = authorities.frmImportSmartPhone.split(';').map((each) => parseInt(each));
        newAuthorities.frmInitDB = authorities.frmInitDB.split(';').map((each) => parseInt(each));
        newAuthorities.frmRFIDPrint = authorities.frmRFIDPrint.split(';').map((each) => parseInt(each));
        newAuthorities.frmSurvey = authorities.frmSurvey.split(';').map((each) => parseInt(each));
        newAuthorities.frmUserLogin = authorities.frmUserLogin.split(';').map((each) => parseInt(each));
        newAuthorities.frmSetting = authorities.frmSetting.split(';').map((each) => parseInt(each));
        store.commit('setAuthorities', newAuthorities);
    }
    
    _obj.setLoginInfo = function(userName, levelName){
        store.commit('setLoginInfo', {userName : userName, levelName : levelName});
    }

    _obj.getLoginUserName = function(){
        return store.state.loginInfo.userName;
    }

    _obj.getLoginLevelName = function(){
        return store.state.loginInfo.levelName;
    }

    return _obj;
})();



export default GlobalStore;
