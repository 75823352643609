<template>
    <div class="asset_change_wrap">
        <div class="asset_change_contents">
            <BatchBtns 
                :is-show-first-checkbox="true" 
                :is-show-delete-btn="true"
                :delete-action="deleteList" 
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :downloadExcel="downloadExcel"
            ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :plist="list" 
                    :pcolumn="column" 
                    :pcustom="custom" 
                    :isShowCheckbox="true" 
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="memoIdx"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    :clickDoubleRow="(data) => {curMode = MODE.UPDATE; curMemoData = data;showModiForm();}"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>
        </div>
        <div class="asset_bottom_btns">
            <button class="btn_ok" @click="() => {curMode = MODE.INSERT; isShowAssetMemoRegister = true}">메모등록</button>
            <!-- <button class="btn_ok" style="margin-left:7px;" @click="showModiForm">메모수정</button> -->
            <button class="btn_canel" @click="hideAssetDetail" style="margin-left:7px;">닫기</button>
        </div>

        <AssetMemoRegister v-if="isShowAssetMemoRegister" :hideModal="() => {isShowAssetMemoRegister = false}" :assetCode="assetCode" :data="curMemoData" :curMode="curMode" :completeAction="() => {savedSelectedList = {}; getMemoHistory();}"></AssetMemoRegister>
    </div>
</template>
<script>
import BatchBtns from '@/components/common/BatchBtns.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import AssetMemoRegister from '@/components/modal/AssetMemoRegister.vue';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE} from '@/util/Constants.js';
export default {
    name : 'AssetMemoHistoryPop',
    components : {
        BatchBtns, CommonTable, ColumnSetting, AssetMemoRegister
    },
    props: {
        hideAssetDetail : Function,
        transferData : {
            type : Object,
            default : () => {
                return {}
            }
        }
    },
    data(){
        return {
            MODE : MODE,
            curMode : -1,
            curMemoData : null,
            isShowColumnSettings : false,
            isShowAssetMemoRegister : false,
            assetCode : '',
            savedSelectedList : {},
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },
            list: [],
            custom : {
                
            },
            column: [
                { key: "memoDate", name: "메모일자", w: "110px", dateLength10 : true},
                { key: "memo", name: "메모내용", w: "400px"},
                { key: "writerName", name: "등록자", w: "100px" },
                { key: "writerGroupName", name: "등록자부서", w: "100px" },
            ],
        }
    },
    methods : {
        showModiForm(){
            if(!this.curMemoData) return;
            this.isShowAssetMemoRegister = true
        },
        deleteList(){
            const parameters = {}; //this.getParameters();
            let deleteCnt = this.searchCnt;
            if(!this.btnState.isSelectAll){
                parameters.memoIdxes = Object.keys(this.savedSelectedList);
                deleteCnt = parameters.memoIdxes.length;
            }

            if(!confirm('[' + deleteCnt + ']개의 메모내역을 삭제하시겠습니까?')){
                return;
            }

            axios.post('/api/asset/memohistory/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.getMemoHistory()
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getMemoHistory(){
            this.savedSelectedList = {};
            const parameters = this.getParameters();

            axios.get('/api/asset/memohistory', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    this.list = res.data.data.assetMemoHistory.content;
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {}; 
            parameters.conditions = "np_assetCode";
            parameters.searchWords = this.assetCode; 
            parameters.deleted = 0; 
            parameters.page = 1;
            parameters.sizePerPage = 1000;
            return parameters;
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/asset/memohistory/excel?" + queryStr);
            location.href = this.$versionPath + "/api/asset/memohistory/excel?" + queryStr
        },
        
    },
    created(){
        this.assetCode = this.transferData.data.assetCode;
        this.getMemoHistory();
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
.asset_change_wrap{display:flex; flex-direction: column;;height:100%;overflow: hidden;}
.asset_change_contents{flex:1; display:flex; flex-direction: column;overflow: hidden;}
.asset_bottom_btns{text-align:right;border-top:1px solid #e6e7ec; padding-top:14px;}
.btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
</style>