
const GlobalRouter = (function(){
    let router;
    let versionPath;
    const _obj = {};

    _obj.init = function(pRouter, pVersionPath){
        router = pRouter;
        versionPath = pVersionPath;
        // _obj.state = store.state;
    }

    _obj.push = function(path){
        router.push({ path: versionPath + path });
    }

    return _obj;
})();



export default GlobalRouter;
