<template>
  <div class="tb_wrap">
      <div class="tb_header_scrollbar" ref="tableHeader">
        <table class="common_tb">
          <thead>
            <tr @mousemove="detectColMoving($event)" @mouseup="detectColMoveEnd" @mouseleave="detectColLeave" class="header_tr">
              <td v-if="isShowNumbering" class="common_td tb_fixed_col td_head" style="padding-left:5px;" :style="{width: firstColWidth, zIndex:10 }">
                <div class="column_wrap">
                  <div class="column_con">&nbsp;</div>
                  <div class="column_line" @mousedown="detectColMoveStart($event, -1)" :style="{cursor: !isUseDragWidth ? 'none' : ''}"></div>
                </div>
              </td>
              <td v-if="isShowCheckbox" class="common_td tb_fixed_col td_head" :style="{width: checkboxColWidth, zIndex:10, left: firstColWidth }">
                <div class="column_wrap">
                  <div class="column_con">&nbsp;</div>
                  <div class="column_line" @mousedown="detectColMoveStart($event, -2)" :style="{cursor: !isUseDragWidth ? 'none' : ''}"></div>
                </div>
              </td>
               <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
              <td
                class="common_td td_head"
                v-for="(col, i) in column"
                :class="[col.fixed ? 'tb_fixed_col' : '']"
                :key="i"
                v-if="col.show !== false"
                :style="{ width: col.w, left: col.fixed ? accumulatedWidth[i + 1] : '', zIndex: col.fixed ? 9 - i : ''}"
                @mousedown="sort(col.key)"
              >
                <div class="column_wrap">
                  <div class="column_con">{{ col.name }}</div>
                  <div class="column_line" @mousedown="$event.stopPropagation();detectColMoveStart($event, i)" :style="{cursor: !isUseDragWidth ? 'none' : ''}"></div>
                </div>
              </td>
              <td class="common_td" style="width:unset"></td>
            </tr>
          </thead>
        </table>
      </div>
      <div
        class="tb_tbody_wrap"
        :style="{ height: 'calc(100% - ' + headerHeight + ' - ' + pagingHeight + ')' }"
        ref="scrollWrap"
        @scroll="scrolled($event)"
      >
        <div :style="{ height: maxHeight + 'px' }">
          <div style="height: 1px; margin-top: -2px"></div> 
          <table class="common_tb" ref="tb_ref">
            <tbody> 
              <tr class="common_tr" 
                  :class="[i + curTopRowIndex === clickedIndex ? 'active' : '']" 
                  v-for="(each, i) in list" 
                  :key="i + curTopRowIndex" 
                  @click="clickRowBefore(each, i + curTopRowIndex)"
                  @dblclick="clickDoubleRowBefore(each, i + curTopRowIndex)"
              >
                <td v-if="isShowNumbering" class="common_td tb_fixed_col" style="padding-left:5px;" :style="{width: firstColWidth}">{{paging ? paging.number * paging.size + i + 1 : i + curTopRowIndex + 1}}</td>
                <td v-if="isShowCheckbox" class="common_td tb_fixed_col" :style="{width: checkboxColWidth, left: firstColWidth }" @click="$event.stopPropagation();toggleCheckbox(i, i + curTopRowIndex)">
                  <div class="checkbox" :style="{borderColor : each._selected === true ? 'black' : ''}">
                    <font-awesome-icon v-if="each._selected === true" icon="fa-solid fa-check" style="margin-left:1px;font-size:19px;color:#a7a8ba;" :style="{color : each._selected === true ? 'black' : ''}"/>
                  </div>
                </td>
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <template v-for="(col, j) in column">
                  <template v-if="col.show !== false">
                    <!-- 그룹 -->
                    <template v-if="each[col.key] != null && each[col.key].hasOwnProperty('rowspan')">
                      <td class="text_color common_td"
                        :class="[col.fixed ? 'tb_fixed_col' : '', pcustom[col.key] == null ? 'ellipsis' : '']" 
                        :key="j" 
                        v-if="each[col.key].rowspan !== 0"
                        :rowspan="each[col.key].rowspan"
                        :style="[{width: col.w, left: col.fixed ? accumulatedWidth[j + 1] : ''}, col.style, each.style]">
                        <template v-if="pcustom[col.key] != null"><component :is="pcustom[col.key]" :data="each" :column="col" :index="i + curTopRowIndex"></component></template>
                        <template v-else>{{ each[col.key].value}}</template>
                      </td>
                    </template>
                    <template v-else>
                      <td class="text_color common_td"
                        :class="[col.fixed ? 'tb_fixed_col' : '', pcustom[col.key] == null ? 'ellipsis' : '']" 
                        :key="j" 
                        :style="[{width: col.w, left: col.fixed ? accumulatedWidth[j + 1] : ''}, col.style]">
                        <template v-if="pcustom[col.key] != null"><component :is="pcustom[col.key]" :data="each" :column="col" :index="i + curTopRowIndex"></component></template>
                        <template v-else-if="col.comma">{{ each[col.key] | comma}}</template>
                        <template v-else-if="col.dateLength10">{{ each[col.key] | dateLength10}}</template>
                        <template v-else-if="col.dateLength16">{{ each[col.key] | dateLength16}}</template>
                        <template v-else-if="col.dateLength19">{{ each[col.key] | dateLength19}}</template>
                        <template v-else>{{ each[col.key]}}</template>
                      </td>
                    </template>
                  </template>
                </template>
               
                <td class="common_td" style="width:unset"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div ref="paging" v-if="paging" >
        <div class="underline"></div>
        <div style="display:flex;margin-top:20px;">
          <div style="padding:12px;color:#969696;">Records: {{paging.number + 1}}-{{paging.totalPages}} of <span style="color:#222536">{{paging.totalElements | comma}}</span></div>
          <div style="margin-left:auto;">
            <div class="paging_arrow" v-if="paging.number >= 5" @click="changePage(1)">
              <img :src="require('@/assets/menu_arrow.png')" class="arrow_img" style="transform:rotate(180deg)">
              <img :src="require('@/assets/menu_arrow.png')" style="transform:rotate(180deg); margin-left: -3px;"  class="arrow_img">
            </div>
            <div class="paging_arrow" v-if="paging.number >= 5" style="margin-left:12px;margin-right:12px;" @click="changePage(startPageIndex - 4)">
              <img :src="require('@/assets/menu_arrow.png')"  class="arrow_img" style="transform:rotate(180deg);margin-left: 4px;">
              </div>
            <template v-for="(n, i) in 5">
              <div class="paging_num" :class="[i === (paging.number % 5) ? 'active' : '']" :key="i" v-if="i + 1 + startPageIndex <= paging.totalPages" @click="changePage(i + 1 + startPageIndex)">{{i + 1 + startPageIndex}}</div>
            </template>
            <div class="paging_arrow" style="margin-left:12px;" v-if="!isLastPageScreen" @click="changePage(startPageIndex + 6)">
              <img :src="require('@/assets/menu_arrow.png')"  class="arrow_img" style=" margin-left: 4px;">
            </div>
            <div class="paging_arrow" style="margin-left:12px;" v-if="!isLastPageScreen" @click="changePage(paging.totalPages)">
              <img :src="require('@/assets/menu_arrow.png')" class="arrow_img">
              <img :src="require('@/assets/menu_arrow.png')" class="arrow_img" style="margin-left: -3px;">
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  name: "CommonTable",
  props: {
    plist: Array,
    pcolumn: Array,
    pcustom: {
      type : Object,
      default: () => {
        return {}
      }
    },
    paging : Object,
    isShowPartial : {
      type : Boolean,
      default : false
    },
    isShowNumbering : {
      type : Boolean,
      default : false
    },
    changePage : Function,
    isShowCheckbox : {
      type : Boolean,
      default : false
    },
    clickRow : {
      type : Function,
      default : () => {}
    },
    clickDoubleRow : {
      type : Function,
      default : () => {}
    },
    savedSelectedList : {
      type : Object,
      default : null
    },
    savedSelectedListKey : {
      type : String,
      default : ''
    },
    signalSelectCurPageAll : {
      type : Boolean,
      default : false
    },
    signalUnselectCurPageAll : {
      type : Boolean,
      default : false
    },
    btnState : {
      type : Object,
      default : () => {
          return {
              isSelectedOneAtLeast : true,
              isSelectAll : false,
              signalSelectCurPageAll : false,
              signalUnselectCurPageAll : false,
          }
      }
    },
    isUseSort : {
      type : Boolean,
      default : false,
    },
    isUseDragWidth : {
      type : Boolean,
      default : false
    },
    isKeepClickedIndex : {
      type : Boolean,
      default : false
    },
    isGoTopWhenRefresh : {
      type : Boolean,
      default : true
    }
  },
  data() {
    return {
      list: [],
      clickedIndex : -1,
    //   listStartIndex : 0,
      originalList: [],
      headerHeight: "0px",
      eachRowHeight: 47,
      maxHeight: 0,
      sparesCnt: 30,
      column: [],
      colMouseStartX: 0,
      isColMouseMoving: false,
      colStartWidth: 0,
      colIndex: 0,
      accumulatedWidth : [],
      firstColWidth : '20px',
      checkboxColWidth : '42px',
      curSortKey: '',
      isCurSortDirectionAsc : false,
      curSparesCnt: 30, //sparseCnt와 동일 
      curTopRowIndex: 0,
      topSparesCnt : 20,
      pagingHeight : '0px',
    };
  },
  watch : {
    plist(){
      if(!this.isKeepClickedIndex) this.clickedIndex = -1;
      this.setFirstColWidth();
      if(this.isGoTopWhenRefresh) this.$refs['scrollWrap'].scrollTop = 0;
      this.setFirstScroll();
      this.renewDisplayRows();
    },
    pcolumn(){
      this.column = this.pcolumn;
    },
    signalSelectCurPageAll(){
      this.changeSelectedState(true)
    },
    signalUnselectCurPageAll(){
      this.changeSelectedState(false)
    },
  },
  computed : {
    isLastPageScreen (){
      return parseInt(this.paging.number / 5) === parseInt(this.paging.totalPages / 5)
    },
    startPageIndex(){
      return (parseInt((this.paging.number) / 5)) * 5 ;
    },
  },
  methods: {
    __getSelectedList(){
      let selectedList = [];
      for(let i = 0; i < this.originalList.length; i++){
        if(this.originalList[i]._selected === true) selectedList.push(this.originalList[i]);
      }

      return selectedList;
    },
    __getClickedIndex(){
      return this.clickedIndex;
    },
    __setClickedIndex(index){
      this.clickedIndex = index;
    },
    clickRowBefore(each, index){
      this.clickedIndex = index;
      this.clickRow(each);
    },
    clickDoubleRowBefore(each, index){
      this.clickedIndex = index;
      this.clickDoubleRow(each);
    },
    detectColMoveStart(e, colIndex) {
      const obj = e.currentTarget.parentNode.parentNode;
      this.isColMouseMoving = true;
      this.colMouseStartX = e.clientX;
      this.colIndex = colIndex;
      this.colStartWidth = parseInt(obj.style.width.replace("px", ""));
    },
    detectColMoving(e) {
      if(this.isColMouseMoving){
        let newWidth = this.colStartWidth + (e.clientX - this.colMouseStartX) + "px";
        if(this.colIndex === -1){ //넘버링 컬럼
          this.setFirstColWidth();
          this.firstColWidth = newWidth;
        }else if(this.colIndex === -2){ //체크박스 컬럼
          this.setFirstColWidth();
          this.checkboxColWidth = newWidth;
        }else {
          this.column[this.colIndex].w = newWidth;
        }
      }
    },
    detectColMoveEnd() {
      this.isColMouseMoving = false;
    },
    detectColLeave(){
       this.isColMouseMoving = false;
    },
    changeSelectedState(flag){
      for(const index in this.originalList){
          this.originalList[index]._selected = flag;
      }

      for(const index in this.list){
        this.$set(this.list[index], '_selected', flag);
      }

      if(this.savedSelectedList){
        if(flag){
          for(const index in this.originalList){
            const key = this.originalList[index][this.savedSelectedListKey];
            this.$set(this.savedSelectedList, key, JSON.parse(JSON.stringify(this.originalList[index])));
          }
          this.$set(this.btnState, 'isSelectedOneAtLeast', true);
        }else {
          for(const index in this.originalList){
            const key = this.originalList[index][this.savedSelectedListKey];
            this.$delete(this.savedSelectedList, key);
          }
          this.$set(this.btnState, 'isSelectedOneAtLeast', false);
        }
      } 

    },
    scrolled(e) {
        const element = e.currentTarget;

        //헤더 좌우 스크롤
        const scrollLeft = element.scrollLeft;
        this.$refs["tableHeader"].scrollLeft = scrollLeft;

        const scrollTop = element.scrollTop; //계속 변함
        const topRowIndex = parseInt(scrollTop / this.eachRowHeight);

        const leftTopCnt = (this.topSparesCnt > topRowIndex) ? topRowIndex : this.topSparesCnt

        if(!this.isShowPartial) return;
        //마진 위치
        const displayBaseTop = element.scrollTop - (scrollTop % this.eachRowHeight)
        const sparesBaseTop = displayBaseTop - (leftTopCnt * this.eachRowHeight);

        this.$refs["tb_ref"].style.marginTop = sparesBaseTop + "px";

        //화면상 보이는 가장 위쪽 행 부터 아래까지 몇개인지
        const leftCount = parseInt((this.maxHeight - displayBaseTop) / this.eachRowHeight);
        let count = this.sparesCnt;
        count = leftCount < count ? leftCount : count;

        this.curSparesCnt = count + leftTopCnt; //위쪽 여분과 보이는 가장위쪽 행 부터 여분갯수만큼(끝에 가까워지면 차감)
        this.curTopRowIndex = topRowIndex - leftTopCnt; //위쪽 여분 시작부분부터

        this.renewDisplayRows();
    },
    setFirstColWidth(){

      //넘버링 숫자 크기에 맞춰서 넓이 수정
      if(this.paging && this.paging.number){
        this.firstColWidth = ((this.paging.number * this.paging.size).toString().length * 10 + 10)  + 'px';
      }else {
        const strLen = this.plist.length;
        if(strLen > 9){
          strLen.toString().length * 10 + 15 + 'px';
        }else {
          this.firstColWidth = '25px'
        }
      
      }

      if(!this.isShowNumbering) this.firstColWidth = '0px';
      if(!this.isShowCheckbox) this.checkboxColWidth = '0px';

      //넘버링 width, checkbox
      this.accumulatedWidth = [];
      this.accumulatedWidth.push(this.firstColWidth);
      let curTotalWidth = parseInt(this.firstColWidth.replace('px', '')) + parseInt(this.checkboxColWidth.replace('px', ''));
      this.accumulatedWidth.push(curTotalWidth + 'px');

      //고정된 앞의 컬럼들
      for(let i = 0; i < this.pcolumn.length; i++){
        if(this.pcolumn[i].fixed){
          curTotalWidth += parseInt(this.pcolumn[i].w.replace('px', ''))
          this.accumulatedWidth.push(curTotalWidth + 'px');
        }
      }

    },
    renewDisplayRows(){

      //보여줄 리스트.
      let arr = [];
      let length = this.curSparesCnt;

      if(this.originalList.length  <= this.curSparesCnt){
        length = this.originalList.length;
      }

      if(this.savedSelectedList){
        let isSelectedOneAtLeast = false;
        for (let i = 0; i < length; i++) {
          const curData = this.originalList[i + this.curTopRowIndex]
          if(this.savedSelectedList[curData[this.savedSelectedListKey]]){
            isSelectedOneAtLeast = true;
            curData._selected = true;
          }
          arr.push(curData);
        }

        this.$set(this.btnState, 'isSelectedOneAtLeast', isSelectedOneAtLeast);
 
      }else {
        for (let i = 0; i < length; i++) {
          arr.push(this.originalList[i + this.curTopRowIndex]);
        }
      }

      this.list = JSON.parse(JSON.stringify(arr));
      // this.list = arr;
    },
    sort(key){
      if(!this.isUseSort) return

      if(this.curSortKey === key){
        this.isCurSortDirectionAsc = !this.isCurSortDirectionAsc;
      }else {
        this.isCurSortDirectionAsc = true;
        this.curSortKey = key;  
      }

      let newList = _.sortBy(this.originalList, key);
      if(!this.isCurSortDirectionAsc) newList = newList.reverse();
      this.originalList = newList;
      this.renewDisplayRows();
    },
    setFirstScroll(){
      this.curTopRowIndex = 0;

      if(!this.isShowPartial) this.curSparesCnt = this.plist.length;
    
      this.originalList = JSON.parse(JSON.stringify(this.plist));
      this.maxHeight = this.eachRowHeight * this.originalList.length;
    },
    toggleCheckbox(curListIndex, originalListIndex){
      let flag = true;
      if(this.originalList[originalListIndex]._selected) flag = !this.originalList[originalListIndex]._selected;
      if(this.savedSelectedList){
        const key = this.list[curListIndex][this.savedSelectedListKey]
        if(flag){
          this.$set(this.savedSelectedList, key, JSON.parse(JSON.stringify(this.list[curListIndex])));
          this.$set(this.btnState, 'isSelectedOneAtLeast', true);
        }else {
          this.$delete(this.savedSelectedList, key);
          let selectedCnt = 0;
          for(const index in this.originalList){
            if(this.originalList[index]._selected){
              selectedCnt++;
            }
          }

          if(selectedCnt < 2) this.$set(this.btnState, 'isSelectedOneAtLeast', false);
        }
      } 

      this.$set(this.list[curListIndex], '_selected', flag);
      this.originalList[originalListIndex]._selected = flag;
    }
  },
  filters :{
    comma(value){
      if(value == null) return '';
      if(value === '.0000') value = 0;
      var parts = value.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateLength10(value){
      if(!value) return '';
      return value.substr(0, 10);
    },
    dateLength16(value){
      if(!value) return '';
      return value.substr(0, 16);
    },
    dateLength19(value){
      if(!value) return '';
      return value.substr(0, 19);
    }
  },
  mounted() {
    this.headerHeight = this.$refs["tableHeader"].offsetHeight + "px"; //경우에 따라 offsetHeight가 0으로 나오는경우도 있음 그럴경우 크기를 자동이 아니라 수동으로 입력하는방식으로 할수도있음.
    this.pagingHeight = (this.paging ? this.$refs['paging'].offsetHeight : 0) + 'px';
  },
  created() {
    this.setFirstColWidth();
    this.column = this.pcolumn;

    if(this.plist.length > 0){
        this.setFirstScroll();
        this.renewDisplayRows();
    }
  },
};
</script>

<style scoped>
.common_tb {
  table-layout: fixed;
     /* max-width: 100%; */
     width:100%;
  border-collapse: collapse;
}
.common_td {
  width: 30px;
  height: 47px;
  cursor:default;
}
.ellipsis {
  overflow:hidden;text-overflow: ellipsis; white-space: nowrap;
}

.tb_wrap {
  background-color: white;
  height: 100%;
  width: 100%;
  /* border: 1px solid black; */
}
.tb_fixed_col {
  height: 100%;
  position: sticky;
  left: 0px;
  background-color:white;
  z-index:1;
}
.td_head{
    overflow:unset;
    background-color:#f8f7fc;
}
.tb_header_scrollbar {
  /* width: calc(100% - 18px); 임시로 주석처리 혹시 부분데이터 표시 할때 제대로 동작안하면 이것 주석 풀고 아래 padding-right 주석해볼것.*/ 
  padding-right:18px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #f8f7fc;
} /* 스크롤바 크기에 따라 18px는 변경필요*/
.tb_tbody_wrap {
  overflow: auto;
}
.tb_header_scrollbar::-webkit-scrollbar {
  display: none;
}

/* 컬럼 사이즈 변경 */
.column_wrap{display: flex;position:relative;}
.column_con{flex: 1}
.column_line{position:absolute; right:-2px; top:0px; width: 4px; height:100%; cursor: col-resize; }
/* 컬럼 사이즈 변경 끝 */




/* 디자인 */
.header_tr{background-color:#f8f7fc}
.common_tr{border-bottom:1px solid #e6e7ec; }
.common_tr:hover {background-color:#e6efff;}
.common_tr:hover > .tb_fixed_col{background-color:#e6efff;}
.common_tr.active{background-color:#e6efff;}
.common_tr.active > .tb_fixed_col{background-color:#e6efff;}
.column_con{font-weight:bold; color:#969696;}
.text_color{color:#494a4f;}

.underline{
  height:1px; background-color:#e6e7ec;
}

.paging_arrow{
     border: 2px solid #e6e7ec;
    padding: 11px 10px;
    display: inline-block;
    width: 41px;
    height: 40px;
    border-radius: 4px;
    cursor:pointer;

}

.paging_num{
display: inline-block;
    padding: 9px;
    color: #494a4f;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: top;
    line-height: 23px;
    border-radius:20px;
    cursor:pointer;
}
.paging_num.active{
  background-color:#e6efff;  
  color:#0066fd;
}

.arrow_img{
  vertical-align: -1px;;
}

.checkbox{
border:1px solid #a7a8ba; border-radius:4px; width:22px; height:22px;display:inline-block;margin-left:8px;vertical-align: top;
  cursor:pointer;
}
/* .checkbox.active{
  background-color:black;
} */
*{user-select: text;}
</style>
