<template>
    <ModalBack :closeAction="hideAssetDetail">
        <div class="assetDetail_wrap" style="">
            <CommonModalCloseBtn :hideModal="hideAssetDetail"></CommonModalCloseBtn>
            
            <template v-if="curMode === MODE.UPDATE">
                <div class="tab_wrap">
                    <div class="tab" :class="[curTab === 0 ? 'active' : '']" @click="changeTab(0)">상세정보</div>
                    <div class="tab" :class="[curTab === 1 ? 'active' : '']" @click="changeTab(1)">변경내역</div>
                    <div class="tab" :class="[curTab === 2 ? 'active' : '']" @click="changeTab(2)">수리내역</div>
                    <div class="tab" :class="[curTab === 3 ? 'active' : '']" @click="changeTab(3)">대여내역</div>
                    <div class="tab" :class="[curTab === 4 ? 'active' : '']" @click="changeTab(4)">메모내역</div>
                </div>
                <div class="line"></div>
            </template>
            <template v-else>
                <div v-if="curMode === MODE.INSERT || curMode === MODE.COPY" style="font-size: 24px; padding: 8px 0px; font-weight: bold;">자산등록</div>
                <div v-if="curMode === MODE.BATCH" style="font-size: 24px; padding: 8px 0px; font-weight: bold;">자산일괄변경</div>
            </template>

            <div class="contents">
                <AssetInfo v-if="this.curTab === 0" :hide-asset-detail="hideAssetDetail" :transferData="transferData" :changeTab="changeTab"></AssetInfo>
                <AssetChangeHistoryPop v-if="this.curTab === 1" :hide-asset-detail="hideAssetDetail" :transferData="transferData"></AssetChangeHistoryPop>
                <AssetRepairHistoryPop v-if="this.curTab === 2" :hide-asset-detail="hideAssetDetail" :transferData="transferData"></AssetRepairHistoryPop>
                <AssetRentalHistoryPop v-if="this.curTab === 3" :hide-asset-detail="hideAssetDetail" :transferData="transferData"></AssetRentalHistoryPop>
                <AssetMemoHistoryPop v-if="this.curTab === 4" :hide-asset-detail="hideAssetDetail" :transferData="transferData"></AssetMemoHistoryPop>
            </div>
        </div>       
    </ModalBack>
</template>

<script>
import ModalBack from '@/components/common/ModalBack.vue';
import AssetInfo from '@/components/modal/assetdetails/AssetInfo.vue';
import AssetChangeHistoryPop from '@/components/modal/assetdetails/AssetChangeHistoryPop.vue';
import AssetRepairHistoryPop from '@/components/modal/assetdetails/AssetRepairHistoryPop.vue';
import AssetRentalHistoryPop from '@/components/modal/assetdetails/AssetRentalHistoryPop.vue';
import AssetMemoHistoryPop from '@/components/modal/assetdetails/AssetMemoHistoryPop.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';
import {MODE} from '@/util/Constants.js';

export default {
    name : 'AssetDetail',
    components : {ModalBack, AssetInfo, AssetChangeHistoryPop, AssetRepairHistoryPop, AssetRentalHistoryPop, AssetMemoHistoryPop, CommonModalCloseBtn},
    props : {
        hideAssetDetail : Function,
        transferData : {
            type : Object,
            default : () => {
                return {}
            }
        }
    },
    data(){
        return {
            curTab : 0,
            curMode : -1,
            MODE : MODE
        }
    },
    methods : {
        changeTab(index){
            this.curTab = index;
        }
    },
    mounted(){
        this.curTab = 0;
    },
    created(){
        this.curMode = this.transferData.mode;
    }
}
</script>

<style>
.assetDetail_wrap{
    background-color:white; width:950px; height:90%;
    max-height: 843px;
    padding:38px;
    display:flex;
    overflow:hidden;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.tab_wrap{
    display:flex;
}
.contents{
    flex:1;
    overflow: hidden;
}
.tab{font-size:16px; padding: 12px 20px;cursor:pointer; border-top:1px solid #e6e7ec;font-weight:bold; border-left:1px solid #e6e7ec; color:#494a4f;}
.tab.active{background-color:#0066fd; color:white;}
.tab:nth-of-type(1){border-top-left-radius: 5px;;}
.tab:nth-last-of-type(1){border-right:1px solid #e6e7ec; border-top-right-radius: 5px;;}
.line{height:1px; background-color:#e6e7ec;}

</style>