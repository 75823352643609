<template>
    <div style="position:relative; display:flex; width:100%;">
        <div class="img_wrap" :style="{backgroundImage : 'url(' + require('@/assets/triangle.png') + ')'}" @click="() => {isShowSearchList = true}"></div>
        <input type="text" class="condition_input" :class="icon ? 'left_icon' : ''" v-model="model" @click="click($event)" @keyup="enter($event)" :readonly="isReadonly" ref="condition_input">
        <div v-if="isShowSearchList && searchList.length > 0" style="position:fixed; left:0px; top:0px; width:100vw; height:100vh;" @click="() => {isShowSearchList = false}"></div>
        <div v-if="isShowSearchList && searchList.length > 0" class="search_list" style="">
            <div v-for="each, i in searchList" :key="i" class="list" @click="() => {toggleCheck(i, each.checked)}">
                <CommonCheckbox :flag="each.checked" color="#e6e7ec" style="margin-left:4px" :clickCheck="() => {}"></CommonCheckbox>
                <div class="label">{{each.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonCheckbox from '@/components/common/CommonCheckbox';
import ConditionManager from '@/modules/ConditionManager.js'
export default {
    name : 'ConditionInput',
    components : {CommonCheckbox},
    props: {
        icon : {
            type : String,
            default : ''
        },
        clickInput : {
            type : Function,
            default : null
        },
        value : String,
        isReadonly : {
            type : Boolean,
            default : false
        },
        searchList : {
            type : Array,
            default : () => {
                return []
            }
        },
        conditionKey : {
            type : String,
            default : ''
        },
        detectChange : {
            type : Function,
            default : () => {}
        },
        enterAction : {
            type : Function,
            default : () => {}
        },
        isFocus : {
            type : Boolean,
            default : false
        }
    },
    data(){
        return {
            isShowSearchList : false,
        }
    },
    methods : {
        click(e){
            if(this.clickInput != null) this.clickInput(e);
        },
        toggleCheck(index){
            this.$set(this.searchList[index], 'checked', (this.searchList[index].checked === true ? false : true));
            ConditionManager.save(this.conditionKey, this.searchList);
        },
        enter(e){
            if(e.keyCode === 13){
                this.enterAction();
            }
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    mounted(){
        if(this.isFocus) setTimeout(() => {this.$refs['condition_input'].focus()}, 300);
    }
}
</script>
<style scoped>
.condition_input{
    border: 1px solid #e6e7ec;
    display: inline-block;
    font-size: 16px;
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
    flex:1;
    min-width: 0px;
    width:100%;
    padding: 6px 10px 7px 10px;
    margin:0px;
}

.condition_input:read-only{
    background-color: #efefef;
}

.icon_style{
 font-size:18px;color:#9b9cb6
}

.left_icon{
padding: 6px 10px 7px 30px;
}

.img_wrap{
    border-color: #e6e7ec;
    background-color: white;;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;;
    background-repeat: no-repeat;
    background-position: right 11px top 15px;
    border:1px solid #e6e7ec;
    width:40px; height:40px; top:0px;
    border-right-width: 0px;;
}
.search_list{
    width:100%;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 41%);
     max-height:600px;
     overflow:auto; position:absolute; z-index:11; left:0px; top:40px; background-color:white; border:1px solid black; ; padding:5px 0px;
}
.list{
    width:100%;
    padding:5px 15px 5px 10px;
}

.list:hover{
    background-color:#1e90ff;
    color:white;
}

.list + .list{
    /* margin-top:8px; */

    user-select: none;;
}

.label{display:inline-block; line-height:23px; margin-left:8px;user-select: none;;}
</style>