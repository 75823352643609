<template >
    <ModalBack :closeAction="hideModal">
        <div class="company_register_wrap">
            <CommonModalCloseBtn :hideModal="hideModal"></CommonModalCloseBtn>
            <div style="font-size: 24px; padding: 8px 0px 8px 0px; font-weight: bold;" >미리보기</div>
            <div style="height:900px; width:700px">
                <iframe :src="$apiPath + 'view/print'" ref="preview" @load="loaded" style="width:100%;height:100%;border:none"></iframe>
            </div>
            <div class="asset_bottom_btns">
                <button class="asset_bottom_btn_canel" @click="hideModal">취소</button>
                <button class="asset_bottom_btn_ok" @click="print();">인쇄</button>
            </div>
        </div>
    </ModalBack>
</template>
<script>
import ModalBack from '@/components/common/ModalBack.vue';
import CommonModalCloseBtn from '@/components/common/CommonModalCloseBtn.vue';

export default {
    name : 'CardPreview',
    components : {
        ModalBack, CommonModalCloseBtn
    },
    props : {
        hideModal : Function,
        imgSrc : String,
        transferData : {
            type : Array,
            default : () => {
                return []
            }
        },
        column : {
            type : Array,
            default : () => {
                return []
            }
        }
    },
    methods : {
        print(){
            const printEl = this.$refs.preview.contentWindow;
            printEl.postMessage({type : 'print'},'*');
        },
        preview(){
            const printEl = this.$refs.preview.contentWindow;
            const html = this.makeHTML();
            const req = {
                type : 'addHtml',
                data : html
            }

            printEl.postMessage(req, '*');
        },
        makeHTML(){
            let html = "";

            // const tdHeight = (this.$commonFunc.getBrowser() === BROWSER_NAME.CHROME) ? 'height : 1' : 'height:100%;';
            let showFieldList = [];
            for(let i = 0; i < this.column.length; i++){
                const curColumn = this.column[i];
                if(curColumn.key.indexOf('field') > -1 && curColumn.show ){
                    showFieldList.push(curColumn);
                }
            }

            for(let i = 0; i < this.transferData.length; i++){
                let each = this.transferData[i];
                html += `
                    <div class="paper">
                    <div class="content">
                    <div style="text-align:center;"><div style="font-size:26px; text-align:center; padding:8px 20px; border:1px solid black;display:inline-block;">자산이력카드</div></div>
                    <table style=" border-collapse: collapse; width:100%;margin-top:30px;">
                        <colgroup>
                        <col style="width:50%;">
                        <col style="width:25%;">
                        <col style="width:25%;">
                        </colgroup>
                        <tr><td class="title">이미지</td><td class="title">품목명</td><td>${each.itemName ? each.itemName : '&nbsp;'}</td></tr>
                        <tr>
                            <td rowspan="7" style="height: 287px;padding:0px;">
                                <img src="${each.imageData ? 'data:image/png;base64, ' + each.imageData : (this.$versionPath + '/static/front/htm/noimage.jpg')}" style="height:100%; width:100%;">
                            </td><td class="title">자산명칭</td><td>${each.assetName ? each.assetName : '&nbsp;'}</td>
                        </tr>
                        <tr><td class="title">모델명</td><td>${each.modelName ? each.modelName : '&nbsp;'}</td></tr>
                        <tr><td class="title">규격</td><td>${each.standard ? each.standard : '&nbsp;'}</td></tr>
                        <tr><td class="title">구매처</td><td>${each.selling ? each.selling : '&nbsp;'}</td></tr>
                        <tr><td class="title">취득일자</td><td>${each.buyDate ? each.buyDate : '&nbsp;'}</td></tr>
                        <tr><td class="title">취득가액</td><td>${each.buyPrice ? this.$commonFunc.comma(each.buyPrice) : '&nbsp;'}</td></tr>
                        <tr><td class="title">사용상태</td><td>${each.stateName ? each.stateName : '&nbsp;'}</td></tr>
                        <tr><td class="title">위 치</td><td colspan="2" class="title">부 서</td></tr>
                        <tr><td>${each.locName ? each.locName : '&nbsp;'}</td><td colspan="2">${each.groupName ? each.groupName : '&nbsp;'}</td></tr>
                        <tr><td class="title">담당자</td><td colspan="2" class="title">사용자</td></tr>
                        <tr><td>${each.charge ? each.charge : '&nbsp;'}</td><td colspan="2">${each.user ? each.user : '&nbsp;'}</td></tr>
                        <tr><td class="title">바코드</td><td colspan="2" class="title">시리얼</td></tr>
                        <tr><td>${each.barcode ? each.barcode : '&nbsp;'}</td><td colspan="2">${each.serial ? each.serial : '&nbsp;'}</td></tr>
                        `;

                let line1 = ``;
                let line2 = ``;
                let lineConcat = ``;
                for(let j = 0; j < showFieldList.length; j++){
                    const remainder = j % 2;
                    const key = showFieldList[j].key;
                    const name = showFieldList[j].name;
                    if(remainder === 0){
                        line1 += `<td class="title">${name}</td>`;
                        line2 += `<td>${each[key] ? each[key] : '&nbsp;'}</td>`;
                        if(j === showFieldList.length - 1) lineConcat += `<tr>${line1}</tr><tr>${line2}</tr>`;
                    }else if(remainder === 1){  
                        line1 += `<td colspan="2" class="title">${name}</td>`;
                        line2 += `<td colspan="2">${each[key] ? each[key] : '&nbsp;'}</td>`;
                        lineConcat += `<tr>${line1}</tr><tr>${line2}</tr>`;
                        line1 = '';
                        line2 = '';
                    }
                }

                html += lineConcat;

                // html += `
                //         <tr><td class="title">추가항목1</td><td colspan="2" class="title">추가항목2</td></tr>
                //         <tr><td>${each.field1 ? each.field1 : '&nbsp;'}</td><td colspan="2">${each.field2 ? each.field2 : '&nbsp;'}</td></tr>
                //         <tr><td class="title">추가항목3</td><td colspan="2" class="title">추가항목4</td></tr>
                //         <tr><td>${each.field3 ? each.field3 : '&nbsp;'}</td><td colspan="2">${each.field4 ? each.field4 : '&nbsp;'}</td></tr>
                //         <tr><td class="title">추가항목5</td><td colspan="2" class="title">추가항목6</td></tr>
                //         <tr><td>${each.field5 ? each.field5 : '&nbsp;'}</td><td colspan="2">${each.field6 ? each.field6 : '&nbsp;'}</td></tr>
                //         <tr><td class="title">추가항목7</td><td colspan="2" class="title">추가항목8</td></tr>
                //         <tr><td>${each.field7 ? each.field7 : '&nbsp;'}</td><td colspan="2">${each.field8 ? each.field8 : '&nbsp;'}</td></tr>
                // `;

                html += `
                    </table>
                    </div>
                    </div>
                `;
                if(i !== (this.transferData.length - 1)) html += `<p style="page-break-before: always;">`;
            }

            return html;
        },
        loaded(){
            this.preview();
        }
    },
    mounted(){
        console.log('mounted');
        console.log(this.column);
    }
}
</script>
<style scoped>
    .company_register_wrap{
    background-color:white;
    max-width:100%;
    max-height:100%;
    min-width:300px; 
    min-height:300px;
    padding:38px;
    display:flex;
    overflow:auto;
    flex-direction: column;;
    border-radius:5px;
    position:relative;
}

.asset_bottom_btns{text-align:right;padding-top: 14px;}
.asset_bottom_btn_canel{cursor:pointer;background-color:white; border-radius:5px;font-size:16px; width: 113px; height:40px;border:1px solid #e6e7ec;font-weight:bold;color:#222536; }
.asset_bottom_btn_ok{cursor:pointer;margin-left:7px;border-radius:5px;font-size:16px; width: 113px; height:40px; border:0px;background-color:#0066fd; color:white; font-weight:bold;}
table{

}

th, td {
    border: 1px solid black;;
    text-align:center;
    padding:8px 0px;
    font-size:18px;
}
.title{
    background-color:#d3d3d3;
}
</style>