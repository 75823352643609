import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import axios from 'axios'
import VueCookies from 'vue-cookies'


import storeObj from './store.js';
import getRouter from './router.js';
import ResCode from './util/ResponseCode.js';
import GlobalStore from './GlobalStore.js';
import GlobalRouter from './GlobalRouter.js';
import CommonFunc from './util/CommonFunc';
import fontAwesomeIcon from './components/common/FontAwesomeIcon';
import PortalVue from 'portal-vue'
Vue.use(PortalVue);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueCookies);



// font awesome
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faMagnifyingGlass, faLaptop, faBars, faFilePen, faPrint, faTableColumns, faEllipsis, faCheck, faTrashCanArrowUp, faAngleUp, 
//   faAnglesUp, faAngleDown, faAnglesDown} from '@fortawesome/free-solid-svg-icons'
// import { faFileLines, faPenToSquare, faTrashCan, faFileExcel, faCopy, faCalendarMinus  } from '@fortawesome/free-regular-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// library.add(faMagnifyingGlass);
// library.add(faLaptop)
// library.add(faFileLines)
// library.add(faPenToSquare)
// library.add(faBars)
// library.add(faTrashCan)
// library.add(faFilePen)
// library.add(faPrint)
// library.add(faFileExcel)
// library.add(faTableColumns)
// library.add(faEllipsis)
// library.add(faCopy)
// library.add(faCheck)
// library.add(faTrashCanArrowUp)
// library.add(faCalendarMinus)
// library.add(faAngleUp)
// library.add(faAnglesUp)
// library.add(faAngleDown)
// library.add(faAnglesDown)

Vue.component('font-awesome-icon', fontAwesomeIcon)

console.log(process.env);

window.copyObject = function(obj){
  return JSON.parse(JSON.stringify(obj));
};

Vue.filter('comma', (value) => {
  if(value == null) return '';
  if(value === '.0000') value = 0;
  var parts = value.toString().split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter('uncomma', (value) => {
  value = String(value);
  return value.replace(/[^\d]+/g, '');
});


let versionPath = "";
let publicPath = "";
let apiPath = "";
if(process.env.NODE_ENV === 'production' && process.env.VUE_APP_VERSION_NAME){
  axios.defaults.baseURL = "/" + process.env.VUE_APP_VERSION_NAME;
  versionPath = "/" + process.env.VUE_APP_VERSION_NAME;
  
}

if(process.env.NODE_ENV === 'production'){
  publicPath = versionPath + "/static/front";
  apiPath = versionPath + "/";
}

if(process.env.NODE_ENV === 'development'){
  axios.defaults.baseURL = process.env.VUE_APP_TEST_URL + "/";
  apiPath = axios.defaults.baseURL;
  publicPath = "";
}

var store = new Vuex.Store(storeObj);
const router = getRouter(versionPath, store);
GlobalStore.init(store);
GlobalRouter.init(router, versionPath);
Vue.prototype.$GlobalStore = GlobalStore;
Vue.prototype.$GlobalRouter = GlobalRouter;
Vue.prototype.$versionPath = versionPath;
Vue.prototype.$commonFunc = CommonFunc;
Vue.prototype.$publicPath = publicPath;
Vue.prototype.$apiPath = apiPath;

//http 요청전
axios.interceptors.request.use(function (config) {

  //자동로그인 토큰 주입
  if( Object.prototype.hasOwnProperty.call(localStorage, 'token') && localStorage.getItem('token')){
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});


//http 요청 후 응답
axios.interceptors.response.use(function (response) {
  
  // console.log(response);
  return response;
}, function (error) {
  const code = error.response.data.code

  if(code === ResCode.Unauthorized || code === ResCode.AuthTokenVerifyFail){
    // location.href = versionPath + "/view/login";
    router.push({ path: versionPath + '/view/login' })
  } else if(code === ResCode.LoginDuplication){
    localStorage.removeItem('token');
    alert(error.response.data.message);
    router.push({ path: versionPath + '/view/login' })
  }
  return Promise.reject(error);
});


axios.defaults.withCredentials = true;

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router : router,
}).$mount('#app')

