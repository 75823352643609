
<script>
import { Doughnut} from 'vue-chartjs'

export default {
    name : 'DoughnutChart',
    extends : Doughnut,
    props : {
        chartData : {
            type : Object,
            default : () => {
                return {}
            }
        },
        chartOption : {
            type : Object,
            default : () => {
                return {}
            }
        }
    },
    watch : {
        chartData(){
            this.renderChart(this.chartData, this.chartOption);
        }
    },
    mounted(){
        // this.renderChart({
        // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        // datasets: [
        //     {
        //     label: 'GitHub Commits',
        //     backgroundColor: '#f87979',
        //     data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        //     }
        // ]
        // });
        // this.renderChart(this.chartData, this.chartOption);
    }
}
</script>
<style scoped>
    
</style>