<template>
    <div style="position:relative;">
        <input :type="isPasswordSecure ? 'password' : 'text'" 
            class="common_input" :class="[icon === 'glass' ? 'left_icon' : '', icon === 'calendar' ? 'right_icon' : '']" 
            v-model="model" 
            @click="click($event)" :readonly="isReadonly" @keyup="keyup($event)" 
            @focus="() => {isFocus = true}" 
            @blur="() => {isFocus = false}"
            :style="styles"
            autocomplete="off"
            ref="commonInput"
             >
        <font-awesome-icon v-if="icon === 'glass'" icon="fa-solid fa-magnifying-glass" class="icon_style" style="position:absolute; left:10px; top: 11px;" :style="{color : (isFocus ? '0066fd' : '')}" />
        <font-awesome-icon v-if="icon === 'calendar'" icon="fa-regular fa-calendar-minus" class="icon_style" style="position:absolute; right:10px; top: 10px;" :style="{color : (isFocus ? '0066fd' : '')}"/>
    </div>
</template>

<script>
export default {
    name : 'CommonInput',
    props: {
        icon : {
            type : String,
            default : ''
        },
        clickInput : {
            type : Function,
            default : null
        },
        value : String,
        isReadonly : {
            type : Boolean,
            default : false
        },
        enterAction : {
            type : Function,
            default : () => {}
        },
        mode : {
            type : String,
            default : 'text'
        },
        signalFocus : {
            type : Boolean,
            default : false
        }
    },
    methods : {
        click(e){
            if(this.isNumberMode) e.currentTarget.select();
            if(this.clickInput != null) this.clickInput(e);
        },
        keyup(e){
            if(e.keyCode === 13){
                this.enterAction();
            }

            if(this.isNumberMode){
                const target = e.currentTarget;
                const rgx1 = /\D/g;
                const rgx2 = /(\d+)(\d{3})/;
                let num = target.value.replace(rgx1,"");

                while (rgx2.test(num)) num = num.replace(rgx2, '$1' + ',' + '$2');
                this.$emit("input", "" + num);
            }else if(this.isPhone){
                const target = e.currentTarget;
                const text = target.value;
                // if(this.prevValue === '') {
                //     this.prevValue = this.value + '';
                // }

                if(target.selectionStart != text.length) return true;

                const pattern = /^(\d{1,5}|$)(-|$)(\d{1,5}|$)(-|$)(\d{1,5}|$)$/g;
                if(!pattern.test(text)) this.$emit("input", "" + this.prevValue)

                const tempText = target.value.replace(/-/g, '');
                const tempTextLength = tempText.length;

                if(tempTextLength > 0 && tempTextLength <= 4){ // 1자리 ~ 4자리
                    this.$emit("input", "" + tempText);
                }else if(tempTextLength > 4 && tempTextLength < 9){ // 5자리 ~ 8자리
                    this.$emit("input", "" + tempText.substr(0, 4) + "-" + tempText.substr(4, 4));
                }else if(tempTextLength >= 9 && tempTextLength <= 10){ // 10자리
                    this.$emit("input", "" + tempText.substr(0, 3) + "-" + tempText.substr(3, 3) + "-" + tempText.substr(6, 4));
                }else if(tempTextLength > 10){ //11자리
                    this.$emit("input", "" + tempText.substr(0, 3) + "-" + tempText.substr(3, 4) + "-" + tempText.substr(7, 5));
                }

                this.prevValue = this.value + '';
            }else if(this.isDate){
                const target = e.currentTarget;
                const text = target.value;

                if(target.selectionStart != text.length) return true;

                const pattern = /^(\d{1,4}|$)(-|$)(\d{1,2}|$)(-|$)(\d{1,2}|$)$/g;
                if(!pattern.test(text)) this.$emit("input", "" + this.prevValue)
                
                const tempText = target.value.replace(/-/g, '');
                const tempTextLength = tempText.length;

                if(tempTextLength > 0 && tempTextLength <= 4){ // 년도
                    this.$emit("input", "" + tempText);
                }else if(tempTextLength > 4 && tempTextLength <= 6){ // 월
                    this.$emit("input", "" + tempText.substr(0, 4) + "-" + tempText.substr(4, 2));
                }else if(tempTextLength > 6){ // 일
                    this.$emit("input", "" + tempText.substr(0, 4) + "-" + tempText.substr(4, 2) + "-" + tempText.substr(6, 2));
                }

                this.prevValue = this.value + '';
            }
        }
    },
    data(){
        return {
            isPasswordSecure : false,
            isNumberMode : false,
            styles : {},
            isFocus : false,
            isPhone : false,
            prevValue : '',
            isDate : false
        }
    },
    watch : {
        signalFocus(){
            this.$refs.commonInput.focus();
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    created(){
        if(this.mode === 'number'){
            this.isNumberMode = true;
            this.styles.textAlign = 'right';
        }else if(this.mode === 'password'){
            this.isPasswordSecure = true;
        }else if(this.mode === 'phone'){
            this.prevValue = this.value + '';
            this.isPhone = true;
        }else if(this.mode === 'date'){
            this.prevValue = this.value + '';
            this.isDate = true;
        }
    }
}
</script>
<style scoped>
.common_input{
    border: 1px solid #e6e7ec;
    display: inline-block;
    font-size: 16px;
    border-radius:5px;
    flex:1;
    min-width: 0px;
    width:100%;
    padding: 7px 10px 6px 10px;
    margin:0px;
}

.common_input:read-only{
    background-color: #efefef;
}

.common_input[readonly='readonly']{
     background-color: #efefef;
}

.icon_style{
 font-size:18px;color:#9b9cb6
}

.left_icon{
padding: 7px 10px 6px 30px;
}

.right_icon{
    padding: 7px 30px 6px 10px;
}
</style>