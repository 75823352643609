<template>
    <TableSearchPanel :isShowLeftPanel="true" :is-show-right-panel="true" :is-show-top-panel="true" :is-show-table-panel="true" :is-show-search-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="사원관리">
                <CommonAddBtn v-if="isAllowedForInsert" title="사원등록" :clickAction="() => {showUserRegister(MODE.INSERT)}"></CommonAddBtn>
            </PageHeader>
        </template>
        <template v-slot:left_panel>
            <span style="font-weight: bold;color: #969696;">부서</span>
            <CommonTree :p-tree-data="treeData" :multiple="false" :detectSelect="detectUserGroupSelect" ref="groupTree"></CommonTree>
        </template>
        <template v-slot:search_panel>
            <LabelPanel style="width:300px; ">
                <template v-slot:label>검색어</template>
                <template v-slot:content>
                    <ConditionInput v-model="searchWord" :searchList="searchConditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                    <CommonSearchBtn :clickAction="search" style="margin-left:4px;"></CommonSearchBtn>
                </template>
            </LabelPanel>
        </template>
        <template v-slot:table_panel>
            <BatchBtns 
                :is-show-first-checkbox="true && isAllowedForDelete" 
                :is-show-delete-btn="true && isAllowedForDelete" 
                :isShowModifyBtn="true"
                :isShowSelectAllBtn="true"  
                :delete-action="deleteList" 
                :show-column-setting-action="() => {isShowColumnSettings = true}"
                :savedSelectedList="savedSelectedList"
                :btnState="btnState"
                :downloadExcel="downloadExcel"
                :modifyAction="() => {showUserRegister(MODE.BATCH)}"
                :isShowExcelDownload="isAllowedForExcel"
            ></BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable 
                    :isShowNumbering="true"
                    :isShowCheckbox="true && isAllowedForDelete" 
                    :plist="list" 
                    :pcolumn="column" 
                    :pcustom="custom" 
                    :paging="paging" 
                    :change-page="detectChangePage" 
                    :savedSelectedList="savedSelectedList" 
                    savedSelectedListKey="userCode"
                    :signalSelectCurPageAll="btnState.signalSelectCurPageAll"
                    :signalUnselectCurPageAll="btnState.signalUnselectCurPageAll"
                    :btnState="btnState"
                    :clickDoubleRow="(data) => {if(!isAllowedForUpdate) return; transferData = data; showUserRegister(MODE.UPDATE);}"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>

        </template>

        <template v-slot:etc><div><UserRegister v-if="isShowUserRegister" :hideModal="() => {isShowUserRegister = false}" :curMode="curMode" :data="transferData" :completeAction="() => {getGroups(userGroupName);if(!userGroupName) search()}"></UserRegister></div></template>

    </TableSearchPanel>
</template>
<script>
import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonSearchBtn from '@/components/common/CommonSearchBtn.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import CommonTree from '@/components/common/CommonTree.vue';
import UserRegister from '@/components/modal/UserRegister.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import BatchBtns from '@/components/common/BatchBtns.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import {MODE, AUTH, SIZE_PER_PAGE} from '@/util/Constants.js';

export default {
    name : 'UserMng',
    components : {PageHeader, TableSearchPanel, LabelPanel, CommonSearchBtn, CommonAddBtn, BatchBtns, CommonTable, ColumnSetting, CommonTree, UserRegister, ConditionInput },
    data(){
        return {
            curMode : -1,
            MODE : MODE,
            AUTH : AUTH,
            lastPage : 1,
            lastSizePerPage: SIZE_PER_PAGE,
            lastWithoutSearchWord : '',
            searchCnt : 0,
            used : 1,
            searchWord : '',
            isShowUserRegister : false,
            savedSelectedList : {},
            transferData : {},
            userGroupName : '',
            userGroupCode : '-1',
            btnState:{
                isSelectAll : false,
                isSelectedOneAtLeast : false,
                signalSelectCurPageAll : false,
                signalUnselectCurPageAll : false,
            },
            column: [
                { key: "userName", name: "사원명", w: "120px"},
                { key: "groupName", name: "부서", w: "100px"},
                { key: "userClass", name: "직급", w: "100px" },
                { key: "userPhone", name: "전화번호", w: "140px" },
                { key: "userMobile", name: "휴대폰번호", w: "140px" },
                { key: "userEmail", name: "이메일", w: "100px" },
                { key: "barcode", name: "바코드", w: "100px" },
                { key: "used", name: "재직상태", w: "100px" },
                { key: "hire_date", name: "입사일", w: "100px" },
                { key: "retire_date", name: "퇴사일", w: "100px" },
            ],
            isShowColumnSettings : false,
            searchConditions: [
                {name : '사원명', key : 'userName', checked : true},
                {name : '부서', key : 'groupName', checked : true},
                {name : '직급', key : 'userClass', checked : true},
            ],
            list: [

            ],
            custom : {},
            paging : {},
            treeData : [],
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.사원관리, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.사원관리, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.사원관리, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.사원관리, AUTH.EXCEL)
        }
    },
    methods : {
        detectUserGroupSelect(data){
            if(data.value != -1 && data.value != -9999) {
                this.userGroupName = data.text;
                this.userGroupCode = data.value;
            }else {
                this.userGroupName = '';
                this.userGroupCode = '-1';
            }

            if(data.value == -9999){
                this.used = 0
            }else {
                this.used = 1;
            }
            this.getUsers(1, this.lastWithoutSearchWord);
        },
        deleteList(){
            const parameters = this.getParameters();
            parameters.isSelectAll = this.btnState.isSelectAll;

            let deleteCnt = this.searchCnt;

            if(!this.btnState.isSelectAll){
                parameters.userCodes = Object.keys(this.savedSelectedList);
                deleteCnt = parameters.userCodes.length;
            }

            if(!confirm(deleteCnt + '명의 사원을 삭제하시겠습니까?')){
                return
            }

            axios.post('/api/user/delete', this.$commonFunc.convertToForm(parameters)).then(res => {
                if(res.data.code == ResCode.Success){
                    this.savedSelectedList = {};
                    this.getGroups(this.userGroupName)
                    if(!this.userGroupName){
                        this.search();
                    }
                    
                    
                }else if(res.data.code == ResCode.Fail){
                    alert(res.data.message);
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getUsers(page, withoutSearchWord){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            
            const parameters = this.getParameters();

            axios.get('/api/user/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const users = data.users.content;
                    this.searchCnt = data.users.totalElements;
                    data.users.content = null;
                    // const assetSum = data.assetSum;
                    this.list = users;
                    this.paging = data.users;   
      
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;

            let conditions = [];
            let searchWords = [];

            if(this.userGroupName != ''){
                conditions.push('userGroupName');
                searchWords.push(this.userGroupName);
            }

            conditions.push('np_used');
            searchWords.push(this.used);

            if(this.searchWord != '' && !this.lastWithoutSearchWord){
                ConditionManager.addConditions(conditions, searchWords, this.searchConditions, this.searchWord);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }
            
            return parameters;
        },
        getGroups(groupName){
            const parameters = {};
            parameters.topName = '부서전체';
            parameters.isShowDeletedCode = true;
            axios.get('/api/asset/cate/groupCodeWithRetiree', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const tree = res.data.data.tree
                    // tree[0].selected = true;
                    this.treeData = tree;
                    if(groupName){
                        this.$nextTick(() => {
                            this.$refs.groupTree.__setSelectWithSearchWord(groupName);
                        })
                    }
                    
                }
            }).catch(res => {
                console.log(res);
            });
    
        },
        search(){
            this.getUsers(1, this.lastWithoutSearchWord);
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/user/list/excel?" + queryStr);
            location.href = this.$versionPath + "/api/user/list/excel?" + queryStr
        },
        enter(){
            this.search();
        },
        showUserRegister(mode){
            this.curMode = mode;
            if(mode === MODE.BATCH){
            
                this.transferData = {
                    savedSelectedList : this.savedSelectedList,
                    isSelectAll : this.btnState.isSelectAll,
                    queryParameters : this.getParameters(),
                    completeFunc : () => {
                        this.savedSelectedList = {};
                        this.getGroups(this.userGroupName);
                        if(!this.userGroupName){
                            this.search()
                        }
                    }
                }; 
            }else if(mode === MODE.INSERT){
                this.transferData.userGroupCode = this.userGroupCode;
                this.transferData.userGroupName = this.userGroupName;
            }
            
            this.isShowUserRegister = true
        },
        detectChangePage(page){
            this.getUsers(page, this.lastWithoutSearchWord);
        },
    },
    created(){
        this.getGroups('부서전체');
        // this.getUsers(1,false);
        ConditionManager.setConditions(this.$options.name, this.searchConditions);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
}
</script>
<style scoped>
    
</style>