<template >
    <button @click="hideModal" class="x_btn" >
        <img :src="require('@/assets/x_btn.png')" >
    </button>
</template>
<script>
export default {
    name : 'CommonModalCloseBtn',
    props : {
        hideModal : Function
    }   
}
</script>
<style scoped>
    .x_btn{background-color:transparent; border:0px;position:absolute; right:38px; top:38px;cursor:pointer;}
</style>