import _ from "lodash";

const ConditionManager = (function(){
    const _obj = {};

    _obj.save = function(screenName, newConditions){

        const saveCondition = {};

        for(const i in newConditions){
            const condition = newConditions[i]
            if(hasOwnProperty.call(condition, 'checked')){
                saveCondition[condition.key] = condition.checked;
            }
        }

        localStorage.setItem(screenName + 'conditions', JSON.stringify(saveCondition));
    }   

    _obj.setConditions = function(screenName, defaultConditions){
        const hasOwnProperty = Object.prototype.hasOwnProperty;

        if(!hasOwnProperty.call(localStorage, screenName + 'conditions')) return;
        
        const savedConditions = JSON.parse(localStorage.getItem(screenName + 'conditions'));

        for(const i in defaultConditions){
            const condition = defaultConditions[i];
            if(hasOwnProperty.call(savedConditions, condition.key)){
                defaultConditions[i].checked = savedConditions[condition.key];
            }
        }
    }

    _obj.addConditions = function(conditions, searchWords, addConditions, addSearchWord){
        for(const i in addConditions){
            if(addConditions[i].checked){
                conditions.push(addConditions[i].key);
                searchWords.push(addSearchWord);
            }
        }
    }

    return _obj;
})();



export default ConditionManager