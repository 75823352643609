import _ from "lodash";
import { BROWSER_NAME } from "@/util/Constants";
import CommonFunc from "@/util/CommonFunc";
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';

const ColumnManager = (function(){
    const _obj = {};
    const columnSettings = {};

    _obj.setColumnSettings = function(columns){
        for(const key in columns){
            const each = columns[key];
            columnSettings[each['name']] = each['setting'];
        }
    }

    _obj.save = function(screenName, newColumnSettings){
        let filteredColumnSettings = [];

        const hasOwnProperty = Object.prototype.hasOwnProperty;

        for(const key in newColumnSettings){

            const columnSetting = {
                name : newColumnSettings[key].name, 
                key : newColumnSettings[key].key, 
                w : newColumnSettings[key].w, 
                show : newColumnSettings[key].show,
            }

            if(hasOwnProperty.call(newColumnSettings[key], 'fixed')) columnSetting.fixed = newColumnSettings[key].fixed;

            filteredColumnSettings.push(columnSetting);
        }
        // localStorage.setItem(screenName + 'columns', JSON.stringify(filteredColumnSettings));

        //메모리에 저장
        const columnName = screenName + 'columns';
        const settingJson = JSON.stringify(filteredColumnSettings);
        columnSettings[columnName] = settingJson;

        //db에 저장
        const parameters = {};
        parameters.name = columnName;
        parameters.setting = settingJson;

        axios.post('/api/login/column/save', CommonFunc.convertToForm(parameters)).then(res => {
            if(res.data.code == ResCode.Success){
                console.log('성공');
            }
        }).catch(res => {
            console.log(res);
        });
    }

    _obj.getSettings = function(screenName, defaultSettings){
        const copiedDefaultSettings = window.copyObject(defaultSettings);

        const hasOwnProperty = Object.prototype.hasOwnProperty;
        //저장해둔게 없을때
        if(!hasOwnProperty.call(columnSettings, screenName + 'columns')){
            for(let i = 0; i < copiedDefaultSettings.length; i++){
                if(!hasOwnProperty.call(copiedDefaultSettings[i], 'show')) copiedDefaultSettings[i].show = true;
            }
            return JSON.parse(JSON.stringify(copiedDefaultSettings));
        }

        const savedSettings = JSON.parse(columnSettings[screenName + 'columns']);
        const defaultSettingsHashed = _.keyBy(copiedDefaultSettings, 'key');
       
        const modifiedSettings = [];

       

        for(let i = 0; i < savedSettings.length; i++){
            const key = savedSettings[i].key;

            //기본에서 사라진건 표시안함
            if(!hasOwnProperty.call(defaultSettingsHashed, key)) continue;

            defaultSettingsHashed[key].used = true;
            defaultSettingsHashed[key].w = savedSettings[i].w;
            defaultSettingsHashed[key].show = savedSettings[i].show;
            if(hasOwnProperty.call(savedSettings[i], 'fixed')) defaultSettingsHashed[key].fixed = savedSettings[i].fixed;
            modifiedSettings.push(defaultSettingsHashed[key]);
        }

        for (const key in defaultSettingsHashed) {
            if(defaultSettingsHashed[key].used !== true){
                delete defaultSettingsHashed[key].used;
                defaultSettingsHashed[key].show = true;
                modifiedSettings.push(defaultSettingsHashed[key]);
            }
        }

        return modifiedSettings;
    }

    _obj.getSettingsForExcel = function(screenName, defaultSettings){
        const copiedDefaultSettings = _obj.getSettings(screenName, defaultSettings);

        let keyList = [];
        let nameList = [];

        for(const key in copiedDefaultSettings){
            const curSetting = copiedDefaultSettings[key];
            if(curSetting.show && curSetting.name != ''){
                keyList.push(curSetting.key);
                nameList.push(curSetting.name)
            }
        }

        if(CommonFunc.getBrowser() === BROWSER_NAME.IE){
            return "&columnKeys=" + keyList.join('::') + "&columnNames=" + encodeURI(nameList.join("::"));
        }else {
            return "&columnKeys=" + keyList.join('::') + "&columnNames=" + nameList.join("::");
        }

    }

    return _obj;
})();



export default ColumnManager