<template>
    <TableSearchPanel :is-show-right-panel="true" :is-show-search-panel="true" :is-show-table-panel="true">
        <template v-slot:pageheader>
            <PageHeader title="감가상각명세서">
                <CommonAddBtn title="계정과목관리" :isShowPlus="false" :clickAction="() => {isShowDepRegister = true}" :style="{marginRight: (isAllowedForSettingSelect ? '150px' : '')}"></CommonAddBtn>
                <CommonAddBtn v-if="isAllowedForSettingSelect" title="감가상각 환경설정" :clickAction="() => {isShowDepreSetting = true}" :isShowPlus="false"></CommonAddBtn>
            </PageHeader>
        </template>
        <template v-slot:search_panel>
            <div style="display:flex;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label><span style="color:#fe7681">감가상각 시행 월</span></template>
                    <template v-slot:content>
                        <CommonSelect :list="years" style="" :value="thisYear" :detectChange="(value) => {thisYear = value}"></CommonSelect>
                        <CommonSelect :list="months" style="margin-left:19px;width:130px;" :value="thisMonth" :detectChange="(value) => {thisMonth = value}" ></CommonSelect>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>재산구분</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="itemName" :clickInput="(data) => {isShowItemSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>위치</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="locName" :clickInput="(data) => {isShowLocSearch = true;}"></CommonInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>부서</template>
                    <template v-slot:content>
                        <CommonInput icon="glass" v-model="groupName" :clickInput="(data) => {isShowGroupSearch = true;}"></CommonInput> 
                    </template>
                </LabelPanel>
            </div>
            <div style="display:flex;margin-top:12px;">
                <LabelPanel style="width:233px;">
                    <template v-slot:label>사용상태</template>
                    <template v-slot:content>
                        <CommonSelect :list="states" :detectChange="detectStateChange"></CommonSelect>
                    </template>
                </LabelPanel>
                 <LabelPanel style="margin-left:19px;width:233px;">
                    <template v-slot:label>
                        <div style=" position: absolute;top: -5px;left: -2px;"><CommonCheckbox :flag="isBuyDateChecked" :click-check="toggleBuyDateCheck" color="#e6e7ec"></CommonCheckbox></div><span style="padding:13px;"></span>취득일자
                    </template>
                    <template v-slot:content>
                        <CommonInput :click-input="showCalendar" v-model="buyDate"></CommonInput>
                        <div v-if="isShowCalendar" ref="periodWrap" style="position:fixed; z-index:2; background-color:white; border:1px solid #e6e7ec; border-radius:4px; ">
                            <PeriodCalendar :complete-callback="completSelectDate" :init-start-date="startDate" :init-end-date="endDate" :cancel-callback="hideCalendar"></PeriodCalendar>
                        </div>
                    </template>
                </LabelPanel>
                <LabelPanel style="width:233px; margin-left:19px;">
                    <template v-slot:label>검색어</template>
                    <template v-slot:content>
                        <ConditionInput v-model="searchWord" :searchList="conditions" :conditionKey="$options.name" :enterAction="enter"></ConditionInput>
                    </template>
                </LabelPanel>
                <LabelPanel style="margin-left:5px;">
                    <template v-slot:label>&nbsp;</template>
                    <template v-slot:content>
                        <button class="search_btn" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" style="margin-right:20px;" />조회</button>
                    </template>
                </LabelPanel>
            </div>
        </template>
         <template v-slot:table_panel>
            <BatchBtns :show-column-setting-action="() => {isShowColumnSettings = true}"
                :downloadExcel="downloadExcel"
                :isShowExcelDownload="isAllowedForExcel"
                >
                <template v-slot:left_panel><span style="font-size:15px; font-weight:bold;color:#969696;">상각기간</span><span style="font-size:15px;color:#0066fd; font-weight:bold; margin-left:10px;">{{deprePeriod}}</span></template>
            </BatchBtns>
            <div style="flex:1; overflow:hidden">
                <CommonTable :plist="list" :pcolumn="column" :isShowNumbering="true"
                    :clickDoubleRow="(data) => {showDepreciationHistory(data)}"
                ></CommonTable>
            </div>

            <ColumnSetting v-if="isShowColumnSettings" 
                :vue-name="$options.name" 
                :default-settings="column" 
                :closeSettings="() => {isShowColumnSettings = false}" 
                :complete-save="(newColumnSettings) => {isShowColumnSettings = false; column = newColumnSettings}">
            </ColumnSetting>

         </template>
        <template v-slot:etc>
            <div>
                <DepreciationRegister v-if="isShowDepRegister" :hideModal="hideDepRegister"></DepreciationRegister>
                <DepreciationTotalHistory v-if="isShowDepTotalHistory" 
                    :hideModal="hideDepTotalHistory" 
                    :transferData="transferData"
                    >
                </DepreciationTotalHistory>
            </div>
            <LocSearch v-if="isShowLocSearch" :hideModal="() => {isShowLocSearch = false}" :completeSave="(data) => {locName = (data.text !== '위치코드' ?  data.text : ''); locCode = data.value}"></LocSearch>
            <GroupSearch v-if="isShowGroupSearch" :hideModal="() => {isShowGroupSearch = false}" :completeSave="(data) => {groupName = (data.text !== '부서코드' ?  data.text : ''); groupCode = data.value}"></GroupSearch>
            <ItemSearch v-if="isShowItemSearch" :hideModal="() => {isShowItemSearch = false}" :completeSave="(data) => {itemName =  (data.text !== '재산구분코드' ? data.text : ''); itemCode = data.value}"></ItemSearch>
            <DepreciationSetting v-if="isShowDepreSetting" :hideModal="() => {isShowDepreSetting = false}" ></DepreciationSetting>
        </template>

    </TableSearchPanel>
   
</template>

<script>

import PageHeader from '@/components/common/PageHeader.vue';
import TableSearchPanel from '@/components/layout/TableSearchPanel.vue';
import CommonTable from '@/components/common/CommonTable.vue';
import ColumnManager from '@/modules/ColumnManager.js'
import ColumnSetting from '@/components/common/ColumnSetting.vue';
import BatchBtns from '@/components/common/BatchBtns.vue';
import LabelPanel from '@/components/layout/LabelPanel.vue';
import CommonInput from '@/components/common/CommonInput.vue';
import CommonSelect from '@/components/common/CommonSelect.vue';
import PeriodCalendar from '@/components/common/PeriodCalendar.vue';
import CommonCheckbox from '@/components/common/CommonCheckbox.vue';
import CommonAddBtn from '@/components/common/CommonAddBtn.vue';
import ConditionInput from '@/components/common/ConditionInput.vue';
import Calendar from '@/components/common/Calendar.vue';
import LocSearch from '@/components/modal/LocSearch.vue';
import GroupSearch from '@/components/modal/GroupSearch.vue';
import ItemSearch from '@/components/modal/ItemSearch.vue';
import DepreciationRegister from '@/components/modal/DepreciationRegister.vue';
import DepreciationSetting from '@/components/modal/DepreciationSetting.vue';
import DepreciationTotalHistory from '@/components/modal/DepreciationTotalHistory.vue';
import ConditionManager from '@/modules/ConditionManager.js'
import { states, AUTH, NUMBER_STYLE, SIZE_PER_PAGE} from '@/util/Constants.js';
import axios from 'axios';
import ResCode from '@/util/ResponseCode.js';
import moment from 'moment';


export default {
    name: 'DepreciationSpec',
    components : {
        PageHeader, TableSearchPanel, CommonTable, ColumnSetting, BatchBtns, LabelPanel, CommonInput, CommonSelect, PeriodCalendar, 
        CommonCheckbox, CommonAddBtn, DepreciationRegister, DepreciationTotalHistory, ConditionInput, LocSearch, GroupSearch, ItemSearch, DepreciationSetting,
    },
    data(){
        return {
            stateCode : '',
            itemName : '',
            itemCode : '',
            groupName : '',
            groupCode : '',
            locName : '',
            locCode : '',
            isShowColumnSettings : false,
            startDate : '',
            endDate : '',
            buyDate : '',
            thisYear : '',
            thisMonth : '',
            startMonth : '',
            dtEnd : '',
            transferData : {},
            isShowDepreSetting : false,
            isShowLocSearch : false,
            isShowGroupSearch : false,
            isShowItemSearch : false,
            isShowCalendar : false,
            isBuyDateChecked : false,
            isShowDepRegister : false,
            deprePeriod: '2022-01-01 ~ 2022-04-30',
            isShowDepTotalHistory : false,
            searchWord : '',
            lastPage : 1,
            lastSizePerPage: SIZE_PER_PAGE,
            lastWithoutSearchWord : '',
            list: [],
            column: [
                { key: "accountName", name: "계정과목", w: "120px"},
                { key: "itemName", name: "품목명", w: "100px"},
                { key: "assetName", name: "자산명칭", w: "150px" },
                { key: "standard", name: "규격", w: "100px" },
                { key: "groupName", name: "부서", w: "100px" },
                { key: "locName", name: "위치", w: "100px" },
                { key: "buyDate", name: "취득일자", w: "100px" },
                { key: "get_days", name: "취득경과일", w: "100px" },
                { key: "buyPrice", name: "취득가액", w: "100px", comma: true },
                { key: "scrapPrice", name: "잔존가액", w: "100px", comma: true },
                { key: "startPrice", name: "기초장부가액", w: "100px", comma: true },
                { key: "depreLastPrice", name: "전기말상각누계", w: "100px", comma: true },
                { key: "t_deprePrice", name: "당기상각액", w: "100px", comma: true },
                { key: "endPrice", name: "기말장부가액", w: "100px", comma: true },
                { key: "deprePrice", name: "당기말상각누계", w: "100px", comma: true },
                { key: "depreciation", name: "감가상각법", w: "100px" },
                { key: "durableYear", name: "내용연수", w: "100px" },
                { key: "depreDrate", name: "상각율", w: "100px" },
                { key: "depreSRate", name: "감가상각비", w: "100px", comma: true },
            ],
            paging : {},
            states : states,
            conditions: [
                {name : '자산코드', key : 'assetCode', checked : true},
                {name : '자산명칭', key : 'assetName', checked : true},
                {name : '모델명', key : 'modelName', checked : true},
                {name : '시리얼', key : 'serial', checked : true},
                {name : '규격', key : 'standard', checked : true},
                {name : '취득가액/자존가액', key : 'np_price', checked : true},
                {name : '담당자/사용자', key : 'userName', checked : true},
                {name : '구매처', key : 'selling', checked : true},
                {name : '바코드', key : 'barcode', checked : true},
                {name : '추가항목1', key : 'field1', checked : true},
                {name : '추가항목2', key : 'field2', checked : true},
                {name : '추가항목3', key : 'field3', checked : true},
                {name : '추가항목4', key : 'field4', checked : true},
                {name : '추가항목5', key : 'field5', checked : true},
                {name : '추가항목6', key : 'field6', checked : true},
                {name : '추가항목7', key : 'field7', checked : true},
                {name : '추가항목8', key : 'field8', checked : true},
            ],
            years : [],
            months : [
                {name : '1', value : '1'},
                {name : '2', value : '2'},
                {name : '3', value : '3'},
                {name : '4', value : '4'},
                {name : '5', value : '5'},
                {name : '6', value : '6'},
                {name : '7', value : '7'},
                {name : '8', value : '8'},
                {name : '9', value : '9'},
                {name : '10', value : '10'},
                {name : '11', value : '11'},
                {name : '12', value : '12'}
            ],
            totalHistoryData : {}
        }
    },
    computed : {
        isAllowedForInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.INSERT)
        },
        isAllowedForUpdate(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.UPDATE)
        },
        isAllowedForDelete(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.DELETE)
        },
        isAllowedForExcel(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각, AUTH.EXCEL)
        },
        isAllowedForSettingSelect(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각_환경설정, AUTH.SELECT)
        },
        isAllowedForSettingInsert(){
            return this.$GlobalStore.isAllowedFor(AUTH.감가상각_환경설정, AUTH.INSERT)
        },
    },
    methods : {
        showCalendar(){
            this.isShowCalendar = true;
        },
        hideCalendar(){
            this.isShowCalendar = false;
        },
        completSelectDate(startDate, endDate){
            this.isBuyDateChecked = true;
            this.startDate = startDate;
            this.endDate = endDate;
            this.buyDate = startDate + ' ~ ' + endDate;
            this.hideCalendar();
        },
        deleteList(){

        },
        enter(){
            this.search();
        },
        search(){
            this.getDepreciationSpec(1, this.lastWithoutSearchWord);
        },
        detectStateChange(data){
            this.stateCode = data;
        },
        hideDepRegister(){
            this.isShowDepRegister = false;
        },
        hideDepTotalHistory(){
            this.isShowDepTotalHistory = false;
        },
        toggleBuyDateCheck(){
            this.isBuyDateChecked = !this.isBuyDateChecked;
        },
        getDepreciationSpec(page, withoutSearchWord){
            this.lastPage = page;
            this.lastWithoutSearchWord = withoutSearchWord; 
            
            const parameters = this.getParameters();
           
            axios.get('/api/depreciation/spec/list', {params : parameters}).then(res => {
                if(res.data.code == ResCode.Success){
                    const data = res.data.data
                    const depreciations = data.depreciations.content;
                    this.searchCnt = data.depreciations.totalElements;
                    data.depreciations.content = null;
                    this.list = depreciations;
                    this.paging = data.depreciations;   
                    this.startMonth = res.data.data.startMonth;

                    const startMonthStr = (this.startMonth < 10 ? '0' + this.startMonth : this.startMonth)
                    const thisMonthLastMoment = moment(this.thisYear + (this.thisMonth < 10 ? '0' + this.thisMonth : this.thisMonth) + '01', 'YYYYMMDD').endOf('months');

                    if(this.thisMonth >= this.startMonth){
                        this.deprePeriod = this.thisYear + '-' + startMonthStr + '-' + '01' + ' ~ ' + thisMonthLastMoment.format('YYYY-MM-DD');
                    }else {
                        this.deprePeriod = (this.thisYear - 1) + '-' + startMonthStr + '-' + '01 ~ '+ thisMonthLastMoment.format('YYYY-MM-DD');
                    }
                }
            }).catch(res => {
                console.log(res);
            });
        },
        getParameters(){
            const thisMonthLastMoment = moment(this.thisYear + (this.thisMonth < 10 ? '0' + this.thisMonth : this.thisMonth) + '01', 'YYYYMMDD').endOf('months');

            const parameters = {};
            parameters.page = this.lastPage;
            parameters.sizePerPage = this.lastSizePerPage;
            parameters.deleted = 0;
            parameters.dtEnd = thisMonthLastMoment.format('YYYY-MM-DD HH:mm:ss')
            this.dtEnd = parameters.dtEnd;

            let conditions = [];
            let searchWords = [];

            if(this.stateCode !== ''){
                conditions.push('stateCode');
                searchWords.push(this.stateCode);
            }

            if(this.locName != ''){
                conditions.push('np_locName');
                searchWords.push(this.locName);
            }

            if(this.itemName != ''){
                conditions.push('np_itemName');
                searchWords.push(this.itemName);
            }

            if(this.groupName != ''){
                conditions.push('np_groupName');
                searchWords.push(this.groupName);
            }

           if(this.searchWord != ''){
                ConditionManager.addConditions(conditions, searchWords, this.conditions, this.searchWord);
            }

            if(conditions.length > 0){
                parameters.conditions = conditions.join('::');
                parameters.searchWords = searchWords.join("::");
            }

            if(this.isBuyDateChecked){
                parameters.searchBeginDate = this.startDate;
                parameters.searchEndDate = this.endDate;
            }

            return parameters;
        },
        showDepreciationHistory(data){

            this.transferData = {
                totalHistoryData : data,
                dtEnd : this.dtEnd,
                deprePeriod : this.deprePeriod,
            }

            this.isShowDepTotalHistory = true;
        },
        downloadExcel(){
            const queryStr = new URLSearchParams(this.getParameters()).toString() + ColumnManager.getSettingsForExcel(this.$options.name, this.column);
            console.log("/api/depreciation/spec/list/excel?" + queryStr);
            location.href = this.$versionPath + "/api/depreciation/spec/list/excel?" + queryStr
        },
    },
    created(){
        const today = moment().format('YYYY-MM-DD');
        this.startDate = today;
        this.endDate = today;
        this.buyDate = today + ' ~ ' + today;

        const thisYear = parseInt(moment().format('YYYY'));
        this.thisYear = thisYear;
        this.thisMonth = parseInt(today.substr(5, 2)) + '';
 
        let years = [];
        for(let i = 0; i < 5; i++){
            years.push({name : thisYear - i, value : (thisYear - i) + ''});
        }

        this.years = years;

        this.getDepreciationSpec(1, false);

        this.$commonFunc.setFieldNames(this.conditions);
        ConditionManager.setConditions(this.$options.name, this.conditions);
        this.column = ColumnManager.getSettings(this.$options.name, this.column);
    }
    
}
</script>

<style scoped>
    .search_btn{
  text-align:left;position:relative;font-size:16px; font-weight:bold; background-color:#0066fd; color:white; border-radius:5px; cursor:pointer;width:114px;
      padding: 10px 12px;
    border:1px solid #e6e7ec;
    display:inline-block;
    margin-left:4px;
    vertical-align:bottom
  }
</style>
